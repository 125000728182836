import React, { useEffect, useState } from 'react';
import { pdfjs } from 'react-pdf';
import * as xlsx from 'xlsx'; // Import the XLSX library
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const XLSXViewer = ({ fileUrl }) => {
  const [htmlContent, setHtmlContent] = useState(null);

  const loadXLSX = async () => {
    try {
        
      // Load the XLSX file
      const response = await fetch(fileUrl);
      const blob = await response.blob();
      const reader = new FileReader();
      reader.onload = function(e) {
        const data = new Uint8Array(e.target.result);
        const workbook = xlsx.read(data, { type: 'array' });

        // Convert the first sheet to HTML
        const html = xlsx.utils.sheet_to_html(workbook.Sheets[workbook.SheetNames[0]]);
        setHtmlContent(html);
      };
      reader.readAsArrayBuffer(blob);
    } catch (error) {
      console.error('Error loading XLSX file:', error);
    }
  };

  // Call loadXLSX when component mounts
  useEffect(() => {
    loadXLSX();
  }, []);

  if (htmlContent) {
    return (
        <div dangerouslySetInnerHTML={{ __html: htmlContent }} />
      );  
  }
  return (
    <div>
        <span>No File Content Found</span>
        </div>
  );
};

export default XLSXViewer;
