import React, { useState, useEffect } from 'react';
import Bluebtn from './Btns/Bluebtn';

const CookieConsent = () => {
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        setIsVisible(true);
    }, []);

    const handleAccept = () => {
        setIsVisible(false);
    };

    if (!isVisible) return null;

    return (
        <div className="fixed bottom-4 right-4 bg-white border border-gray-300 shadow-lg rounded-lg p-6 max-w-sm w-full">
            <div className="flex items-start">
                {/* <div className="mr-4">
                    <svg className="w-6 h-6 text-yellow-500" fill="currentColor" viewBox="0 0 24 24">
                        <path d="M12 2C6.48 2 2 6.48 2 12c0 4.06 2.44 7.55 6 9.15V24l6-3.15C19.56 19.55 22 16.06 22 12c0-5.52-4.48-10-10-10zm-2.4 12.75c-.3.3-.85.29-1.18-.04-.3-.31-.29-.85.04-1.18.31-.3.85-.29 1.18.04.31.31.3.86-.04 1.18zm3.18 2.22c-.46.19-.98.26-1.49.19-.5-.08-.99-.28-1.42-.57-.45-.31-.87-.74-1.24-1.24-.29-.43-.49-.92-.57-1.42-.07-.51-.01-1.03.19-1.49.2-.46.51-.86.9-1.2.08-.07.16-.14.24-.2.5-.34 1.08-.56 1.68-.65.4-.07.81-.02 1.19.15.38.18.71.47.96.83.27.38.4.83.4 1.28 0 .79-.44 1.52-1.12 1.91-.23.13-.48.2-.74.2-.15 0-.31-.03-.45-.1-.43-.2-.73-.63-.73-1.1 0-.2.05-.4.14-.58.14-.25.4-.42.7-.45.22-.02.43.05.61.19.08.07.14.15.19.24.1.14.16.3.16.47 0 .26-.14.48-.36.61-.07.04-.15.07-.23.08.18-.07.36-.18.52-.33.1-.08.18-.17.25-.27.08-.09.15-.2.19-.31.14-.22.21-.47.21-.73 0-.44-.18-.87-.49-1.18-.32-.32-.73-.49-1.17-.49-.44 0-.86.17-1.18.49-.23.23-.36.54-.36.88 0 .27.1.53.29.72.07.07.14.14.22.21.11.1.22.2.34.29.02.02.03.04.05.06-.22-.11-.44-.25-.63-.41-.42-.33-.75-.75-1-1.2-.24-.44-.37-.91-.4-1.39-.02-.42.03-.85.15-1.26.09-.3.26-.58.48-.82.46-.49 1.11-.76 1.79-.76.62 0 1.22.2 1.71.58.54.42.94 1.01 1.15 1.68.26.78.24 1.64-.05 2.43-.26.69-.7 1.32-1.28 1.8-.27.22-.59.42-.92.57zm1.24-7.68c-.3.3-.85.29-1.18-.04-.3-.31-.29-.85.04-1.18.31-.3.85-.29 1.18.04.31.31.3.86-.04 1.18z" />
                    </svg>
                </div> */}
                <div className="flex-1">
                    <h3 className="text-lg font-semibold mb-2">Cookie Consent</h3>
                    <p className="text-gray-600 text-sm">
                        This website uses cookies or similar technologies, to enhance your browsing experience and provide personalized recommendations. By continuing to use our website, you agree to our{` `}
                        <a href="#" className="text-blue-500 underline">{`Privacy Policy`}</a>.
                    </p>
                </div>
            </div>
            <div className="mt-4 flex justify-end">
                <Bluebtn onClick={() => handleAccept()} className="px-4 py-2 text-[#ffffff]" Label={'Accept'}></Bluebtn>
            </div>
        </div>
    );
};

export default CookieConsent;
