import PageNotFound from "../../../pages/Pagenotfound"
import PostPage from "../../../pages/Post"
import Profile from "../../../pages/Profile"

const UserRoutes = {
    path: "user",
    children: [
        {
            path: "",
            element: <PageNotFound />,
        },
        {
            path: ":type/:id",
            element: <Profile />,
        },
        {
            path: "post/:id",
            element: <PostPage />,
        },
    ]
}
export default UserRoutes