import { LinkedInCallback } from "react-linkedin-login-oauth2"
import Login from "../../../Components/Auth/Login"
import ResetPassword from "../../../Components/Auth/Login/ResetPassword"
import Signup from "../../../Components/Auth/Signup"
import Home from "../../../pages/Website/Home"
import AdminLogin from "../../../Admin/Login"


const publicRoutes = [
    {
        path: "/login",
        element: <Login />,
    },
    {
        path: "/resetpassword",
        element: <ResetPassword />,
    },
    {
        path: "/home",
        element: <Home />,
    },
    {
        path: "/signup",
        element: <Signup />,
    },
    {
        path: "/linkedin",
        element: <LinkedInCallback />,
    },

]
export default publicRoutes