import { Menu, MenuButton, MenuItem } from '@szhsin/react-menu';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { icons } from '../../helpers/images';
import Toast from '../../helpers/Toast';
import autoInstance from '../../utils/axios';
import { selectUser } from '../../utils/reduxtoolkit/slices/userSlice';
import { Founder, Member, Pending } from '../Tags/Buttons';
import Bluebtn from './Btns/Bluebtn';
import CustomModal from './CustomModel';
import PageLoader from './PageLoader';
import UpdateCommunityCollaboration from '../CreateCommunityCollaboration/Update';


function CommunitiesCard({ item, updateContents }) {
    const { image, name, description, fullname, isActive, members, _id, userId } = item;
    const dispatch = useDispatch();
    const user = useSelector(selectUser);
    const [type, setType] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [isDeleteLoading, setIsDeleteLoading] = useState(false);
    useEffect(() => {
        const isPending = !isActive;
        const isFounder = item?.userId?._id === user?._id;
        const isMember = members.some(member => member?.memberId?._id === user?._id);

        setType(isPending ? "Pending" : isFounder ? "Founder" : isMember ? "Member" : "Join");
    }, [updateContents, item]);


    const joinCommunities = async () => {
        try {
            const response = await autoInstance.post(`community/${_id}/join-member`);
            if (response.status === 200) {
                Toast(response.data.message, "success");
                updateContents();
                setIsLoading(false);
            }
        } catch (error) {
            console.error(error);
        }
        finally {
            setIsLoading(false);
        }
    }



    const handleDelete = async (index) => {
        try {
            setIsDeleteLoading(true)
            const response = await autoInstance.delete(`/community/${item?._id}`)
            if (response.status === 200) {
                setIsDeleteLoading(false)
                updateContents()
                Toast("Community deleted successfully", "success")
            }
        } catch (error) {
            setIsDeleteLoading(false)
            console.error(error)
        };
    };


    const handleCopyLink = () => {
        const link = `${window.location.origin}/communities/details/${item?._id}`;
        if (navigator.clipboard && navigator.clipboard.writeText) {
            navigator.clipboard
                .writeText(link)
                .then(() => {
                    Toast("Link copied to clipboard:", link);
                    // You can provide feedback to the user that the link is copied successfully if needed
                })
                .catch((error) => {
                    Toast("Failed to copy link:", error);
                    // Handle error or provide feedback to the user
                });
        } else {
            const textArea = document.createElement("textarea");
            textArea.value = link;
            document.body.appendChild(textArea);
            textArea.select();
            try {
                document.execCommand("copy");
                Toast("Link copied to clipboard:", link);
            } catch (error) {
                Toast("Failed to copy link:", error);
            }
            document.body.removeChild(textArea);
        }
    };

    return (
        <div className='p-[8px] w-full rounded-[12px] bg-white hover:bg-[#EAF4FC] flex items-center justify-between gap-[8px] cursor-pointer border border-[#EFF3F4]'>
            {
                isDeleteLoading && <PageLoader />
            }
            <Link to={type === "Pending" ? null : `/communities/details/${_id}`} className='flex items-center justify-start gap-[8px] w-[calc(100%_-_105px)]'>
                {
                    image ?
                        <div className='w-[60px] h-[60px] rounded-[8px] overflow-hidden flex items-center justify-center'>
                            <img className='w-auto h-auto min-w-[60px] min-h-[60px] object-cover' src={process.env.REACT_APP_IMAGE_URL + image} alt={name} />
                        </div> :
                        <div className='w-[60px] h-[60px] rounded-[8px] overflow-hidden flex items-center justify-center' dangerouslySetInnerHTML={{ __html: icons?.defaultProfile }} />
                }
                <div className='flex flex-col justify-between w-[calc(100%_-_75px)]'>
                    <p className='text-[12px] font-medium text-[#0C1835] 3xl:pb-[8px] pb-[6px] whitespace-nowrap line-clamp-2 overflow-hidden text-ellipsis'>{name}</p>
                    <div>
                        <p className='text-[#6D7486] text-[10px] font-normal pb-[4px]'>{`${userId?.first_name ?? ""
                            } ${userId?.last_name ?? ""}`}</p>
                        <p className='text-[#0C1835] text-[10px] font-normal whitespace-nowrap line-clamp-2 overflow-hidden text-ellipsis'>{description}</p>
                    </div>
                </div>

            </Link>
            <div className='flex items-center justify-end gap-[8px]'>
                {
                    type === "Pending" && <Pending />
                }
                {
                    type === "Join" && <div onClick={() => { joinCommunities(); setIsLoading(true) }} className='py-[4px] px-[8px] w-fit leading-[13px] text-[#fff] bg-[#2A8DDE] border-[#2A8DDE] border rounded-[4px] text-[12px] font-normal'>
                        {isLoading ? 'Joining...' : 'Join'}
                    </div>
                }
                {
                    type === "Founder" && <Founder />
                }
                {
                    type === "Member" && <Member />
                }

                <Menu
                    direction={"bottom"}
                    align={"end"}
                    viewScroll={"close"}
                    menuButton={
                        <MenuButton>
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M9.99984 10.8332C10.4601 10.8332 10.8332 10.4601 10.8332 9.99984C10.8332 9.5396 10.4601 9.1665 9.99984 9.1665C9.5396 9.1665 9.1665 9.5396 9.1665 9.99984C9.1665 10.4601 9.5396 10.8332 9.99984 10.8332Z" stroke="#6D7486" strokeWidth="1.7" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M9.99984 4.99992C10.4601 4.99992 10.8332 4.62682 10.8332 4.16659C10.8332 3.70635 10.4601 3.33325 9.99984 3.33325C9.5396 3.33325 9.1665 3.70635 9.1665 4.16659C9.1665 4.62682 9.5396 4.99992 9.99984 4.99992Z" stroke="#6D7486" strokeWidth="1.7" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M9.99984 16.6667C10.4601 16.6667 10.8332 16.2936 10.8332 15.8333C10.8332 15.3731 10.4601 15 9.99984 15C9.5396 15 9.1665 15.3731 9.1665 15.8333C9.1665 16.2936 9.5396 16.6667 9.99984 16.6667Z" stroke="#6D7486" strokeWidth="1.7" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                        </MenuButton>
                    }
                    transition
                >
                    {type === "Join" &&
                        <>
                            <MenuItem onClick={handleCopyLink}>
                                <span className="!border-[0px]">
                                    Copy Link
                                </span>
                            </MenuItem>
                        </>
                    }
                    {type === "Founder" &&
                        <>
                            <MenuItem onClick={handleCopyLink}>
                                <span className="!border-[0px]">
                                    Copy Link
                                </span>
                            </MenuItem>
                            <MenuItem >
                                <span onClick={() => { setShowEditModal(true) }} >
                                    Edit
                                </span>
                            </MenuItem>
                            <MenuItem onClick={() => { setShowDeleteModal(true) }} >
                                <span>
                                    Delete
                                </span>
                            </MenuItem>
                        </>
                    }
                    {type === "Member" &&
                        <>
                            <MenuItem onClick={handleCopyLink}>
                                <span className="!border-[0px]">
                                    Copy Link
                                </span>
                            </MenuItem>
                            {/* <MenuItem>Leave Community</MenuItem> */}
                        </>
                    }
                    {type === "Pending" &&
                        <>
                            <MenuItem onClick={() => { setShowEditModal(true) }}>
                                <span className="!border-[0px]">
                                    Edit
                                </span>
                            </MenuItem>
                            <MenuItem onClick={() => { setShowDeleteModal(true) }}>
                                <span>
                                    Delete
                                </span>
                            </MenuItem>
                        </>
                    }

                </Menu>
                {/* Edit Model */}
                <CustomModal bodyClassName=" rounded-[16px] !overflow-visible " showModal={showEditModal} setShowModal={setShowEditModal}  >
                    <UpdateCommunityCollaboration pageTitle={"Community"} setCreateNew={setShowEditModal} Data={item} updateData={updateContents} />
                </CustomModal>
                {/* Edit Model */}
                {/* Delete Model   */}
                <CustomModal bodyClassName=" rounded-[16px] " showModal={showDeleteModal} setShowModal={setShowDeleteModal} >
                    <div class="w-full h-full  bg-white shadow-lg rounded-[16px] 2sm:p-[24px] xsm:p-[20px] p-[15px] relative ">
                        <div className='flex items-center justify-between'>
                            <h1 className='2lg:text-[26px] 2sm:text-[24px] md:text-[22px] text-[20px]  leading-[normal] font-[600]'>Delete {item?.name} Community</h1>
                            <div onClick={() => { setShowDeleteModal(false); }} className='cursor-pointer ' dangerouslySetInnerHTML={{ __html: icons.closeIcon }} />
                        </div>
                        <p className='text-[#21272A] font-normal text-[12px] leading-[140%] py-[10px]'>Are you sure you want to delete this community?</p>
                        <div className='flex justify-end'>
                            <Bluebtn className="text-[14px] font-medium py-[8px] px-[16px] w-fit mt-[16px]  text-white bg-[#6D7486]" Label="Delete" onClick={() => { handleDelete(); setShowDeleteModal(false) }} />
                        </div>
                    </div>
                </CustomModal>
                {/* Delete Model  */}

            </div>
        </div >
    )
}

export default CommunitiesCard