import { Menu } from 'primereact/menu';
import { classNames } from 'primereact/utils';
import React, { useEffect, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { icons } from '../../helpers/images';
import { useSocket } from '../Socket/WebSocketContext';

function ChatHeader({ selectedUser, handleUserSelect }) {
    const menuRight = useRef();
    const { onDeleteAllMessages, onBlockUser, onUnBlockUser, socket } = useSocket();
    const navigate = useNavigate();
    const [updateLastMessage, setUpdateLastMessage] = useState(null)

    useEffect(() => {
        if (socket) {
            socket.on(`last-message-receive-${selectedUser?.conversation}`, (data) => {
                setUpdateLastMessage(data)
            });
        }
    }, [socket, selectedUser])

    const viewProfile = () => {
        navigate(`/user/in/${selectedUser?._id}`);
    }


    const items = [
        {
            items: [
                {
                    command: () => {
                        navigate(`/user/in/${selectedUser?._id}`);
                    },
                    template: (item, options) => {
                        return (
                            <div
                                onClick={(e) => options.onClick(e)}
                                className={classNames(
                                    options.className,
                                    "mx-[10px] mt-[10px] p-[12px] rounded-[8px] transition-all duration-300 hover:bg-[#EFF3F4]"
                                )}
                            >

                                <p className="text-[16px] font-medium text-[#0C1835]">
                                    View Profile
                                </p>
                            </div>
                        );
                    },
                },
                {
                    command: async () => {
                        if (selectedUser?.isBlocked) {
                            onUnBlockUser(selectedUser?._id, (data) => {
                                handleUserSelect({ ...selectedUser, isBlocked: false })
                            })
                        } else {
                            onBlockUser(selectedUser?._id, (data) => {
                                handleUserSelect({ ...selectedUser, isBlocked: true })
                            })
                        }
                    },
                    template: (item, options) => {
                        return (
                            <div
                                onClick={(e) => options.onClick(e)}
                                className={classNames(
                                    options.className,
                                    "mx-[10px] p-[12px] rounded-[8px] transition-all duration-300 hover:bg-[#EFF3F4]"
                                )}
                            >

                                <p className="text-[16px] font-medium text-[#0C1835]">{selectedUser?.isBlocked ? "UnBlock User" : "Block User"}</p>
                            </div>
                        );
                    },
                },
                {
                    command: () => {
                        onDeleteAllMessages(selectedUser?.conversation)
                    },
                    template: (item, options) => {
                        return (
                            <div
                                onClick={(e) => options.onClick(e)}
                                className={classNames(
                                    options.className,
                                    "mx-[10px] mb-[10px]  p-[12px] rounded-[8px] transition-all duration-300 hover:bg-[#EFF3F4]"
                                )}
                            >

                                <p className="text-[16px] font-medium text-[#0C1835]">Delete all messages</p>
                            </div>
                        );
                    },
                },
            ],
        },
    ];
    return (
        <div className={`flex items-center justify-start 2sm:gap-[.625rem] gap-[6px]  relative 2lg:py-[16px] py-[12px] 2lg:px-[1.25rem] px-[12px]  transition-all duration-300  translate-x-0 border-b border-[#EBEBEB] `}>
            <div className='2md:hidden block' onClick={() => { handleUserSelect({}) }}>

                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M15 18L9 12L15 6" stroke="#6D7486" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                </svg>

            </div>
            <div className="w-[calc(100%_-_50px)] overflow-hidden text-ellipsis">
                <Link to={`/user/in/${selectedUser?._id}`} className="">
                    <p className='2md:text-[20px] md:text-[18px] sm:text-[16px] font-semibold   leading-[110%]'>{`${selectedUser?.first_name ?? 'No Name'} ${selectedUser?.last_name ?? ''}`}</p>
                    {/* {
                        updateLastMessage ?
                            <p className=pt-[8px] text-[14px] font-normal leading-[140%] text-[#6D7486]'>{updateLastMessage?.content} {moment(updateLastMessage?.createdAt).format('LTS')}</p>
                            :
                            <p className='pt-[8px] text-[14px] font-normal leading-[140%] text-[#6D7486]'></p>
                    } */}
                </Link>
            </div>
            <div>
                <div className="">
                    {/* <div
                        onClick={(event) => menuRight.current.toggle(event)}
                        aria-controls="Chat_menu"
                        aria-haspopup
                        className='' dangerouslySetInnerHTML={{ __html: icons.options }} /> */}

                    <div className="">
                        <div
                            onClick={(event) => menuRight.current.toggle(event)}
                            aria-controls="Chat_menu"
                            aria-haspopup
                            className='!cursor-pointer' dangerouslySetInnerHTML={{ __html: icons.options }} />
                        <Menu
                            model={items}
                            popup
                            ref={menuRight}
                            id="Chat_menu"
                            className="profile_menu_right_hide "
                            popupAlignment="right"
                        />
                    </div>

                    {/* <Menu
                        direction={"bottom"}
                        align={"end"}
                        menuButton={
                            <MenuButton>
                                <div className='' dangerouslySetInnerHTML={{ __html: icons.options }} />
                            </MenuButton>
                        }
                    >
                        <MenuItem tabIndex={0} onClick={() => {
                            if (selectedUser?.isBlocked) {
                                onUnBlockUser(selectedUser?._id)
                            } else {
                                onBlockUser(selectedUser?._id)
                            }
                        }}>{selectedUser?.isBlocked ? 'Un Block' : 'Block'}</MenuItem>
                        <MenuItem tabIndex={0} onClick={() => { viewProfile() }}>View Profile</MenuItem>

                        <MenuItem tabIndex={1} onClick={() => {
                            onDeleteAllMessages(selectedUser?.conversation)
                        }}>Delete All Messages</MenuItem>

                    </Menu> */}
                </div>
            </div>
        </div>
    );
}

export default ChatHeader;
