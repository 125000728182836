import CommunityCollaborationLayout from '../../layouts/CommunityCollaborationLayout'

function Communities() {
  return (
    <CommunityCollaborationLayout
    pageTitle={"Communities"}
    listUrl={"Community"}
    FilterUrl={"Community"} />
  )
}

export default Communities