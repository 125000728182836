import { Menu, MenuButton, MenuItem } from '@szhsin/react-menu';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { icons, images } from '../../helpers/images';
import { selectUser } from '../../utils/reduxtoolkit/slices/userSlice';
import Bluebtn from '../Common/Btns/Bluebtn';
import CustomModal from '../Common/CustomModel';
import autoInstance from '../../utils/axios';
import Toast from '../../helpers/Toast';

function Showcase({ item, index, disableClick, isSingle, showDelete, UpdateData, HomeGridTrue }) {
    const [show, setShow] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const StoreUser = useSelector(selectUser)
    const user = item?.userId ? item?.userId : StoreUser
    const handleClick = () => {
        setShow(true);
    };
    const ShowcaseDelete = async () => {
        try {
            const res = await autoInstance.delete(`/show-case/${item?._id}`)
            if (res.status === 200) {
                UpdateData()
                Toast(res.data.message, 'success')
                setShowDeleteModal(false)
            }
        }
        catch (err) {
            console.error(err)
        }
    }
    return (
        <div className={`${isSingle ? "w-full" : ""}`}>
            <div className={`p-[8px] border border-[#EFF3F4] rounded-[12px] bg-white  cursor-pointer ${HomeGridTrue ? "" : isSingle ? "w-full" : "max-w-[310px] mx-[8px] "} `} >
                <div className={`rounded-[8px] overflow-hidden flex items-center justify-center ${showDelete ? "relative" : ""}  ${isSingle ? "max-h-[500px]" : "h-[150px]"}   `}>
                    {showDelete &&
                        <div className='absolute top-[10px] right-[10px] flex-center w-[24px] h-[24px] rounded-md bg-[#00000050] '>
                            <Menu
                                direction={"bottom"}
                                align={"end"}
                                viewScroll={"close"}
                                menuButton={
                                    <MenuButton>
                                        {/* only show in other user */}
                                        <svg
                                            width="16"
                                            height="16"
                                            viewBox="0 0 16 16"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                d="M8.00016 8.66683C8.36835 8.66683 8.66683 8.36835 8.66683 8.00016C8.66683 7.63197 8.36835 7.3335 8.00016 7.3335C7.63197 7.3335 7.3335 7.63197 7.3335 8.00016C7.3335 8.36835 7.63197 8.66683 8.00016 8.66683Z"
                                                stroke="#FFFFFF"
                                                strokeWidth="1.4"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                            />
                                            <path
                                                d="M8.00016 4.00008C8.36835 4.00008 8.66683 3.7016 8.66683 3.33341C8.66683 2.96522 8.36835 2.66675 8.00016 2.66675C7.63197 2.66675 7.3335 2.96522 7.3335 3.33341C7.3335 3.7016 7.63197 4.00008 8.00016 4.00008Z"
                                                stroke="#FFFFFF"
                                                strokeWidth="1.4"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                            />
                                            <path
                                                d="M8.00016 13.3333C8.36835 13.3333 8.66683 13.0349 8.66683 12.6667C8.66683 12.2985 8.36835 12 8.00016 12C7.63197 12 7.3335 12.2985 7.3335 12.6667C7.3335 13.0349 7.63197 13.3333 8.00016 13.3333Z"
                                                stroke="#FFFFFF"
                                                strokeWidth="1.4"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                            />
                                        </svg>
                                    </MenuButton>
                                }
                                transition
                            >
                                <MenuItem>
                                    <span className='!border-[0px]' onClick={() => setShowDeleteModal(true)} >
                                        Delete
                                    </span>
                                </MenuItem>
                            </Menu>
                        </div>
                    }
                    <img onClick={disableClick ? null : handleClick} src={`${item?.uploadMedia ? process.env.REACT_APP_IMAGE_URL + item?.uploadMedia : images.Marbonate}`} className='w-full h-full object-cover' alt="post" />
                </div>
                <div onClick={disableClick ? null : handleClick} className=' border-b-[#EFF3F4] border-b pb-[8px] mb-[8px] pt-[12px] '>
                    <p className='text-[#0C1835] text-[16px] font-medium pb-[4px]'>{item?.title ? item?.title : "Marbonate Products Inc."}</p>
                    <p className='text-[12px] font-normal text-[#6D7486] md:h-[42px] h-[32px] overflow-hidden text-ellipsis'>
                        {item?.description ? item?.description : "Convert your captured carbon onsite into commercial grade flooring tiles."}
                    </p>
                </div>
                <div className='flex items-center justify-between'>
                    {user &&
                        <div className='flex items-center justify-start gap-[6px] '>
                            {
                                user?.profileImage ?
                                    <div className='h-[28px] w-[28px] rounded-[6px]   overflow-hidden'>
                                        <img src={process.env.REACT_APP_IMAGE_URL + user?.profileImage} className='w-full h-full object-cover' alt="profile" />
                                    </div> :
                                    <div className='flex-center h-[28px] w-[28px] rounded-[8px]' dangerouslySetInnerHTML={{ __html: icons.defaultProfile }} />
                            }

                            <div>
                                <p className='text-[12px] font-medium text-[#0C1835]'>{`${user?.first_name ?? ""} ${user?.last_name ?? ""}`}</p>
                                <p className='text-[10px] text-[#6D7486] font-normal'>{user?.iam}</p>
                            </div>
                        </div>
                    }
                    <div>
                        {
                            item?.companyLogo ?
                                <img className='max-w-[65px] w-[auto] h-[auto]
                            max-h-[28px]' src={process.env.REACT_APP_IMAGE_URL + item?.companyLogo} alt="logo" /> :
                                <img className='max-w-[65px] w-[auto] h-[auto]
                            max-h-[28px]' src={images.MarbonateLogo} alt="logo" />
                        }
                    </div>
                </div>
            </div>
            <CustomModal bodyClassName=" rounded-[16px] " showModal={show} setShowModal={setShow}  >
                <ShowcaseDetails Data={item} setIsOpen={setShow} />
            </CustomModal>

            <CustomModal showModal={showDeleteModal} setShowModal={setShowDeleteModal} bodyClassName={`rounded-[16px]  max-w-[90vw]`} >
                <div class="w-full h-full  bg-white shadow-lg rounded-[16px] 2sm:p-[24px] xsm:p-[20px] p-[15px] relative ">
                    <div className='flex items-center justify-between'>
                        <h1 className='2lg:text-[26px] 2sm:text-[24px] md:text-[22px] text-[20px] leading-[normal] font-[600]'>Delete Showcase</h1>
                        <div onClick={() => { setShowDeleteModal(false); }} className='cursor-pointer ' dangerouslySetInnerHTML={{ __html: icons.closeIcon }} />
                    </div>
                    <p className='text-[#21272A] font-normal text-[12px] leading-[140%] py-[10px]'>Are you sure you want to delete this showcase?</p>
                    <div className='flex justify-end gap-[10px]'>
                        <Bluebtn className="text-[14px] font-medium py-[8px] px-[16px] w-fit mt-[16px]  text-[#6D7486] border bg-[#FFF] border-[#6D7486] hover:bg-[#6D7486] hover:text-white" Label="Cancel" onClick={() => { setShowDeleteModal(false) }} />
                        <Bluebtn className="text-[14px] font-medium py-[8px] px-[16px] w-fit mt-[16px]  text-white bg-[#6D7486] hover:bg-[#6D7486] hover:text-white" Label="Delete" onClick={() => { ShowcaseDelete(); setShowDeleteModal(false) }} />
                    </div>
                </div>
            </CustomModal>
        </div>
    )
}

export default Showcase



const ShowcaseDetails = ({ Data, setIsOpen }) => {
    return (
        <>
            <div className='2sm:p-[24px] xsm:p-[20px] p-[15px]'>
                <div className='flex items-center justify-between gap-[16px]'>
                    <h1 className='text-[21px] leading-[normal] font-[600]'>{Data?.title}</h1>
                    <div onClick={() => { setIsOpen(false); }} className='cursor-pointer ' dangerouslySetInnerHTML={{ __html: icons.closeIcon }} />
                </div>
                <div className='my-[14px] '>
                    <div className='max-h-[210px] h-full w-full overflow-hidden rounded-[8px] flex-center'>
                        <img className='w-full h-full ' src={` ${Data?.uploadMedia ? process.env.REACT_APP_IMAGE_URL + Data?.uploadMedia : images.GHG}`} alt={Data?.title} />
                    </div>

                </div>
                <div className='py-[10px]'>
                    <pre className='caption-text text-[14px] font-normal leading-[140%] mt-[10px] text-[#000] w-full caption-text'>{Data?.description}</pre>
                </div>
                <div className='flex items-center justify-start gap-[10px]'>
                    {Data?.siteUrl && <Link to={Data?.siteUrl} target="_blank" rel="noreferrer" className='text-[#2A8DDE] text-[14px] leading-[24px] font-[500]'>{Data?.siteUrl}</Link>}
                </div>

            </div>
        </>
    )
}