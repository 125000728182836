import { useClickOutside } from "primereact/hooks";
// import { Menu } from 'primereact/menu';
import { Menu, MenuButton, MenuItem } from "@szhsin/react-menu";
import "@szhsin/react-menu/dist/index.css";
import "@szhsin/react-menu/dist/transitions/slide.css";
import React, { useEffect, useImperativeHandle, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { icons } from "../../helpers/images";
import Toast from "../../helpers/Toast";
import autoInstance from "../../utils/axios";
import { selectUser } from "../../utils/reduxtoolkit/slices/userSlice";
import Bluebtn from "../Common/Btns/Bluebtn";
import CustomModal from "../Common/CustomModel";
import EditPost from "../EditPost/EditPost";

function Post({ PostData, updateData, isDetailsPage }) {

  const [item, setItem] = useState(PostData || {});


  useEffect(() => {
    setItem(PostData || {})
  }, [PostData])
  const user = useSelector(selectUser);
  const [comment, setComment] = useState("");
  const [comments, setComments] = useState([]);
  const [showMore, setShowMore] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const contentRef = useRef(null);
  const {
    _id,
    uploadMedia,
    image,
    name,
    events,
    Community,
    is_mark_question,
    description,
    response = [{}, {}],
    likes,
  } = item;
  const customStyles = {
    menu: {
      borderRadius: '1rem',
    },
    menuItem: {
      fontSize: '1rem',
      fontWeight: '500',
    },
  };

  useEffect(() => {
    const content = contentRef.current;
    if (content) {
      setShowMore(content.scrollHeight > content.clientHeight);
    }
  }, [description]);

  const toggleShowMore = () => {
    setExpanded(!expanded);
  };

  const PostUser = item?.userId;
  const commentsData = item?.comments;
  const location = useLocation();
  const pathName = location.pathname;
  const isUserPostPath = location.pathname.startsWith('/user/post/');
  const [isMyPost, setIsMyPost] = useState(false);
  const menuRight = useRef(null);
  const DeleteMenu = useRef(null);
  const [liked, setLiked] = useState(false);
  const [reponseliked, setResponseLiked] = useState(false);
  const [showResponse, setShowResponse] = useState(isUserPostPath ? true : false);
  const [countLikes, setCountLikes] = useState();
  const [likedStatus, setLikedStatus] = useState({});
  const [showDelete, setShowDelete] = useState(false);
  const [isDeleteLoading, setIsDeleteLoading] = useState(false);
  const customInputRef = useRef(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [showDeleteCommentModal, setShowDeleteCommentModal] = useState(false);
  const [deleteCommentId, setDeleteCommentId] = useState();
  const [isLikeLoading, setLikeLoading] = useState(false)
  const [commentLoading, setCommentLoading] = useState(false)
  const [showEditModal, setShowEditModal] = useState(false)
  useEffect(() => {
    setIsMyPost(user?._id === PostUser?._id);
    const userLiked = likes?.some((like) => like?._id === user?._id);
    setLiked(userLiked);
    setCountLikes(likes?.length);
  }, [user, PostUser, likes]);
  useEffect(() => {
    const userLiked = likes?.some((like) => like?._id === user?._id);
    setLiked(userLiked);
    setCountLikes(likes?.length);
  }, [PostData])
  // Assuming you have a state or variable to store all comments
  const allComments = item?.comments?.slice().reverse();

  // Assuming you have a variable to store the last comment
  const lastTwoComments = allComments && allComments.length >= 0 ? allComments.slice(0, 2) : [];

  // Check if the current path is /user/post/{id}


  const AllCommunity = item?.postJoin?.find(
    (item) => item.type === "Community"
  );
  const AllCollaboration = item?.postJoin?.find(
    (item) => item.type === "Collaboration"
  );
  const handleLikeClick = async () => {
    try {
      setLikeLoading(true);
      const response = await autoInstance.put(`/post/${_id}/like`);

      if (response.status === 200) {
        // Toggle the liked state
        const updatedLiked = !liked;
        setLiked(updatedLiked);
        setLikeLoading(false);

        // Update the count of likes locally without fetching all likes again
        setCountLikes((prevCount) => (updatedLiked ? prevCount + 1 : prevCount === 0 ? 0 : prevCount - 1));
      }
    } catch (err) {
      setLikeLoading(false);
      console.error(err);
    }
  };

  const [visible, setVisible] = useState(false);
  const overlayRef = useRef(null);
  const op = useRef(null);

  useClickOutside(overlayRef, () => {
    setVisible(false);
  });


  const dateString = events && events[0]?.date;
  const timeString = events && events[0]?.time;

  const eventDate = new Date(dateString);

  const formattedDate = `${eventDate.getMonth() + 1}/${eventDate.getDate()}/${eventDate.getFullYear()}`;

  const formattedTime = new Date(`2000-01-01T${timeString}`).toLocaleTimeString('en-US', { hour: 'numeric', minute: '2-digit' });

  const formattedDateTime = `${formattedDate}, ${events && events[0]?.time}`;


  useEffect(() => {
    if (showResponse && customInputRef.current) {
      customInputRef.current.focusInput();
    }
  }, [showResponse]);


  const postDataUpdate = async () => {
    try {
      const response = await autoInstance.get(`/post/${_id}`);
      if (response.status === 200) {
        setItem(response.data.data)
      }
    } catch (error) {
      console.error("Error updating post:", error.message);
    }
  };

  // Function to handle user logout

  const handleInputChange = (event) => {
    setComment(event.target.value);
  };


  const handlePostDelete = async () => {
    try {
      const response = await autoInstance.delete(`/post/${_id}`);

      if (response.status === 200) {
        updateData && updateData();
        Toast("Post deleted successfully");
      }
    } catch (error) {
      console.error("Error deleting post:", error.message);
    }
  };




  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      createComment();
    }
  };


  const myCommentOptions = [
    {
      label: "Delete",
    },
  ];
  const handleCopyLink = () => {
    const link = `${window.location.origin}/user/post/${_id}`;
    if (navigator.clipboard && navigator.clipboard.writeText) {
      navigator.clipboard
        .writeText(link)
        .then(() => {
          Toast("Link copied to clipboard:", link);
          // You can provide feedback to the user that the link is copied successfully if needed
        })
        .catch((error) => {
          Toast("Failed to copy link:", error);
          // Handle error or provide feedback to the user
        });
    } else {
      const textArea = document.createElement("textarea");
      textArea.value = link;
      document.body.appendChild(textArea);
      textArea.select();
      try {
        document.execCommand("copy");
        Toast("Link copied to clipboard:", link);
      } catch (error) {
        Toast("Failed to copy link:", error);
      }
      document.body.removeChild(textArea);
    }
  };


  function getTimeAgo(dateString) {
    const date = new Date(dateString);
    const now = new Date();
    const timeDiff = now - date;
    const seconds = Math.floor(timeDiff / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);
    const weeks = Math.floor(days / 7);

    if (weeks > 0) {
      return weeks === 1 ? "1w" : `${weeks}w`;
    } else if (days > 0) {
      return days === 1 ? "1d" : `${days}d`;
    } else if (hours > 0) {
      return hours === 1 ? "1h" : `${hours}h`;
    } else if (minutes > 0) {
      return minutes === 1 ? "1m" : `${minutes}m`;
    } else {
      return "Just now";
    }
  }

  const createComment = async () => {
    try {
      setCommentLoading(true);
      const response = await autoInstance.post(`/post/${_id}/comment`, {
        content: comment,
      });
      if (response.status === 200) {
        setComment("");
        postDataUpdate();
        setCommentLoading(false);
        Toast("Comment added successfully");
      }
    } catch (error) {
      setCommentLoading(false);
      console.error("Error creating comment:", error.message);
    }
  };

  const handleCommentDelete = async () => {
    try {
      const response = await autoInstance.delete(`/comments/${deleteCommentId}`);
      postDataUpdate();
      if (response.status === 200) {
        updateData && updateData();
        setDeleteCommentId(null);
        Toast("Comment deleted successfully");
      }
    } catch (error) {
      setDeleteCommentId(null);
      postDataUpdate();
      console.error("Error deleting comment:", error.message);
    }
  };

  const updateLikedStatus = () => {
    const newLikedStatus = {};
    item?.comments?.likes?.forEach((like) => {
      newLikedStatus[like.id] = like.id === user?._id; // Set liked status for each comment ID
    });
    setLikedStatus(newLikedStatus);
  };

  // Fetch comments and update liked status when item changes
  useEffect(() => {
    updateLikedStatus();
  }, [item]);

  const handleResponseLike = async (commentId) => {
    try {
      updateLikedStatus();
    } catch (error) {
      console.error("Error liking comment:", error.message);
    }
  };

  const handleEdit = async () => {
    try {

    } catch (error) {

    }
  };



  const inputRef = useRef(null);
  useImperativeHandle(customInputRef, () => ({
    focusInput: () => {
      inputRef.current.focus();
    }
  }));
  return (
    <div className={` relative 4lg:w-[660px] 2lg:w-[570px] 2md:w-[420px] w-full mx-auto pb-[16px] rounded-[16px] bg-white border border-[#EFF3F4] ${isUserPostPath ? 'max-h-full h-fit overflow-auto hide-scrollbar  ' : ''}  `}>
      <div className={` ${isUserPostPath ? 'bg-white sticky top-0' : ''} pt-[16px]`}>
        <div className="flex items-center justify-between gap-[12px] mb-[12px] px-[16px]">
          <div
            to={`/user/in/${PostUser?._id}`}
            className="flex items-center justify-start gap-[12px] sm:w-[calc(100%-100px)] w-[calc(100%-60px)]"
          >
            {PostUser?.profileImage ? (
              <Link
                to={`/user/in/${PostUser?._id}`}>
                <div className="w-[40px] h-[40px] flex items-center justify-center rounded-[8px] overflow-hidden border border-[#EFF3F4]">
                  <img
                    className="h-full w-full object-cover"
                    src={process.env.REACT_APP_IMAGE_URL + PostUser?.profileImage}
                    alt="Profile image"
                  />
                </div>
              </Link>
            ) : (
              <Link
                to={`/user/in/${PostUser?._id}`}>
                <div
                  className="w-[40px] h-[40px] flex items-center justify-center rounded-[8px] overflow-hidden border border-[#EFF3F4]"
                  dangerouslySetInnerHTML={{ __html: icons.defaultProfile }}
                ></div>
              </Link>
            )}
            <div className="w-[calc(100%-60px)]">
              <div className="text-[#0C1835] flex flex-wrap items-center justify-start gap-[4px] text-[14px] font-medium leading-[110%] pb-[3px]">
                <Link
                  to={`/user/in/${PostUser?._id}`}>
                  <p className="text-[14px] font-medium leading-[110%] whitespace-nowrap">
                    {`${PostUser?.first_name ?? ""} ${PostUser?.last_name ?? ""}`}
                  </p>
                </Link>
                {
                  (item?.postJoin?.length > 1 || AllCommunity?.community || AllCollaboration?.collaboration) &&
                  <div className="flex flex-wrap items-center justify-start gap-[4px]" >
                    {(item?.postJoin?.length > 1 || AllCommunity?.community || AllCollaboration?.collaboration) && (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="3"
                        height="4"
                        viewBox="0 0 3 4"
                        fill="none"
                      >
                        <circle cx="1.5" cy="2" r="1.5" fill="#B7BCCA" />
                      </svg>
                    )}

                    {
                      AllCommunity?.community ?
                        <>
                          {AllCommunity?.community?.name && (
                            <Link
                              to={`/communities/details/${AllCommunity?.community?._id}`} className="text-[10px] font-normal leading-[140%]">
                              {AllCommunity?.community?.name}
                            </Link>
                          )}
                        </> :
                        <>
                          {AllCollaboration?.collaboration?.name && (
                            <Link
                              to={`/collaborations/details/${AllCollaboration?.collaboration?._id}`} className="text-[10px] font-normal leading-[140%]">
                              {AllCollaboration?.collaboration?.name}
                            </Link>
                          )}
                        </>
                    }

                  </div>
                }
              </div>
              <p className="text-[#6d7486] text-[12px] font-medium leading-[110%]">
                {PostUser?.iam}
              </p>
            </div>
          </div>
          <div className="flex items-center justify-end gap-[8px]">
            <p className="text-[10px] font-normal leading-[140%]">
              {getTimeAgo(item?.createdAt)}
            </p>
            <Menu
              direction={"bottom"}
              align={"end"}
              viewScroll={"close"}
              menuButton={
                <MenuButton className="">
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8.00016 8.66683C8.36835 8.66683 8.66683 8.36835 8.66683 8.00016C8.66683 7.63197 8.36835 7.3335 8.00016 7.3335C7.63197 7.3335 7.3335 7.63197 7.3335 8.00016C7.3335 8.36835 7.63197 8.66683 8.00016 8.66683Z"
                      stroke="#6D7486"
                      strokeWidth="1.4"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M8.00016 4.00008C8.36835 4.00008 8.66683 3.7016 8.66683 3.33341C8.66683 2.96522 8.36835 2.66675 8.00016 2.66675C7.63197 2.66675 7.3335 2.96522 7.3335 3.33341C7.3335 3.7016 7.63197 4.00008 8.00016 4.00008Z"
                      stroke="#6D7486"
                      strokeWidth="1.4"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M8.00016 13.3333C8.36835 13.3333 8.66683 13.0349 8.66683 12.6667C8.66683 12.2985 8.36835 12 8.00016 12C7.63197 12 7.3335 12.2985 7.3335 12.6667C7.3335 13.0349 7.63197 13.3333 8.00016 13.3333Z"
                      stroke="#6D7486"
                      strokeWidth="1.4"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </MenuButton>}
              styles={customStyles.menu}  // Apply custom styles to Menu
              transition
            >
              {isMyPost ? (
                <>
                  <MenuItem
                    onClick={() => setShowEditModal(true)}
                    styles={customStyles.menuItem}  // Apply custom styles to MenuItem
                  >
                    <span className="!border-[0px]">
                      Edit Post
                    </span>
                  </MenuItem>
                  <MenuItem
                    onClick={() => setShowDeleteModal(true)}
                    styles={customStyles.menuItem}  // Apply custom styles to MenuItem
                  >
                    <span >
                      Delete Post
                    </span>
                  </MenuItem>
                  <MenuItem
                    onClick={handleCopyLink}
                    styles={customStyles.menuItem}  // Apply custom styles to MenuItem
                  >
                    <span>
                      Copy Link
                    </span>
                  </MenuItem>
                </>
              ) : (
                <>
                  <MenuItem
                    onClick={handleCopyLink}
                    styles={customStyles.menuItem}  // Apply custom styles to MenuItem
                  >
                    <span className="!border-[0px]">
                      Copy Link
                    </span>
                  </MenuItem>
                  {/* <MenuItem
                    styles={customStyles.menuItem}  // Apply custom styles to MenuItem
                  >
                    Report Post
                  </MenuItem> */}
                </>
              )}
            </Menu>
          </div>
        </div>
        <div>
          <div className="px-[16px] 2sm:pb-[16px] pb-[12px]">
            {/* Post type */}
            {is_mark_question && !events.date && (
              <p className="pb-[7px] text-[#2A8DDE] font-normal text-[12px]">
                I’m looking for an answer to:
              </p>
            )}
            {/* Captions */}
            {events && events[0]?.date && <p className="text-[#2A8DDE] pb-[7px] font-normal text-[12px]">Event on : {formattedDateTime}</p>}
            {
              description === "null" || description === "" || description === null || description === undefined ? (
                <></>
              ) : (
                <div>
                  {
                    isDetailsPage ?
                      <div
                        className={`text-[#21272A] font-normal text-[14px] leading-[140%]`}
                      >
                        <pre ref={contentRef}
                          className={`caption-text ${expanded ? '' : uploadMedia ? 'text-ellipsis-desc' : 'text-ellipsis-200h'
                            }`}>{description}</pre>
                      </div> :
                      <Link to={`/user/post/${_id}`}
                        className={`text-[#21272A] font-normal text-[14px] leading-[140%]`}
                      >
                        <pre ref={contentRef}
                          className={`caption-text ${expanded ? '' : uploadMedia ? 'text-ellipsis-desc' : 'text-ellipsis-200h'
                            }`}>{description}</pre>
                      </Link>
                  }
                  {showMore && (
                    <span className="text-[#2A8DDE] font-normal text-[14px] cursor-pointer" onClick={toggleShowMore}>
                      {expanded ? 'Show less.' : 'Show more.'}
                    </span>
                  )}
                </div>
              )
            }
          </div>

          {uploadMedia && (
            <Link to={`/user/post/${_id}`} className="">
              <div className=" overflow-hidden max-h-[710px] max-w-[100%] h-full relative 2sm:mb-[16px] mb-[12px]">
                <div
                  className="absolute inset-0 bg-cover bg-center dark:bg-[#130720] bg-[#f8f8f8] opacity-75"
                  style={{
                    backgroundImage: `url(${process.env.REACT_APP_IMAGE_URL
                      }${uploadMedia.replace(/\\/g, "/")})`,
                    filter: "blur(10px)",
                  }}
                ></div>
                <div className="relative flex items-center justify-center max-h-[710px] max-w-[100%] h-full">
                  <img
                    src={process.env.REACT_APP_IMAGE_URL + uploadMedia}
                    alt=""
                    className="w-auto max-h-[700px] h-full"
                  />
                </div>
              </div>
            </Link>
          )}
        </div>
        <div className="flex items-center justify-start gap-[20px] px-[16px]">
          <div
            className="flex no-select items-center justify-start gap-[4px] text-[14px] font-medium leading-[110%] text-[#6D7486] cursor-pointer"
            onClick={isLikeLoading ? () => { } : handleLikeClick}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              className={`heart-button-container ${liked ? "liked" : ""}`}
            >
              <g clipPath="url(#clip0_1036_7533)">
                <path
                  d="M4.66671 6.66683V14.6668M10 3.92016L9.33337 6.66683H13.22C13.427 6.66683 13.6312 6.71502 13.8163 6.80759C14.0015 6.90016 14.1625 7.03457 14.2867 7.20016C14.4109 7.36576 14.4948 7.55799 14.5319 7.76165C14.5689 7.9653 14.558 8.17478 14.5 8.3735L12.9467 13.7068C12.8659 13.9838 12.6975 14.2271 12.4667 14.4002C12.2359 14.5733 11.9552 14.6668 11.6667 14.6668H2.66671C2.31309 14.6668 1.97395 14.5264 1.7239 14.2763C1.47385 14.0263 1.33337 13.6871 1.33337 13.3335V8.00016C1.33337 7.64654 1.47385 7.3074 1.7239 7.05735C1.97395 6.80731 2.31309 6.66683 2.66671 6.66683H4.50671C4.75476 6.6667 4.99786 6.59737 5.20868 6.46664C5.41949 6.33592 5.58966 6.14897 5.70004 5.92683L8.00004 1.3335C8.31443 1.33739 8.62387 1.41228 8.90524 1.55256C9.18662 1.69284 9.43266 1.8949 9.62498 2.14363C9.81729 2.39236 9.95092 2.68134 10.0159 2.98896C10.0808 3.29659 10.0754 3.61492 10 3.92016Z"
                  stroke={liked ? "#2A8DDE" : "#6D7486"}
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </g>
              <defs>
                <clipPath id="clip0_1036_7533">
                  <rect width="16" height="16" fill="white" />
                </clipPath>
              </defs>
            </svg>
            like
            <div className="text-[10px] font-medium leading-[110%] rounded-full bg-[#EAF4FC] text-[#2A8DDE] w-[16px] h-[16px] flex items-center justify-center">
              {countLikes}
            </div>
          </div>
          <div
            onClick={() => setShowResponse(!showResponse)}
            className="flex no-select items-center justify-start gap-[4px] text-[14px] font-medium leading-[110%] text-[#6D7486] cursor-pointer"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
            >
              <path
                d="M8.66667 5.33333H4.66667M11.3333 8H4.66667M14 10C14 10.3536 13.8595 10.6928 13.6095 10.9428C13.3594 11.1929 13.0203 11.3333 12.6667 11.3333H4.66667L2 14V3.33333C2 2.97971 2.14048 2.64057 2.39052 2.39052C2.64057 2.14048 2.97971 2 3.33333 2H12.6667C13.0203 2 13.3594 2.14048 13.6095 2.39052C13.8595 2.64057 14 2.97971 14 3.33333V10Z"
                stroke="#6D7486"
                strokeWidth="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            Responses
            <div className="text-[10px] font-medium leading-[110%] rounded-full bg-[#EAF4FC] text-[#2A8DDE] w-[16px] h-[16px] flex items-center justify-center">
              {item?.comments?.filter(comment => comment?.userId !== null).length || 0}
            </div>
          </div>
        </div>
        {
          showResponse && (
            <div className={`flex items-center justify-start gap-[12px] px-[16px] pt-[16px] ${allComments.length > 0 ? 'pb-[16px]' : 'pb-0'}`}>
              <div className="flex-center w-[40px] h-[40px] rounded-[8px] overflow-hidden border border-[#EFF3F4]">
                {user?.profileImage ? (
                  <img
                    className="h-full w-full object-cover"
                    src={process.env.REACT_APP_IMAGE_URL + user?.profileImage}
                    alt=""
                    srcSet=""
                  />
                ) : (
                  <div
                    className="w-[40px] h-[40px] flex items-center justify-center rounded-[8px] overflow-hidden border border-[#EFF3F4]"
                    dangerouslySetInnerHTML={{ __html: icons.defaultProfile }}
                  ></div>
                )}
              </div>
              <div className=" w-[calc(100%_-_50px)] relative rounded-[8px] border border-[#EFF3F4] overflow-hidden">
                <input
                  type="text"
                  ref={inputRef}
                  value={comment}
                  onChange={handleInputChange}
                  onKeyPress={commentLoading ? null : handleKeyPress}
                  className="text-[14px] py-[12.3px] !mt-0 font-medium leading-[110%] text-[#6D7486] cursor-pointer  lg:px-[16px] w-[calc(100%_-_30px)] px-[14px] focus:outline-none placeholder:text-[#6D7486]  "
                  placeholder="Add a response"
                />
                <div
                  onClick={commentLoading ? null : createComment}
                  className="gray-icon absolute right-[12px] top-[50%] translate-y-[-50%] transform w-[18px] h-[18px] flex-center"
                  dangerouslySetInnerHTML={{ __html: icons.Send }}
                />
              </div>
            </div>
          )
        }
      </div>

      {
        showResponse && (
          <div>


            <div className=" ">
              {/* Show comments */}
              {isUserPostPath ? (
                allComments.filter(comment => comment?.userId !== null).map((comment, index) => {
                  return (
                    <div key={index} className="flex items-start justify-start gap-[12px] py-[8px] px-[19px]">
                      {/* Comment user photo */}
                      <div>
                        {
                          comment?.userId?.profileImage ? (
                            <Link to={`/ user /in/${comment?.userId?._id}`} className="w-[33px] h-[33px] flex-center rounded-[8px] overflow-hidden">
                              <img src={process.env.REACT_APP_IMAGE_URL + comment?.userId?.profileImage} alt={comment?.userId?.first_name} />
                            </Link>
                          ) : (
                            <Link to={`/ user /in/${comment?.userId?._id}`}
                              className="w-[33px] h-[33px] flex-center rounded-[8px] overflow-hidden border border-[#EFF3F4]"
                              dangerouslySetInnerHTML={{ __html: icons.defaultProfile }}
                            ></Link>
                          )
                        }
                      </div>
                      {/* Comment user profile */}
                      <div className="max-w-[calc(100%_-_50px)] w-fit">
                        <div>
                          <div className="flex items-center justify-between pb-[4.5px]">
                            <Link to={`/user/in/${comment?.userId?._id}`} className="text-[12px] font-medium leading-[110%]">{`${comment?.userId?.first_name ?? ""} ${comment?.userId?.last_name ?? ""}`}</Link>
                            <div className="flex items-end justify-end gap-[8px]">
                              <p className="ml-[6px] text-[10px] font-normal leading-[110%]">{getTimeAgo(comment?.createdAt)}</p>
                              {comment?.userId?._id === user?._id && (
                                <Menu
                                  direction={"bottom"}
                                  align={"end"}
                                  viewScroll={"close"}
                                  menuButton={
                                    <MenuButton>
                                      {/* only show in other user */}
                                      <div dangerouslySetInnerHTML={{ __html: icons.moreHorizontal }} />
                                    </MenuButton>
                                  }
                                  transition
                                >
                                  {comment?.userId?._id === user?._id &&
                                    <MenuItem onClick={() => { setShowDeleteCommentModal(true); setDeleteCommentId(comment?._id) }}>
                                      <span className="!border-[0px]">
                                        Delete
                                      </span>
                                    </MenuItem>
                                  }
                                </Menu>
                              )}
                            </div>
                          </div>
                          {/* Comment */}
                          <pre className="caption-text p-[10px] bg-[#F4F4F4] font-normal text-[14px] leading-[140%] rounded-r-[8px] rounded-bl-[8px] rounded-tl-none">{comment?.content}</pre>
                        </div>
                      </div>
                    </div>)
                })
              ) : (
                // Render last two comments if it's not a user post path
                <>
                  {
                    lastTwoComments.filter(comment => comment?.userId !== null).map((comment, index) => (
                      <div key={index} className="flex items-start justify-start gap-[12px] py-[8px] px-[19px]">
                        {/* Comment user photo */}
                        <div>
                          {
                            comment?.userId?.profileImage ? (
                              <Link to={`/user/in/${comment?.userId?._id}`} className="w-[33px] h-[33px] flex-center rounded-[8px] overflow-hidden">
                                <img src={process.env.REACT_APP_IMAGE_URL + comment?.userId?.profileImage} alt={comment?.userId?.first_name} />
                              </Link>
                            ) : (
                              <Link to={`/user/in/${comment?.userId?._id}`}
                                className="w-[33px] h-[33px] flex-center rounded-[8px] overflow-hidden border border-[#EFF3F4]"
                                dangerouslySetInnerHTML={{ __html: icons.defaultProfile }}
                              ></Link>
                            )
                          }
                        </div>
                        {/* Comment user profile */}
                        <div className="max-w-[calc(100%_-_50px)] w-fit">
                          <div>
                            <div className="flex items-center justify-between pb-[4.5px]">
                              <Link to={`/user/in/${comment?.userId?._id}`} className="text-[12px] font-medium leading-[110%]">{`${comment?.userId?.first_name ?? ""} ${comment?.userId?.last_name ?? ""}`}</Link>
                              <div className="flex items-end justify-end gap-[8px]">
                                <p className="ml-[6px] text-[10px] font-normal leading-[110%]">{getTimeAgo(comment?.createdAt)}</p>
                                {comment?.userId?._id === user?._id && (
                                  <Menu
                                    direction={"bottom"}
                                    align={"end"}
                                    viewScroll={"close"}
                                    menuButton={
                                      <MenuButton>
                                        {/* only show in other user */}
                                        <div dangerouslySetInnerHTML={{ __html: icons.moreHorizontal }} />
                                      </MenuButton>
                                    }
                                    transition
                                  >
                                    {comment?.userId?._id === user?._id && (
                                      <MenuItem onClick={() => { setShowDeleteCommentModal(true); setDeleteCommentId(comment?._id); }}>
                                        <span className="!border-[0px]">
                                          Delete
                                        </span>
                                      </MenuItem>
                                    )}
                                  </Menu>
                                )}
                              </div>
                            </div>
                            {/* Comment */}
                            <div className={`caption-text p-[10px] bg-[#F4F4F4] text-[14px] font-normal leading-[110%] rounded-r-[8px] rounded-bl-[8px] rounded-tl-none`}>{comment?.content}</div>
                          </div>
                        </div>
                      </div>
                    ))
                  }
                </>
              )}
              {
                !isUserPostPath && item?.comments?.filter(comment => comment?.userId !== null).length > 2 && (
                  <div className="flex items-center justify-center py-[10px]">
                    <Link
                      to={`/user/post/${_id}`}
                      className="text-[#21272A] font-medium text-[12px] leading-[140%] "
                    >
                      View all {item?.comments?.length > 0 ? item?.comments?.length : 0} comments
                    </Link>
                  </div>
                )
              }
            </div >
          </div >
        )
      }

      <CustomModal showModal={showEditModal} setShowModal={setShowEditModal} bodyClassName={"2sm:h-auto h-screen 2sm:rounded-[1rem] rounded-[0rem]"}>
        <EditPost DataInfo={item} setShowModal={setShowEditModal} uploadPost={postDataUpdate} />
      </CustomModal>
      <CustomModal showModal={showDeleteModal} setShowModal={setShowDeleteModal} bodyClassName={`rounded-[16px]  max-w-[90vw]`} >
        <div class="w-full h-full  bg-white shadow-lg rounded-[16px] 2sm:p-[24px] xsm:p-[20px] p-[15px] relative ">
          <div className='flex items-center justify-between'>
            <h1 className='2lg:text-[26px] 2sm:text-[24px] md:text-[22px] text-[20px] leading-[normal] font-[600]'>Delete Post</h1>
            <div onClick={() => { setShowDeleteModal(false); }} className='cursor-pointer ' dangerouslySetInnerHTML={{ __html: icons.closeIcon }} />
          </div>
          <p className='text-[#21272A] font-normal text-[12px] leading-[140%] py-[10px]'>Are you sure you want to delete this post?</p>
          <div className='flex justify-end gap-[10px]'>
            <Bluebtn className="text-[14px] font-medium py-[8px] px-[16px] w-fit mt-[16px]  text-[#6D7486] border bg-[#FFF] border-[#6D7486] hover:bg-[#6D7486] hover:text-white" Label="Cancel" onClick={() => { setShowDeleteModal(false) }} />
            <Bluebtn className="text-[14px] font-medium py-[8px] px-[16px] w-fit mt-[16px]  text-white bg-[#6D7486] hover:bg-[#6D7486] hover:text-white" Label="Delete" onClick={() => { handlePostDelete(); setShowDeleteModal(false) }} />
          </div>
        </div>
      </CustomModal>
      <CustomModal showModal={showDeleteCommentModal} setShowModal={setShowDeleteCommentModal} bodyClassName={`rounded-[16px]  max-w-[90vw]`} >
        <div class="w-full h-full  bg-white shadow-lg rounded-[16px] 2sm:p-[24px] xsm:p-[20px] p-[15px] relative ">
          <div className='flex items-center justify-between'>
            <h1 className='2lg:text-[26px] 2sm:text-[24px] md:text-[22px] text-[20px] leading-[normal] font-[600]'>Delete Comment</h1>
            <div onClick={() => { setShowDeleteCommentModal(false); }} className='cursor-pointer ' dangerouslySetInnerHTML={{ __html: icons.closeIcon }} />
          </div>
          <p className='text-[#21272A] font-normal text-[12px] leading-[140%] py-[10px]'>Are you sure you want to delete this comment ?</p>
          <div className='flex justify-end gap-[10px]'>
            <Bluebtn className="text-[14px] font-medium py-[8px] px-[16px] w-fit mt-[16px]  text-[#6D7486] border bg-[#FFF] border-[#6D7486] hover:bg-[#6D7486] hover:text-white" Label="Cancel" onClick={() => { setShowDeleteCommentModal(false) }} />
            <Bluebtn className="text-[14px] font-medium py-[8px] px-[16px] w-fit mt-[16px]  text-white bg-[#6D7486] hover:bg-[#6D7486] hover:text-white" Label="Delete" onClick={() => { handleCommentDelete(); setShowDeleteCommentModal(false) }} />
          </div>
        </div>
      </CustomModal>
    </div >
  );
}

export default Post;
