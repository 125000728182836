import { Calendar } from "primereact/calendar";
import { OverlayPanel } from "primereact/overlaypanel";
import { Tooltip } from "primereact/tooltip";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import Bluebtn from "../../Components/Common/Btns/Bluebtn";
import CustomModal from "../../Components/Common/CustomModel";
import { icons } from "../../helpers/images";
import Toast from "../../helpers/Toast";
import autoInstance from "../../utils/axios";
import { selectUser } from "../../utils/reduxtoolkit/slices/userSlice";

function EditPost({ uploadPost, pageTitle, Data, className, children, DataInfo, setShowModal }) {
    const user = useSelector(selectUser);
    const [isPosting, setIsPosting] = useState(false);
    const location = useLocation();
    const pathName = location.pathname;
    const minDate = new Date(); // Today
    const maxDate = new Date(); // Some future date, e.g., one month ahead
    maxDate.setDate(maxDate.getDate() + 90);
    const [formattedDateTime, setFormattedDateTime] = useState(null);
    const [myNetwork, setMyNetwork] = useState(
        pageTitle === "communities" || pageTitle === "collaborations" ? false : true
    );
    useEffect(() => {
        const dateString = DataInfo?.events[0].date;
        const timeString = DataInfo?.events[0].time;
        // Parse the date string to a Date object
        const oldeventDate = dateString && new Date(dateString);

        // Format date
        const formattedDate = oldeventDate && `${oldeventDate.getMonth() + 1}/${oldeventDate.getDate()}/${oldeventDate.getFullYear()}`;

        // Combine Date and Time
        const formattedDateTime = formattedDate && `${formattedDate}, ${DataInfo?.events[0].time}`;

        setFormattedDateTime(formattedDateTime);
    }, [])
    const [hideimage, setHideimage] = useState(false);
    const handleOpenModal = () => {
        setShowModal(true);
    };
    const handleCloseModal = () => {
        setShowModal(false);
    };
    const selectedData = DataInfo?.postJoin.map(item => ({ id: item.id, type: item.type }));

    const [showSelectModal, setShowSelectModal] = useState(false);
    const handleOpenSelectModal = () => {
        setShowSelectModal(true);
    };
    const handleCloseSelectModal = () => {
        setShowSelectModal(false);
    };
    const [checkedCommunities, setCheckedCommunities] = useState([]);
    const [checkedCollobrations, setCheckedCollobrations] = useState([]);
    const [myCommunities, setMyCommunities] = useState([]);
    const [myCollobrations, setMyCollobrations] = useState([]);
    const [communityLoading, setCommunityLoading] = useState(true);
    const [postJoinData, setPostJoinData] = useState([]);
    const [overlayVisible, setOverlayVisible] = useState(false);
    const op = useRef(null);
    const [datetime24h, setDateTime24h] = useState(null);
    const [postData, setPostData] = useState({
        description: DataInfo?.description ? DataInfo?.description : null,
        image: null,
        community: [],
        markAsQuestion: DataInfo?.is_mark_question ? DataInfo?.is_mark_question : false,
    });
    const mergedChecked = [...checkedCollobrations, ...checkedCommunities];
    const CurrentpostJoin = DataInfo?.postJoin;
    useEffect(() => {
        if (datetime24h !== null) {
            const selectedDate = datetime24h.toISOString();
            const selectedTime = datetime24h.toLocaleTimeString([], {
                hour: "2-digit",
                minute: "2-digit",
            });

            // Set eventDate to the selected Date and Time
            setEventDate({
                date: selectedDate,
                time: selectedTime,
            });
        } else {
            setEventDate({
                date: null,
                time: null,
            });
        }
    }, [datetime24h]);


    const [eventDate, setEventDate] = useState({
        date: "",
        time: "",
    });



    useEffect(() => {
        const updatePostJoinData = () => {
            const newData = [];

            if (myNetwork) {
                newData.push({
                    id: user?._id,
                    type: "User",
                });
            }

            if (checkedCommunities.length > 0) {
                checkedCommunities.forEach((community) => {
                    newData.push({
                        id: community?._id,
                        type: "Community",
                    });
                });
            }
            if (checkedCollobrations.length > 0) {
                checkedCollobrations.forEach((collaboration) => {
                    newData.push({
                        id: collaboration?._id,
                        type: "Collaboration",
                    });
                });
            }
            if (Data) {
                newData.push({
                    id: Data?._id,
                    type: `${pageTitle === "communities" ? "Community" : "Collaboration"
                        }`,
                });
            }

            setPostJoinData(newData);
        };

        updatePostJoinData();
    }, [checkedCommunities, checkedCollobrations, myNetwork, user, Data]);

    useEffect(() => {
        myCommunitiesData();
        myCollobrationsData();
    }, []);
    const [descriptionError, setDescriptionError] = useState("");
    const [imageError, setImageError] = useState("");

    const handleCheckboxChange = (item) => {
        if (checkedCommunities.includes(item)) {
            setCheckedCommunities((prevChecked) =>
                prevChecked.filter((items) => items !== item)
            );
        } else {
            setCheckedCommunities((prevChecked) => [...prevChecked, item]);
        }
    };
    const handleCheckCollobrationsChange = (item) => {
        if (checkedCollobrations.includes(item)) {
            setCheckedCollobrations((prevChecked) =>
                prevChecked.filter((items) => items !== item)
            );
        } else {
            setCheckedCollobrations((prevChecked) => [...prevChecked, item]);
        }
    };

    const myCommunitiesData = async () => {
        try {
            const myCommunityList = await autoInstance.get(
                "/community?type=my-community"
            );
            setMyCommunities(myCommunityList.data.data);
            setCommunityLoading(false);
        } catch (error) {
            console.error(error);
        } finally {
            setCommunityLoading(false);
        }
    };
    const myCollobrationsData = async () => {
        try {
            const myCommunityList = await autoInstance.get(
                "/collaboration?type=my"
            );
            setMyCollobrations(myCommunityList.data.data);
            setCommunityLoading(false);
        } catch (error) {
            console.error(error);
        } finally {
            setCommunityLoading(false);
        }
    };

    const handleError = () => {
        setDescriptionError("");
        setImageError("");
        let isError = false;
        if (postData.description === null && postData.image === null) {
            setDescriptionError("Required");
            setImageError("Required");
            isError = true;
        }
        // if (postData.description === null  && !postData.image === null) {
        //     setDescriptionError("Please Enter Description");
        //     isError = true
        // }
        // if (postData.image === null && !postData.description === null) {
        //     setImageError("Please Select Image");
        //     isError = true
        // }
        if (isError) throw new Error("Validation Error");
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            setIsPosting(true);
            handleError();
            if (descriptionError === "" && imageError === "") {
                const response = await autoInstance.patch(`/post/${DataInfo?._id}`, {
                    title: postData?.description,
                    description: postData?.description,
                    is_mark_question: postData?.markAsQuestion,
                    events: JSON.stringify(eventDate),
                    postJoin: JSON.stringify(postJoinData)
                });
                if (response.status === 200) {
                    handleCloseModal();
                    setIsPosting(false);
                    uploadPost();
                    setPostData({
                        description: null,
                        image: null,
                        markAsQuestion: false,
                        community: [],
                    });
                    Toast("Post Updated Successfully", "success");
                    document.getElementById("upload").value = "";
                }
            }
        } catch (error) {
            setIsPosting(false);
            console.error(error.message);
        }
    };

    const handleImageChange = (e) => {
        const imageFile = e.target.files[0];
        setPostData({ ...postData, image: imageFile });
    };

    const clearImage = () => {

        setPostData({ ...postData, image: null });
        document.getElementById("upload").value = "";

    };

    const handleConfirm = () => {
        const panel = document.getElementById("calendar-24h_panel");
        if (panel) {
            // panelNaNpxove();
        }
    };
    useEffect(() => {
        const addCustomButton = () => {
            const panel = document.getElementById("calendar-24h_panel");
            if (panel) {
                const confirmButton = document.createElement("button");
                confirmButton.className = "p-button p-button-text";
                confirmButton.innerText = "Confirm";
                confirmButton.addEventListener("click", handleConfirm);
                panel.appendChild(confirmButton);
            }
        };

        addCustomButton();

        return () => { };
    }, []);

    const handleSelectButtonClick = () => {
        setOverlayVisible(false); // Close the overlay
      };

    const footerTemplate = () => {
        return (
            <div className="flex justify-end">
                <Bluebtn className="text-[14px] font-medium py-[8px] px-[16px] w-fit text-white" Label="Select" onClick={handleSelectButtonClick} />
            </div>
        );
    };

    return (
        <>
            <div className="2sm:hidden flex items-center justify-between gap-[.625rem] py-[1rem] px-[1rem] border-b border-[#EFF3F4]">
                <div className="flex items-center justify-start gap-[.625rem]">
                    <div onClick={() => setShowModal(false)}>
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M15 18L9 12L15 6" stroke="#6D7486" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                    </div>
                    <p className='text-[1.25rem] font-semibold leading-[110%]'>Create Post</p>
                </div>
                <Bluebtn
                    Label={`${isPosting ? "Posting..." : "Post"} `}
                    onClick={isPosting ? () => { } : handleSubmit}
                    className={`text-[.875rem] font-medium py-[.5rem] px-[1rem] w-fit text-white ${isPosting ? "cursor-not-allowed" : "cursor-pointer"}`}
                />
            </div>
            <div className="p-[1rem] ">
                <div className="flex items-center justify-start gap-[.75rem] ">
                    {user?.profileImage ? (
                        <div className="h-[2.125rem] w-[2.125rem] rounded-[.375rem] flex items-center justify-center overflow-hidden">
                            <img
                                src={process.env.REACT_APP_IMAGE_URL + user?.profileImage}
                                alt="Proflie Image"
                            />
                        </div>
                    ) : (
                        <div
                            className="cursor-pointer h-[2.125rem] w-[2.125rem] rounded-[.375rem] overflow-hidden flex items-center justify-center"
                            dangerouslySetInnerHTML={{ __html: icons.defaultProfile }}
                        />
                    )}
                    <div>
                        <p className="text-[.875rem] font-medium text-[#0C1835] leading-[110%]">{`${user?.first_name ?? ""
                            } ${user?.last_name ?? ""}`}</p>
                        <div className="flex items-center justify-start">
                            <p className="text-[.875rem] text-[#6D7486] font-normal leading-[140%]">
                                Post on :{" "}
                            </p>
                            {Data ? (
                                <div className="text-[.875rem] text-[#6D7486] font-normal leading-[140%] flex items-center justify-start">
                                    {Data?.name}
                                </div>
                            ) : (
                                <div className="flex items-center justify-start">
                                    <div className="text-[.875rem] text-[#6D7486] font-normal leading-[140%] flex items-center justify-start">
                                        {myNetwork && <p className="px-[.125rem]"> My Network </p>}

                                        {mergedChecked?.length > 0 && (
                                            <div>
                                                {myNetwork ? " , " : ""}
                                                {mergedChecked[0]?.name}
                                                {mergedChecked?.length > 1 && (
                                                    <span>
                                                        {" "}
                                                        + other {mergedChecked?.length - 1}
                                                    </span>
                                                )}
                                            </div>
                                        )}
                                    </div>
                                    {/* <div
                                        className="cursor-pointer"
                                        onClick={handleOpenSelectModal}
                                        dangerouslySetInnerHTML={{ __html: icons.downChevron }}
                                    /> */}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                <div
                    className={`my-[.75rem] p-[.75rem] border ${descriptionError ? "border-red-500" : "border-[#EFF3F4]"
                        } rounded-[.5rem]`}
                >
                    {formattedDateTime && <p className="text-[14px] font-normal text-[#6D7486]">Event on : {formattedDateTime}</p>}
                    {datetime24h && !formattedDateTime && <p className="text-[14px] font-normal text-[#6D7486]">Event on : {new Date(datetime24h).toLocaleString()}</p>}
                    <textarea
                        onChange={(e) =>
                            setPostData({ ...postData, description: e.target.value })
                        }
                        defaultValue={DataInfo ? DataInfo?.description === "null" || DataInfo?.description === null ? "" : DataInfo?.description : ""}
                        name="postDescription"
                        id="postDescription"
                        className="h-[7.5rem] focus-visible:outline-none w-full"
                        placeholder="Ask a question or post a comment "
                    ></textarea>

                    {DataInfo?.uploadMedia && postData?.image === null &&
                        <div className={`relative mb-4 w-fit ${hideimage ? "hidden" : ""}`}>
                            <img
                                src={process.env.REACT_APP_IMAGE_URL + DataInfo?.uploadMedia}
                                alt="Selected Image"
                                className="w-[6.25rem] h-[6.25rem] object-cover rounded"
                            />
                            {/* <div
                                onClick={() => setHideimage(true)}
                                className="absolute top-[-0.5rem] right-[-0.5rem] cursor-pointer text-white w-[1.375rem] h-[1.3125rem] p-[.125rem] flex-center bg-[#fff] border border-[#EFF3F4] rounded-full"
                                dangerouslySetInnerHTML={{ __html: icons.closeIcon }}
                            /> */}
                        </div>}
                    <>
                        {postData?.image && (
                            <div className="relative mb-4 w-fit">
                                <img
                                    src={URL.createObjectURL(postData?.image)}
                                    alt="Selected Image"
                                    className="w-[6.25rem] h-[6.25rem] object-cover rounded"
                                />
                                <div
                                    onClick={clearImage}
                                    className="absolute top-[-0.5rem] right-[-0.5rem] cursor-pointer text-white w-[1.375rem] h-[1.3125rem] p-[.125rem] flex-center bg-[#fff] border border-[#EFF3F4] rounded-full"
                                    dangerouslySetInnerHTML={{ __html: icons.closeIcon }}
                                />
                            </div>
                        )}
                    </>
                    {
                        DataInfo?.events[0]?.date === null && datetime24h === null &&
                        <div className="flex items-center justify-start gap-[.5rem]" onClick={() => setPostData({ ...postData, markAsQuestion: !postData?.markAsQuestion })}>
                            <div className={`${postData?.markAsQuestion || DataInfo?.markAsQuestion ? "border-[#2A8DDE]" : "border-[#a9b3cc]"} w-[16px] h-[16px] flex-center rounded-full border-2    cursor-pointer`}>
                                {
                                    postData?.markAsQuestion || DataInfo?.markAsQuestion ? <div className="w-[10px] h-[10px] bg-[#2A8DDE] rounded-full"></div> : <div className="w-[10px] h-[10px] bg-[#FFF] rounded-full"></div>
                                }
                            </div>
                            <label
                                htmlFor="Markasquestion"
                                className="text-[.75rem]  cursor-pointer font-normal text-[#6D7486]"
                            >
                                Mark as question
                            </label>
                            <Tooltip
                                title="Mark as question"
                                target=".Markasquestioninfo"
                                placement="bottom"
                                className="custom-tooltip"
                            />
                            <span
                                data-pr-tooltip="Marking as a question ensures it appears accurately in a search for others to respond to."
                                data-pr-position="right"
                                data-pr-at="right+2 top+9"
                                data-pr-my="left center-2"
                                className="Markasquestioninfo"
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="14"
                                    height="15"
                                    viewBox="0 0 14 15"
                                    fill="none"
                                >
                                    <path
                                        d="M6.99984 13.3334C10.2215 13.3334 12.8332 10.7217 12.8332 7.50008C12.8332 4.27842 10.2215 1.66675 6.99984 1.66675C3.77818 1.66675 1.1665 4.27842 1.1665 7.50008C1.1665 10.7217 3.77818 13.3334 6.99984 13.3334Z"
                                        stroke="#6D7486"
                                        stroke-width="1.4"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                    />
                                    <path
                                        d="M7 9.83341V7.50008M7 5.16675H7.00583"
                                        stroke="#6D7486"
                                        stroke-width="1.4"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                    />
                                </svg>
                            </span>
                        </div>
                    }
                </div>

                {
                    !postData?.markAsQuestion &&
                    <div className="grid  pb-[.75rem]">

                        {
                            formattedDateTime || datetime24h ?
                                <div
                                    className="flex items-center justify-center gap-[.625rem] cursor-pointer"
                                    onClick={() => datetime24h ? setDateTime24h(null) : setFormattedDateTime(null)}
                                >
                                    <p className="text-[.875rem] font-[500] text-[#6D7486]">
                                        Cancel Event
                                    </p>
                                </div>
                                :
                                <div
                                    className="flex items-center justify-center gap-[.625rem] cursor-pointer"
                                    onClick={(e) => op.current.toggle(e)}
                                >
                                    <div dangerouslySetInnerHTML={{ __html: icons?.EventIcon }} />

                                    <p className="text-[.875rem] font-[500] text-[#6D7486]">
                                        Create Event
                                    </p>
                                </div>

                        }
                        <OverlayPanel ref={op}>
                            <div className="">
                                <label
                                    htmlFor="calendar-24h"
                                    className="text-[1rem] font-medium"
                                >
                                    Event Date
                                </label>
                                <div className=" mt-[.375rem] relative">
                                    <div className='absolute top-[50%] translate-y-[-50%] transform right-[16px] gray-icon cursor-pointer' dangerouslySetInnerHTML={{ __html: icons.downChevron }} />
                                    <Calendar
                                        id="calendar-24h"
                                        value={datetime24h}
                                        onChange={(e) => setDateTime24h(e.value)}
                                        showTime
                                        showButtonBar
                                        placeholder="Select Date and Time"
                                        hourFormat="24"
                                        footerTemplate={footerTemplate}
                                        visible={overlayVisible}
                                        onVisibleChange={(e) => setOverlayVisible(e.visible)}
                                        minDate={minDate}
                                        maxDate={maxDate}
                                    />
                                </div>
                                <div className="flex justify-end">
                                    <Bluebtn
                                        Label="Done"
                                        onClick={(e) => op.current.hide(e)}
                                        className="!w-fit mt-[.625rem] text-[.875rem] font-medium leading-[100%] py-[.5rem] px-[1rem] rounded-[.375rem] text-white"
                                    />
                                </div>
                            </div>
                        </OverlayPanel>

                      

                    </div>
                }
                <div className="2sm:flex hidden justify-end">
                    <Bluebtn
                        Label={`${isPosting ? "Updating..." : "Update"} `}
                        onClick={isPosting ? () => { } : handleSubmit}
                        className={`text-[.875rem] font-medium py-[.5rem] px-[1rem] w-fit text-white ${isPosting ? "cursor-not-allowed" : "cursor-pointer"}`}
                    />
                </div>
                {/* Select model Start  */}
                <CustomModal
                    overlayClassName="!z-[600]"
                    bodyClassName="!z-[666] max-w-[24.375rem] rounded-[1rem] "
                    showModal={showSelectModal}
                    setShowModal={setShowSelectModal}
                >
                    <div className="2sm:p-[1.5rem] xsm:p-[1.25rem] p-[.9375rem] ">
                        {/* Select  Model header  */}
                        <div className="flex justify-between items-center">
                            <p className="text-[1.5rem] leading-[110%] font-[600]">Post on</p>
                            <div
                                className="cursor-pointer"
                                onClick={handleCloseSelectModal}
                                dangerouslySetInnerHTML={{ __html: icons.closeIcon }}
                            />
                        </div>
                        {/* my network   */}
                        <div className="pb-[.625rem] pt-[1rem] mb-[.625rem] border-b border-[#EFF3F4]">
                            <label
                                htmlFor="network"
                                className="flex items-center justify-between"
                            >
                                <p className="text-[.875rem] font-[500] leading-[100%] text-[#0C1835]">
                                    My network
                                </p>
                                <div class="checkboxes__row">
                                    <div class="checkboxes__item">
                                        <label class="checkbox style-c">
                                            <input
                                                checked={`${myNetwork && "checked"}`}
                                                className="Post-CheckBox"
                                                onChange={(e) => setMyNetwork(e.target.checked)}
                                                type="checkbox"
                                                name="network"
                                                id="network" />
                                            <div class="checkbox__checkmark"></div>
                                        </label>
                                    </div>
                                </div>
                            </label>
                        </div>
                        {/* my communities */}
                        <p className="text-[.75rem] font-[400] text-[#6D7486] leading-[140%] pb-[.375rem]">
                            My communities
                        </p>
                        <div className="space-y-[1rem]">
                            {communityLoading ? (
                                <>Loading...</>
                            ) : (
                                <>
                                    {myCommunities?.length > 0 ? (
                                        <>
                                            {myCommunities &&
                                                myCommunities?.map((item, index) => (
                                                    <label
                                                        key={index}
                                                        htmlFor={`communities${index}`}
                                                        className="flex items-center justify-between"
                                                    >
                                                        <div className="flex items-center justify-start gap-[.5rem]">
                                                            <div className="flex-center rounded-[.25rem] w-[1.25rem] h-[1.25rem] overflow-hidden">
                                                                <img
                                                                    src={
                                                                        process.env.REACT_APP_IMAGE_URL +
                                                                        item.image
                                                                    }
                                                                    alt={item.name}
                                                                    className="w-full h-full object-cover"
                                                                />
                                                            </div>
                                                            <div className="text-[.875rem] font-[500] leading-[100%] text-[#0C1835]">
                                                                {item.name}
                                                            </div>
                                                        </div>
                                                        <div class="checkboxes__row">
                                                            <div class="checkboxes__item">
                                                                <label class="checkbox style-c">
                                                                    <input
                                                                        type="checkbox"
                                                                        className="Post-CheckBox"
                                                                        name={item.name}
                                                                        id={`communities${index}`}
                                                                        checked={checkedCommunities.includes(item)}
                                                                        onChange={() => handleCheckboxChange(item)} />
                                                                    <div class="checkbox__checkmark"></div>
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </label>
                                                ))}
                                        </>
                                    ) : (
                                        <>
                                            <p className="text-[.875rem] font-[500] leading-[100%] text-[#6D7486]">
                                                No communities found
                                            </p>
                                        </>
                                    )}
                                </>
                            )}
                        </div>
                        <p className="text-[.75rem] font-[400] text-[#6D7486] leading-[140%] pb-[.375rem] pt-[.875rem]">
                            My collaborations
                        </p>
                        <div className="space-y-[1rem]">
                            {communityLoading ? (
                                <>Loading...</>
                            ) : (
                                <>
                                    {myCollobrations?.length > 0 ? (
                                        <>
                                            {myCollobrations &&
                                                myCollobrations?.map((item, index) => (
                                                    <label
                                                        key={index}
                                                        htmlFor={`collobrations${index}`}
                                                        className="flex items-center justify-between"
                                                    >
                                                        <div className="flex items-center justify-start gap-[.5rem]">
                                                            <div className="flex-center rounded-[.25rem] w-[1.25rem] h-[1.25rem] overflow-hidden">
                                                                <img
                                                                    src={
                                                                        process.env.REACT_APP_IMAGE_URL +
                                                                        item.image
                                                                    }
                                                                    alt={item.name}
                                                                    className="w-full h-full object-cover"
                                                                />
                                                            </div>
                                                            <div className="text-[.875rem] font-[500] leading-[100%] text-[#0C1835]">
                                                                {item.name}
                                                            </div>
                                                        </div>
                                                        <div class="checkboxes__row">
                                                            <div class="checkboxes__item">
                                                                <label class="checkbox style-c">
                                                                    <input
                                                                        type="checkbox"
                                                                        className="Post-CheckBox"
                                                                        name={item.name}
                                                                        id={`collobrations${index}`}
                                                                        checked={checkedCollobrations?.includes(item)}
                                                                        onChange={() => handleCheckCollobrationsChange(item)} />
                                                                    <div class="checkbox__checkmark"></div>
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </label>
                                                ))}
                                        </>
                                    ) : (
                                        <>
                                            <p className="text-[.875rem] font-[500] leading-[100%] text-[#6D7486]">
                                                No collaborations found
                                            </p>
                                        </>
                                    )}
                                </>
                            )}
                            <div className="2sm:flex hidden justify-end">
                                <Bluebtn
                                    Label="Done"
                                    onClick={handleCloseSelectModal}
                                    className="text-[.875rem] font-medium py-[.5rem] px-[1rem] w-fit text-white"
                                />
                            </div>
                        </div>
                    </div>
                </CustomModal>
            </div>
        </>
    );
}

export default EditPost;