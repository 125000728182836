import { Menu, MenuButton, MenuItem } from "@szhsin/react-menu";
import { TabPanel, TabView } from "primereact/tabview";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import Bluebtn from "../../Components/Common/Btns/Bluebtn";
import CustomModal from "../../Components/Common/CustomModel";
import EmptyState from "../../Components/Common/EmptyState";
import CustomInput from "../../Components/Common/Inputs/CustomInput";
import { CreateExperience } from "../../Components/Common/Models/CreateExperience";
import CreateShowCase from "../../Components/Common/Models/CreateShowCase";
import PageLoader from "../../Components/Common/PageLoader";
import Post from "../../Components/Post";
import Showcase from "../../Components/Showcase";
import { icons, images } from "../../helpers/images";
import Toast from "../../helpers/Toast";
import HomeLayout from "../../layouts/HomeLayout";
import autoInstance from "../../utils/axios";
import { selectUser } from "../../utils/reduxtoolkit/slices/userSlice";

function Profile() {
  const StoreUser = useSelector(selectUser);
  const location = useLocation();
  const sliderRef = useRef(null);
  const [user, setUser] = useState();
  const userId = location.pathname.split("/")[3];
  const storeUserId = StoreUser?._id;
  const [isLoading, setIsLoading] = useState(false);
  const [experience, setExperience] = useState([]);
  const [myProfile, setMyProfile] = useState(false);
  const [addExperience, setshowAddExperience] = useState(false);
  const [EditEducation, setEditEducation] = useState(false);
  const [updatedUser, setUpdatedUser] = useState();
  const [pageLoaded, setPageLoaded] = useState(false);
  const [ModelData, setModelData] = useState();
  const [userResponse, setUserResponse] = useState();
  const [ModelType, setModelType] = useState();
  const [showCaseData, setShowCaseData] = useState();
  const [showCaseModel, setShowCaseModel] = useState(false);
  const [PostData, setPostData] = useState([]);
  const [onlyPostData, setonlyPostData] = useState([]);
  const [ResponsePostData, setResponsePostData] = useState([]);
  const [isFollowLoading, setIsFollowLoading] = useState(false);
  const [isFollowing, setIsFollowing] = useState(false);
  const [selectedType, setSelectedType] = useState("All");
  const [filteredData, setFilteredData] = useState([])
  const [otherAboutUserData, setOtherAboutUserData] = useState({
    industry: "",
    interest: "",
  });
  const [Followers, setFollowers] = useState([]);
  const [Following, setFollowing] = useState(false);
  const customStyles = {
    menu: {
      borderRadius: '1rem',
    },
    menuItem: {
      fontSize: '1rem',
      fontWeight: '500',
    },
  };

  const [OnLoad, setOnLoad] = useState(true)
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [showDeleteExperience, setShowDeleteExperience] = useState(false)
  const [experienceDeleteId, setExperienceDeleteId] = useState(null)
  const [allPostData, setAllPostData] = useState([])
  const [currentSlide, setCurrentSlide] = useState(0);

  const settings = {
    dots: false,
    infinite: showCaseData?.length > 1,
    speed: 500,
    slidesToShow: showCaseData?.length > 1 ? 2 : 1,
    slidesToScroll: 1,
    swipeToSlide: true,
    draggable: true,
    afterChange: (index) => setCurrentSlide(index)
  };

  const totalSlides = showCaseData?.length || 0;

  const goToNextSlide = () => {
    if (sliderRef.current && currentSlide < totalSlides - settings.slidesToShow) {
      sliderRef.current.slickNext();
    }
  };

  const goToPrevSlide = () => {
    if (sliderRef.current && currentSlide > 0) {
      sliderRef.current.slickPrev();
    }
  };


  useEffect(() => {
    if (storeUserId === userId) {
      setMyProfile(true);
    } else {
      setMyProfile(false);
    }
  }, [userId, storeUserId]);

  useEffect(() => {
    if (userId) {
      Promise.all([getUser(), getExperience(), getShowCase(), getPostData(), getFollowings(),])
        .then(() => {
          setOnLoad(false);
        })
        .catch(error => {
          // Handle error
          console.error("Error occurred:", error);
          setOnLoad(false); // Ensure to set onLoad false even in case of error
        });
    }
  }, [userId]);

  useEffect(() => {
    if (onlyPostData.length > 0) {
      PostFilter('All')
    }
  }, [onlyPostData]);

  const getShowCase = async () => {
    try {
      const response = await autoInstance.get(`/show-case?type=user&userId=${userId}`);
      const source = response.data;
      setShowCaseData(source?.data);
    } catch (error) {
      console.error(error);
    }
    setIsLoading(false);
  };
  useEffect(() => {
    setUpdatedUser(StoreUser);
  }, [user]);
  const getUser = async () => {
    try {
      setIsLoading(true);
      const response = await autoInstance.get(`/user/${userId}`);
      const source = response?.data;
      setUser(source?.data);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error(error);
    }
    setIsLoading(false);
  };

  const getExperience = async () => {
    try {
      const response = await autoInstance.get(
        `/user-experience/get-user-experience?userId=${userId}`
      );
      if (response.status === 200) {
        setExperience(response.data.data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const AllActivities = (options) => {
    return (
      <div
        className="py-[10px] px-[20px] 2md:rounded-[18px] md:rounded-[16px] 2sm:rounded-[12px] rounded-[10px] overflow-hidden cursor-pointer"
        style={{ cursor: "pointer" }}
        onClick={options.onClick}
      >
        <span className="font-[500] white-space-nowrap text-[16px] leading-[110%]">
          All Activities
        </span>
      </div>
    );
  };

  const Questions = (options) => {
    return (
      <div
        className="py-[10px] px-[20px] 2md:rounded-[18px] md:rounded-[16px] 2sm:rounded-[12px] rounded-[10px] overflow-hidden cursor-pointer"
        style={{ cursor: "pointer" }}
        onClick={options.onClick}
      >
        <span className="font-[500] white-space-nowrap text-[16px] leading-[110%]">
          Questions / Posts
        </span>
      </div>
    );
  };

  const Responses = (options) => {
    return (
      <div
        className="py-[10px] px-[20px] 2md:rounded-[18px] md:rounded-[16px] 2sm:rounded-[12px] rounded-[10px] overflow-hidden cursor-pointer"
        style={{ cursor: "pointer" }}
        onClick={options.onClick}
      >
        <span className="font-[500] white-space-nowrap text-[16px] leading-[110%]">
          Responses
        </span>
      </div>
    );
  };
  const Activity = (options) => {
    return (
      <p
        className="font-[600] text-[#6D7486] white-space-nowrap text-[14px] leading-[110%] p-[12px]"
        style={{ cursor: "pointer" }}
        onClick={options.onClick}
      >
        {myProfile ? "Activity" : "Questions / Posts"}
      </p>
    );
  };
  const About = (options) => {
    return (
      <p
        className="font-[600] text-[#6D7486] white-space-nowrap text-[14px] leading-[110%] p-[12px]"
        style={{ cursor: "pointer" }}
        onClick={options.onClick}
      >
        About
      </p>
    );
  };

  const menuRight = useRef(null);



  // const [Options, setOptions] = useState([])
  const [ShowModal, setShowModal] = useState(false);
  const getPostData = async () => {
    try {
      setIsLoading(true);
      const allResponse = await autoInstance.get(
        `/post?category_id=${userId}&type=users`
      );
      const onlyPostresponse = await autoInstance.get(
        `/post?category_id=${userId}&type=users`
      );
      const OtherResponse = await autoInstance.get(
        `/comments?type=my`
      );
      setonlyPostData(onlyPostresponse?.data?.data);
      setResponsePostData(OtherResponse?.data?.data);
      setFilteredData(onlyPostresponse?.data?.data);
      const otherPostIds = OtherResponse?.data?.data?.map(post => post.postId);
      const allPostData = [...onlyPostresponse?.data?.data, ...otherPostIds];
      if (myProfile) {
        setAllPostData(onlyPostresponse?.data?.data);
      } else {
        setAllPostData(allResponse?.data?.data);
      }
      setIsLoading(false);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  };
  const getResponseData = async () => {
    try {
      setIsLoading(true);
      const response = await autoInstance.get(`/comment?category_id=${userId}`);
      const source = response.data;
      setIsLoading(false);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  };

  const renderCoverImage = () => {
    if (user?.coverImage) {
      return (
        <img
          src={process.env.REACT_APP_IMAGE_URL + user?.coverImage}
          className="object-cover w-full h-full"
          alt="Cover image"
        />
      );
    } else {
      return <img src={images.banner} className="object-cover w-full h-full" alt="Default banner" />;
    }
  };

  const updateProfile = async () => {
    try {
      setPageLoaded(true);
      const formData = new FormData();
      formData.append("first_name", updatedUser.first_name);
      formData.append("last_name", updatedUser.last_name);
      formData.append("levelEducation", updatedUser.levelEducation);

      const response = await autoInstance.post(
        "/user/update-profile",
        formData
      );
      if (response.status === 200) {
        getUser();
        setPageLoaded(false);
        Toast(response.data.message, "success");
      }
    } catch (error) {
      Toast(error.message, "error");
    }
  };


  const getFollowers = async () => {
    try {
      const response = await autoInstance.get(`/followers/${userId}`);
      setFollowers(response.data.data);
    } catch (error) {
      console.error(error);
    }
  };

  const getFollowings = async () => {
    try {
      const response = await autoInstance.get(`/follow-request/my-followers/${storeUserId}`);
      if (response.status === 200) {
        const data_ = response?.data?.data?.map(item => (item.receiverId._id))
        const isFollowing = data_?.includes(userId);
        setFollowing(isFollowing);
      }


    } catch (error) {
      console.error(error);
    }
  };

  const handleFollow = async () => {
    setIsFollowLoading(true);
    try {
      const response = await autoInstance.post(`/follow-request/${userId}`);
      if (response.status === 200) {
        getUser();
        getFollowings();
        Toast(response.data.message, 'success');
      }
    } catch (error) {
      console.error(error);
    }
    setIsFollowLoading(false);
  };
  const handleUnFollow = async () => {
    setIsFollowLoading(true);
    try {
      const response = await autoInstance.delete(`/follow-request/${userId}`);
      if (response.status === 200) {
        getUser();
        getFollowings()
        Toast(response.data.message, 'success');
      }
    } catch (error) {
      console.error(error);
    }
    setIsFollowLoading(false);
  };


  const handleCopyLink = () => {
    const link = `${window.location.origin}/user/in/${userId}`;
    if (navigator.clipboard && navigator.clipboard.writeText) {
      navigator.clipboard
        .writeText(link)
        .then(() => {
          Toast("Link copied to clipboard:", link);
          // You can provide feedback to the user that the link is copied successfully if needed
        })
        .catch((error) => {
          Toast("Failed to copy link:", error);
          // Handle error or provide feedback to the user
        });
    } else {
      const textArea = document.createElement("textarea");
      textArea.value = link;
      document.body.appendChild(textArea);
      textArea.select();
      try {
        document.execCommand("copy");
        Toast("Link copied to clipboard:", link);
      } catch (error) {
        Toast("Failed to copy link:", error);
      }
      document.body.removeChild(textArea);
    }
  };


  const handleDeleteExperience = async (_id) => {
    try {
      const response = await autoInstance.delete(`/user-experience/${_id}`);
      if (response.status === 200) {
        getExperience();
        Toast(response.data.message, 'success');
      }
    } catch (error) {

    }
  }

  const postIds = ResponsePostData?.map(post => post.postId);

  if (OnLoad) {
    return <PageLoader />
  }



  const PostFilter = (type) => {
    if (type === "Questions") {
      const filteredQuestions = onlyPostData.filter(item => item.is_mark_question);
      setFilteredData(filteredQuestions);
    } else if (type === "Post") {
      const filteredPosts = onlyPostData.filter(item => !item.is_mark_question);
      setFilteredData(filteredPosts);
    } else {
      setFilteredData(onlyPostData);
    }
  }
  const sortedAllPostData = allPostData?.sort((a, b) => {

    // Convert the createdAt strings to Date objects
    const dateA = new Date(a?.createdAt);
    const dateB = new Date(b?.createdAt);

    // Compare the Date objects
    return dateA - dateB;
  });

  const postIdData = ResponsePostData?.map((item) => {
    if (item.postId) {
      return {
        ...item.postId,
        source: 'ResponsePostData'  // Add new key to differentiate the source
      };
    }
    return null;
  }).filter(Boolean);

  // Combine sortedAllPostData with the modified postIdData
  const combinedData = [
    ...sortedAllPostData,
    ...postIdData
  ];

  // Sort the combined array by 'createdAt' date
  const mergedAndSortedData = combinedData.sort((a, b) => {
    // Convert 'createdAt' strings to Date objects
    const dateA = new Date(a?.createdAt);
    const dateB = new Date(b?.createdAt);

    // Compare the Date objects
    return dateA - dateB;
  });



  return (
    <div>
      <HomeLayout
        children={
          <div className="overflow-y-auto lg:h-[calc(100vh_-_80px)] md:h-[calc(100vh_-_70px)] h-[calc(100vh_-_128px)] hide-scrollbar bg-white   w-full">
            {/* Profile banner  */}
            <div className="py-[20px]  bg-[#fff]  4lg:w-[660px] 2lg:w-[570px] 2md:w-[420px] w-full mx-auto 2md:px-[0px] md:px-[80px] sm:px-[20px] px-[15px]">
              <div className="w-[100%] 3lg:h-[200px] 2lg:h-[160px] h-[140px]  rounded-[12px] border border-[#EAF4FC] overflow-hidden mt-[8px] flex-center  relative">
                {renderCoverImage()}
              </div>
              <div className="mt-[-30px] mx-[20px]">
                {/* Prfile Image & Edit Btn */}
                <div className="flex items-start justify-between">
                  <div className="relative">
                    {
                      user?.profileImage ?
                        <div className="relative w-fit rounded-[16px] overflow-hidden border border-[#EAF4FC]">
                          <div className="md:w-[100px] md:h-[100px] w-[80px] h-[80px]  flex items-center justify-start  z-10 relative ">
                            <img
                              className="w-full h-full object-cover"
                              src={
                                process.env.REACT_APP_IMAGE_URL + user?.profileImage
                              }
                              alt="profile image"
                            />
                          </div>
                        </div> :
                        <div
                          className="cursor-pointer md:w-[100px] md:h-[100px] w-[80px] h-[80px]  z-10 relative  rounded-[16px] overflow-hidden flex items-center justify-center border border-[#EFF3F4]"
                          dangerouslySetInnerHTML={{ __html: icons.defaultProfile }}
                        />
                    }
                  </div>
                  <div className="flex items-end self-end justify-end gap-[6px]">
                    {myProfile ? (
                      <Link to={"/setting/editprofile"}>
                        <Bluebtn
                          Label="Edit"
                          className="text-[14px] leading-[100%] font-medium py-[8px] px-[16px] w-fit text-white"
                        />
                      </Link>
                    ) : (
                      <div className="flex items-center justify-center gap-[10px]">
                        <Link
                          to={`/chat/${userId}`}
                          className="flex items-center justify-center !cursor-pointer bg-[#2A8DDE] rounded-[6px] text-[14px] leading-[100%] font-medium py-[8px] px-[16px] w-fit text-white">
                          Message
                        </Link>
                        <Bluebtn
                          Label={isFollowLoading ? 'Loading...' : Following ? 'Unfollow' : 'Follow'}
                          onClick={() => { Following ? handleUnFollow() : handleFollow() }}
                          className="text-[14px] leading-[100%] font-medium py-[8px] px-[16px] w-fit text-white"
                        />
                      </div>
                    )}
                    {!myProfile && (
                      <Menu
                        direction={"bottom"}
                        align={"end"}
                        viewScroll={"close"}
                        menuButton={
                          <MenuButton className="">
                            <div className="flex items-center justify-center w-[30px] h-[30px] bg-[#EAF4FC] rounded-[6px]">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="4"
                                height="12"
                                viewBox="0 0 4 12"
                                fill="none"
                              >
                                <path
                                  d="M2.00016 6.66634C2.36835 6.66634 2.66683 6.36786 2.66683 5.99967C2.66683 5.63148 2.36835 5.33301 2.00016 5.33301C1.63197 5.33301 1.3335 5.63148 1.3335 5.99967C1.3335 6.36786 1.63197 6.66634 2.00016 6.66634Z"
                                  stroke="#2A8DDE"
                                  strokeWidth="1.2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                                <path
                                  d="M2.00016 1.99984C2.36835 1.99984 2.66683 1.70136 2.66683 1.33317C2.66683 0.964981 2.36835 0.666504 2.00016 0.666504C1.63197 0.666504 1.3335 0.964981 1.3335 1.33317C1.3335 1.70136 1.63197 1.99984 2.00016 1.99984Z"
                                  stroke="#2A8DDE"
                                  strokeWidth="1.2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                                <path
                                  d="M2.00016 11.3333C2.36835 11.3333 2.66683 11.0349 2.66683 10.6667C2.66683 10.2985 2.36835 10 2.00016 10C1.63197 10 1.3335 10.2985 1.3335 10.6667C1.3335 11.0349 1.63197 11.3333 2.00016 11.3333Z"
                                  stroke="#2A8DDE"
                                  strokeWidth="1.2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                              </svg>
                            </div>
                          </MenuButton>

                        }
                        styles={customStyles.menu}  // Apply custom styles to Menu
                        transition
                      >
                        <MenuItem
                          onClick={handleCopyLink}
                          styles={customStyles.menuItem}  // Apply custom styles to MenuItem
                        >
                          <span>
                            Copy Link
                          </span>
                        </MenuItem>

                      </Menu>

                    )}
                  </div>
                </div>
                {/* Prfile Name & Bio */}
                {/* ${showCaseData?.length === 0 ? "" : "border-b border-[#EAF4FC]"} */}
                <div className={`md:pt-[20px] pt-[16px] md:pb-[16px] pb-[12px] border-b border-[#EAF4FC] `}>
                  <p className="md:text-[24px] text-[20px] font-semibold text-[#0C1835]">{`${user?.first_name ?? ""
                    } ${user?.last_name ?? ""}`}</p>
                  <p className="text-[#6D7486] font-medium md:text-[12px] text-[10px] leading-[140%] pt-[4px]">
                    {user?.areaOfInterest && user?.areaOfInterest?.join(',  ')}
                  </p>
                </div>
                {/* } */}
                <div className="md:pt-[16px] pt-[12px]">
                  <div className="flex items-center justify-between">
                    {showCaseData?.length === 0 ? (<></>) : (
                      <p className="md:text-[18px] text-[14px] font-semibold text-[#0C1835]">{myProfile && "My"} Showcases</p>)
                    }
                    {myProfile && (
                      <Bluebtn
                        onClick={() => {
                          setShowCaseModel(true);
                        }}
                        Label="Add Showcase"
                        className="text-[14px] leading-[100%] font-medium py-[8px] px-[16px] w-fit text-white"
                      />
                    )}
                  </div>
                  <>
                    {showCaseData?.length === 0 ? (
                      <div>
                        <EmptyState title={"No current showcase"} />
                      </div>
                    ) : (
                      <div className="py-[10px] relative">
                        <button className="absolute left-[-20px] top-[50%] translate-y-[-50%] " onClick={goToPrevSlide} style={{ display: currentSlide === 0 ? 'none' : 'block' }}>
                          <div>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                              <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 19.5 8.25 12l7.5-7.5" />
                            </svg>
                          </div>
                        </button>
                        <button className="absolute right-[-20px] top-[50%] translate-y-[-50%]" onClick={goToNextSlide} style={{ display: totalSlides > 0 && currentSlide === totalSlides - settings.slidesToShow ? 'none' : 'block' }}>
                          <div>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                              <path stroke-linecap="round" stroke-linejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
                            </svg>
                          </div>
                        </button>
                        <Slider ref={sliderRef} {...settings}>
                          {showCaseData?.map((item, index) => (
                            <Showcase showDelete={!!myProfile} key={index} item={item} UpdateData={getShowCase} />
                          ))}
                        </Slider>
                      </div>
                    )}
                  </>
                </div>
              </div>
            </div>
            <div className="border-y border-[#EFF3F4] bg-white profile-tabs ">
              <div className="">
                <TabView className="">
                  {/* Activity Tab */}
                  <TabPanel headerTemplate={Activity} className="">
                    <div className="bg-[#F3F5F7] py-[16px] 2md:px-[40px] md:px-[30px] sm:px-[20px] px-[15px] tabs-header-bg ">
                      <div className="profile-content-tabs relative  mx-auto">


                        {
                          myProfile &&
                          <TabView activeIndex={activeTabIndex} className="">
                            <TabPanel header="" headerTemplate={AllActivities}>
                              <div className="py-[11px] space-y-[10px]">
                                {mergedAndSortedData?.reverse().map((item, index) => (
                                  <Post
                                    key={index}
                                    PostData={item}
                                    updateData={getPostData}
                                  />
                                ))}
                                {mergedAndSortedData?.length === 0 && (
                                  <div className="py-[45px]">
                                    <EmptyState page={"Posts"} />
                                  </div>
                                )}
                              </div>
                            </TabPanel>
                            <TabPanel
                              headerTemplate={Questions}
                              headerClassName="flex align-items-center"
                            >
                              <div className="">
                                {/* {myProfile &&
                                  <div className="absolute right-0 top-[15px] cursor-pointer z-30">
                                    <Menu
                                      className={""}
                                      direction={"bottom"}
                                      align={"end"}
                                      viewScroll={"close"}
                                      menuButton={
                                        <MenuButton>
                                          <div
                                            aria-controls="post_menu_right"
                                            aria-haspopup
                                            className=""
                                            dangerouslySetInnerHTML={{ __html: icons.Filter }}
                                          />
                                        </MenuButton>
                                      }
                                      transition
                                    >
                                      <MenuItem onClick={() => { PostFilter("All"); setSelectedType("All") }}>All</MenuItem> 
                                      <MenuItem onClick={() => { PostFilter("Questions"); setSelectedType("Questions") }}>Questions</MenuItem>
                                      <MenuItem onClick={() => { PostFilter("Post"); setSelectedType("Post") }}>Post</MenuItem>
                                    </Menu>
                                  </div>
                                } */}
                                <div className="py-[11px] space-y-[10px]">
                                  {filteredData?.map((item, index) => (
                                    <Post
                                      key={index}
                                      PostData={item}
                                      updateData={getPostData}
                                    />
                                  ))}
                                </div>
                                {filteredData?.length === 0 && (
                                  <div className="py-[45px]">
                                    <EmptyState page={"Questions"} />
                                  </div>
                                )}
                              </div>
                            </TabPanel>
                            <TabPanel
                              headerTemplate={Responses}
                              headerClassName="flex align-items-center"
                            >
                              <div className="py-[11px] space-y-[10px]">
                                {postIds?.filter(item => item !== null)?.map((item, index) => (
                                  <Post
                                    key={index}
                                    PostData={item}
                                    updateData={getPostData}
                                  />
                                ))}
                                {postIds?.filter(item => item !== null).length === 0 && (
                                  <div className="py-[45px]">
                                    <EmptyState page={"Responses"} />
                                  </div>
                                )}
                              </div>
                            </TabPanel>
                          </TabView>
                        }
                        {
                          !myProfile &&
                          <div className="py-[11px] space-y-[10px]">
                            {mergedAndSortedData?.reverse().map((item, index) => (
                              <Post
                                key={index}
                                PostData={item}
                                updateData={getPostData}
                              />
                            ))}
                            {mergedAndSortedData?.length === 0 && (
                              <div className="py-[45px]">
                                <EmptyState page={"Posts"} />
                              </div>
                            )}
                          </div>
                        }
                      </div>
                    </div>
                  </TabPanel>
                  {/* About Tab */}
                  <TabPanel headerTemplate={About}>
                    <div className="bg-[#F3F5F7] py-[16px] 2md:px-[40px] md:px-[30px] sm:px-[20px] px-[15px] ">
                      <div className="m-[16px] bg-[#fff] py-[13px] px-[20px] rounded-[12px] 4lg:w-[660px] 2lg:w-[570px] 2md:w-[420px] w-full mx-auto ">
                        {/* About Links */}
                        <div className="flex items-center justify-start gap-[4px] p-[6px]">
                          <div
                            dangerouslySetInnerHTML={{
                              __html: icons.LocationIcon,
                            }}
                          />
                          <p className="text-[12px] text-[#6D7486] leading-[110%] font-medium">
                            {user?.location}
                          </p>
                        </div>
                        <div className="p-[6px] flex items-center justify-start gap-[8px]">
                          <Link target="_blank" to={user?.linkdinUrl} >
                            <div>
                              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect width="24" height="24" rx="12" fill="#EAF4FC" />
                                <g clip-path="url(#clip0_796_3555)">
                                  <path d="M9.3275 10.2173H7.31998C7.23088 10.2173 7.15869 10.2895 7.15869 10.3786V16.8279C7.15869 16.917 7.23088 16.9892 7.31998 16.9892H9.3275C9.41659 16.9892 9.48879 16.917 9.48879 16.8279V10.3786C9.48879 10.2895 9.41659 10.2173 9.3275 10.2173Z" fill={`${user?.linkdinUrl ? "#007EBB" : "#B7BCCA"}`} />
                                  <path d="M8.32471 7.01123C7.59426 7.01123 7 7.60484 7 8.33449C7 9.06446 7.59426 9.65829 8.32471 9.65829C9.05458 9.65829 9.64835 9.06442 9.64835 8.33449C9.64839 7.60484 9.05458 7.01123 8.32471 7.01123Z" fill={`${user?.linkdinUrl ? "#007EBB" : "#B7BCCA"}`} />
                                  <path d="M14.4338 10.0571C13.6275 10.0571 13.0314 10.4037 12.6699 10.7976V10.3787C12.6699 10.2896 12.5977 10.2174 12.5086 10.2174H10.5861C10.497 10.2174 10.4248 10.2896 10.4248 10.3787V16.828C10.4248 16.9171 10.497 16.9893 10.5861 16.9893H12.5892C12.6783 16.9893 12.7505 16.9171 12.7505 16.828V13.6371C12.7505 12.5618 13.0426 12.1429 13.7921 12.1429C14.6085 12.1429 14.6733 12.8145 14.6733 13.6924V16.828C14.6733 16.9171 14.7455 16.9893 14.8346 16.9893H16.8385C16.9276 16.9893 16.9998 16.9171 16.9998 16.828V13.2905C16.9998 11.6916 16.6949 10.0571 14.4338 10.0571Z" fill={`${user?.linkdinUrl ? "#007EBB" : "#B7BCCA"}`} />
                                </g>
                                <defs>
                                  <clipPath id="clip0_796_3555">
                                    <rect width="10" height="10" fill="white" transform="translate(7 7)" />
                                  </clipPath>
                                </defs>
                              </svg>
                            </div>
                          </Link>
                          <Link target="_blank" to={user?.shareUrl} className=" w-[calc(100%-40px)]" >
                            <div className="flex-start gap-[5px] w-[calc(100%-40px)]">
                              <div>
                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <g clip-path="url(#clip0_796_3603)">
                                    <path d="M6.66699 8.66648C6.95329 9.04923 7.31856 9.36594 7.73803 9.59511C8.15749 9.82428 8.62133 9.96056 9.09809 9.9947C9.57485 10.0288 10.0534 9.96006 10.5012 9.793C10.9491 9.62594 11.3557 9.36453 11.6937 9.02648L13.6937 7.02648C14.3009 6.39781 14.6368 5.5558 14.6292 4.68181C14.6216 3.80782 14.2711 2.97178 13.6531 2.35375C13.035 1.73573 12.199 1.38516 11.325 1.37757C10.451 1.36997 9.609 1.70595 8.98033 2.31315L7.83366 3.45315" stroke={`${user?.shareUrl ? "#007EBB" : "#B7BCCA"}`} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M9.33347 7.33332C9.04716 6.95057 8.68189 6.63387 8.26243 6.40469C7.84297 6.17552 7.37913 6.03924 6.90237 6.0051C6.4256 5.97095 5.94707 6.03974 5.49924 6.2068C5.0514 6.37386 4.64472 6.63527 4.3068 6.97332L2.3068 8.97332C1.69961 9.60199 1.36363 10.444 1.37122 11.318C1.37881 12.192 1.72938 13.028 2.3474 13.646C2.96543 14.2641 3.80147 14.6146 4.67546 14.6222C5.54945 14.6298 6.39146 14.2938 7.02013 13.6867L8.16013 12.5467" stroke={`${user?.shareUrl ? "#007EBB" : "#B7BCCA"}`} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                  </g>
                                  <defs>
                                    <clipPath id="clip0_796_3603">
                                      <rect width="16" height="16" fill="white" />
                                    </clipPath>
                                  </defs>
                                </svg>
                              </div>
                              <p className={` w-full whitespace-nowrap   text-[12px] text-[#6D7486] leading-[110%] font-medium overflow-hidden text-ellipsis`}>
                                {user?.shareUrl}
                              </p>
                            </div>
                          </Link>
                        </div>
                        {/* Experience */}
                        <div className="py-[8px] my-[4px] border-[#EFF3F4] border-y">
                          {/* Add Experience */}
                          <div className="py-[4px] flex items-center justify-between">
                            <p className="text-[16px] leading-[110%] font-medium">
                              Experience
                            </p>
                            {myProfile &&
                              <div className="flex items-center justify-end gap-[10px]">
                                <div
                                  onClick={() => {
                                    setshowAddExperience(true);
                                    setModelType("Add");
                                  }}
                                  className="cursor-pointer"
                                  dangerouslySetInnerHTML={{ __html: icons.Plus }}
                                />
                              </div>
                            }
                          </div>
                          {/* Show Experience  */}
                          {experience?.length > 0 &&
                            experience.map((item, index) => {
                              const fromDate = new Date(
                                item.from
                              ).toLocaleString("en-US", {
                                month: "short",
                                year: "numeric",
                              });
                              const toDate = item.to
                                ? new Date(item.to).toLocaleString("en-US", {
                                  month: "short",
                                  year: "numeric",
                                })
                                : "Present";
                              return (
                                <div
                                  key={index}
                                  className="flex items-center justify-start gap-[8px] py-[8px] group relative"
                                >
                                  {/* <div className='flex-center overflow-hidden w-[36px] h-[36px] rounded-[4px] border border-[#EFF3F4]'>
                                                                                        <img src="" alt="" />
                                                                                    </div> */}
                                  <div className="">
                                    <p className="text-[12px] font-normal leading-[140%] text-[#21272A]">
                                      {item?.organization}
                                    </p>
                                    <div className="flex items-center justify-start gap-[5px]">
                                      <p className="text-[10px] font-[400] leading-[140%] text-[#6D7486]">
                                        {item?.title}
                                      </p>
                                      <div className="w-[3px] h-[3px] rounded-full overflow-hidden bg-[#6D7486]"></div>
                                      <p className="text-[10px] font-[400] leading-[140%] text-[#6D7486]">
                                        {fromDate} - {toDate}
                                      </p>
                                    </div>
                                  </div>
                                  {
                                    myProfile &&
                                    <div className="absolute  right-[0px] top-[50%] translate-y-[-50%]">
                                      <div
                                        className="flex items-center justify-end gap-[10px]"
                                      >
                                        <div
                                          onClick={() => {
                                            setshowAddExperience(true);
                                            setModelData(item);
                                            setModelType("Edit");
                                          }}
                                          className="cursor-pointer"
                                          dangerouslySetInnerHTML={{
                                            __html: icons.Edit,
                                          }}
                                        />
                                        <div onClick={() => { setShowDeleteExperience(true); setExperienceDeleteId(item?._id) }} className='cursor-pointer' dangerouslySetInnerHTML={{ __html: icons.deleteExperience }} />
                                      </div>
                                    </div>
                                  }
                                </div>
                              );
                            })
                          }

                          {
                            experience?.length === 0 && <p className='text-[14px] font-medium pt-[12px] text-[#6D7486]'>{myProfile ? "Add your experience" : "No Experience Found"}</p>
                          }
                        </div>
                        {/* Experience Level */}
                        <div>
                          <div className="py-[4px] flex items-center justify-between">
                            <p className="text-[16px] leading-[110%] font-medium">
                              Education Level
                            </p>
                            {/* {!EditEducation ?
                                                        <div onClick={() => setEditEducation(!EditEducation)} className='text-[12px] cursor-pointer font-medium text-[#2A8DDE]'><div className='cursor-pointer' dangerouslySetInnerHTML={{ __html: icons.Edit }} /></div>
                                                        :
                                                        <p onClick={() => setEditEducation(!EditEducation)} className='text-[12px] cursor-pointer font-medium text-[#6D7486]'>Cancel</p>
                                                    } */}
                          </div>
                          {!EditEducation ? (
                            <p className="text-[12px] font-medium leading-[110%] text-[#6D7486]">
                              {user?.levelEducation}
                            </p>
                          ) : (
                            <div>
                              <CustomInput
                                className="max-w-[314px]"
                                type="text"
                                defultValue={user?.levelEducation}
                                onChange={(e) => {
                                  setUpdatedUser({
                                    ...updatedUser,
                                    levelEducation: e.target.value,
                                  });
                                }}
                                placeholder="Enter Name"
                              />
                              <Bluebtn
                                className="!w-fit mt-[16px] text-[14px] font-medium leading-[100%] py-[8px] px-[16px] rounded-[6px] text-white"
                                Label="Update"
                                onClick={() => {
                                  updateProfile();
                                  setEditEducation(!EditEducation);
                                }}
                              />
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </TabPanel>
                </TabView>
              </div>
            </div>
            {/* MOdel  */}
            <CustomModal
              showModal={addExperience}
              setShowModal={setshowAddExperience}
              bodyClassName=" rounded-[16px] "
            >
              <CreateExperience
                setIsOpen={setshowAddExperience}
                type={ModelType}
                experienceModalData={ModelData}
                ClearData={setModelData}
                updateData={getExperience}
              />
            </CustomModal>
            <CustomModal
              bodyClassName={"rounded-[16px]  !w-fit"}
              showModal={showCaseModel}
              setShowModal={setShowCaseModel}
            >
              <CreateShowCase setIsOpen={setShowCaseModel} updateData={getShowCase} />
            </CustomModal>
            {/* MOdel ENd  */}

            {/* Delete Experince  */}
            <CustomModal bodyClassName=" rounded-[16px] max-w-[525px] " showModal={showDeleteExperience} setShowModal={setShowDeleteExperience}>
              <div class="w-full  bg-white shadow-lg rounded-[16px] 2sm:p-[24px] xsm:p-[20px] p-[15px] relative ">
                <div className='flex items-center justify-between'>
                  <h1 className='2lg:text-[26px] 2sm:text-[24px] md:text-[22px] text-[20px]  leading-[normal] font-[600]'>Delete Experience</h1>
                  <div onClick={() => { setShowDeleteExperience(false); }} className='cursor-pointer ' dangerouslySetInnerHTML={{ __html: icons.closeIcon }} />
                </div>
                <p className='text-[#21272A] font-normal text-[12px] leading-[140%] py-[10px]'>Are you sure you want to delete this experience?</p>
                <div className='flex justify-end'>
                  <Bluebtn className="text-[14px] font-medium py-[8px] px-[16px] w-fit mt-[16px]  text-white bg-[#6D7486]" Label="Delete" onClick={() => { handleDeleteExperience(experienceDeleteId); setShowDeleteExperience(false) }} />
                </div>
              </div>
            </CustomModal>
            {/* Delete Experince End */}
          </div>
        }
      />
    </div>
  );
}

export default Profile;
