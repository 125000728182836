import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import EmptyState from "../../Components/Common/EmptyState";
import PageLoader from "../../Components/Common/PageLoader";
import CreatePost from "../../Components/CreatePost";
import Post from "../../Components/Post";
import HomeLayout from "../../layouts/HomeLayout";
import autoInstance from "../../utils/axios";
import { selectUser } from "../../utils/reduxtoolkit/slices/userSlice";
import Showcase from "../../Components/Showcase";

function Home() {
  const [isLoading, setIsLoading] = useState(false);
const user = useSelector(selectUser);
const [postData, setPostData] = useState([]);
const [showcases, setShowcases] = useState([]);
const [combinedData, setCombinedData] = useState([]);
const [pageSize, setPageSize] = useState(4);

// Fetch posts from the server
const getPostData = async () => {
  try {
    setIsLoading(true);
    const response = await autoInstance.get(
      `/post?pageSize=${pageSize}&category_id=${user._id}&type=all`
    );
    const source = response.data;
    const filteredPosts = source?.data?.filter((item) => item?.userId !== null);
    setPostData(filteredPosts);
    setIsLoading(false);
  } catch (error) {
    console.error("Error fetching data:", error);
    setIsLoading(false);
  }
};

// Fetch showcases and limit to a maximum of 2
const fetchShowcases = async () => {
  try {
    const res = await autoInstance.get('/show-case/random');
    if (res.status === 200) {
      setShowcases(res.data.data); // Limit the showcases to a maximum of 2
    }
  } catch (err) {
    console.error('Error fetching showcases:', err);
  }
};

// Combine posts and showcases
const combinePostsAndShowcases = (posts, showcases) => {
  const combined = [];
  let showcaseIndex = 0;

  for (let i = 0; i < posts.length; i++) {
    combined.push(posts[i]);

    // Insert showcases after every 2 posts if there are at least 2 showcases left
    if ((i + 1) % 2 === 0 && showcaseIndex < showcases.length) {
      let groupedShowcases = [];

      // Ensure that we have at least two different showcases to display
      if (showcases.length > 1) {
        groupedShowcases = showcases.slice(showcaseIndex, showcaseIndex + 2);
        combined.push({ type: 'showcase', data: groupedShowcases });
        showcaseIndex += 2; // Move to the next pair of showcases
      }
    }
  }

  return combined;
};

useEffect(() => {
  getPostData();
  fetchShowcases();
}, []);

useEffect(() => {
  if (postData.length > 0) {
    const combined = combinePostsAndShowcases(postData, showcases);
    setCombinedData(combined);
  } else {
    setCombinedData(postData); // Set only posts if no showcases are available
  }
}, [postData, showcases]);

  return (
    <HomeLayout>
      <div className="sm:py-[20px] py-[15px] space-y-[10px] overflow-y-auto lg:h-[calc(100vh_-_80px)] 2sm:h-[calc(100vh_-_70px)] h-[calc(100vh_-_128px)] hide-scrollbar 2sm:px-[20px] px-[15px] ">
        {isLoading && <PageLoader />}
        <CreatePost uploadPost={getPostData} />
        <div className="w-full">
          {combinedData.length > 0 ? (
            <div className="space-y-[10px]">
              {combinedData.map((item, index) =>
                item.type === 'showcase' ? (
                  <div key={index} className={`4lg:w-[660px] 2lg:w-[570px] 2md:w-[420px] w-full mx-auto ${item?.data?.length > 1 ? 'grid grid-cols-2 gap-[10px]' : ''} `}>
                    {
                      item?.data?.length < 1 ? (
                        <Showcase item={item?.data[0]} isSingle={true} />
                      ) :
                        item?.data?.map((item, index) => (
                          <Showcase key={index} item={item} isSingle={false} HomeGridTrue={true} />
                        ))
                    }
                    {/* <Showcase item={item?.data} /> */}
                  </div>
                ) : (
                  <Post key={index} PostData={item} updateData={getPostData} />
                )
              )}
            </div>
          ) : (
            <div className="h-[calc(100vh_-_260px)]">
              <EmptyState page="Post" />
            </div>
          )}
        </div>
      </div>
    </HomeLayout>
  );
}

export default Home;
