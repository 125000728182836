import React, { useEffect, useState } from "react";
import CollaborationsCard from "../Components/Common/CollaborationsCard";
import CommunitiesCard from "../Components/Common/CommunitiesCard";
import EmptyState from "../Components/Common/EmptyState";
import Navbar from "../Components/Common/Navbar";
import Sidebar from "../Components/Common/Sidebar";
import { icons } from "../helpers/images";
import autoInstance from "../utils/axios";
import { selectUser } from "../utils/reduxtoolkit/slices/userSlice";
import { useSelector } from "react-redux";

function HomeLayout({ children }) {
    const [Communitiesdata, setCommunitiesdata] = useState([]);
    const user = useSelector(selectUser);
    const [otherCommunitiesdata, setOtherCommunitiesdata] = useState([]);
    const [Collaboratingdata, setCollaboratingdata] = useState([]);
    const [otherCollaboratingdata, setOtherCollaboratingdata] = useState([]);


    useEffect(() => {
        getCommunityCollaborationData();
    }, []);
    const getCommunityCollaborationData = async () => {
        try {
            const getCommunitydata = async () => {
                const response = await autoInstance("/community?type=my-community");
                const otherResponse = await autoInstance("/community?type=other");
                const myCommunityList = response?.data?.data ?? []
                const otherCommunityList = otherResponse?.data?.data ?? []
                setCommunitiesdata(myCommunityList);
                setOtherCommunitiesdata(otherCommunityList);
            };
            const getColloborationdata = async () => {
                const response = await autoInstance("/collaboration?type=my-community");
                const otherResponse = await autoInstance("/collaboration?type=other");
                const myCollaborationList = response?.data?.data ?? []
                const otherCollaborationList = otherResponse?.data?.data ?? []
                setCollaboratingdata(myCollaborationList);
                setOtherCollaboratingdata(otherCollaborationList);
            };

            getColloborationdata();
            getCommunitydata();
        } catch (error) {
            console.error(error);
        }
    }

    const sortedCommunities = React.useMemo(() => {
        const sorted = [...Communitiesdata];

        sorted.sort((a, b) => {
            // Sorting by isActive: false first
            if (!a.isActive && b.isActive) return -1;
            if (a.isActive && !b.isActive) return 1;

            // Sorting by userId._id === user._id next
            const aIsUser = a.userId && a.userId._id === user._id;
            const bIsUser = b.userId && b.userId._id === user._id;

            if (aIsUser && !bIsUser) return -1;
            if (!aIsUser && bIsUser) return 1;

            // Default order for other cases (remaining items)
            return 0;
        });

        return sorted;
    }, [Communitiesdata, user._id]);

    const sortedCollaborating = React.useMemo(() => {
        const sorted = [...Collaboratingdata];

        sorted.sort((a, b) => {
            // Sorting by isActive: false first
            if (!a.isActive && b.isActive) return -1;
            if (a.isActive && !b.isActive) return 1;

            // Sorting by userId._id === user._id next
            const aIsUser = a.userId && a.userId._id === user._id;
            const bIsUser = b.userId && b.userId._id === user._id;

            if (aIsUser && !bIsUser) return -1;
            if (!aIsUser && bIsUser) return 1;

            // Default order for other cases (remaining items)
            return 0;
        });

        return sorted;
    }, [Collaboratingdata, user._id]);

    return (
        <div>
            {/* Header */}
            <Navbar />
            {/* ENd Header */}
            <div className={`flex items-start justify-between lg:h-[calc(100vh_-_80px)] 2sm:h-[calc(100vh_-_70px)] h-[calc(100vh_-_128px)] overflow-hidden bg-[#F3F5F7] `}>
                {/* Sidebar  */}
                <Sidebar />

                {/* Sidebar  */}
                {/* Center Content  */}
                <div
                    className={`h-full bg-[#F3F5F7] 3lg:w-[calc(100%_-_700px)] lg:w-[calc(100%_-_579px)] 2md:w-[calc(100%_-_398px)] md:w-[calc(100%_-_60px)] 2sm:w-[calc(100%_-_60px)] mx-auto w-full `}>
                    {children}
                </div>
                {/* Center Content  */}
                {/* My Communities & Collaborating */}
                <div className="3lg:w-[400px] 2md:w-[330px] 2md:block hidden border-l border-[#EFF3F4] bg-[#fff] h-full">
                    <div className="px-[16px] w-full border-b border-b-[#EFF3F4] pb-[12px]">
                        <p className="text-[18px] font-semibold text-[#0C1835] pt-[16px] pb-[12px]">
                            My Communities
                        </p>
                        <div className="space-y-[4px] h-[37vh] overflow-y-auto hide-scrollbar">
                            {[...sortedCommunities, ...otherCommunitiesdata].map((item, index) => (
                                <CommunitiesCard key={index} item={item} updateContents={getCommunityCollaborationData} />
                            ))}
                            {Communitiesdata?.length === 0 && otherCommunitiesdata?.length === 0 && (
                                <EmptyState
                                    icon={icons.HomeCommunity}
                                    page={"communities"}
                                />
                            )}
                        </div>
                    </div>
                    <div className="px-[16px] w-full  pb-[12px]">
                        <p className="text-[18px] font-semibold text-[#0C1835] pt-[16px] pb-[12px]">
                            I’m Collaborating on
                        </p>
                        <div className="space-y-[4px] h-[37vh] overflow-y-auto hide-scrollbar">
                            {[...sortedCollaborating, ...otherCollaboratingdata]?.map((item, index) => (
                                <CollaborationsCard key={index} item={item} updateContents={getCommunityCollaborationData} />
                            ))}
                            {Collaboratingdata.length === 0 && otherCollaboratingdata.length === 0 && (
                                <EmptyState
                                    icon={icons.HomeCollaboration}
                                    page={"collaboration"}
                                />
                            )}
                        </div>
                    </div>
                </div>
                {/* My Communities & Collaborating */}
            </div>
        </div>
    );
}

export default HomeLayout;
