import { Button } from 'primereact/button'
import React from 'react'
import { Link } from 'react-router-dom'

function PostSearchCard({ item }) {
    const { _id, name, first_name, postJoin, last_name, description, uploadMedia } = item
    const Postuser = postJoin?.find(item => item.type === "user") || postJoin?.[0];
    return (
        <div className='p-[8px] w-full rounded-[12px] bg-white hover:bg-[#EAF4FC] flex items-center justify-between gap-[8px] cursor-pointer border border-[#EFF3F4]'>
            <Link to={`/user/post/${_id}`} className='flex items-center justify-start gap-[8px] w-[calc(100%_-_105px)]'>
                {/* {
                    uploadMedia === null || uploadMedia === "" || uploadMedia === undefined || uploadMedia === "null" ? <></> :
                        <div className='w-[60px] h-[60px] rounded-[8px] overflow-hidden flex items-center justify-center'>
                            <img className='w-full h-full' src={process.env.REACT_APP_IMAGE_URL + uploadMedia} alt={name} />
                        </div>
                } */}
                <div className='flex flex-col justify-between w-[calc(100%_-_75px)]'>
                    {/* <p className='text-[12px] font-medium text-[#0C1835] 3xl:pb-[10px] pb-[6px]'>{name}</p> */}
                    <div>
                        {/* <p className='text-[#6D7486] text-[10px] font-light pb-[4px]'>{Postuser?.first_name + " " + Postuser?.last_name}</p> */}
                        <p className='text-[#0C1835] text-[12px] font-normal  line-clamp-2'>{description}</p>
                    </div>
                </div>

            </Link>
            <div className='flex items-center justify-end gap-[8px]'>

                <Button className="focus-visible:outline-0 focus:shadow-none" aria-controls="card_menu_right" aria-haspopup >
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M9.99984 10.8332C10.4601 10.8332 10.8332 10.4601 10.8332 9.99984C10.8332 9.5396 10.4601 9.1665 9.99984 9.1665C9.5396 9.1665 9.1665 9.5396 9.1665 9.99984C9.1665 10.4601 9.5396 10.8332 9.99984 10.8332Z" stroke="#6D7486" strokeWidth="1.7" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M9.99984 4.99992C10.4601 4.99992 10.8332 4.62682 10.8332 4.16659C10.8332 3.70635 10.4601 3.33325 9.99984 3.33325C9.5396 3.33325 9.1665 3.70635 9.1665 4.16659C9.1665 4.62682 9.5396 4.99992 9.99984 4.99992Z" stroke="#6D7486" strokeWidth="1.7" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M9.99984 16.6667C10.4601 16.6667 10.8332 16.2936 10.8332 15.8333C10.8332 15.3731 10.4601 15 9.99984 15C9.5396 15 9.1665 15.3731 9.1665 15.8333C9.1665 16.2936 9.5396 16.6667 9.99984 16.6667Z" stroke="#6D7486" strokeWidth="1.7" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                </Button>

            </div>
        </div>
    )
}

export default PostSearchCard