import axios from 'axios';
import { Calendar } from 'primereact/calendar';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { icons } from '../../../helpers/images';
import Toast from '../../../helpers/Toast';
import autoInstance from '../../../utils/axios';
import Bluebtn from '../Btns/Bluebtn';
import CustomInput from '../Inputs/CustomInput';
import CustomSelect from '../Select/CustomSelect';

export const CreateExperience = ({ setIsOpen, type, experienceModalData, ClearData, updateData }) => {

    const [showAddExperience, setShowAddExperience] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [isPosting, setIsPosting] = useState(false);
    const [locationOptions, setLocationOptions] = useState([]);

    const [experienceError, setExperienceError] = useState({
        organization: null,
        country: null,
        role: null,
        from: null,
        to: null,
        workingHere: null,
        description: null
    });
    const [selectedExperience, setSelectedExperience] = useState({
        organization: experienceModalData?.organization ? experienceModalData?.organization : null,
        country: experienceModalData?.country ? experienceModalData?.country : null,
        role: experienceModalData?.title ? experienceModalData?.title : null,
        from: experienceModalData?.from ? new Date(experienceModalData?.from) : null,
        to: experienceModalData?.to ? new Date(experienceModalData?.to) : null,
        workingHere: experienceModalData?.to ? experienceModalData?.to : false,
        description: experienceModalData?.description ? experienceModalData?.description : ""
    })

    const [experience, setExperience] = useState([]);
    const cityOptions = [
        { value: 'Ahmedabad', label: 'Ahmedabad' },
        { value: 'Surat', label: 'Surat' },
        { value: 'Vadodara', label: 'Vadodara' },
        { value: 'Mumbai', label: 'Mumbai' },
        { value: 'Pune', label: 'Pune' },
        { value: 'Chennai', label: 'Chennai' },
        { value: 'Delhi', label: 'Delhi' },
        { value: 'Kolkata', label: 'Kolkata' },
        { value: 'Jaipur', label: 'Jaipur' },
        { value: 'Bengaluru', label: 'Bengaluru' },
        { value: 'Hyderabad', label: 'Hyderabad' },
        { value: 'Noida', label: 'Noida' },
    ]


    const countryOptions = [
        { value: 'India', label: 'India' },
        { value: 'USA', label: 'USA' },
        { value: 'UK', label: 'UK' },
        { value: 'Canada', label: 'Canada' },
    ]

    const handleExperienceError = () => {
        setExperienceError({
            organization: null,
            country: null,
            role: null,
            from: null,
            to: null,
            workingHere: null,
            description: null
        });
        let isError = false;
        if (selectedExperience.organization === null) {
            setExperienceError(prevState => ({
                ...prevState,
                organization: "Required",
            }));
            isError = true;
        }
        if (selectedExperience.country === null) {
            setExperienceError(prevState => ({
                ...prevState,
                country: "Required",
            }));
            isError = true;
        }
        if (selectedExperience.role === null) {
            setExperienceError(prevState => ({
                ...prevState,
                role: "Required",
            }));
            isError = true;
        }
        if (selectedExperience.from === null) {
            setExperienceError(prevState => ({
                ...prevState,
                from: "Required",
            }));
            isError = true;
        }
        console.error(isError, "isError")
        return isError;
    };

    useEffect(() => {
        getCountryName();
    }, []);

    const handleSaveExperience = () => {
        try {
            const isError = handleExperienceError();
            if (!isError) {
                const newExperience = {
                    organization: selectedExperience?.organization,
                    city: selectedExperience?.city,
                    country: selectedExperience?.country,
                    role: selectedExperience?.role,
                    from: selectedExperience?.from,
                    ...(selectedExperience?.workingHere ? {} : { to: selectedExperience?.to }),
                    workingHere: selectedExperience?.workingHere,
                    description: selectedExperience?.description
                };
                setExperience(prevExperience => [...prevExperience, newExperience]);
                clearFormFields();
                setShowAddExperience(false);
            }
        } catch (error) {
            // If validation error occurs, set the experienceError state
            setExperienceError({
                organization: error.message,
                city: null,
                country: null,
                role: null,
                from: null,
                to: null,
                workingHere: null,
                description: null
            });
        }
    };


    const clearFormFields = () => {
        setSelectedExperience({
            organization: null,
            city: null,
            country: null,
            role: null,
            from: null,
            to: null,
            workingHere: false,
            description: ""
        })
    };

    const handleSave = async () => {
        try {
            setLoading(true)
            const isError = handleExperienceError();
            const experienceData = {
                "title": selectedExperience?.role,
                "organization": selectedExperience?.organization,
                "country": selectedExperience?.country,
                "from": selectedExperience?.from,
                "to": selectedExperience?.to,
                "workingHere": selectedExperience?.workingHere,
                "description": selectedExperience?.description
            }
            if (!isError) {
                if (experienceModalData) {
                    const response = await autoInstance.patch(`/user-experience/${experienceModalData?._id}`, experienceData)
                    if (response.status === 200) {
                        updateData();
                        Toast('Experience updated successfully', 'success')
                        setSelectedExperience({
                            organization: null,
                            city: null,
                            country: null,
                            role: null,
                            from: null,
                            to: null,
                            workingHere: false,
                            description: ""
                        })
                        setLoading(false)
                        setIsOpen(false)
                    }
                } else {
                    const response = await autoInstance.post(`/user-experience`, experienceData)
                    if (response.status === 200) {
                        updateData();
                        Toast('Experience added successfully', 'success')
                        setLoading(false)
                        setIsOpen(false)
                    }
                }
            }
        }
        catch (error) {
            console.error(error)
            if (error.response?.data) { Toast(error.response.data.message, "error") }
            else { console.error(error.message) }
        }
        finally {
            setLoading(false)
        }
    }

    const getCountryName = useCallback(async () => {
        try {
            const response = await axios.get('https://countriesnow.space/api/v0.1/countries');
            if (response.status === 200) {
                const countryOptions = response.data.data.map(country => ({
                    label: country.country,
                    value: country.country,
                }));
                setLocationOptions(countryOptions);
            } else {
                Toast(response.data.message, 'error');
            }
        } catch (error) {
            Toast(error.message, 'error');
        }
    }, []);

    return (

        <div className='p-[25px]'>
            <div className='flex items-center justify-between gap-[16px]'>
                <h1 className='2lg:text-[26px] 2sm:text-[24px] md:text-[22px] text-[20px]   leading-[normal] font-[600]'>{type} experience</h1>
                <div onClick={() => { setIsOpen(false); ClearData(""); }} className='cursor-pointer ' dangerouslySetInnerHTML={{ __html: icons.closeIcon }} />
            </div>
            <div className='space-y-[12px] py-[10px] 2md:max-h-[70vh] max-h-[calc(100vh-140px)] overflow-y-auto'>
                {/* Organization */}
                <div>
                    <CustomInput
                        onChange={(e) => setSelectedExperience({ ...selectedExperience, organization: e.target.value })}
                        label='Organization'
                        defultValue={experienceModalData?.organization}
                        type='text'
                        placeholder='Organization name'
                    />
                    {experienceError.organization && (
                        <div className="text-[14px] text-red-500">{experienceError.organization}</div>
                    )}
                </div>

                {/* City and Country */}
                <div>
                    <CustomSelect
                        label="Country"
                        onChange={(slected) => setSelectedExperience({ ...selectedExperience, country: slected.label })}
                        value={selectedExperience?.country ? { label: selectedExperience.country, value: selectedExperience.country } : undefined}
                        options={locationOptions}
                        placeholder="Select Country"
                        className=" w-full "
                    />
                    {experienceError.country && (
                        <div className="text-[14px] text-red-500">{experienceError.country}</div>
                    )}
                </div>

                {/* Role */}
                <div>
                    <CustomInput
                        onChange={(e) => setSelectedExperience({ ...selectedExperience, role: e.target.value })}
                        label='Title / Role'
                        defultValue={experienceModalData?.title}
                        type='text'
                        placeholder='Ex. Climate expert'
                    />
                    {experienceError.role && (
                        <div className="text-[14px] text-red-500">{experienceError.role}</div>
                    )}
                </div>

                {/* From and To */}
                <div className='grid 2sm:grid-cols-2 gap-[10px]'>
                    <div>
                        <label b className='text-[16px] font-medium mb-[6px]'>From</label>
                        <div className='w-full relative'>
                            <div className='absolute top-[50%] translate-y-[-50%] transform right-[16px] gray-icon  cursor-pointer' dangerouslySetInnerHTML={{ __html: icons.downChevron }} />
                            <Calendar
                                className='w-full'
                                value={selectedExperience?.from}
                                onChange={(selected) => {
                                    const selectedDate = new Date(selected.value);
                                    const today = new Date();

                                    if (selectedDate > today) {
                                        setExperienceError(prevState => ({
                                            ...prevState,
                                            from: "From date should not be greater than today's date",
                                        }));
                                    } else {
                                        setExperienceError(prevState => ({
                                            ...prevState,
                                            from: null,
                                        }));
                                        setSelectedExperience({ ...selectedExperience, from: selected.value });
                                    }
                                }}
                                view="month"
                                dateFormat="mm/yy"
                                placeholder="Select Month/Year"
                            />
                        </div>
                        {experienceError?.from && (
                            <div className="text-[14px] text-red-500">{experienceError?.from}</div>
                        )}
                    </div>
                    <div>
                        {experienceModalData?.to === null && selectedExperience?.workingHere === true ? null :
                            <>
                                <label b className='text-[16px] font-medium mb-[6px]'>To</label>
                                <div className='w-full relative'>
                                    <div className='absolute top-[50%] translate-y-[-50%] transform right-[16px] gray-icon  cursor-pointer' dangerouslySetInnerHTML={{ __html: icons.downChevron }} />
                                    <Calendar
                                        value={selectedExperience?.to}
                                        className='w-full'
                                        onChange={(selected) => {
                                            const selectedDate = new Date(selected.value);
                                            const fromDate = new Date(selectedExperience.from);
                                            const today = new Date();

                                            if (selectedDate <= fromDate) {
                                                setExperienceError(prevState => ({
                                                    ...prevState,
                                                    to: "To date should be greater than From date",
                                                }));
                                            } else if (selectedDate > today) {
                                                setExperienceError(prevState => ({
                                                    ...prevState,
                                                    to: "To date should not be in the future",
                                                }));
                                            } else {
                                                setExperienceError(prevState => ({
                                                    ...prevState,
                                                    to: null,
                                                }));
                                                setSelectedExperience({ ...selectedExperience, to: selected.value });
                                            }
                                        }}
                                        view="month"
                                        dateFormat="mm/yy"
                                        placeholder="Select Month/Year"
                                    />
                                </div>
                            </>
                        }
                        {experienceError.to && (
                            <div className="text-[14px] text-red-500">{experienceError.to}</div>
                        )}
                    </div>
                </div>

                {/* Working Here */}
                <div className='flex items-center justify-start gap-[8px]'>
                    <div class="checkboxes__row">
                        <div class="checkboxes__item">
                            <label class="checkbox style-c">
                                <input
                                    onChange={(e) => setSelectedExperience({ ...selectedExperience, workingHere: e.target.checked })}
                                    type="checkbox"
                                    name="working"
                                    id="working"
                                    checked={selectedExperience?.to ? false : selectedExperience?.workingHere} />
                                <div class="checkbox__checkmark"></div>
                            </label>
                        </div>
                    </div>

                    <label className='text-[12px] font-medium leading-[110%] cursor-pointer' htmlFor="working">I’m working here</label>
                </div>

                {/* Description */}
                <div>
                    <label htmlFor="Description" className='2md:text-[16px] text-[14px]  font-medium'>Description (optional)</label>
                    <div className='pt-[6px]'>
                        <textarea
                            onChange={(e) => setSelectedExperience({ ...selectedExperience, description: e.target.value })}
                            className='w-full focus-visible:outline-none border border-[#EFF3F4]  py-[16px] px-[10px] rounded-[8px]'
                            name="description"
                            defaultValue={experienceModalData?.description}
                            id="Description"
                            rows="2"
                            placeholder='Enter description'
                        ></textarea>
                    </div>
                </div>

            </div>
            {/* Save Button */}
            <div className='flex justify-end'>
                <Bluebtn
                    Label={`${loading ? "Saving..." : "Save"}`}
                    onClick={handleSave}
                    className={`text-[14px] font-medium py-[8px] px-[16px] w-fit text-white ${loading ? "cursor-not-allowed !bg-[#B7BCCA]" : "cursor-pointer"} `}
                />
            </div>

        </div>

    )
}
