import axios from 'axios';
import { Calendar } from 'primereact/calendar';
import { Tooltip } from 'primereact/tooltip';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { icons } from '../../../helpers/images';
import PhotoCropper from '../../../helpers/PhotoCropper';
import Toast from '../../../helpers/Toast';
import autoInstance from '../../../utils/axios';
import { login, selectUser } from '../../../utils/reduxtoolkit/slices/userSlice';
import Bluebtn from '../../Common/Btns/Bluebtn';
import CustomModal from '../../Common/CustomModel';
import CustomInput from '../../Common/Inputs/CustomInput';
import CustomSelect from '../../Common/Select/CustomSelect';
function Step4({ setStephandler }) {
    const user = useSelector(selectUser);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [showAddExperience, setShowAddExperience] = useState(false);
    const [showPackage, setShowPackage] = useState(false);
    const [loading, setLoading] = useState(false);
    const screenWidth = window.innerWidth;
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const [emailParam, setEmailParam] = useState(queryParams.get('email'));
    const [otherServicesProvided, setotherServicesProvided] = useState();
    const [showOtherServicesProvided, setShowOtherServicesProvied] = useState();
    const [otherAboutus, setOtherAboutus] = useState(false)
    const [shareUrlError, setShareUrlError] = useState("")
    const [linkedinUrlError, setLinkedinUrlError] = useState("");
    const [fileError, setFileError] = useState("");
    const [experienceError, setExperienceError] = useState({
        organization: null,
        country: null,
        role: null,
        from: null,
        to: null,
        workingHere: null,
        description: null
    });

    const [binaryImage, setBinaryImage] = useState(null);
    useEffect(() => {
        const convertUrlToFile = async (url) => {
            try {
                const response = await fetch(url);
                const blob = await response.blob();
                const file = new File([blob], 'image.jpg', { type: blob.type });
                setBinaryImage(file);
            } catch (error) {
                console.error('Error converting URL to file:', error);
            }
        };

        const imageUrl = user?.profileImage;
        if (user?.profileImage) {
            convertUrlToFile(imageUrl);
        }
    }, [user]);


    const [selectedExperience, setSelectedExperience] = useState({
        organization: null,
        country: null,
        role: null,
        from: null,
        to: null,
        workingHere: false,
        description: ""
    })
    const [experience, setExperience] = useState([]);
    const [userData, setUserData] = useState({
        profileImage: "",
        heardAboutUsFrom: "",
        servicesProvided: null,
        shareUrl: "",
        linkedinUrl: "",
    })
    const [cropperVisible, setCropperVisible] = useState(false)
    const [isCreatePostChecked, setCreatePostChecked] = useState(false)

    const validateUrl = (url) => {
        const urlPattern = /^(https?:\/\/)?(www\.)?[a-zA-Z0-9-]+(\.[a-zA-Z]{2,})+(\/[\w@?^=%&/~+#-]*)?$/;

        return urlPattern.test(url);
    }


    useEffect(() => {
        setEmailParam(emailParam ?? user?.email);
    }, [emailParam, user?.email]);

    const handleExperienceError = () => {
        setExperienceError({
            organization: null,
            country: null,
            role: null,
            from: null,
            to: null,
            workingHere: null,
            description: null
        });
        let isError = false;
        if (selectedExperience.organization === null) {
            setExperienceError(prevState => ({
                ...prevState,
                organization: "Required",
            }));
            isError = true;
        }
        if (selectedExperience.country === null) {
            setExperienceError(prevState => ({
                ...prevState,
                country: "Required",
            }));
            isError = true;
        }
        if (selectedExperience.role === null) {
            setExperienceError(prevState => ({
                ...prevState,
                role: "Required",
            }));
            isError = true;
        }
        if (selectedExperience.from === null) {
            setExperienceError(prevState => ({
                ...prevState,
                from: "Required",
            }));
            isError = true;
        }
        return isError;
    };

    const handleShareUrlChange = (e) => {
        const url = e.target.value;
        setUserData({ ...userData, shareUrl: url });

        if (url.trim() !== "" && !validateUrl(url)) {
            setShareUrlError("Please enter a valid URL");
        } else {
            setShareUrlError(""); // Clear the error state
        }
    }

    const handleLinkedinUrlChange = (e) => {
        const url = e.target.value;
        setUserData({ ...userData, linkedinUrl: url });

        if (url.trim() !== "" && !validateUrl(url)) {
            setLinkedinUrlError("Please enter a valid LinkedIn URL");
        } else {
            setLinkedinUrlError(""); // Clear the error state
        }
    }
    const [selectedFile, setSelectedFile] = useState(null);
    const [Croped, setCroped] = useState(null);
    const [isFilePicked, setIsFilePicked] = useState(null);
    const [locationOptions, setLocationOptions] = useState([]);
    const handleFileChange = (e) => {
        const file = e.target.files[0];
        setIsFilePicked(file);
        if (file) {
            if (file.size > 2.9 * 1024 * 1024) {
                setFileError("File size exceeds 2MB. Please select a smaller file.");
                setSelectedFile(null);
                e.target.value = null;
            } else {
                // Reset error message
                setFileError(""); // or setError(null);
                const reader = new FileReader();
                reader.onload = () => {
                    setCropperVisible(true);
                    setSelectedFile(reader.result);
                };
                reader.readAsDataURL(file);
            }
        }
    };



    const handleSaveExperience = () => {
        try {
            const isError = handleExperienceError();
            // Validation for From and To dates
            const fromDate = new Date(selectedExperience.from);
            const toDate = selectedExperience.workingHere ? new Date() : new Date(selectedExperience.to);
            const today = new Date();

            // Validate "From" date
            if (fromDate > today) {
                setExperienceError(prevState => ({
                    ...prevState,
                    from: "From date should not be greater than today's date",
                }));
                return;
            }

            // Validate "To" date
            if (toDate <= fromDate) {
                setExperienceError(prevState => ({
                    ...prevState,
                    to: "To date should be greater than From date",
                }));
                return;
            }

            if (toDate > today) {
                setExperienceError(prevState => ({
                    ...prevState,
                    to: "To date should not be in the future",
                }));
                return;
            }

            if (!isError) {
                const newExperience = {
                    organization: selectedExperience.organization,
                    country: selectedExperience.country,
                    role: selectedExperience.role,
                    from: selectedExperience.from,
                    ...(selectedExperience.workingHere ? {} : { to: selectedExperience.to }),
                    workingHere: selectedExperience.workingHere,
                    description: selectedExperience.description
                };
                setExperience(prevExperience => [...prevExperience, newExperience]);
                clearFormFields();
                setShowAddExperience(false);
            }
        } catch (error) {
            // If validation error occurs, set the experienceError state
            setExperienceError({
                organization: error.message,
                country: null,
                role: null,
                from: null,
                to: null,
                workingHere: null,
                description: null
            });
        }
    };




    const clearFormFields = () => {
        setSelectedExperience({
            organization: null,
            country: null,
            role: null,
            from: null,
            to: null,
            workingHere: false,
            description: ""
        })
    };

    const otherProvided = Array.isArray(otherServicesProvided) ? otherServicesProvided : (otherServicesProvided ? [otherServicesProvided] : []);
    const selectedProvided = Array.isArray(userData?.servicesProvided) ? userData?.servicesProvided : (userData?.servicesProvided ? [userData?.servicesProvided] : []);
    const mergedServicesProvided = [...new Set([...selectedProvided, ...otherProvided])];

    const handleSave = async () => {
        try {
            setLoading(true)
            const formData = new FormData();
            const experienceData = experience.map((exp) => ({
                "title": exp.role,
                "organization": exp.organization,
                "country": exp.country,
                "from": exp.from,
                "to": exp.to,
                "description": exp.description
            }))
            formData.append('profileImage', binaryImage && !Croped ? binaryImage : isFilePicked);
            formData.append('experience', JSON.stringify(experienceData));
            formData.append('heardAboutUsFrom', userData.heardAboutUsFrom);
            mergedServicesProvided.length > 0 && mergedServicesProvided?.map((item) => {
                if (item !== "Other, please specify") {
                    formData.append('servicesProvided', item)
                }
            });
            // formData.append('servicesProvided', mergedServicesProvided.filter(item => item !== "Other, please specify"));
            formData.append('shareUrl', userData.shareUrl);
            formData.append('linkdinUrl', userData.linkedinUrl);
            formData.append('package', "Premium");

            const response = await autoInstance.post(`/user/update-profile`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
            if (response.status === 200) {
                localStorage.setItem('signupStep', 1);
                dispatch(login({
                    user: response.data,
                    userType: response.data.roleId
                }));
                navigate("/");
                Toast("Registration successful", "success")
            }
        }
        catch (error) {
            if (error.response?.data) { Toast(error.response.data.message, "error") }
            else { console.error(error.message) }
        }
        finally {
            setLoading(false)
        }
    }

    const aboutOptions = [
        { value: 'LinkedIn', label: 'LinkedIn' },
        { value: 'Facebook', label: 'Facebook' },
        { value: 'Twitter', label: 'Twitter' },
        { value: 'Instagram', label: 'Instagram' },
        { value: 'Other', label: 'Other' },
    ]

    const servicesProvidedOptions = [
        { value: 'Climate Risk Assessment and Management', label: 'Climate Risk Assessment and Management' },
        { value: 'Climate Change Mitigation Solutions', label: 'Climate Change Mitigation Solutions' },
        { value: 'Monitoring, Reporting and Analysis', label: 'Monitoring, Reporting and Analysis' },
        { value: 'Carbon Footprint Measurement and Reduction', label: 'Carbon Footprint Measurement and Reduction' },
        { value: 'Sustainable Infrastructure and Design', label: 'Sustainable Infrastructure and Design' },
        { value: 'Renewable Energy Solutions', label: 'Renewable Energy Solutions' },
        { value: 'Climate Adaptation Planning', label: 'Climate Adaptation Planning' },
        { value: 'Regulatory Compliance', label: 'Regulatory Compliance' },
        { value: 'Sustainable Supply Chain Management', label: 'Sustainable Supply Chain Management' },
        { value: 'Climate Education', label: 'Climate Education' },
        { value: 'Data and Technology Solutions', label: 'Data and Technology Solutions' },
        { value: 'Environmental Consulting', label: 'Environmental Consulting' },
        { value: 'Funding for Climate Projects', label: 'Funding for Climate Projects' },
        { value: 'Other, please specify', label: 'Service not listed' },
    ]

    useEffect(() => {
        getCountryName()
    }, [])

    const countryOptions = [
        { value: 'India', label: 'India' },
        { value: 'USA', label: 'USA' },
        { value: 'UK', label: 'UK' },
        { value: 'Canada', label: 'Canada' },
    ]

    const OptionshandleChange = (selected, field) => {

        const selectedValues = selected.map(option => option.value);
        setUserData(prev => ({ ...prev, [field]: selectedValues }));
    }

    const showModal = () => {
        setExperienceError({ organization: null, country: null, role: null, from: null, to: null, workingHere: null, description: null })
        setShowAddExperience(true)
    }

    const removeExperience = (indexToRemove) => {
        setExperience(prevExperience => prevExperience.filter((_, index) => index !== indexToRemove));
    };
    useEffect(() => {
        const foundOption = aboutOptions.find(option => option.value === userData?.heardAboutUsFrom);


        if (userData?.servicesProvided && userData?.servicesProvided?.includes("Other, please specify")) {
            setShowOtherServicesProvied(true);
        } else if (userData?.servicesProvided && !userData.servicesProvided.includes("Other, please specify")) {
            setShowOtherServicesProvied(false);
        }
        if (userData?.heardAboutUsFrom && userData?.heardAboutUsFrom === "Other") {
            setOtherAboutus(true);
        } else if (foundOption) {
            setOtherAboutus(false);
        }

    }, [userData]);

    const getCountryName = useCallback(async () => {
        try {
            const response = await axios.get('https://countriesnow.space/api/v0.1/countries');
            if (response.status === 200) {
                const countryOptions = response.data.data.map(country => ({
                    label: country.country,
                    value: country.country,
                }));
                setLocationOptions(countryOptions);
            } else {
                Toast(response.data.message, 'error');
            }
        } catch (error) {
            Toast(error.message, 'error');
        }
    }, []);

    const cropFileChange = (file) => {
        setCroped(file);
        // Check if the file is base64 encoded
        if (file?.startsWith('data:')) {
            // Extract the base64 data
            const base64Data = file.split(',')[1];
            // Decode base64 to binary
            const binaryData = atob(base64Data);
            // Convert binary to Uint8Array
            const byteArray = new Uint8Array(binaryData.length);
            for (let i = 0; i < binaryData.length; i++) {
                byteArray[i] = binaryData.charCodeAt(i);
            }
            // Create Blob object from binary data
            const blob = new Blob([byteArray], { type: 'image/jpeg' }); // Change the MIME type as per your requirement
            // Convert Blob to File
            const imageFile = new File([blob], 'image.jpg', { type: 'image/jpeg' }); // Change the filename and MIME type as per your requirement
            // Set image file using setIsFilePicked
            setIsFilePicked(imageFile);
            setBinaryImage(null)
        } else {
            // If not base64 encoded, directly set the file using setIsFilePicked
            setIsFilePicked(file);
        }
    };


    const startsWithHttp = (url) => {
        return url.startsWith("http");
    };

    const tooltipContent = `Supported files:
  - JPEG
  - PNG
  - GIF
Maximum image size: 2MB`;

    return (
        <div className='w-full 2lg:p-[50px] lg:p-[40px] md:p-[35px] 2sm:p-[30px] sm:p-[25px] p-[20px]'>

            <div className='2sm:w-[463px] w-[75vw]'>
                <div className='2sm:flex items-center justify-between'>
                    <h1 className='2lg:text-[26px] 2sm:text-[24px] md:text-[22px] text-[20px] leading-[normal] font-[600]'>Additional Information</h1>
                    <p className='2md:text-[16px] text-[14px]  font-medium text-[#6D7486] leading-[normal]'>Optional</p>
                </div>
                <div className='2sm:pt-[30px] pt-[20px] space-y-[16px]'>
                    {/* Upload Experience Profile */}
                    <label htmlFor="Upload" className='cursor-pointer'>
                        <p className='2md:text-[16px] text-[14px]  font-medium'>Upload a clear profile photo</p>
                        <input
                            type="file"
                            id="Upload"
                            className='hidden'
                            accept="image/*"
                            onChange={handleFileChange}
                        />
                        <div className='mt-[10px] flex items-center justify-start gap-[12px]'>
                            <div className='w-[60px] h-[60px] flex items-center justify-center'>
                                {/* Display image preview if selected */}
                                {
                                    user?.profileImage && !Croped ?
                                        <img
                                            src={startsWithHttp(user?.profileImage) ? user?.profileImage : `${process.env.REACT_APP_IMAGE_URL}${user?.profileImage}`}
                                            alt="Preview"
                                            className='rounded-[8px] overflow-hidden border border-[#EFF3F4]'
                                            style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                                        /> :
                                        Croped ? <img src={Croped} alt="Preview" className='rounded-[8px] overflow-hidden border border-[#EFF3F4]' style={{ width: '100%', height: '100%', objectFit: 'cover' }} /> : <div dangerouslySetInnerHTML={{ __html: icons.bluePlus }} />
                                }
                            </div>
                            <div>
                                <div className='flex items-center justify-start gap-[6px] pb-[4px]'>
                                    <p className='text-[14px] font-[500] leading-[110%]  text-[#2A8DDE]'>Upload photo</p>
                                    <Tooltip
                                        title={tooltipContent}
                                        target=".Postinfo"
                                        placement="bottom"
                                        className="custom-tooltip"
                                    />
                                    <span
                                        data-pr-tooltip={tooltipContent}
                                        data-pr-position="right"
                                        data-pr-at="right+2 top+9"
                                        data-pr-my="left center-2"
                                        className="Postinfo cursor-pointer"
                                    >
                                        <svg
                                            xmlns='http://www.w3.org/2000/svg'
                                            viewBox='0 0 24 24'
                                            fill='none'
                                            stroke='currentColor'
                                            strokeWidth='2'
                                            strokeLinecap='round'
                                            strokeLinejoin='round'
                                            className='lucide lucide-info text-[#6D7486] h-4 w-4'
                                        >
                                            <circle cx='12' cy='12' r='10' />
                                            <path d='M12 16v-4' />
                                            <path d='M12 8h.01' />
                                        </svg>
                                    </span>

                                </div>
                                <p className='text-[12px] leading-[140%] text-[#6D7486]'>Max 2 MB</p>
                                {fileError && <p className="text-red-500 text-sm">{fileError}</p>}

                            </div>
                        </div>
                    </label>

                    <CustomSelect
                        label="How did you hear about us?"
                        onChange={(selected) => setUserData({ ...userData, heardAboutUsFrom: selected.label })}
                        options={aboutOptions}
                        placeholder="Select"
                        className="w-full" 
                        showClearIcon={true}
                        />

                    {otherAboutus &&
                        <div>
                            <CustomInput label="Please specify" type="text" placeholder="Please specify other"
                                onChange={(e) => setUserData({ ...userData, heardAboutUsFrom: e.target.value })}
                            />
                        </div>
                    }
                    <div className='flex items-center  justify-between w-full  '>
                        <p className='2md:text-[16px] text-[14px]  font-medium'>Experience</p>
                        <svg onClick={() => showModal()} className='cursor-pointer w-[25px] h-[26px]' xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
                            <path d="M3.33301 8.51103H12.6663M7.99967 3.84436V13.1777" stroke="#2A8DDE" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                    </div>
                    {
                        experience.length > 0 && experience.map((item, index) => {
                            const fromDate = new Date(item.from).toLocaleString('en-US', { month: 'short', year: 'numeric' });
                            const toDate = item.to ? new Date(item.to).toLocaleString('en-US', { month: 'short', year: 'numeric' }) : "Present";

                            return (
                                <div>
                                    <div key={index} className='flex items-center justify-start gap-[8px]  border broder-[#EFF3F4] rounded-[6px] p-[14px] group relative'>
                                        <div className=''>
                                            <p className='text-[16px] font-normal leading-[140%] text-[#21272A]'>{item?.organization}</p>
                                            <div className='flex items-center justify-start gap-[5px]'>
                                                <p className='text-[14px] font-[400] leading-[140%] text-[#6D7486]'>{item?.role}</p>
                                                <div className='w-[3px] h-[3px] rounded-full overflow-hidden bg-[#6D7486]'></div>
                                                <p className='text-[12px] font-[400] leading-[140%] text-[#6D7486]'>{fromDate} - {toDate}</p>
                                            </div>
                                        </div>
                                        <div className='absolute  right-[16px] top-[50%] translate-y-[-50%]'>
                                            {/* <div onClick={() => { setshowAddExperience(true); setModeltype("Edit") }} className="flex items-center justify-end gap-[10px]">
                                                    <div className='cursor-pointer' dangerouslySetInnerHTML={{ __html: icons.Edit }} />
                                                </div> */}
                                            <p className='cursor-pointer' onClick={() => removeExperience(index)}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="#0C1835" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round">
                                                    <path d="M3 6h18"></path>
                                                    <path d="M19 6v14c0 1-1 2-2 2H7c-1 0-2-1-2-2V6"></path>
                                                    <path d="M8 6V4c0-1 1-2 2-2h4c1 0 2 1 2 2v2"></path>
                                                    <line x1="10" x2="10" y1="11" y2="17"></line>
                                                    <line x1="14" x2="14" y1="11" y2="17"></line>
                                                </svg>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            );
                        })
                    }

                    <CustomSelect
                        label="Services provided"
                        onChange={(selected) => OptionshandleChange(selected, "servicesProvided")}
                        options={servicesProvidedOptions}
                        isMulti={true}
                        placeholder="Select one or more"
                        className=" w-full "
                        showClearIcon={true}
                    />
                    {
                        showOtherServicesProvided &&
                        <div>
                            <CustomInput label="Please specify Services Provided" type="text" placeholder="Please specify Services Provided"
                                onChange={(e) => setotherServicesProvided(e.target.value)}
                            />
                        </div>
                    }

                    <CustomInput
                        label={`${user?.roleId === 2 ? "Company" : "Personal"} URL`}
                        type="text"
                        onChange={handleShareUrlChange}
                        placeholder="https://get2net0.com"
                        className="w-full"
                    />
                    {shareUrlError && <span className="text-red-500">{shareUrlError}</span>}

                    <CustomInput
                        label={`LinkedIn profile`}
                        type="text"
                        onChange={handleLinkedinUrlChange}
                        placeholder="https://www.linkedin.com/"
                        className="w-full"
                    />
                    {linkedinUrlError && <span className="text-red-500">{linkedinUrlError}</span>}

                    <Bluebtn
                        onClick={() => {
                            if (!shareUrlError && !linkedinUrlError) {
                                handleSave()
                            } else {
                                if (shareUrlError) {
                                    console.error(shareUrlError);
                                }
                                if (linkedinUrlError) {
                                    console.error(linkedinUrlError);
                                }
                            }
                        }}
                        className={`lg:p-[18px] p-[14px] w-full 2sm:text-[16px] text-[14px] font-medium leading-[100%]  text-white`} Label={`${loading ? "loading..." : "Next"}`} />
                    <button onClick={() => navigate("/")} className={`lg:p-[18px] p-[14px] w-full 2sm:text-[16px] text-[14px] font-medium leading-[100%]  bg-[#EAF4FC] text-[#2A8DDE] `} Label={`Next`} >Do it later</button>
                </div>
                <CustomModal
                    showModal={showAddExperience}
                    ModelType="Experience"
                    setShowModal={setShowAddExperience}
                    bodyClassName={` 2md:rounded-[16px] overflow-hidden rounded-none 2md:w-auto 2md:h-auto w-screen h-screen max-w-[525px]`}
                >
                    <div className='p-[25px] 2md:rounded-[16px] overflow-hidden   bg-white'>
                        <div className='flex items-center justify-between header-shadow pb-[10px]'>
                            <h1 className='2lg:text-[26px] 2sm:text-[24px] md:text-[22px] text-[20px]   leading-[normal] font-[600]'>Add experience</h1>
                            <div onClick={() => setShowAddExperience(false)} className='cursor-pointer' dangerouslySetInnerHTML={{ __html: icons.closeIcon }} />
                        </div>
                        <div className='space-y-[12px] pb-[10px] 2md:max-h-[70vh] max-h-[calc(100vh-140px)] overflow-y-auto'>
                            {/* Organization */}
                            <div>
                                <CustomInput
                                    onChange={(e) => setSelectedExperience({ ...selectedExperience, organization: e.target.value })}
                                    label='Organization'
                                    type='text'
                                    placeholder='Organization name'
                                />
                                {experienceError.organization && (
                                    <div className="text-[14px] text-red-500">{experienceError.organization}</div>
                                )}
                            </div>

                            {/*  Country */}
                            <div>
                                <CustomSelect
                                    label="Country"
                                    onChange={(slected) => setSelectedExperience({ ...selectedExperience, country: slected.label })}
                                    options={locationOptions}
                                    placeholder="Select Country"
                                    className=" w-full "
                                />
                                {experienceError.country && (
                                    <div className="text-[14px] text-red-500">{experienceError.country}</div>
                                )}
                            </div>


                            {/* Role */}
                            <div>
                                <CustomInput
                                    onChange={(e) => setSelectedExperience({ ...selectedExperience, role: e.target.value })}
                                    label='Title / Role'
                                    type='text'
                                    placeholder='Ex. Climate expert'
                                />
                                {experienceError.role && (
                                    <div className="text-[14px] text-red-500">{experienceError.role}</div>
                                )}
                            </div>

                            {/* From and To */}
                            <div className='grid 2sm:grid-cols-2 gap-[10px]'>
                                <div>
                                    <label className='text-[16px] font-medium mb-[6px]'>From</label>
                                    <div className='w-full relative'>
                                        <div className='absolute top-[50%] translate-y-[-50%] transform right-[16px] gray-icon cursor-pointer' dangerouslySetInnerHTML={{ __html: icons.downChevron }} />
                                        <Calendar
                                            className='w-full'
                                            value={selectedExperience.from}
                                            onChange={(selected) => {
                                                const selectedDate = new Date(selected.value);
                                                const today = new Date();

                                                if (selectedDate > today) {
                                                    setExperienceError(prevState => ({
                                                        ...prevState,
                                                        from: "From date should not be greater than today's date",
                                                    }));
                                                } else {
                                                    setExperienceError(prevState => ({
                                                        ...prevState,
                                                        from: null,
                                                    }));
                                                    setSelectedExperience({ ...selectedExperience, from: selected.value });
                                                }
                                            }}
                                            view="month"
                                            dateFormat="mm/yy"
                                            placeholder="Select Month/Year"
                                        />
                                    </div>
                                    {experienceError.from && (
                                        <div className="text-[14px] text-wrap text-red-500">{experienceError.from}</div>
                                    )}
                                </div>
                                <div>

                                    {selectedExperience.workingHere ? null :
                                        <>
                                            <label className='text-[16px] font-medium mb-[6px]'>to</label>
                                            <div className='w-full relative'>
                                                <div className='absolute top-[50%] translate-y-[-50%] transform right-[16px] gray-icon cursor-pointer' dangerouslySetInnerHTML={{ __html: icons.downChevron }} />
                                                <Calendar
                                                    className='w-full'
                                                    value={selectedExperience.to}
                                                    onChange={(selected) => {
                                                        const selectedDate = new Date(selected.value);
                                                        const fromDate = new Date(selectedExperience.from);
                                                        const today = new Date();

                                                        if (selectedDate <= fromDate) {
                                                            setExperienceError(prevState => ({
                                                                ...prevState,
                                                                to: "To date should be greater than From date",
                                                            }));
                                                        } else if (selectedDate > today) {
                                                            setExperienceError(prevState => ({
                                                                ...prevState,
                                                                to: "To date should not be in the future",
                                                            }));
                                                        } else {
                                                            setExperienceError(prevState => ({
                                                                ...prevState,
                                                                to: null,
                                                            }));
                                                            setSelectedExperience({ ...selectedExperience, to: selected.value });
                                                        }
                                                    }}
                                                    view="month"
                                                    dateFormat="mm/yy"
                                                    placeholder="Select Month/Year"
                                                />
                                            </div>
                                        </>
                                    }
                                    {experienceError.to && (
                                        <div className="text-[14px] text-red-500">{experienceError.to}</div>
                                    )}
                                </div>
                            </div>

                            {/* Working Here */}
                            <div className='flex items-center justify-start gap-[8px]'>
                                <div class="checkboxes__row">
                                    <div class="checkboxes__item">
                                        <label class="checkbox style-c">
                                            <input
                                                onChange={(e) => setSelectedExperience({ ...selectedExperience, workingHere: e.target.checked })}
                                                type="checkbox"
                                                name="working"
                                                id="working"
                                                className='w-[16px] h-[16px] checked:bg-[#2A8DDE] hover:bg-[#2A8DDE]' />
                                            <div class="checkbox__checkmark"></div>
                                        </label>
                                    </div>
                                </div>
                                <label className='text-[12px] font-medium leading-[110%] cursor-pointer' htmlFor="working">I’m working here</label>
                            </div>

                            {/* Description */}
                            <div>
                                <label htmlFor="Description" className='2md:text-[16px] text-[14px]  font-medium'>Description (optional)</label>
                                <div className='pt-[6px]'>
                                    <textarea
                                        onChange={(e) => setSelectedExperience({ ...selectedExperience, description: e.target.value })}
                                        className='w-full focus-visible:outline-none border border-[#EFF3F4]  py-[16px] px-[10px] rounded-[8px]'
                                        name="description"
                                        id="Description"
                                        rows="2"
                                        placeholder='Enter description'
                                    ></textarea>
                                </div>
                            </div>

                        </div>
                        {/* Save Button */}
                        <div className='flex justify-end pt-[16px]'>
                            <Bluebtn
                                Label="Save"
                                onClick={handleSaveExperience}
                                className="text-[14px] font-medium py-[8px] px-[16px] w-fit text-white"
                            />
                        </div>

                    </div>
                </CustomModal>
            </div>
            {
                cropperVisible &&
                <PhotoCropper
                    modalTitle={"Crop profile photo"}
                    yourImage={selectedFile}
                    setYourImage={cropFileChange}
                    cropperVisible={cropperVisible}
                    setBinaryImage={setBinaryImage}
                    setCropperVisible={setCropperVisible}
                    isCreatePostChecked={isCreatePostChecked}
                    setCreatePostChecked={setCreatePostChecked}
                    aspectRatios={[{ label: '1:1', value: 1 / 1 }]}
                />
            }
        </div>
    )
}

export default Step4;
