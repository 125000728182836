import { useCallback, useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import Toast from '../../../helpers/Toast';
import Bluebtn from '../../Common/Btns/Bluebtn';
import CustomInput from '../../Common/Inputs/CustomInput';

import { useDispatch, useSelector } from 'react-redux';
import { icons, images } from '../../../helpers/images';
import autoInstance from '../../../utils/axios';
import { selectLoading, setLoading } from '../../../utils/reduxtoolkit/slices/skeletonSlice';
import { step1 } from '../../../utils/reduxtoolkit/slices/userSlice';




const Step1 = ({ onNextStep, setStephandler }) => {
    const dispatch = useDispatch();
    const isLoading = useSelector(selectLoading);
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const emailParam = queryParams.get('email');
    const code = queryParams.get('code');
    const [PageLoading, setPageLoading] = useState(false)
    const [selectedOption, setSelectedOption] = useState(null);
    const [Next, setNext] = useState(false);
    const [showSelectType, setShowSelectType] = useState(false);
    const [toggleModel, setToggleModel] = useState(false);
    const [emailError, setEmailError] = useState("");
    const [VerifyLoad, setVerifyLoad] = useState(false);
    const [confirmEmailError, setConfirmEmailError] = useState("");
    const [signupLoading, setSignupLoading] = useState(false);
    const [linkdinLoading, setLinkdinLoading] = useState(false);
    const [userStatus, setuserStatus] = useState();
    const [countdown, setCountdown] = useState(0);
    const [userData, setUserData] = useState({
        email: '',
        confirmEmail: '',
    });

    const [sendingEmail, setSendingEmail] = useState(false);

    const initialCountdown = 60;

    const startCountdown = () => {
        setCountdown(initialCountdown);
    };

    const decrementCountdown = () => {
        setCountdown(prevCountdown => prevCountdown - 1);
    };

    useEffect(() => {
        if (countdown > 0) {
            const timer = setTimeout(() => {
                decrementCountdown();
            }, 1000);

            return () => clearTimeout(timer);
        }
    }, [countdown]);

    const isResendDisabled = countdown > 0 || sendingEmail || signupLoading;
    const formattedCountdown = `${countdown} seconds`;

    const EmailVerifiedHandler = async () => {
        try {
            dispatch(setLoading(true));
            const response = await autoInstance.get(`/verify-email/${emailParam}/${code}`);
            if (response.status === 200) {
            }
        } catch (error) {
            Toast(error?.response?.data?.message, "error");
        }
        finally {
            dispatch(setLoading(false));
        }
    };

    useEffect(() => {
        const verifyEmail = async () => {
            if (emailParam) {
                EmailVerifiedHandler();
            }
        };

        verifyEmail();
    }, [emailParam]);




    const UserHandleOptionChage = (e) => {
        setSelectedOption(e.target.value);
    };
    const handleError = () => {
        setEmailError("");
        setConfirmEmailError("");
        let isError = false;
        if (!/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(userData.email)) {
            setEmailError("Please Enter a valid Email Address");
            isError = true
        }
        if (!/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(userData.confirmEmail)) {
            setConfirmEmailError("Please Enter a valid Email Address");
            isError = true
        }
        if (userData.email !== userData.confirmEmail) {
            // setEmailError("Email does not match");
            setConfirmEmailError("Email does not match");
            isError = true
        }
        if (userData.confirmEmail !== userData.email) {
            // setEmailError("Email does not match");
            setConfirmEmailError("Email does not match");
            isError = true
        }
        if (isError) throw new Error("Validation Error");
    }
    const handleCreateAccount = async (e) => {
        e.preventDefault();
        try {
            setSignupLoading(true);
            handleError();
            if (emailError === "" && confirmEmailError === "") {
                const response = await autoInstance.post(`/auth/register`,
                    {
                        email: userData?.email,
                        roleId: selectedOption === "Individual" ? 1 : 2
                    }, {
                    toastShow: true
                });
                if (response.status === 200) {

                    setToggleModel(true)
                    dispatch(step1({ email: userData.email }));
                }
            }
            startCountdown();

        } catch (error) { }
        finally {
            setSignupLoading(false);
        }
    }

    const handleKeyPress = (event) => {
        if (event.key === "Enter") {
            handleCreateAccount(event);
        }
    };


    const onLoginStart = useCallback(() => {
        setLinkdinLoading(true);
        window.location.href = `${process.env.REACT_APP_BACKEND_URL}/api/v1/auth/linkedin-redirect`;
    }, [])
    return (
        <div className={`${isLoading ? 'hidden' : ''}`}>
            {
                !VerifyLoad ?
                    <>
                        {
                            !Next ? (
                                // Select Type of User
                                <div className='2lg:p-[50px] lg:p-[40px] md:p-[35px] 2sm:p-[30px] sm:p-[25px] p-[20px]'>
                                    <div className='2sm:w-[315px] w-[75vw]'>
                                        <p className='text-[16px] font-medium leading-[110%]'>Register as</p>
                                        <div className='grid grid-cols-2 py-[16px] 2md:gap-[24px] md:gap-[22px] 2sm:gap-[18px] gap-[16px]'>
                                            {/* Radio Btn 1 */}
                                            <div className={`border 2sm:p-[16px] p-[12px] rounded-[14px] text-center cursor-pointer ${selectedOption === 'Individual' ? 'border-[#2A8DDE]' : 'border-[#EFF3F4]'}`}>
                                                <label>
                                                    <input
                                                        type='radio'
                                                        name='registrationType'
                                                        value='Individual'
                                                        checked={selectedOption === 'Individual'}
                                                        onChange={UserHandleOptionChage}
                                                        style={{ display: 'none' }}
                                                    />
                                                    <span className='2sm:text-[14px] text-[12px] leading-[110%] font-medium cursor-pointer text-[#3D465D] '>An Individual</span>
                                                    <div className='mx-auto flex-center 2sm:pt-[8px] pt-[4px] 2sm:w-auto h-auto w-[40px] cursor-pointer' dangerouslySetInnerHTML={{ __html: icons.IndividualIcon }} />
                                                </label>
                                            </div>
                                            {/* Radio Btn 2 */}
                                            <div className={`border 2sm:p-[16px] p-[12px] rounded-[14px] text-center cursor-pointer ${selectedOption === 'Company' ? 'border-[#2A8DDE]' : 'border-[#EFF3F4]'}`}>
                                                <label>
                                                    <input
                                                        type='radio'
                                                        name='registrationType'
                                                        value='Company'
                                                        checked={selectedOption === 'Company'}
                                                        onChange={UserHandleOptionChage}
                                                        style={{ display: 'none' }}
                                                    />
                                                    <span className='2sm:text-[14px] text-[12px] leading-[110%] font-medium cursor-pointer text-[#3D465D] '>
                                                        A Company
                                                    </span>
                                                    <div className='mx-auto flex-center 2sm:pt-[8px] pt-[4px] 2sm:w-auto h-auto w-[50px] cursor-pointer' dangerouslySetInnerHTML={{ __html: icons.CompanyIcon }} />
                                                </label>
                                            </div>
                                        </div>
                                        {showSelectType ? <p className='text-red-500 text-[14px] pb-[16px]'>Select an option</p> : null}
                                        <Bluebtn onClick={() => selectedOption ? setNext(true) : setShowSelectType(true)} className={`lg:p-[18px] p-[14px] w-full 2sm:text-[16px] text-[14px] font-medium leading-[100%]  text-white`} Label={'Next'} />
                                        <div className='flex font-medium 2md:text-[16px] text-[14px]  items-center justify-center gap-[8px] pt-[16px]'>
                                            <p>Already have an account?</p><Link to='/login' className='text-[#2A8DDE] font-medium'>sign in</Link>
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <>
                                    {!toggleModel ? (
                                        <div className='2md:flex grid justify-start 2md:max-w-[unset] md:max-w-[650px] max-w-[unset]' >
                                            {/* // Email & Confim email */}
                                            <div className='2lg:py-[50px] lg:py-[40px] md:py-[35px] 2sm:py-[30px] sm:py-[25px] py-[20px] 2lg:pl-[50px] lg:pl-[40px] md:pl-[35px] 2sm:pl-[30px] sm:pl-[25px] pl-[20px] 2md:pr-[00px] md:pr-[35px] 2sm:pr-[30px] sm:pr-[25px] pr-[20px] '>
                                                <div className=' 2md:w-[315px] w-[100%] mx-auto flex flex-col justify-center'>
                                                    <h1 className=' 2lg:text-[24px] 2sm:text-[22px] md:text-[20px] text-[18px] leading-[110%] font-[500] 2md:text-left text-center pb-[16px]'>Create your account</h1>
                                                    {
                                                        selectedOption === "Individual" &&
                                                        <div className='pb-[16px]'>
                                                            <Bluebtn className={`lg:p-[18px] p-[14px] w-full 2sm:text-[16px] text-[14px] font-medium leading-[100%]  text-white gap-[8px] ${linkdinLoading ? "bg-[#B7BCCA] text-[#FFFFFF]" : "!bg-[#007EBB] hover:bg-[#3f7996ed]"}`}   Label={`${linkdinLoading ? 'Loading...' : ' Sign up using LinkedIn'} `} onClick={linkdinLoading ? null : onLoginStart} />
                                                        </div>
                                                    }
                                                    {
                                                        selectedOption === "Individual" &&
                                                        <div className='flex items-center justify-center gap-[10px]'>
                                                            <div className='h-[1px] bg-[#EFF3F4] w-[40%]'></div>
                                                            <div className='text-[16px] font-medium '>or</div>
                                                            <div className='h-[1px] bg-[#EFF3F4] w-[40%]'></div>
                                                        </div>
                                                    }
                                                    <div className='space-y-[16px]'>
                                                        <CustomInput label='Email' type='email' value={userData.email} name='email' onPaste={(e) => e.preventDefault()} onCopy={(e) => e.preventDefault()} onChange={(e) => setUserData({ ...userData, email: e.target.value })} placeholder='xyz@example.com' />
                                                        {emailError.length > 0 && <p className='text-[14px] text-red-500'>{emailError}</p>}
                                                        <CustomInput onKeyPress={handleKeyPress} label='Confirm your email' value={userData.confirmEmail} type='email' name='confirmEmail' onPaste={(e) => e.preventDefault()} onChange={(e) => setUserData({ ...userData, confirmEmail: e.target.value })} placeholder='xyz@example.com' />
                                                        {confirmEmailError.length > 0 && <p className='text-[14px] text-red-500'>{confirmEmailError}</p>}
                                                    </div>
                                                    <div className='my-[16px]'>
                                                        <Bluebtn className={`lg:p-[18px] p-[14px] w-full 2sm:text-[16px] text-[14px] font-medium leading-[100%]  text-white ${signupLoading ? "bg-[#B7BCCA] text-[#FFFFFF]" : "!bg-[#007EBB]"}`} Label={`${signupLoading ? 'Loading...' : 'Create Account'}`} onClick={(e) => signupLoading ? null : handleCreateAccount(e)} />
                                                    </div>
                                                    <div className='flex font-medium 2md:text-[16px] text-[14px]  items-center justify-center gap-[8px]'>
                                                        <p>Already have an account?</p><Link to='/login' className='text-[#2A8DDE] font-medium'>sign in</Link>
                                                    </div>
                                                    {
                                                        selectedOption !== "Individual" &&
                                                        <p className='text-[#6D7486] text-[14px] leading-[140%] font-[500] pt-[12px] text-center'>Use your linkedin profile to  sign up as individual </p>
                                                    }
                                                </div>
                                            </div>
                                            <div className='2lg:max-w-[578px] lg:max-w-[520px] 2md:max-w-[440px] max-w-auto  2lg:pl-[50px] lg:pl-[40px] 2md:pl-[30px] text-center 2md:pt-0 pt-[16px] 2md:rounded-tr-[24px] rounded-tr-[0px] md:rounded-br-[24px]   2sm:rounded-br-[16px] rounded-br-[14px] 2md:rounded-bl-[0px] md:rounded-bl-[24px] 2sm:rounded-bl-[16px] rounded-bl-[14px]'>
                                                {/* md:rounded-[22px] 2sm:rounded-[16px] rounded-[14px] */}
                                                {
                                                    images.Signup ?
                                                        <div className='  w-full h-auto 2md:h-[529px] 2sm:h-[529px]  flex-center 2md:rounded-tr-[24px] rounded-tr-[0px] overflow-hidden mx-auto md:rounded-br-[24px]   2sm:rounded-br-[16px] rounded-br-[14px] 2md:rounded-bl-[0px] md:rounded-bl-[24px] 2sm:rounded-bl-[16px] rounded-bl-[14px] '>
                                                            <img src={images.Signup} alt="" className='w-full h-full object-cover' />
                                                        </div> :
                                                        <div>
                                                            <h2 className='2lg:text-[32px] lg:text-[28px] 2md:text-[26px] md:text-[24px] 2sm:text-[22px] text-[20px] leading-[110%] font-[600] text-[#0C1835] '>Membership has its benefits!</h2>
                                                            <p className=' 2lg:text-[18px] lg:text-[16px] 2md:text-[14px] text-[12px] leading-[140%] pt-[8px] text-[#6D7486]'>Post your Climate Related Issue and let Experts Help you find a Solution OR if you're an Expert or Service Provider, suggest a Solution</p>
                                                            <div className='mt-[16px] 2md:w-[300px] 2sm:w-[300px] w-full h-auto 2md:h-[300px] 2sm:h-[300px]     bg-[#C8E7F5] mx-auto'></div>
                                                        </div>
                                                }
                                            </div>
                                        </div>) : (
                                        <>
                                            {/* Verify email  */}
                                            <div className='2lg:p-[50px] lg:p-[40px] md:p-[35px] 2sm:p-[30px] sm:p-[25px] p-[20px]'>
                                                <div className='2sm:w-[315px] w-[75vw]'>
                                                    <h1 className='2lg:text-[24px] 2sm:text-[22px] md:text-[20px] text-[18px] leading-[110%] font-[500] text-center'>Verify your email</h1>
                                                    <p className='text-[#6D7486] text-[14px] leading-[140%] font-[500] pt-[12px] text-center'>If email not received, check your spam folder</p>
                                                    <div className='mt-[20px]'>
                                                        <Bluebtn onClick={(e) => signupLoading || isResendDisabled ? null : handleCreateAccount(e)} className={`${signupLoading || isResendDisabled ? "bg-[#B7BCCA] " : ""} lg:p-[18px] p-[14px] w-full 2sm:text-[16px] text-[14px] font-medium leading-[100%] text-white`} Label={`${signupLoading ? 'Loading...' : isResendDisabled ? formattedCountdown : 'Resend verification email'}`} />

                                                    </div>
                                                </div>

                                            </div>
                                        </>
                                    )

                                    }
                                </>
                            )
                        }
                    </> : <></>
            }
        </div>
    );
};

export default Step1;

export const LinkedInIcon = () => {
    return (
        <svg className='w-auto  h-[20px]' xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M3.16667 24.511H21.8333C23.3061 24.511 24.5 23.3171 24.5 21.8443V3.17765C24.5 1.70489 23.3061 0.510986 21.8333 0.510986H3.16667C1.69391 0.510986 0.5 1.70489 0.5 3.17765V21.8443C0.5 23.3171 1.69391 24.511 3.16667 24.511Z" fill="#007EBB" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M21.1668 21.1777H17.6054V15.1117C17.6054 13.4486 16.9734 12.5192 15.6571 12.5192C14.225 12.5192 13.4769 13.4864 13.4769 15.1117V21.1777H10.0446V9.62214H13.4769V11.1787C13.4769 11.1787 14.5088 9.2691 16.961 9.2691C19.4121 9.2691 21.1668 10.7659 21.1668 13.8614V21.1777ZM5.94995 8.10903C4.78085 8.10903 3.8335 7.15425 3.8335 5.9767C3.8335 4.79915 4.78085 3.84436 5.94995 3.84436C7.11904 3.84436 8.06583 4.79915 8.06583 5.9767C8.06583 7.15425 7.11904 8.10903 5.94995 8.10903ZM4.17768 21.1777H7.75663V9.62214H4.17768V21.1777Z" fill="white" />
        </svg>
    )
}