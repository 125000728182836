import React, { useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import PDFViewer from "./PdfViewer";
import XLSXViewer from "./XLSXViewer";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const DocumentViewer = ({ value }) => {
  const pdfUrl = process.env.REACT_APP_IMAGE_URL + value?.attachment_url;

  // Function to extract file extension
  const getFileExtension = (filename) => {
    return filename.slice(((filename.lastIndexOf(".") - 1) >>> 0) + 2);
  };

  const fileExtension = getFileExtension(pdfUrl);

  // Render corresponding viewer component based on file extension
  const renderViewer = () => {
    switch (fileExtension.toLowerCase()) {
      case "pdf":
        return <PDFViewer fileUrl={pdfUrl} />;
      case "xlsx":
        return <XLSXViewer fileUrl={pdfUrl} />;
      // Add cases for other document types as needed
      default:
        return <div>Unsupported file type</div>;
    }
  };




  return (
    <div className="relative pt-[2px]">
      <div
        style={{
          position: "relative",
          width: "300px",
          height: "300px",
          overflow: "hidden",
          border: "1px solid #eaf4fc",
          borderRadius: "10px",
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        {renderViewer()}
        <div className="absolute top-0 left-0 right-0 p-[10px] bg-[#eaf4fc] bg-opacity-90 flex justify-between">
          <span className="text-[14px] font-normal leading-[140%] text-[#000]">
            {value?.attachment_url.split("\\").pop()}
          </span>
          <a
            href={pdfUrl}
            download
            className="text-blue-500 hover:underline"
            target="_blank"
            rel="noreferrer"
          >
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
              <path stroke-linecap="round" stroke-linejoin="round" d="M3 16.5v2.25A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75V16.5M16.5 12 12 16.5m0 0L7.5 12m4.5 4.5V3" />
            </svg>

          </a>
        </div>
      </div>
      <div className="absolute bottom-0 left-0 right-0 p-[10px] bg-[#eaf4fc] bg-opacity-90 border-bottom-left-radius-10 border-bottom-right-radius-10">
        <pre className="text-[14px] font-normal leading-[140%] text-[#000] w-full caption-text">
          {value.content}
        </pre>
      </div>
    </div>
  );
};

export default DocumentViewer;
