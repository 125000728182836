import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { icons } from '../../../helpers/images';
import Toast from '../../../helpers/Toast';
import autoInstance from '../../../utils/axios';
import { selectUser } from '../../../utils/reduxtoolkit/slices/userSlice';
import Bluebtn from '../Btns/Bluebtn';
import CustomSelect from '../Select/CustomSelect';


function LeaveModel({ title, show, setIsOpen, _id, updateData }) {
    const [selecteReasone, setSelecteReasone] = useState();
    const [isLoading, setIsLoading] = useState(false)
    const dispatch = useDispatch()
    const user = useSelector(selectUser)
    const navigate = useNavigate()

    const ReasoneOptions = [
        { value: 'I am not sure', label: 'I am not sure' },
        { value: 'I am not a good fit', label: 'I am not a good fit' },
        { value: 'I am not happy with my profile', label: 'I am not happy with my profile' },
        { value: 'Other', label: 'Other' },
    ]
    const LeaveHandle = async () => {
        try {
            setIsLoading(true)
            const response = await autoInstance.delete(`/${title === "communities" ? "community" : "collaboration"}/removeMember/${_id}/${user?._id}`)
            if (response.status === 200) {
                updateData()
                setIsLoading(false)
                setIsOpen(false)
                Toast("You have successfully left from the community", "success")
            }

        } catch (error) {
            Toast(error?.response?.data?.message, "error")
            setIsLoading(false)
            console.error(error)
        }
    }


    return (
        <div class="w-full  bg-white shadow-lg rounded-[16px]  2sm:p-[24px] xsm:p-[20px] p-[15px] relative ">
            <div className='flex items-center justify-between'>
                <h1 className='2lg:text-[26px] 2sm:text-[24px] md:text-[22px] text-[20px]  leading-[normal] font-[600]'>Leave {title}</h1>
                <div onClick={() => { setIsOpen(false); }} className='cursor-pointer ' dangerouslySetInnerHTML={{ __html: icons.closeIcon }} />
            </div>
            <div className='py-[10px]'>
                <CustomSelect label="Select reason"
                    onChange={(slected) => setSelecteReasone(slected.value)}
                    options={ReasoneOptions}
                    placeholder="Select - optional"
                    className=" w-full "
                />
            </div>
            <p className='text-[#21272A] font-normal text-[12px] leading-[140%]'>Please share the reson to confirm you want to leave the {title}</p>
            <div className='flex justify-end'>
                <Bluebtn className={`text-[14px] font-medium py-[8px] px-[16px] w-fit mt-[16px]  text-white bg-[#6D7486]   `} Label="Leave" onClick={() => isLoading ? null : LeaveHandle()} />
            </div>
        </div>
    )   
}

export default LeaveModel