import React, { useCallback, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import autoInstance from "../../utils/axios";
import { selectUser } from "../../utils/reduxtoolkit/slices/userSlice";
import EmptyState from "../Common/EmptyState";
import Navbar from "../Common/Navbar";
import Sidebar from "../Common/Sidebar";
import { SocketProvider } from "../Socket/WebSocketContext";
import ChatBody from "./ChatBody";
import ChatHeader from "./ChatHeader";
import ChatSIdebar from "./ChatSIdebar";
import { MessageInputBox } from "./MessageInput";

const ChatRoom = () => {
  const navigate = useNavigate();
  const chatContainerRef = useRef(null);

  const params = useParams();
  const user = useSelector(selectUser);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedUser, setSelectedUser] = useState({});
  const [conversationList, setConversationList] = useState([]);
  const sessionUserString = localStorage.getItem("g2n0User");
  const sessionUser = sessionUserString ? JSON.parse(sessionUserString) : null;


  const getConversationList = useCallback(async () => {
    try {
      const response = await autoInstance.get(`/messages`);
      const sources = response.data;
      setConversationList(sources.data);
    } catch (error) { }
  }, []);

  const handleUserSelect = useCallback((user, isConversationRefresh = false) => {
    setSelectedUser(user)
    if (isConversationRefresh) {
      getConversationList();
    }
  }, [])

  useEffect(() => {
    getConversationList();
  }, []);

  useEffect(() => {
    // Scroll chat to bottom when messages update
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
    }
  }, []);

  return (
    <div>
      <Navbar />

      <div className="flex items-start justify-start lg:h-[calc(100vh_-_80px)] 2sm:h-[calc(100vh_-_70px)] h-[calc(100vh_-_60px)] overflow-hidden">
        <Sidebar />
        <div className="h-full bg-[#fff] 3lg:w-[calc(100vw_-_300px)] lg:w-[calc(100vw_-_250px)] 2md:w-[calc(100vw_-_60px)] md:w-[calc(100vw_-_60px)]  w-full">
          <p className="2lg:p-[24px] lg:p-[20px] p-[15px] 2lg:text-[24px] lg:text-[20px] text-[16px] font-semibold leading-[110%] border-b border-[#EFF3F4]">
            Messages
          </p>
          <div className="flex items-start justify-end w-full">
            <div className="3lg:w-[calc(100vw_-_300px)] lg:w-[calc(100vw_-_250px)] 2md:w-[calc(100vw_-_60px)] 2sm:w-[calc(100vw_-_60px)]  w-full flex items-start justify-start">
              <ChatSIdebar
                conversationList={conversationList}
                handleUserSelect={handleUserSelect}
                selectedUser={selectedUser}
              />
              {Object.keys(selectedUser).length !== 0 ? (
                <div className={`3lg:w-[calc(100%_-_450px)] 2md:w-[calc(100%_-_320px)] ${Object.keys(selectedUser).length !== 0 && "!block"} 2md:block hidden w-full relative `}>

                  <>
                    <ChatHeader handleUserSelect={setSelectedUser} selectedUser={selectedUser} />
                    <div className="2md:px-[16px] px-[20px]">
                      <ChatBody selectedUser={selectedUser} />
                    </div>
                    <MessageInputBox
                      selectedUser={selectedUser}
                    />
                  </>


                </div>)
                : (
                  <div className={`h-screen 3lg:w-[calc(100%_-_450px)] 2md:w-[calc(100%_-_320px)] w-full   2md:block hidden `}>
                    <EmptyState
                      icon={``}
                      title={"Send your first message now."} />
                  </div>)
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChatRoom;
