import moment from 'moment';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { icons, images } from '../../../helpers/images';
import autoInstance from '../../../utils/axios';

function NotificationCard({ data, key, updateData }) {
    const { name, userName, reqFor, onClick, IsPost, payload, profileImage, className, type, isNotificationPage = false, status, _id } = data;
    const uploadMedia = payload?.data?.uploadMedia;
    const navigate = useNavigate();
    const title = payload?.notification?.body;
    const time = data?.createdAt
    const comment = payload?.data?.comment;
    const [notificationType, setNotificationType] = useState("");
    const NotificationRedirect = async () => {
        try {
            const response = await autoInstance.patch(`/notification/${_id}`, { status: "read" });
            if (response.status === 200) {
                updateData();
                navigate(type === "post" || type === "Comments" || type === "Like" ? `/user/post/${payload?.data?._id}` : ``);
            }
        }
        catch (error) {
            console.error(error);
        }
    }



    return (
        <div key={key} onClick={NotificationRedirect} className={` ${isNotificationPage ? " py-[12px] mx-[16px]" : "lg:p-[20px] 2md:p-[16px] md:p-[14px] 2sm:p-[12px] p-[10px] mx-[0px] w-full"} cursor-pointer   border-b border-[#F0F6F9] ${status === "unread" ? "bg-[#2A8DDE1A]" : "bg-white"}  `}>
            <div className='flex items-center justify-between gap-[16px] w-full'>
                <div className='flex items-center justify-start gap-[16px]'>
                    <div className='relative'>
                        {
                            uploadMedia ?
                                <div className="flex-center w-[40px] h-[40px] rounded-[8px] overflow-hidden">
                                    <img src={process.env.REACT_APP_IMAGE_URL + uploadMedia} className="w-full h-full object-cover" alt="" />
                                </div> :
                                <div className='w-[40px] h-[40px] rounded-[8px] overflow-hidden flex items-center justify-center border border-[#EFF3F4]' dangerouslySetInnerHTML={{ __html: icons.defaultProfile }} />
                        }
                    </div>
                    <div className='w-[calc(100%_-_50px)] overflow-hidden text-ellipsis'>
                        <p className='text-[16px] font-medium leading-[110%] '><span dangerouslySetInnerHTML={{ __html: title }} /></p>
                        <p className='text-[12px] font-normal leading-[140%] text-[#6D7486] pt-[4px]'>{time ? moment(time).format("LT") : "22:00"}</p>
                    </div>
                </div>
                {
                    notificationType !== "" &&
                    <div className="flex-center w-[40px] h-[40px] rounded-[8px] overflow-hidden border border-[#EFF3F4]">
                        <img className="w-full h-full object-cover" src={uploadMedia ? process.env.REACT_APP_IMAGE_URL + uploadMedia : images?.profileImage} alt="" />
                    </div>
                }
            </div>
            {
                notificationType !== "" &&
                <div className='m-[8px] bg-[#EAF4FC] p-[8px] rounded-[8px] overflow-hidden flex items-center justify-start gap-[10px] w-[calc(100%_-_152px)] mx-auto'>
                    <div className="flex-center w-[40px] h-[40px] rounded-[8px] overflow-hidden border border-[#EFF3F4]">
                        <img className="w-full h-full object-cover" src={uploadMedia ? process.env.REACT_APP_IMAGE_URL + uploadMedia : images?.profileImage} alt="" />
                    </div>
                    <div className='w-[calc(100%_-_50px)]'>
                        <p className='text-[14px] font-medium leading-[100%]'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Delectus, nesciunt sequi voluptates a iure rerum!</p>

                    </div>
                </div>
            }
        </div>
    )
}

export default NotificationCard