import React, { useState } from 'react'
import ReactPlayer from 'react-player'
import { Link } from 'react-router-dom'
import { icons } from '../../../helpers/images'
import CustomModal from '../CustomModel'
import moment from 'moment/moment'
import DOMPurify from 'dompurify'


function ClimateVaultCard({ item, index }) {
    const sanitizedHTML = DOMPurify.sanitize(item?.description);
    const sanitizedSummryHTML = DOMPurify.sanitize(item?.summary);


    return (
        <Link to={`/climatevault/${item?._id}`} key={index} className='rounded-[8px] overflow-hidden 3lg:h-[200px] 2lg:h-[160px] 2md:h-[140px] 2sm:h-[180px] h-[200px]' style={{ backgroundImage: `url(${process.env.REACT_APP_IMAGE_URL}${item?.file?.replace(/\\/g, '/')})`, backgroundSize: 'cover', backgroundPosition: 'center' }}>
            <div className='pb-[16px] w-full  h-full px-[16px] flex items-end justify-end bg-blacklinear'>
                <div className='w-full'>
                    <p className="font-[500] text-white text-[16px] leading-[110%] pb-[4px] whitespace-nowrap overflow-hidden text-ellipsis w-[90%]">{item?.title}</p>
                    <p className='text-[10px] font-normal leading-[140%]  text-[#EFF3F4] text-ellipsis-desc-valut'>
                        {
                            item?.summary ? <div className='text-[#EFF3F4]' dangerouslySetInnerHTML={{ __html: sanitizedSummryHTML }} /> :
                                <div className='text-[#EFF3F4]' dangerouslySetInnerHTML={{ __html: sanitizedHTML }} />
                        }
                    </p>
                    <div className='flex items-center justify-between pt-[4px]'>
                        <div className='flex items-center justify-start gap-[4px]'>
                            <div className='w-[16px] h-[16px] rounded-[4px] overflow-hidden flex-center'>
                                <img src={process.env.REACT_APP_IMAGE_URL + item?.userId?.profileImage} alt="Nav" />
                            </div>
                            <p className='text-[10px] font-normal leading-[140%] text-[#B7BCCA]'>{item?.userId?.first_name}</p>
                        </div>
                        <p className='text-[10px] font-normal leading-[140%] text-[#B7BCCA]'>{moment(item?.createdAt).format("MMM DD, YYYY")}</p>
                    </div>
                </div>
            </div>
        </Link>)
}

export default ClimateVaultCard

export const ClimateVaultVideoCard = ({ item, index }) => {
    const [showModal, setShowModal] = useState(false);


    return (
        <>
            <Link onClick={() => setShowModal(true)} key={index} className='rounded-[8px] overflow-hidden h-[200px]' style={{ backgroundImage: `url(${item.image})`, backgroundSize: 'cover', backgroundPosition: 'center' }}>
                <div className=' h-full flex items-center justify-center bg-blacklinear'>
                    <div className='color-white' dangerouslySetInnerHTML={{ __html: icons.playCircle }} />
                </div>
            </Link>
            <CustomModal showModal={showModal} setShowModal={setShowModal} bodyClassName={"rounded-[16px]  2md:w-[90vw] max-w-[90vw] 2md:h-[90vh] h-[auto]"} >
                <div className='p-[16px] flex-center h-full'>
                    <ReactPlayer
                        url={`https://youtu.be/jAa58N4Jlos?si=ZtPV16Ccp6ua_Aru&t=9`}
                        loop={false}
                        controls={true}
                        playing={true}
                        volume={1}
                        width="100%"
                        height="100%"
                        style={{ borderRadius: '12px', overflow: 'hidden' }}
                        muted={false}
                    />
                </div>
            </CustomModal>
        </>
    )
}