import React from 'react';

function Bluebtn({ Label, onClick, icon, className }) {
  return (
    <button onClick={onClick} className={`${className}  flex items-center justify-center !cursor-pointer bg-[#2A8DDE] rounded-[6px] trasiction-all duration-300`}>
      {icon && <>{icon}</>}
      {Label && <span className=''>{Label}</span>}
    </button>
  );
}

export default Bluebtn;
