import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Toast from "../../../../helpers/Toast";
import autoInstance from "../../../../utils/axios";
import Bluebtn from "../../../Common/Btns/Bluebtn";
import CustomInput from "../../../Common/Inputs/CustomInput";
import AuthLayout from "../../AuthLayout";

function ResetPassword({ onNextStep }) {
    const navigate = useNavigate()
    const location = useLocation()
    const queryParams = new URLSearchParams(location.search);
    const token = queryParams.get('token');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const [confirmPasswordError, setConfirmPasswordError] = useState('');
    const [signupLoading, setSignupLoading] = useState(false);
    const [keepMePosted, setkeepMePosted] = useState(false);
    const [EmailVerified, setEmailVerified] = useState(false);
    const [showPasswordSet, setShowPasswordSet] = useState(false);

    const handleSetPassword = async () => {

        setPasswordError('');
        setConfirmPasswordError('');
        let isError = false;

        if (password !== confirmPassword) {
            setConfirmPasswordError("Passwords do not match");
            isError = true
        }
        if (password.length < 8 || password.length > 20) {
            setPasswordError("Password must be between 8 and 20 characters");
            isError = true
        }
        if (password.length < 8) {
            setPasswordError("Please Enter a valid Password");
            isError = true
        }
        if (password.length > 20) {
            setPasswordError("Password must be below 20 characters");
            isError = true
        }
        if (!/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@#$!%*?&_])[A-Za-z\d@#$!%*?&_]{8,20}$/.test(password)) {
            setPasswordError("Please write a strong password");
            isError = true
        }
        if (confirmPassword.length < 8) {
            setConfirmPasswordError("Please Enter a valid Password");
            isError = true
        }
        if (confirmPassword.length > 20) {
            setConfirmPasswordError("Confirm Password must be below 20 characters");
            isError = true
        }
        if (confirmPassword !== password) {
            setConfirmPasswordError("Passwords do not match");
            isError = true
        }
        if (isError) {
            return;
        }

        try {
            const response = await autoInstance.post(`/auth/resetPassword`, {
                password,
                reset_password_token: token,

            });

            if (response.status === 200) {
                
                navigate('/login');
                
                Toast(response.data.message, "success");
            }
        } catch (error) {
            console.error('Error setting password:', error.response.data.message);
            // Handle error, e.g., display error message to the user
        }
        finally {
            setSignupLoading(false);
        }
    };

    const handleKeyPress = (event) => {
        if (event.key === "Enter") {
            handleSetPassword(event);
        }
      };
    
    return (
        <AuthLayout children={
            <div className="2lg:p-[50px] lg:p-[40px] md:p-[35px] 2sm:p-[30px] sm:p-[25px] p-[20px]">
                <p className='text-[24px] leading-[110%] font-[500]'>Set New password</p>
                <div className='2sm:pt-[30px] pt-[20px] 2sm:w-[315px]  space-y-[16px]' >
                    <CustomInput
                        label='Set a Password'
                        type='password'
                        // value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        placeholder='Password'
                    />
                    {passwordError && <p className='text-[14px] text-red-500'>{passwordError}</p>}
                    <CustomInput
                        label='Confirm Password'
                        type='password'
                        onKeyPress={handleKeyPress}
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        placeholder='Confirm Password'
                    />
                    {confirmPasswordError && <p className='text-[14px] text-red-500'>{confirmPasswordError}</p>}
                    {/* <div className='flex items-center justify-start gap-[8px]'>
                        <input onChange={(e) => setkeepMePosted(e.target.checked)} type="checkbox" name="offers" id="offers" className='w-[16px] h-[16px] checked:bg-[#2A8DDE] hover:bg-[#2A8DDE]' />
                        <label className='text-[12px] font-medium leading-[110%]' htmlFor="offers">Keep me posted on news and offers.</label>
                    </div> */}
                    <Bluebtn onClick={handleSetPassword} className={`p-[18px] w-full  text-[16px] font-medium leading-[100%]  text-white`} Label={'Set New password'} />
                </div>
            </div>
        } />
    )
}

export default ResetPassword