import Cookies from 'js-cookie';
import { Tooltip } from 'primereact/tooltip';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { images } from '../../../helpers/images';
import autoInstance from '../../../utils/axios';
import { login, selectUser } from '../../../utils/reduxtoolkit/slices/userSlice';
import Bluebtn from '../../Common/Btns/Bluebtn';
import CustomInput from '../../Common/Inputs/CustomInput';


const Step2 = ({ onNextStep }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const user = useSelector(selectUser)
  const queryParams = new URLSearchParams(location.search);
  const [token, settoken] = useState(queryParams.get('token'));
  const dispatch = useDispatch();
  const screenWidth = window.innerWidth;
  const code = queryParams.get('code');
  const [password, setPassword] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [signupLoading, setSignupLoading] = useState(false);
  const [passloading, setPassLoading] = useState(false);
  const [keepMePosted, setkeepMePosted] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState('');
  const [confirmPasswordError, setConfirmPasswordError] = useState('');
  const [EmailVerified, setEmailVerified] = useState(false);
  const [showPasswordSet, setShowPasswordSet] = useState(false);



  const handleSetPassword = async () => {

    setPasswordError('');
    setConfirmPasswordError('');
    let isError = false;

    if (password !== confirmPassword) {
      setConfirmPasswordError("Password does not match");
      isError = true
    }
    if (password.length < 8 || password.length > 20) {
      setPasswordError("Password must be between 8 and 20 characters");
      isError = true
    }
    if (password.length < 8) {
      setPasswordError("Please enter a valid Password");
      isError = true
    }
    if (password.length > 20) {
      setPasswordError("Password must be below 20 characters");
      isError = true
    }
    // if (!/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@#$!%*?&_\.])[A-Za-z\d@#$!%*?&_\.]{8,20}$/.test(password)) {
    if (!/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@#$!%*?&_])[A-Za-z\d@#$!%*?&_]{8,20}$/.test(password)) {
      setPasswordError("Please enter a stronger password");
      isError = true;
    }
    // if (confirmPassword.length < 8) {
    //   setConfirmPasswordError("Please enter a valid Password");
    //   isError = true
    // }
    if (confirmPassword.length > 20) {
      setConfirmPasswordError("Confirm Password must be below 20 characters");
      isError = true
    }
    // if (confirmPassword !== password) {
    //   setConfirmPasswordError("Passwords do not match");
    //   isError = true
    // }
    if (isError) {
      return;
    }

    try {
      setSignupLoading(true);
      const response = await autoInstance.post(`/auth/verify-email`, {
        keepMePosted: keepMePosted,
        token: token,
        password,
        confirmPassword
      });
      if (response.status === 200) {
        const expirationDate = new Date();
        expirationDate.setMonth(expirationDate.getMonth() + 2);
        Cookies.set('g2n0Auth', response.data.data.token, { expires: expirationDate });
        dispatch(login({
          user: response.data.data,
          userType: response.data.data.roleId
        }));
        navigate('/profiledetails');
      }
    } catch (error) {
      console.error('Error setting password:', error.response.data.message);
      // Handle error, e.g., display error message to the user
    }
    finally {
      setSignupLoading(false);
    }
  };


  const tooltipContent = `At least one uppercase letter
  At least one lowercase letter
  At least one digit
  At least one special character
  @#$!%*?_&
  Length of 8 to 20 characters`;

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSetPassword(event);
    }
  };

  return (
    <div>
      <>
        {EmailVerified ?
          <div className='w-full 2lg:p-[50px] lg:p-[40px] md:p-[35px] 2sm:p-[30px] sm:p-[25px] p-[20px]'>
            <p className='2lg:text-[24px] 2sm:text-[22px] md:text-[20px] text-[18px] leading-[110%] font-[500]'>Set password</p>
            <div className='2sm:pt-[30px] pt-[20px] 2sm:w-[315px] w-[75vw]  space-y-[16px]'>
              <CustomInput
                label='Set a Password'
                type='password'
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder='Password'
              />
              {passwordError &&
                <>
                  {!passwordError === "Please enter a strong password" ? <p className='text-[14px] text-red-500'>{passwordError}</p> : <div className='flex items-center justify-start gap-[8px] text-[14px] text-red-500'>
                    <p>{passwordError}</p>
                    <div className='cursor-pointer'>
                      <Tooltip
                        title={tooltipContent}
                        target=".Postinfo"
                        placement="bottom"
                        className="custom-tooltip"
                      />
                      <span
                        data-pr-tooltip={tooltipContent}
                        data-pr-position="right"
                        data-pr-at="right+2 top+9"
                        data-pr-my="left center-2"
                        className="Postinfo cursor-pointer"
                      >
                        <svg
                          xmlns='http://www.w3.org/2000/svg'
                          viewBox='0 0 24 24'
                          fill='none'
                          stroke='currentColor'
                          strokeWidth='2'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                          className='lucide lucide-info text-[#6D7486] h-4 w-4'
                        >
                          <circle cx='12' cy='12' r='10' />
                          <path d='M12 16v-4' />
                          <path d='M12 8h.01' />
                        </svg>
                      </span>
                    </div>
                  </div>}
                </>
              }

              <CustomInput
                label='Confirm Password'
                type='password'
                onKeyPress={handleKeyPress}
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                placeholder='Confirm Password'
              />
              {confirmPasswordError && <p className='text-[14px] text-red-500'>{confirmPasswordError}</p>}
              <div className='flex items-center justify-start gap-[8px]'>
                <div class="checkboxes__row">
                  <div class="checkboxes__item">
                    <label class="checkbox style-c">
                      <input
                        onChange={(e) => setkeepMePosted(e.target.checked)}
                        name="offers"
                        id="offers"
                        type="checkbox" />
                      <div class="checkbox__checkmark"></div>
                    </label>
                  </div>
                </div>
                <label className='text-[12px] font-medium leading-[110%]' htmlFor="offers">Keep me posted on news and offers</label>
              </div>
              <Bluebtn onClick={signupLoading ? null : handleSetPassword} className={`${signupLoading ? "bg-[#B7BCCA] text-[#FFFFFF]" : "!bg-[#007EBB]"} lg:p-[18px] p-[14px] w-full 2sm:text-[16px] text-[14px] font-medium leading-[100%]  text-white`} Label={`${signupLoading ? 'Loading...' : 'Set password'}`} />
            </div>
          </div> :
          <div className='2lg:p-[50px] lg:p-[40px] md:p-[35px] 2sm:p-[30px] sm:p-[25px] p-[20px]'>
            <div className='2sm:w-[315px] w-[75vw] '>
              <img className='mx-auto' src={images.verifiedGIF} alt="verified" />
              <h1 className='text-[24px] leading-[110%] font-[500] text-center pt-[10px]'>email Verified</h1>
              <div className='mt-[20px]'>
                <Bluebtn onClick={() => setEmailVerified(true)} className={`lg:p-[18px] p-[14px] w-full 2sm:text-[16px] text-[14px] font-medium leading-[100%]  text-white`} Label={`${signupLoading ? 'Loading...' : 'Continue'}`} />
              </div>
            </div>
          </div>
        }
      </>
    </div >
  )
}

export default Step2;
