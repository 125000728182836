import Collaboration from "../../../pages/Collaboration"
import CollaborationsDetails from "../../../pages/Collaboration/CollaborationsDetails"
import Home from "../../../pages/Home"
const CollaborationRoutes = {
    path: "/collaborations",
    children: [
        {
            path: "",
            element: <Collaboration />,
            // element: <Home />,
        },
        {
            path: "details/:id",
            element: <CollaborationsDetails />,
            // element: <Home />,
        },
    ]
}
export default CollaborationRoutes