import AdminLogin from "../../Admin/Login";
import PageNotFound from "../../pages/Pagenotfound";
import About from "../../pages/Website/About";
import Cic from "../../pages/Website/Cic";

const commonRoutes = [
    {
        path: "*",
        element: <PageNotFound />,
    },
    {
        path: "/admin",
        element: <AdminLogin />,
    },
    {
        path: "/about-us",
        element: <About />,
    },
    {
        path: "/ceguide",
        element: <Cic />,
    }
]
export default commonRoutes;