import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Toast from '../../../helpers/Toast';
import { icons } from '../../../helpers/images';
import autoInstance from '../../../utils/axios';
import { logout, selectUser } from '../../../utils/reduxtoolkit/slices/userSlice';
import Bluebtn from '../Btns/Bluebtn';
import CustomSelect from '../Select/CustomSelect';


function DeleteAccount({ show, setIsOpen }) {
    const [selecteReasone, setSelecteReasone] = useState();
    const user = useSelector(selectUser)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const ReasoneOptions = [
        { value: 'I am not sure', label: 'I am not sure' },
        { value: 'I am not a good fit', label: 'I am not a good fit' },
        { value: 'I am not happy with my profile', label: 'I am not happy with my profile' },
        { value: 'Other', label: 'Other' },
    ]
    const sendInfo = async () => {
        try {
            const response = await autoInstance.delete(`/user/${user?._id}`)
            if (response.status === 200) {
                dispatch(logout());
                navigate('/login');
                setIsOpen(false)
            }

        } catch (error) {
            console.error(error)
        }
    }
    return (
        <div class="w-full  bg-white shadow-lg rounded-[16px] 2sm:p-[24px] xsm:p-[20px] p-[15px] relative ">
            <div className='flex items-center justify-between'>
                <h1 className='2lg:text-[26px] 2sm:text-[24px] md:text-[22px] text-[20px]  leading-[normal] font-[600]'>Delete Account</h1>
                <div onClick={() => { setIsOpen(false); }} className='cursor-pointer ' dangerouslySetInnerHTML={{ __html: icons.closeIcon }} />
            </div>
            <div className='py-[10px]'>
                <CustomSelect label="Select reason"
                    onChange={(slected) => setSelecteReasone(slected.value)}
                    options={ReasoneOptions}
                    placeholder="Select - optional"
                    className=" w-full "
                />
            </div>
            <p className='text-[#21272A] font-normal text-[12px] leading-[140%]'>By deleting your account, you will no longer have access to your account.</p>
            <div className='flex justify-end'>
                <Bluebtn className="text-[14px] font-medium py-[8px] px-[16px] w-fit mt-[16px]  text-white bg-[#6D7486]" Label="Delete" onClick={() => { sendInfo(); setIsOpen(false) }} />
            </div>
        </div>
    )
}

export default DeleteAccount