import Section from './Section/Section';

function Home() {

    return (
        <>
            <Section />
        </>
    );
}
export default Home;
