import Aos from 'aos'
import React, { useEffect } from 'react'
import { images } from '../../helpers/images'
import { Newsletter } from './Section/Section'
import WebFooter from './WebFooter'
import WebHeader from './WebHeader'

function About() {

    useEffect(() => {
        Aos.init({
            duration: 800,
            easing: 'ease-in-out',
            once: true
        });
    }, []);

    return (
        <div className='overflow-y-auto overflow-x-hidden  hide-scrollbar h-screen w-screen bg-[#F9F9F9]'>
            <WebHeader className="bg-white " />
            <div className='xl:max-w-6xl 3lg:max-w-5xl max-w-4xl w-full mx-auto 2md:px-[0px] px-[20px]  2lg:py-[34px] 2md:py-[32px] md:py-[30px] 2sm:py-[22px] py-[20px] mt-[82px]' aria-hidden="true" data-aos="fade-up" data-aos-delay="300">
                {/* <div className='2md:text-[34px] md:text-[32px] sm:text-[28px] text-[24px] font-medium leading-[normal]   2md:py-[34px] md:py-[32px] 2sm:py-[30px] py-[22px]  '>
                    About <span className='text-[#2A8DDE] font-bold'>Us</span>
                </div> */}
                <div className='xl:max-w-6xl 3lg:max-w-5xl max-w-4xl w-full mx-auto 2md:px-[0px] px-[0px]    2lg:text-[40px] lg:text-[36px] 2md:text-[32px] md:text-[28px] 2sm:text-[24px] text-[20px]  font-extrabold 2md:leading-[50px]  leading-[normal]  2md:pb-[16px] pb-[10px]  '>
                    <p className=''>The World’s First Professional</p>
                    <p className=''>Climate Action Network</p>
                </div>
                <div className='xl:max-w-6xl 3lg:max-w-5xl max-w-4xl w-full mx-auto 2md:px-[0px] px-[x0px]   2lg:text-[18px] lg:text-[17px] 2md:text-[16px] md:text-[15px] 2sm:text-[14px] sm:text-[13px] text-[12px] leading-[140%] font-medium  2lg:space-y-[30px] lg:space-y-[26px] 2md:space-y-[24px] md:space-y-[20px] space-y-[18px] text-[#4a3f51]'>

                    <p>Concerned about climate change and its devastating impact around the world, a group of entrepreneurs from across the globe pooled in their experiences and resources to launch get2net0.</p>
                    <p>
                        We recognized the incredible amount of effort required for government bodies and private enterprises to achieve their net-zero ambitions and it is our endeavour to make this process efficient and to bring in economies of scale.
                    </p>
                    <p>
                        Get2net0 accelerates public and corporate climate action by providing a platform for solution seekers, solution providers, academics and innovators to collaborate.
                    </p>
                </div>
                {/* <div className='pb-[20px]'>
                    <p className='text-[18px] font-semibold 2md:pb-[20px] pb-[10px]'>Together We Will! </p>
                    <div>
                        <img className='2lg:w-[auto] mx-auto' src={images.about} alt="About_Image" />
                    </div>
                </div> */}

            </div>
            <div className='2lg:p-[45px] lg:p-[30px] 2md:p-[24px] md:p-[20px] p-[20px] bg-[#2a8dde] text-center 2md:text-[32px] md:text-[28px] 2sm:text-[24px] text-[] font-medium italic text-white ' data-aos="fade-in" data-aos-delay="200">
                <p className='  w-full mx-auto' >
                    “We bring resources together to solve climate challenges”
                </p>
            </div>


            <Teams />
            <Newsletter />
            <WebFooter />
        </div>
    )
}

const Teams = () => {

    const Desilo = [
        {
            name: "Parthik Poshiya",
            image: images.ParthikPoshiya,
            position: "Product & Design Strategist, Business Development & Leadership",
            gradation: "BE Computer Engineering",
            alt: "Desilo - Parthik Poshiya"
        },
        {
            name: "Chirag Poshiya",
            image: images.ChiragPoshiya,
            position: "Product Engineer, Software Development, Team Leadership",
            gradation: "BCA, MCA",
            alt: "Desilo - Chirag Poshiya"
        },

    ]

    const AdvisoryPanel = [
        {
            name: "Chris Feltin",
            subTitle: "Lead Advisor & Investor",
            image: images?.ChrisFeltin,
            position: "Energy Strategy, Capital Markets, Equity Research, Corporate Development, Consulting",
            gradation: "MBA (Queen’s, BASc (Regina)"
        },
        {
            name: "JP Gladu",
            subTitle: "Advisor",
            image: images.JPGladu,
            position: "Energy, Renewables, Forestry, Corporate Leadership, Indigenous Development",
            gradation: "MBA (Queen’s), BSc (Northern Arizona)"
        },
        {
            name: "Le Luong",
            subTitle: "Advisor",
            image: images.LeLuong,
            position: "Business Strategy, Start-Up Expert, Corporate Leadership, Community Service, Athletics",
            gradation: "York University"
        },
        {
            name: "Douglas Reid",
            subTitle: "Advisor",
            image: images.DouglasReid,
            position: "Professor of Strategy at Queens University, Board Member, Educator, Consultant",
            gradation: "PhD (Western), MBA, BSc (Toronto)"
        },
        {
            name: "Partha Mohanram",
            subTitle: "Advisor",
            image: images.ParthaMohanram,
            position: "John H. Watson Chair in Value Investing at Rotman, Fin. Analysis, Valuation, Accounting",
            gradation: "PhD Harvard, MBA (IIM, India), Btech (IIT, India)"
        },
    ]

    const Bhavesh = {
        name: "Bhavesh Bhimani, CIO",
        // subTitle: "Advisor",
        image: images.BhaveshBhimani,
        position: "IT Strategies & Services, Fintech, Business Strategy, Innovation, Transformation",
        gradation: "B.Com, M.Com (Univ of Mumbai), MBA (DePaul)"
    }
    const Dev = {
        name: "Dev Kandoi, CTO",
        // subTitle: "Advisor",
        image: images.DevKandoi,
        position: "IT Infrastructure Strategy, Innovation & Transformation Specialist",
        gradation: "B.Com (Univ of Mumbai), MIS (Charles Sturt Aus)"
    }

    const Promoter = [
        {
            name: "Naveed Naseem",
            subTitle: "Director",
            image: images.NaveedNaseem,
            position: "Human Resources Specialist, Entrepreneur, Investor",
            gradation: "B.Com (Mumbai University)"
        },
        {
            name: "Nadeem Lashkaria",
            subTitle: "Director",
            image: images.NadeemLashkaria,
            position: "Digital Marketing Expert, Entrepreneur, Investor",
            gradation: "MS in Network Security (NYIT, USA)"
        },

    ]

    const Navb = {
        name: 'Nav Bubber, CEO',
        position: 'Engineering, Financial Services, Business Strategy, Innovation, Transformation, M&A',
        image: images.NavBubber,
        gradation: 'BE, ME (Cornell), MBA (Queen’s)'
    }

    const DorothyWarren = {
        name: "Dorothy Warren, CBO",
        position: "Retail, Corporate & Commercial Banking, Operations, Sales Mgmt., Project Mgmt.",
        image: images.DorothyWarren,
        gradation: "Business & Treasury Mgmt. Cert. (York)"
    }
    const DaveBumra = {
        name: "Dave Bumra, CFO",
        position: "Commercial Banking, Accounting & Taxation, Entrepreneurship, Risk Mgmt.",
        image: images.DaveBumra,
        gradation: "CPA, CGA, MBA (Queen’s)"
    }
    const KavindaWelagedara = {
        name: "Kavinda Welagedara, CMO",
        position: "Positioning, Brand and Behavioral Strategy, ACIM, ACMA, CGMA",
        image: images.KavindaWelagedara,
        gradation: "BBA Finance and Mgmt. (Heriot Watt) Cert. Behavioural Science in Marketing"
    }




    return (
        <div className='xl:max-w-6xl 3lg:max-w-5xl max-w-4xl w-full mx-auto 2ms:px-[0px] px-[20px]  2md:py-[70px] md:py-[55px] 2sm:py-[40px] py-[20px]'>
            <div className='   2lg:text-[34px] lg:text-[32px] 2md:text-[30px] md:text-[26px] 2sm:text-[22px] text-[18px] font-medium leading-[normal]   2lg:py-[34px] 2md:py-[32px] md:py-[30px] 2sm:py-[22px] py-[20px]   text-center '>
                Meet the <span className='text-[#4a3f51] font-bold'>get2net0 Team</span>
            </div>
            <div className='2lg:py-[53px] lg:py-[42px] 2md:py-[32px] md:py-[28px] 2sm:py-[24px] py-[0px]  grid 2lg:grid-cols-3 2sm:grid-cols-2 grid-cols-1   items-start justify-start 2lg:gap-[60px] 2md:gap-[50px] md:gap-[40px] 2sm:gap-[30px] gap-[20px]'  >
                <TeamCard item={Navb} />
                <TeamCard item={DorothyWarren} />
                <TeamCard item={DaveBumra} />
                <TeamCard item={Bhavesh} />
                <TeamCard item={Dev} />
                <TeamCard item={KavindaWelagedara} />
            </div>
            <div className=' 2lg:pb-[53px] lg:pb-[42px] 2md:pb-[32px] md:pb-[28px] 2sm:pb-[24px]'>
                <p className='2md:text-[24px] md:text-[22px] text-[20px] leading-[normal] font-bold text-[#4a3f51] 2md:pb-[38px] 2sm:pb-[28px] pb-[20px] 2sm:pt-[0px] pt-[30px] 2sm:text-left text-center '>Advisory Panel</p>
                <div className='grid 2lg:grid-cols-3 2sm:grid-cols-2 grid-cols-1  2lg:gap-[60px] 2md:gap-[50px] md:gap-[40px] 2sm:gap-[30px] gap-[20px]'>
                    {
                        AdvisoryPanel?.map((item, index) => <TeamCard key={index} item={item} />)
                    }
                </div>
            </div>
            <div className='2lg:pb-[53px] lg:pb-[42px] 2md:pb-[32px] md:pb-[28px] 2sm:pb-[24px]'>
                <p className='2md:text-[24px] md:text-[22px] text-[20px] leading-[normal] font-bold text-[#4a3f51] 2md:pb-[38px] 2sm:pb-[28px] pb-[20px] 2sm:pt-[0px] pt-[30px] 2sm:text-left text-center '>Promoter Investors</p>
                <div className='grid 2lg:grid-cols-3 2sm:grid-cols-2 grid-cols-1 2lg:gap-[60px] 2md:gap-[50px] md:gap-[40px] 2sm:gap-[30px] gap-[20px]'>
                    {
                        Promoter?.map((item, index) => <TeamCard key={index} item={item} />)
                    }
                </div>
            </div>
            <div className='  '>
                <p className='2md:text-[24px] md:text-[22px] text-[20px] leading-[normal] font-bold text-[#4a3f51] 2md:pb-[38px] 2sm:pb-[28px] pb-[20px] 2sm:pt-[0px] pt-[30px] 2sm:text-left text-center '>Product Engineering & Development</p>
                <div className='grid 2lg:grid-cols-3 2sm:grid-cols-2 grid-cols-1 2lg:gap-[60px] 2md:gap-[50px] md:gap-[40px] 2sm:gap-[30px] gap-[20px]'>
                    {
                        Desilo?.map((item, index) => <TeamCard key={index} item={item} />)
                    }
                </div>
            </div>

        </div>
    )
}

const TeamCard = ({ key, item }) => {
    const { name, position, image, gradation, subTitle, alt = name } = item
    return (
        <div className='2sm:max-w-[371px]' key={key ? key : 0}>
            <div className='2sm:h-[173px] h-[220px] 2lg:w-[324px] 2md:w-[300px] md:w-[280px] w-[100%] flex-center rounded-[20px] overflow-hidden mx-auto' >
                <img src={image} className='w-full h-full object-cover' alt={alt} />
            </div>
            <div className='text-center pt-[20px]'>
                <p className='2md:text-[18px] md:text-[16px] text-[14px] font-bold text-[#4a3f51]'>{name}</p>
                {subTitle && <p className='2md:text-[14px] md:text-[12px] text-[10px] font-bold text-[#4a3f51]'>{subTitle}</p>}
                <p className='2md:text-[14px] md:text-[13px] text-[12px] font-bold text-[#4a3f51] 2sm:leading-[18px] 2md:py-[10px] md:py-[8px] py-[6px] 2sm:max-w-auto max-w-[70vw] 2sm:mx-0 mx-auto    '>{position}</p>
                <p className='2md:text-[14px] md:text-[13px] text-[12px] font-normal text-[#4a3f51]'>{gradation}</p>
            </div>
        </div>
    )
}

export default About