import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import Bluebtn from '../../Components/Common/Btns/Bluebtn';
import { icons } from '../../helpers/images';
import autoInstance from '../../utils/axios';

function Packages({ handleSave }) {
    const location = useLocation();
    const [PackageData, setPackageData] = useState([])

    useEffect(() => {
        GetPackageFromBackend()
    }, [])

    const GetPackageFromBackend = async () => {
        try {
            const response = await autoInstance.get('/subscriptions')
            setPackageData(response.data.data)
        }
        catch (error) {
            console.error(error)
        }
    }
    const resetSteps = () => {
        if (location.pathname === '/signup' || location.pathname === '/profiledetails') {
            handleSave();
        }
    }
    return (
        <div className={`bg-[#fff] `}>
            <p className='2lg:text-[24px] 2sm:text-[22px] md:text-[20px] text-[18px]  leading-[110%] font-[600] text-center pb-[16px]'>Choose the plan that fits you best</p>
            <Tabs defaultIndex={2}>
                <TabList className="custom-tab-list justify-center bg-[#EAF4FC] w-fit !p-[4px] rounded-[8px] mx-auto">
                    <Tab className="custom-tab">Pay Monthly</Tab>
                    <Tab tabFor="2" className="custom-tab">Pay Annually</Tab>
                </TabList>

                <div className="tab-content md:p-0 xsm:p-[30px]  p-[15px]">
                    <TabPanel>
                        <div className='flex flex-wrap items-end justify-center gap-[16px] pt-[16px]'>
                            {PackageData?.map((data, index) => (
                                <PackageBox key={index} data={data} resetSteps={resetSteps} />
                            ))}
                        </div>
                    </TabPanel>
                    <TabPanel id="2">
                        <p className='text-[12px] leading-[110%] text-center py-[18px] text-[#6D7486]'>Pay Annually and get 2 months Free!</p>
                        <div className='flex flex-wrap items-end justify-center gap-[16px]'>
                            {PackageData?.map((data, index) => (
                                <PackageBox key={index} data={data} resetSteps={resetSteps} />
                            ))}
                        </div>
                    </TabPanel>
                </div>
            </Tabs>
        </div>
    )
}

export default Packages;

export const PackageBox = ({ data, resetSteps }) => {
    return (
        <div className={`${data.isRecomanded ? 'bg-[#2A8DDE]' : 'bg-[#EFF3F4]'} rounded-[16px] 2sm:max-w-[340px] max-w-[330px]`}>
            {
                data.isRecomanded &&
                <div className='text-[14px] text-white uppercase font-[500] leading-[110%] rounded-t-[16px] bg-[#2A8DDE] py-[6px] text-center'>
                    Recommended
                </div>
            }
            <div className={`${data.isRecomanded ? 'border-[#2A8DDE]' : 'border-[#EFF3F4]'} border rounded-[16px] bg-white py-[30px] px-[20px]`}>
                <div className='space-y-[8px]'>
                    {data.offer &&
                        <div className='text-[#30D85C] text-center bg-[#30D85C10] py-[4px] px-[10px] rounded-[10px]'>
                            Free Until 07 April 2024
                        </div>
                    }
                    <p className='2lg:text-[24px] 2sm:text-[22px] md:text-[20px] text-[18px]  leading-[110%] font-[500] text-center'>{data.name}</p>
                    <p className='2sm:text-[12px] text-[9px] bg-[#EAF4FC] rounded-[8px] font-normal py-[5px] px-[8px] leading-[140%]'>{data.description[0]}</p>
                    <p className='2sm:text-[12px] text-[9px] bg-[#EAF4FC] rounded-[8px] font-normal py-[5px] px-[8px] leading-[140%]'>{data.description[1]}</p>
                </div>
                <div className=' py-[16px]'>
                    {
                        data.fee === 0 ?
                            <div>
                                <h2 className='2lg:text-[32px]  2sm:text-[30px] md:text-[28px] text-[24px]  leading-[110%] font-[600] text-center'>Free</h2>
                            </div> :
                            <div className='flex items-end justify-center'>
                                <h2 className='2lg:text-[32px]  2sm:text-[30px] md:text-[28px] text-[24px]  leading-[110%] font-[600] '>${data?.fee}</h2>
                                <p className='text-[16px] font-medium leading-[110%]'>/month</p>
                            </div>
                    }
                    {
                        data.price_annually > 0 &&
                        <p className='text-[#6D7486] text-[14px] font-medium text-center leading-[110%] pt-[8px]'>or US${data.price_annually} Annually</p>
                    }
                </div>

                <div onClick={resetSteps}>
                    <div >
                        <Bluebtn className={`${data.fee === 0 ? '!bg-[#6D7486]' : ''} w-full p-[16px] text-[16px] font-medium leading-[100%] text-white`} Label={data.btn} />
                    </div>
                </div>
                <div className='space-y-[16px] pt-[16px]'>
                    <div className='text-[16px] leading-[140%] font-normal'>{data.feature_description}</div>
                    {data.features.map((feature, index) => (
                        <div className='flex items-start justify-start gap-[8px]'>
                            <div>
                                {
                                    feature.status === 'current' ? <div dangerouslySetInnerHTML={{ __html: icons.GreenCheck }} /> : <div dangerouslySetInnerHTML={{ __html: icons.Feature }} />
                                }
                            </div>
                            <div>
                                <p className='text-[14px] font-medium leading-[110%]'>{feature.content}</p>
                                {
                                    feature.status === 'future' && <p className='text-[12px] font-normal leading-[140%]'>Coming soon</p>
                                }
                            </div>
                        </div>
                    ))}
                </div>
            </div>

        </div>
    )
};
