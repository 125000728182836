import CommunitieCollaborationDetails from '../../layouts/CommunitieCollaborationDetails'

function CollaborationsDetails() {


  return (
    <CommunitieCollaborationDetails
      pageTitle={"Collaboration Details"}
    />
  )
}

export default CollaborationsDetails