import { Menu, MenuButton, MenuItem } from '@szhsin/react-menu'
import Cookies from 'js-cookie'
import { Chart } from 'primereact/chart'
import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import PageLoader from '../Components/Common/PageLoader'
import { icons } from '../helpers/images'
import autoInstance from '../utils/axios'
import AdminSidebar from './AdminSidebar'
import AdminNavBar from './NavBar/AdminNavbar'


function AdminReports() {
  const navigate = useNavigate()
  const [communityChartData, setCommunityChartData] = useState({});
  const [communityData, setcommunityData] = useState({});
  const [collaborationData, setCollaborationData] = useState({});
  const [colloborationChartData, setColloborationChartData] = useState({});
  const [chartOptions, setChartOptions] = useState({});
  const [IsPageLoading, setIsPageLoading] = useState(false)
  const [communityPost, setCommunityPost] = useState([])
  const [collaborationPost, setCollaborationPost] = useState([])

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [communityDataResponse, colloborationDataResponse] = await Promise.all([
          autoInstance.get('/report', { isAdmin: true }),
          autoInstance.get('/report/get-collaboration-report', { isAdmin: true })
        ]);

        if (communityDataResponse.status === 200) {
          setCommunityChartData(communityDataResponse.data.data);
          const labels = communityDataResponse.data.data?.map(item => item.name);
          const membersData = communityDataResponse.data.data?.map(item => item.totalMembers);
          const postsData = communityDataResponse.data.data?.map(item => item.totalPosts);
          // Creating the data object for Chart.js
          const communityData_ = {
            labels: labels,
            datasets: [
              {
                label: 'Members',
                backgroundColor: '#2A8DDE',
                borderColor: '#2A8DDE',
                data: membersData
              },
              {
                label: 'Posts',
                backgroundColor: '#30D85C',
                borderColor: '#30D85C',
                data: postsData
              }
            ]
          };
          setcommunityData(communityData_)
        }

        if (colloborationDataResponse.status === 200) {
          setColloborationChartData(colloborationDataResponse.data.data);
          const collaborationLabels = colloborationDataResponse.data.data?.map(item => item.name);
          const collaborationMembersData = colloborationDataResponse.data.data?.map(item => item.totalMembers);
          const collaborationPostsData = colloborationDataResponse.data.data?.map(item => item.totalPosts);
          const collaborationData_ = {
            labels: collaborationLabels,
            datasets: [
              {
                label: 'Members',
                backgroundColor: '#2A8DDE',
                borderColor: '#2A8DDE',
                data: collaborationMembersData
              },
              {
                label: 'Posts',
                backgroundColor: '#30D85C',
                borderColor: '#30D85C',
                data: collaborationPostsData
              }
            ]
          }
          setCollaborationData(collaborationData_)
        }

        setIsPageLoading(false);
      } catch (error) {
        console.error("Error occurred:", error);
        setIsPageLoading(false);
      }
    };

    fetchData();
  }, []);
  useEffect(() => {
    const documentStyle = getComputedStyle(document.documentElement);
    const textColor = documentStyle.getPropertyValue('--text-color');
    const textColorSecondary = documentStyle.getPropertyValue('--text-color-secondary');
    const surfaceBorder = documentStyle.getPropertyValue('--surface-border');

    const options = {
      maintainAspectRatio: false,
      aspectRatio: 2,
      plugins: {
        legend: {
          display: false
        }
      },
      scales: {
        x: {
          barPercentage: 0.5,
          categoryPercentage: 0.8,
          ticks: {
            color: '#1D1D1D',
            font: {
              size: 12,
              family: 'Poppins',
              weight: 300
            }
          },
          grid: {
            display: false,
            color: '#FFFFFF'
          }
        },
        y: {
          ticks: {
            beginAtZero: true,
            font: {
              size: 12,
              family: 'Poppins',
              weight: 300
            },
            color: '#1D1D1D'
          },
          grid: {
            color: '#E5EAEB',
            tickColor: '#Fff'
            // tickColor:
          }
        }
      }
    };

    setChartOptions(options);

    setIsPageLoading(true)
    Promise.all([communityTrending(), collaborationTrending(),])
      .then(() => {
        setIsPageLoading(false);
      })
      .catch(error => {
        // Handle error
        console.error("Error occurred:", error);
        setIsPageLoading(false); // Ensure to set onLoad false even in case of error
      });


  }, []);




  // const CollobrationReport = async () => {
  //   try {
  //     const response = await autoInstance.get('/report/get-collaboration-report', { isAdmin: true })
  //     if (response.status === 200) {
  //       setColloborationChartData(response.data.data)
  //     }
  //   } catch (error) {
  //     console.error(error)
  //   }
  // }
  // const CommunityReport = async () => {
  //   try {
  //     const response = await autoInstance.get('/report', { isAdmin: true })
  //     if (response.status === 200) {
  //       setCommunityChartData(response.data.data)
  //     }
  //   } catch (error) {
  //     console.error(error)
  //   }
  // }

  const communityTrending = async () => {
    try {
      const response = await autoInstance.get('/report/get-trending-post-community', { isAdmin: true })
      if (response.status === 200) {
        setCommunityPost(response.data.data)
      }
    } catch (error) {

    }
  }
  const collaborationTrending = async () => {
    try {
      const response = await autoInstance.get('/report/get-trending-post-collaboration', { isAdmin: true })
      if (response.status === 200) {
        setCollaborationPost(response.data.data)
      }
    } catch (error) {

    }
  }

  useEffect(() => {
    document.title = "Reports | Admin"
    const Auth = () => { return !!Cookies.get('g2n0AdminAuth') }
    if (!Auth()) {
      navigate('/admin');
    }
  }, [])

  if (IsPageLoading) {
    return <PageLoader />
  }
  return (
    <>
      <div className='flex items-start justify-start h-screen admin-tabs overflow-hidden admin-chart'>
        <AdminSidebar />
        <div className='2md:w-[calc(100%-250px)] w-[calc(100%-60px)] '>
          <AdminNavBar page="Dashboard" />
          <div className='border-b border-[#EFF3F4] rounded-[8px] '>
            <p className="2sm:p-[20px] xsm:p-[20px] p-[15px] text-[24px] font-semibold leading-[110%] border-b border-[#EFF3F4]">
              Report
            </p>
            <div className='p-[24px] bg-[#F3F5F7]  h-[calc(100vh_-_155px)] overflow-auto'>
              <div className='bg-white rounded-[16px] h-fit'>
                <h3 className='py-[16px] px-[20px] text-[18px] font-bold leading-[110%]'>Communities</h3>
                <div className=' grid grid-cols-2 gap-[16px] pb-[20px] px-[20px]'>
                  <div className='bg-white w-full rounded-[16px] lg:overflow-auto overflow-hidden  min-h-[310px]'>
                    <Chart type="bar" data={communityData} options={chartOptions} />
                  </div>
                  <div className='bg-white w-full  lg:overflow-auto overflow-hidden  min-h-[310px]'>
                    <h3 className='text-[18px] font-medium leading-[110% pb-[16px]'>Trending posts</h3>
                    <div className='space-y-[8px]'>
                      {communityPost && communityPost?.slice(0, 2)?.map((item, index) => (
                        <PostCard key={index} item={item} />
                      ))
                      }
                    </div>
                  </div>

                </div>
              </div>
              <div className='bg-white rounded-[16px] h-fit mt-[24px]'>
                <h3 className='py-[16px] px-[20px] text-[18px] font-bold leading-[110%]'>Collaboration</h3>
                <div className=' grid grid-cols-2 gap-[16px] pb-[20px] px-[20px]'>
                  <div className='bg-white w-full rounded-[16px] lg:overflow-auto overflow-hidden  min-h-[310px]'>
                    <Chart type="bar" data={collaborationData} options={chartOptions} />
                  </div>
                  <div className='bg-white w-full  lg:overflow-auto overflow-hidden  min-h-[310px]'>
                    <h3 className='text-[18px] font-medium leading-[110% pb-[16px]'>Trending posts</h3>
                    <div className='space-y-[8px]'>
                      {collaborationPost && collaborationPost?.slice(0, 2)?.map((item, index) => (
                        <PostCard key={index} item={item} />
                      ))
                      }
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </>
  )
}

export default AdminReports

export const PostCard = ({ item }) => {
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [showEditModal, setShowEditModal] = useState(false)
  const [showReportModal, setShowReportModal] = useState(false)
  const [isMyPost, setIsMyPost] = useState(true);

  const AllCollaboration = item?.postJoin?.find(
    (item) => item.type === "Collaboration"
  );
  const AllCommunity = item?.postJoin?.find(
    (item) => item.type === "Community"
  );

  const PostUser = item?.userId
  const customStyles = {
    menu: {
      borderRadius: '1rem',
    },
    menuItem: {
      fontSize: '1rem',
      fontWeight: '500',
    },
  };

  function getTimeAgo(dateString) {
    const date = new Date(dateString);
    const now = new Date();
    const timeDiff = now - date;
    const seconds = Math.floor(timeDiff / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);
    const weeks = Math.floor(days / 7);

    if (weeks > 0) {
      return weeks === 1 ? "1w" : `${weeks}w`;
    } else if (days > 0) {
      return days === 1 ? "1d" : `${days}d`;
    } else if (hours > 0) {
      return hours === 1 ? "1h" : `${hours}h`;
    } else if (minutes > 0) {
      return minutes === 1 ? "1m" : `${minutes}m`;
    } else {
      return "Just now";
    }
  }
 
  return <>
    <div className='p-[12px] border border-[#EFF3F4] rounded-[16px] '>
      <div className='flex items-center justify-start gap-[10px]'>
        <div className='w-[150px] h-[120px] rounded-[10px] overflow-hidden flex-center'>
          <img src={process.env.REACT_APP_IMAGE_URL + item?.uploadMedia} className="object-cover w-full h-full" alt={item.name} />
        </div>
        <div className='w-[calc(100%-160px)]'>
          <div className=' '>
            <div className='flex items-center justify-between'>
              <p className='text-[#2A8DDE] font-normal text-[12px]'>I’m looking for an answer to:</p>
              <div className="flex items-center justify-end gap-[8px]">
                <p className="text-[10px] font-normal leading-[140%]">
                  {getTimeAgo(item?.createdAt)}
                </p>
                <Menu
                  direction={"bottom"}
                  align={"end"}
                  viewScroll={"close"}
                  menuButton={
                    <MenuButton className="">
                      <svg
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M8.00016 8.66683C8.36835 8.66683 8.66683 8.36835 8.66683 8.00016C8.66683 7.63197 8.36835 7.3335 8.00016 7.3335C7.63197 7.3335 7.3335 7.63197 7.3335 8.00016C7.3335 8.36835 7.63197 8.66683 8.00016 8.66683Z"
                          stroke="#6D7486"
                          strokeWidth="1.4"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M8.00016 4.00008C8.36835 4.00008 8.66683 3.7016 8.66683 3.33341C8.66683 2.96522 8.36835 2.66675 8.00016 2.66675C7.63197 2.66675 7.3335 2.96522 7.3335 3.33341C7.3335 3.7016 7.63197 4.00008 8.00016 4.00008Z"
                          stroke="#6D7486"
                          strokeWidth="1.4"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M8.00016 13.3333C8.36835 13.3333 8.66683 13.0349 8.66683 12.6667C8.66683 12.2985 8.36835 12 8.00016 12C7.63197 12 7.3335 12.2985 7.3335 12.6667C7.3335 13.0349 7.63197 13.3333 8.00016 13.3333Z"
                          stroke="#6D7486"
                          strokeWidth="1.4"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </MenuButton>}
                  styles={customStyles.menu}  // Apply custom styles to Menu
                  transition
                >
                  {isMyPost ? (
                    <>
                      <MenuItem
                        onClick={() => setShowEditModal(true)}
                        styles={customStyles.menuItem}  // Apply custom styles to MenuItem
                      >
                        <span>
                          Edit Post
                        </span>
                      </MenuItem>
                      <MenuItem
                        onClick={() => setShowDeleteModal(true)}
                        styles={customStyles.menuItem}  // Apply custom styles to MenuItem
                      >
                        <span className="!border-[0px]">
                          Delete Post
                        </span>
                      </MenuItem>
                      <MenuItem
                        onClick={() => { }}
                        styles={customStyles.menuItem}  // Apply custom styles to MenuItem
                      >
                        <span className="!border-[0px]">
                          Copy Link
                        </span>
                      </MenuItem>
                    </>
                  ) : (
                    <>
                      <MenuItem
                        onClick={() => { }}
                        styles={customStyles.menuItem}  // Apply custom styles to MenuItem
                      >
                        <span className="!border-[0px]">
                          Copy Link
                        </span>
                      </MenuItem>
                      {/* <MenuItem
                    styles={customStyles.menuItem}  // Apply custom styles to MenuItem
                  >
                    Report Post
                  </MenuItem> */}
                    </>
                  )}
                </Menu>
              </div>
            </div>
            <p className='text-[10px] font-normal leading-[140%] text-[#21272A] pb-[8px] text-ellipsis-Admin-desc'>{item?.description}</p>


          </div>
          <div className='flex items-center justify-between'>
            <div className='w-[calc(100%-108px)]'>
              <div className="flex items-center justify-between gap-[12px] ">
                <div
                  to={`/user/in/${PostUser?._id}`}
                  className="flex items-center justify-start gap-[12px] sm:w-[calc(100%-100px)] w-[calc(100%-60px)]"
                >
                  {PostUser?.profileImage ? (
                    <Link
                      to={`/user/in/${PostUser?._id}`}>
                      <div className="w-[40px] h-[40px] flex items-center justify-center rounded-[8px] overflow-hidden border border-[#EFF3F4]">
                        <img
                          className="h-full w-full object-cover"
                          src={process.env.REACT_APP_IMAGE_URL + PostUser?.profileImage}
                          alt="Profile image"
                        />
                      </div>
                    </Link>
                  ) : (
                    <Link
                      to={`/user/in/${PostUser?._id}`}>
                      <div
                        className="w-[40px] h-[40px] flex items-center justify-center rounded-[8px] overflow-hidden border border-[#EFF3F4]"
                        dangerouslySetInnerHTML={{ __html: icons.defaultProfile }}
                      ></div>
                    </Link>
                  )}
                  <div className='flex items-center justify-between w-full'>
                    <div className="w-[calc(100%-60px)]">
                      <div className="text-[#0C1835] flex flex-wrap items-center justify-start gap-[4px] text-[14px] font-medium leading-[110%] pb-[3px]">
                        <Link
                          to={`/user/in/${PostUser?._id}`}>
                          <p className="text-[14px] font-medium leading-[110%] whitespace-nowrap">
                            {`${PostUser?.first_name ?? ""} ${PostUser?.last_name ?? ""}`}
                          </p>
                        </Link>
                        {
                          (item?.postJoin?.length > 1 || AllCommunity?.community || AllCollaboration?.collaboration) &&
                          <div className="flex flex-wrap items-center justify-start gap-[4px]" >
                            {(item?.postJoin?.length > 1 || AllCommunity?.community || AllCollaboration?.collaboration) && (
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="3"
                                height="4"
                                viewBox="0 0 3 4"
                                fill="none"
                              >
                                <circle cx="1.5" cy="2" r="1.5" fill="#B7BCCA" />
                              </svg>
                            )}

                            {
                              AllCommunity?.community ?
                                <>
                                  {AllCommunity?.community?.name && (
                                    <Link
                                      to={`/community/details/${AllCommunity?.community?._id}`} className="text-[10px] font-normal leading-[140%]">
                                      {AllCommunity?.community?.name}
                                    </Link>
                                  )}
                                </> :
                                <>
                                  {AllCollaboration?.collaboration?.name && (
                                    <Link
                                      to={`/collaborations/details/${AllCollaboration?.collaboration?._id}`} className="text-[10px] font-normal leading-[140%]">
                                      {AllCollaboration?.collaboration?.name}
                                    </Link>
                                  )}
                                </>
                            }

                          </div>
                        }
                      </div>
                      <p className="text-[#6d7486] text-[12px] font-medium leading-[110%]">
                        {PostUser?.iam}
                      </p>
                    </div>
                  </div>

                </div>

              </div>
            </div>
            <div className='flex items-center justify-between gap-[10px]'>
              <div
                className="flex items-center justify-start gap-[4px] text-[14px] font-medium leading-[110%] text-[#6D7486] cursor-pointer"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  className={`heart-button-container  `}
                >
                  <g clipPath="url(#clip0_1036_7533)">
                    <path
                      d="M4.66671 6.66683V14.6668M10 3.92016L9.33337 6.66683H13.22C13.427 6.66683 13.6312 6.71502 13.8163 6.80759C14.0015 6.90016 14.1625 7.03457 14.2867 7.20016C14.4109 7.36576 14.4948 7.55799 14.5319 7.76165C14.5689 7.9653 14.558 8.17478 14.5 8.3735L12.9467 13.7068C12.8659 13.9838 12.6975 14.2271 12.4667 14.4002C12.2359 14.5733 11.9552 14.6668 11.6667 14.6668H2.66671C2.31309 14.6668 1.97395 14.5264 1.7239 14.2763C1.47385 14.0263 1.33337 13.6871 1.33337 13.3335V8.00016C1.33337 7.64654 1.47385 7.3074 1.7239 7.05735C1.97395 6.80731 2.31309 6.66683 2.66671 6.66683H4.50671C4.75476 6.6667 4.99786 6.59737 5.20868 6.46664C5.41949 6.33592 5.58966 6.14897 5.70004 5.92683L8.00004 1.3335C8.31443 1.33739 8.62387 1.41228 8.90524 1.55256C9.18662 1.69284 9.43266 1.8949 9.62498 2.14363C9.81729 2.39236 9.95092 2.68134 10.0159 2.98896C10.0808 3.29659 10.0754 3.61492 10 3.92016Z"
                      stroke={"#6D7486"}
                      strokeWidth="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_1036_7533">
                      <rect width="16" height="16" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
                <div className="text-[10px] font-medium leading-[110%] rounded-full bg-[#EAF4FC] text-[#2A8DDE] w-[16px] h-[16px] flex items-center justify-center">
                  {item?.likes?.length > 0 ? item?.likes?.length : 0}
                </div>
              </div>
              <div
                className="flex items-center justify-start gap-[4px] text-[14px] font-medium leading-[110%] text-[#6D7486] cursor-pointer"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                >
                  <path
                    d="M8.66667 5.33333H4.66667M11.3333 8H4.66667M14 10C14 10.3536 13.8595 10.6928 13.6095 10.9428C13.3594 11.1929 13.0203 11.3333 12.6667 11.3333H4.66667L2 14V3.33333C2 2.97971 2.14048 2.64057 2.39052 2.39052C2.64057 2.14048 2.97971 2 3.33333 2H12.6667C13.0203 2 13.3594 2.14048 13.6095 2.39052C13.8595 2.64057 14 2.97971 14 3.33333V10Z"
                    stroke="#6D7486"
                    strokeWidth="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                <div className="text-[10px] font-medium leading-[110%] rounded-full bg-[#EAF4FC] text-[#2A8DDE] w-[16px] h-[16px] flex items-center justify-center">
                  {item?.comments?.length > 0 ? item?.comments?.length : 0}
                </div>
              </div>
            </div>
            <div>

            </div>

          </div>
        </div>
      </div>
    </div>
  </>
}