import Setting from "../../../pages/Setting/Setting";

const SettingRoutes = {
    path: "/setting",
    element: <Setting />,
    children: [
        {
            path: "editprofile",
            element: <Setting />,
        },
        {
            path: "userprofile",
            element: <Setting />,
        },
        {
            path: "loginsecurity",
            element: <Setting />,
        },
        {
            path: "membership",
            element: <Setting />,
        },
    ]
}
export default SettingRoutes;