import ProfileDetails from "../../../Components/Auth/Signup/ProfileDetails";
import ChatRoom from "../../../Components/Chat";
import Home from "../../../pages/Home"
import NotificationPage from "../../../pages/Notifications";
import PackagePage from "../../../pages/Packages/PackagePage";

const HomePageRoutes = {
    path: "/",
    children: [
        {
            path: "",
            element: <Home />,
        },
        {
            path: "profiledetails",
            element: <ProfileDetails />,
        },
        {
            path: "notification",
            element: <NotificationPage />,
        },
        {
            path: "chat",
            element: <ChatRoom />,
        },
        {
            path: "chat/:id",
            element: <ChatRoom />,
        },
        {
            path: "packages",
            element: <PackagePage />,
        },
    ]
}
export default HomePageRoutes