import "../../../assets/css/PageLoader.css";

const PageLoader = ({ className }) => {
    return (
        <div className={`${className} absolute top-0 left-0 w-[100vw] h-[100vh] bg-[#00000040] flex items-center justify-center z-20`}>
            <div id="loader"></div>
        </div>
    )
}

export default PageLoader