export function Pending() {
  return (
    <div className='py-[4px] px-[8px] w-fit leading-[13px] text-[#6D7486] bg-[#EFF3F4] border-[#EFF3F4] border rounded-full text-[12px] font-medium'>
      Pending
    </div>
  )
}

export function Founder() {
  return (
    <div className='py-[4px] px-[8px] w-fit leading-[13px] text-[#2A8DDE] bg-[#EAF4FC] border-[#EAF4FC] border rounded-full text-[12px] font-medium'>
      Founder
    </div>
  )
}

export function Member() {
  return (
    <div className='py-[4px] px-[8px] w-fit leading-[13px] text-[#2A8DDE] bg-[#EAF4FC] border-[#EAF4FC] border rounded-full text-[12px] font-medium'>
      Member
    </div>
  )
}

export function Join({ onClick, isLoading }) {
  return (
    <div onClick={onClick} className='py-[4px] px-[8px] w-fit leading-[13px] text-[#fff] bg-[#2A8DDE] border-[#2A8DDE] border rounded-[4px] text-[12px] font-medium'>
      {isLoading ? '...' : 'Join'}
    </div>
  )
}
