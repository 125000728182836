import { Menu, MenuButton, MenuItem } from "@szhsin/react-menu";
import { TabPanel, TabView } from 'primereact/tabview';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Bluebtn from "../Components/Common/Btns/Bluebtn";
import CustomModal from "../Components/Common/CustomModel";
import EmptyState from '../Components/Common/EmptyState';
import LeaveModel from "../Components/Common/Models/LeaveModel";
import PageLoader from "../Components/Common/PageLoader";
import UpdateCommunityCollaboration from "../Components/CreateCommunityCollaboration/Update";
import CreatePost from '../Components/CreatePost';
import Post from '../Components/Post';
import { icons } from '../helpers/images';
import Toast from '../helpers/Toast';
import autoInstance from '../utils/axios';
import { selectUser } from '../utils/reduxtoolkit/slices/userSlice';
import HomeLayout from './HomeLayout';

const CommunitieCollaborationDetails = (props) => {
  const { pageTitle } = props;
  const StoreUser = useSelector(selectUser);
  const location = useLocation();
  const [user, setUser] = useState();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const type = location.pathname.split('/')[0, 1];
  const id = location.pathname.split('/')[3];
  const [community, setCommunity] = useState();
  const [collaboration, setCollaboration] = useState();
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [isJoined, setIsJoined] = useState(false);
  const [data, setData] = useState()
  const [isLoading, setIsLoading] = useState(false);
  const [isPageLoading, setIsPageLoading] = useState(false);
  const [getPostDataLoading, setGetPostDataLoading] = useState(false);
  const [PostData, setPostData] = useState([]);
  const [isDeleteLoading, setIsDeleteLoading] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showLeaveModal, setShowLeaveModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [isFollowing, setIsFollowing] = useState(false);
  const [isFollowLoading, setIsFollowLoading] = useState(false);
  const [Following, setFollowing] = useState([]);
  const userId = user?._id;

  const [isOwner, setIsOwner] = useState();

  const [activeIndex, setActiveIndex] = useState(0);

  const handleTabChange = (index) => {
    setActiveIndex(index);
  };

  useEffect(() => {
    if (type === "communities") {
      getCommunityDetails()
    } else if (type === "collaborations") {
      getCollaborationDetails()
    }
    setIsPageLoading(true)
    Promise.all([getProfile(), getPostData()])
      .then(() => {
        setIsPageLoading(false);
      })
      .catch(error => {
        // Handle error
        console.error("Error occurred:", error);
        setIsPageLoading(false); // Ensure to set onLoad false even in case of error
      });


  }, [id, type, isLoading])



  const getProfile = async () => {
    try {
      setIsPageLoading(true)
      const response = await autoInstance.get('/user')
      if (response.status === 200) {
        const source = response.data
        setUser(source?.data)
        // setIsLoading(false)
        getFollowings();
        setIsPageLoading(false)
      }
    } catch (error) {
      setIsPageLoading(false)
      console.error(error)
    }
  }

  const getPostData = async () => {
    try {
      const response = await autoInstance.get(`/post?category_id=${id}&type=${type === "communities" ? "community" : "collaboration"}`)
      const source = response.data
      setPostData(source?.data)
    } catch (error) {
      console.error(error)
    }
  }

  const getFollowings = async () => {
    try {
      const response = await autoInstance.get(`/follow-request/my-followers/${StoreUser?._id}`);
      if (response.status === 200) {
        const data_ = response.data.data.map(item => (item.receiverId._id))
        setFollowing(data_);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleFollow = async (SelectedId) => {
    setIsFollowLoading(true);
    try {
      const response = await autoInstance.post(`/follow-request/${SelectedId}`);
      if (response.status === 200) {
        getFollowings();
        Toast(response.data.message, 'success');
      }
    } catch (error) {
      console.error(error);
    }
    setIsFollowLoading(false);
  };
  const handleUnFollow = async (SelectedId) => {
    setIsFollowLoading(true);
    try {
      const response = await autoInstance.delete(`/follow-request/${SelectedId}`);
      if (response.status === 200) {
        getFollowings()
        Toast(response.data.message, 'success');
      }
    } catch (error) {
      console.error(error);
    }
    setIsFollowLoading(false);
  };


  const handleDelete = async (index) => {
    try {
      setIsDeleteLoading(true)
      const response = await autoInstance.delete(`/${type === "communities" ? "community" : "collaboration"}/${data?._id}`)
      if (response.status === 200) {
        navigate(`/${type === "communities" ? "communities" : "collaborations"}`)
        setIsDeleteLoading(false)
        Toast("success", "Post deleted successfully")
      }
    } catch (error) {
      setIsDeleteLoading(false)
      console.error(error)
    };
  };

  const checkMembership = (data, userId) => {
    if (data && data.members) {
      for (const member of data.members) {
        if (member?.memberId?._id === userId) {
          return true;
        }
      }
    }
    return false;
  };


  useEffect(() => {
    const membershipStatus = checkMembership(data, userId);
    setIsJoined(membershipStatus);
  }, [data, userId, isLoading]);


  const getCommunityDetails = async () => {
    try {
      const response = await autoInstance.get(`/community/${id}`)
      const source = response.data
      setData(source?.data)
      setIsOwner(source?.data?.userId?._id === StoreUser?._id)
    } catch (error) {
      console.error(error)
    }
  }
  const getCollaborationDetails = async () => {
    try {
      const response = await autoInstance.get(`/collaboration/${id}`)
      const source = response.data
      setData(source?.data)
      setIsOwner(source?.data?.userId?._id === StoreUser?._id)
    } catch (error) {
      console.error(error)
    }
  }

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
      "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const month = monthNames[date.getMonth()];
    const year = date.getFullYear();
    return `${month} ${year}`;
  };

  // Usage
  const formattedDate = formatDate(data?.createdAt);


  const Activity = (options) => {
    return (
      <p className="font-[600] text-[#6D7486] white-space-nowrap text-[14px] leading-[110%] p-[12px]" style={{ cursor: 'pointer' }} onClick={options.onClick}>Activity</p>

    )
  };
  const About = (options) => {
    return (
      <p className="font-[600] text-[#6D7486] white-space-nowrap text-[14px] leading-[110%] p-[12px]" style={{ cursor: 'pointer' }} onClick={options.onClick}>About</p>
    )
  };

  const menuRight = useRef(null);

  const joinCommunities = async () => {
    try {
      if (type === "communities") {
        const response = await autoInstance.post(`community/${id}/join-member`)
        if (response === 200) {
          getCommunityDetails()
          getProfile()
          setIsLoading(false)
          Toast(response.data.message, 'success')
        }
      } else if (type === "collaborations") {
        const response = await autoInstance.post(`collaboration/${id}/join-member`)
        if (response === 200) {
          getCollaborationDetails()
          getProfile()
          setIsLoading(false)
          Toast(response.data.message, 'success')
        }
      }
    } catch (error) {
      console.error(error);
    }
    finally {
      setIsLoading(false);
    }
  }

  const handleMenuItemClick = (event, tabIndex) => {
    setActiveTabIndex(tabIndex);
  };



  const ListOfMember = data?.members;
  const currentMember = ListOfMember?.filter(item => item?.memberId !== null);

  const sortedListOfMembers = currentMember?.slice().sort((a, b) => {
    // Sorting by 'following' status, items with following true appear first
    if (a.following && !b.following) return -1;
    if (!a.following && b.following) return 1;
    return 0;
  });

  // const memberLength = ListOfMember.map(item => item.memberId



  // if (handleDeleteLoading) {
  //   return (
  //     <PageLoader />
  //   )
  // }


  const handleCopyLink = () => {
    const link = `${window.location.origin}/${type}/details/${data?._id}`;
    if (navigator.clipboard && navigator.clipboard.writeText) {
      navigator.clipboard
        .writeText(link)
        .then(() => {
          Toast("Link copied to clipboard:", link);
          // You can provide feedback to the user that the link is copied successfully if needed
        })
        .catch((error) => {
          Toast("Failed to copy link:", error);
          // Handle error or provide feedback to the user
        });
    } else {
      const textArea = document.createElement("textarea");
      textArea.value = link;
      document.body.appendChild(textArea);
      textArea.select();
      try {
        document.execCommand("copy");
        Toast("Link copied to clipboard:", link);
      } catch (error) {
        Toast("Failed to copy link:", error);
      }
      document.body.removeChild(textArea);
    }
  };

  const followingMap = {};
  Following?.forEach(id => {
    followingMap[id] = true;
  });

  if (isPageLoading) {
    return (
      <PageLoader />
    )
  }

  return (
    <div>
      <HomeLayout children={
        <>
          <div className='py-[20px] px-[24px] flex items-center justify-between border-b border-[#EFF3F4] bg-white'>
            <div className="flex justify-start gap-[12px] items-center 2md:w-[calc(100%-170px)] w-[calc(100%-76px)]">
              <div className='2sm:w-[60px] 2sm:h-[60px] w-[54px] h-[54px] flex-center rounded-[8px] overflow-hidden border border-[#EFF3F4] '>
                <img src={`${data?.image ? process.env.REACT_APP_IMAGE_URL + data?.image : "https://via.placeholder.com/60"}`} alt="" className='h-full w-full object-cover' />
              </div>
              <div className='w-[calc(100%-70px)] overflow-hidden text-ellipsis'>
                {/* <div className='flex items-center justify-start gap-[4px]'> */}
                <p className='2md:text-[24px] 2sm:text-[20px] text-[18px] font-semibold leading-[110%] pb-[4px]'>{data?.name}</p>
                {/* <span>
                    <div className='flex items-center justify-start gap-[4px]'>
                      <div className='w-[3px] h-[3px] rounded-full overflow-hidden bg-[#6D7486] 2sm:hidden block'></div>
                      <p className='text-[#6D7486] 2sm:text-[12px] text-[10px] font-medium leading-[110%]'>{ListOfMember?.length} Members</p>
                    </div>
                  </span> */}
                {/* </div> */}
                <div className='2sm:flex items-center justify-start gap-[4px]'>
                  <div className='flex items-center justify-start gap-[4px]'>
                    <div className='w-[3px] h-[3px] rounded-full overflow-hidden bg-[#6D7486] 2sm:hidden block'></div>
                    <p onClick={() => handleTabChange(1)} className='text-[#6D7486] 2sm:text-[12px] text-[10px] font-medium leading-[110%] cursor-pointer'>{currentMember?.length} Members</p>

                  </div>
                  <div className='w-[3px] h-[3px] rounded-full overflow-hidden bg-[#6D7486] 2sm:block hidden'></div>
                  <div className='flex items-center justify-start gap-[4px]'>
                    <div className='w-[3px] h-[3px] rounded-full overflow-hidden bg-[#6D7486] 2sm:hidden block'></div>
                    <p className='text-[#6D7486] 2sm:text-[12px] text-[10px] font-medium leading-[110%]'>Created {formattedDate}</p>
                  </div>
                </div>
              </div>
            </div>
            <div className='flex items-center justify-end gap-[8px]'>
              {user &&
                <button onClick={() => {
                  if (!isJoined) {
                    joinCommunities();
                    setIsLoading(true);
                  }
                }} className='bg-[#EAF4FC] 2sm:py-[12px] py-[8px] 2sm:px-[24px] px-[8px] rounded-[6px] leading-[100%] tracking-[0.06px] text-[#2A8DDE]'>
                  <div className='2sm:hidden flex items-center justify-center blue-icon text-white w-[18px] h-[18px]' dangerouslySetInnerHTML={{ __html: icons.JoinIcons }} />
                  {
                    isOwner ?
                      <p className='2sm:block hidden'>
                        Founder
                      </p> :
                      <p className='2sm:block hidden'>
                        {isJoined ? "Member" : isLoading ? "Joining..." : "Join"}
                      </p>
                  }
                </button>
              }
              <Menu
                direction={"bottom"}
                align={"end"}
                viewScroll={"close"}
                menuButton={
                  <MenuButton>
                    <div className='2sm:w-[38px] 2sm:h-[38px] w-[34px] h-[34px] flex-center bg-[#EAF4FC] rounded-[6px] ' dangerouslySetInnerHTML={{ __html: icons.moreVertical }} />
                  </MenuButton>
                }
                transition
              >
                {isJoined ? (
                  <>

                    {!isOwner && <MenuItem onClick={() => setShowLeaveModal(true)}>
                      <span className="!border-[0px]">
                        Leave
                      </span>
                    </MenuItem>}
                    {isOwner && <MenuItem onClick={() => setShowEditModal(true)}>
                      <span className="!border-[0px]">
                        Edit
                      </span>
                    </MenuItem>}
                    <MenuItem onClick={() => handleCopyLink()}>
                      <span className={`${isOwner && "!border-[0px]"}`}>
                        Copy Link
                      </span>
                    </MenuItem>
                    {isOwner && <MenuItem onClick={() => setShowDeleteModal(true)}>
                      <span>
                        Delete
                      </span>
                    </MenuItem>}
                  </>
                ) : (
                  <>
                    <MenuItem onClick={() => handleCopyLink()}>
                      <span className="!border-[0px]">
                        Copy Link
                      </span>
                    </MenuItem>
                  </>
                )}
              </Menu>

            </div>
          </div>
          <div className='border-b-nav-container bg-white CollobraDetails-tabs '>
            <div className=''>
              <TabView className='' activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}>
                {/* Activity Tab */}
                <TabPanel headerTemplate={Activity} className=''>
                  <div className='bg-[#F3F5F7] py-[16px] px-[24px] tabs-header-bg space-y-[16px] h-[calc(100vh-220px)] overflow-y-auto hide-scrollbar'>
                    {isJoined && <CreatePost pageTitle={type} Data={data} uploadPost={getPostData} />}
                    {PostData?.length > 0 && PostData.map((item, index) => <Post key={index} PostData={item} updateData={getPostData} />)}
                    {
                      PostData?.length === 0 && <EmptyState page={type} title={"No Post Found"} />
                    }
                  </div>
                </TabPanel>
                {/* About Tab */}
                <TabPanel headerTemplate={About}>
                  <div className='bg-[#fff] py-[16px] px-[24px] 2sm:h-[calc(100vh-220px)] h-[calc(100vh-257px)] overflow-auto hide-scrollbar'>
                    <div className='space-y-[16px]'>
                      <p className='text-[13px] leading-[110%] font-medium'>{data?.description}</p>
                    </div>
                    <div className='flex justify-start gap-[10px] py-[10px] mt-[16px] border-t border-[#EFF3F4]'>
                      <p className='text-[16px] font-medium leading-[110%]'>Member</p>
                      <div className='min-w-[16px] h-[16px] p-[4px] bg-[#EAF4FC] rounded-full flex-center text-[10px] font-medium leading-[110%]'>
                        {currentMember?.length}
                      </div>
                    </div>
                    <div className='py-[10px] space-y-[16px]'>
                      {
                        sortedListOfMembers?.map((item, index) => {
                          // Check if the current member's _id is in the Followings array
                          const isFollowing = followingMap[item?.memberId?._id];
                          if (!item?.memberId) return <></>
                          return (
                            <div to={`/user/in/${item?.memberId?._id}`} className='flex items-center justify-between' key={index}>
                              <Link to={`/user/in/${item?.memberId?._id}`} className='flex items-center justify-start gap-[12px]'>
                                {
                                  item?.memberId?.profileImage ?
                                    <div className='w-[40px] h-[40px] rounded-[8px] flex-center overflow-hidden border border-[#EFF3F4]'>
                                      <img src={process.env.REACT_APP_IMAGE_URL + item?.memberId?.profileImage} alt="" className='w-full h-full object-cover' />
                                    </div> :
                                    <div
                                      className="cursor-pointer w-[40px] h-[40px] rounded-[8px]  z-10 relative overflow-hidden flex items-center justify-center border border-[#EFF3F4]"
                                      dangerouslySetInnerHTML={{ __html: icons.defaultProfile }}
                                    />
                                }
                                <div>
                                  <p className='text-[16px] leading-[110%] font-medium'>{`${item?.memberId?.first_name ?? ""} ${item?.memberId?.last_name ?? ""}`}</p>
                                  <p className='text-[12px] leading-[140%] font-normal text-[#7B7B7B]'>{item?.memberId?.iam}</p>
                                </div>
                              </Link>
                              {userId !== item?.memberId?._id &&
                                <button onClick={() => { isFollowing ? handleUnFollow(item?.memberId?._id) : handleFollow(item?.memberId?._id) }} className={`${isFollowing ? "bg-[#EAF4FC] text-[#2A8DDE] text-[14px]" : "bg-[#2A8DDE] text-[#fff] text-[14px]"}} py-[4px] px-[8px] rounded-[4px]  text-[14px] font-medium leading-[100%]`}>
                                  {isFollowing ? "Following" : "Follow"}
                                </button>
                              }
                            </div>
                          );
                        })
                      }
                    </div>
                  </div>
                </TabPanel>
              </TabView>
            </div>
          </div>
          {/* Edit Model  */}
          <CustomModal bodyClassName=" rounded-[16px] !overflow-visible " showModal={showEditModal} setShowModal={setShowEditModal}  >
            <UpdateCommunityCollaboration pageTitle={pageTitle === "Community Details" ? "Community" : "Collaboration"} setCreateNew={setShowEditModal} Data={data} updateData={type === "communities" ? getCommunityDetails : getCollaborationDetails} />
          </CustomModal>
          {/* Edit Model  */}
          {/* Leave Model  */}
          <CustomModal bodyClassName=" rounded-[16px] !overflow-visible " showModal={showLeaveModal} setShowModal={setShowLeaveModal}  >
            <LeaveModel title={type} show={showLeaveModal} setIsOpen={setShowLeaveModal} _id={data?._id} updateData={type === "communities" ? getCommunityDetails : getCollaborationDetails} />
          </CustomModal>
          {/* Leave Model  */}
          {/* Delete Model   */}
          <CustomModal bodyClassName=" rounded-[16px] " showModal={showDeleteModal} setShowModal={setShowDeleteModal} >
            <div class="w-full h-full  bg-white shadow-lg  rounded-[16px] 2sm:p-[24px] xsm:p-[20px] p-[15px] relative ">
              <div className='flex items-center justify-between'>
                <h1 className='2lg:text-[26px] 2sm:text-[24px] md:text-[22px] text-[20px]  leading-[normal] font-[600]'>Delete {data?.name} {type === "communities" ? "Community" : "Collaboration"}</h1>
                <div onClick={() => { setShowDeleteModal(false); }} className='cursor-pointer ' dangerouslySetInnerHTML={{ __html: icons.closeIcon }} />
              </div>
              <p className='text-[#21272A] font-normal text-[12px] leading-[140%] py-[10px]'>Are you sure you want to delete this {type === "communities" ? "community" : "collaboration"}?</p>
              <div className='flex justify-end'>
                <Bluebtn className="text-[14px] font-medium py-[8px] px-[16px] w-fit mt-[16px]  text-white bg-[#6D7486]" Label="Delete" onClick={() => { handleDelete(); setShowDeleteModal(false) }} />
              </div>
            </div>
          </CustomModal>
          {/* Delete Model  */}
        </>
      } />
    </div>
  )
}

export default CommunitieCollaborationDetails