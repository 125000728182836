import Cookies from 'js-cookie'
import { InputSwitch } from 'primereact/inputswitch'
import { TabPanel, TabView } from 'primereact/tabview'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Bluebtn from '../Components/Common/Btns/Bluebtn'
import CustomModal from '../Components/Common/CustomModel'
import PageLoader from '../Components/Common/PageLoader'
import { icons } from '../helpers/images'
import Toast from '../helpers/Toast'
import autoInstance from '../utils/axios'
import AdminSidebar from './AdminSidebar'
import AdminNavBar from './NavBar/AdminNavbar'

function AdminDashboard() {
  const navigate = useNavigate()
  const [userList, setUserList] = useState([])
  const [userLoading, setUserLoading] = useState(false)
  const [communitiesdata, setCommunitiesdata] = useState([])
  const [collaboratingdata, setCollaboratingdata] = useState([])
  const [myCollaboratingdata, setMyCollaboratingdata] = useState([])
  const [pageLoaded, setPageLoaded] = useState(true);
  useEffect(() => {
    document.title = "Dashboard | Admin"
    const Auth = () => { return !!Cookies.get('g2n0AdminAuth') }
    if (!Auth()) {
      navigate('/admin');
    }
    getUserList()
    getCommunitydata()
    getColloborationdata()
  }, [])

  const getCommunitydata = async () => {
    const otherCommunitiesResponse = await autoInstance('/community?type=all', { isAdmin: true });
    const suggestedList = otherCommunitiesResponse?.data?.data ?? [];
    // Filter out communities where isActive is true
    const activeCommunities = suggestedList.filter(item => item.isActive === false);

    setCommunitiesdata(activeCommunities);
    setPageLoaded(false);
  };

  const getColloborationdata = async () => {
    const allCollaborationResponse = await autoInstance('/collaboration?type=all', { isAdmin: true });

    const suggestedList = allCollaborationResponse?.data?.data ?? [];

    // Filter out collaborations where isActive is true
    const activeCollaborations = suggestedList.filter(item => item.isActive === false);

    setCollaboratingdata(activeCollaborations);
    setPageLoaded(false);
  };


  const getUserList = async () => {
    try {
      setUserLoading(true)
      const response = await autoInstance.get('/user/list', { isAdmin: true })
      if (response.status === 200) {
        const source = response.data
        setUserList(source?.data)
        setUserLoading(false)
      }
    } catch (error) {
      setUserLoading(false)
      console.error(error)
    }
  }

  const CommunitiesTab = (options) => {
    return (
      <div className='flex items-center justify-start gap-[4px] p-[12px]' onClick={options.onClick} style={{ cursor: 'pointer' }}>
        <p className="font-[500] text-[#6D7486] whitespace-nowrap text-[14px] leading-[110%] "  >Communities</p>
      </div>

    )
  };

  const CollaborationsTab = (options) => {
    return (
      <div className='flex items-center justify-start gap-[4px] p-[12px]' onClick={options.onClick} style={{ cursor: 'pointer' }}>
        <p className="font-[500] text-[#6D7486] whitespace-nowrap text-[14px] leading-[110%] "  >Collaborations</p>
      </div>
    )
  };

  if (pageLoaded) {
    return (
      <PageLoader />
    )
  }

  return (
    <div className='flex items-start justify-start h-screen admin-tabs overflow-hidden'>
      <AdminSidebar />
      <div className='2md:w-[calc(100%-250px)] w-[calc(100%-60px)] '>
        <AdminNavBar page="Dashboard" />
        <div className='border-b border-[#EFF3F4] rounded-[8px] '>
          <p className="2sm:p-[20px] xsm:p-[20px] p-[15px] text-[24px] font-semibold leading-[110%] border-b border-[#EFF3F4]">
            Dashboard
          </p>
          <div className='p-[24px] bg-[#F3F5F7] grid lg:grid-cols-3 grid-cols-1 gap-[16px] h-[calc(100vh_-_155px)] lg:overflow-hidden overflow-auto'>
            <div className='bg-white col-span-2  rounded-[16px] lg:overflow-auto overflow-hidden lg:h-[calc(100vh_-_155px)] min-h-[310px]'>
              <div className='pt-[16px] px-[20px]'>
                <p className='text-[18px] font-semibold leading-[110%]'>Pending request</p>
              </div>
              <TabView className='px-[20px]'>
                {/* Activity Tab */}
                <TabPanel headerTemplate={CommunitiesTab} className=''>
                  <div className='2lg:h-[calc(100vh_-_270px)] lg:h-[calc(100vh_-_225px)] h-[calc(27vh_-_0px)] overflow-auto hide-scrollbar  min-h-[210px] py-[20px] space-y-[8px] '>
                    {
                      communitiesdata?.map((item, index) => {
                        return (
                          <AdminCommunity item={item} updateData={getCommunitydata} type="community" />
                        )
                      })
                    }
                  </div>
                </TabPanel>
                {/* Users Tab */}
                <TabPanel headerTemplate={CollaborationsTab} className=''>
                  <div className='2lg:h-[calc(100vh_-_270px)] lg:h-[calc(100vh_-_225px)] h-[calc(27vh_-_0px)] overflow-auto hide-scrollbar  min-h-[210px] py-[20px] space-y-[8px]  '>
                    {
                      collaboratingdata?.map((item, index) => {
                        return (
                          <AdminCommunity item={item} updateData={getColloborationdata} type="collaboration" />
                        )
                      })
                    }
                  </div>
                </TabPanel>
              </TabView>
            </div>
            <div className='bg-white py-[16px] px-[20px] rounded-[16px] lg:overflow-auto overflow-hidden lg:h-[calc(100vh_-_155px)] min-h-[210px]'>
              <p className='text-[18px] font-semibold leading-[110%] '>Users</p>
              <div className='flex items-center justify-between py-[12px]  mb-[4px] font-[500] text-[#6D7486] whitespace-nowrap text-[14px] leading-[110%] '>
                <p className=' '>Name</p>
                <p className=' '>Status</p>
              </div>
              <div className='space-y-[16px] lg:h-[calc(100vh_-_258px)] h-[calc(27vh_-_0px)] overflow-auto hide-scrollbar w-full'>
                {
                  userList
                    ?.filter(item => item.roleId !== 3 && item?.verified === true && item?.isProfileCompleted === true)
                    .map((item, index) => {
                      return (
                        <AdminUser item={item} updateData={getUserList} />
                      )
                    })
                }



              </div>
            </div>
          </div>
        </div>

      </div>
    </div >
  )
}

export default AdminDashboard




export const AdminUser = ({ item, updateData }) => {
  const [isLoading, setIsLoading] = useState(false);

  const onInputChange = async (e, name) => {
    try {
      setIsLoading(true);
      const val = e.target.value;
      const response = await autoInstance.post('/user/change-status', { id: e.target.id, isActive: val }, { isAdmin: true }).then((data) => {
        if (data?.status == 200) {
          updateData();
          setIsLoading(false);
        }
      })
    } catch (error) {
      setIsLoading(false);
      console.error(error);
    }
  };

  return (
    <div className=' w-full  flex items-center justify-between gap-[8px] cursor-pointer '>
      {
        isLoading && <PageLoader />
      }
      <div className='flex items-center justify-start gap-[8px] w-[calc(100%-57px)]'>
        {
          item?.profileImage ?
            <div className='w-[40px] h-[40px] rounded-[8px] overflow-hidden flex items-center justify-center'>
              <img className='w-full h-full object-cover' src={process.env.REACT_APP_IMAGE_URL + item?.profileImage} alt={item?.first_name} />
            </div> :
            <div className='w-[40px] h-[40px] rounded-[8px] overflow-hidden flex items-center justify-center' dangerouslySetInnerHTML={{ __html: icons?.defaultProfile }} />
        }
        <div className='w-[calc(100%-40px)] overflow-hidden'>
          <p className='text-[16px] font-medium text-[#0C1835] whitespace-nowrap text-ellipsis overflow-hidden w-[99%]'>{`${item?.first_name ?? ""} ${item?.last_name ?? ""}`}</p>
          <p className='text-[12px] font-normal leading-[140%] text-[#6D7486] whitespace-nowrap text-ellipsis overflow-hidden w-[99%]'>{item?.iam}</p>
        </div>
      </div>
      <div className='flex items-center justify-start gap-[8px]'>
        {/* <div className='py-[4px] px-[8px] bg-[#EAF4FC]  rounded-[12px] text-[12px] font-medium leading-[110%] text-[#2A8DDE]'>Premium</div> */}
        <InputSwitch id={item?._id} checked={item?.isActive} className='custom-inputswitch' onChange={(e) => onInputChange(e, 'isActive')} />

      </div>

    </div>
  )
}


export const AdminCommunity = ({ item, updateData, type }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isApporved, setIsApporved] = useState(item?.isActive ?? false);
  const [isDeleteLoading, setIsDeleteLoading] = useState(false);
  const [adminData, setAdminData] = useState({
    email: '',
    description: '',
    image: '',
    userId: '',
  });


  const handleSend = async () => {
    try {
      setIsSending(true)
      const response = await autoInstance.post(`${type}/${item?._id}/send-message-to-owner`,
        { content: adminData?.description },
        { isAdmin: true })
      if (response?.status === 200) {
        setIsSending(false)
        setIsOpen(false)
        Toast(`Message has been sent `, 'success')
      }
    } catch (err) {
      setIsSending(false)
    }
  };

  const handleApprove = async () => {
    try {
      setIsLoading(true)
      const response = await autoInstance.patch(`${type}/${item?._id}`,
        { isActive: true },
        { isAdmin: true })
      if (response?.status === 200) {
        updateData();
        setIsLoading(false)
        Toast(` ${type} has been approved `, 'success')
      }
    } catch (err) {
      setIsLoading(false)
      console.error(err);
    }
  }

  return (
    <div className='p-[8px] w-full rounded-[12px] bg-white hover:bg-[#EAF4FC] flex items-center justify-between gap-[8px] cursor-pointer border border-[#EFF3F4]'>
      {isLoading || isSending && <PageLoader />}
      <div onClick={() => setShowDetails(!showDetails)} className='flex items-center justify-start gap-[8px] w-[calc(100%_-_40%)]'>
        {
          item.image ?
            <div className='w-[60px] h-[60px] rounded-[8px] overflow-hidden flex items-center justify-center'>
              <img className='w-auto h-auto min-w-[60px] min-h-[60px] object-cover' src={process.env.REACT_APP_IMAGE_URL + item.image} alt={item?.name} />
            </div> :
            <div className='w-[60px] h-[60px] rounded-[8px] overflow-hidden flex items-center justify-center' dangerouslySetInnerHTML={{ __html: icons?.defaultProfile }} />
        }
        <div className='flex flex-col justify-between w-[calc(100%_-_75px)]'>
          <p className='text-[12px] font-medium text-[#0C1835] 3xl:pb-[8px] pb-[6px]'>{item?.name}</p>
          <div>

            <p className='text-[#6D7486] text-[10px] font-normal pb-[4px]'>{`${item?.userId?.first_name ?? ""
              } ${item?.userId?.last_name ?? ""}`}</p>
            {/* <p className='text-[#6D7486] text-[10px] font-normal pb-[4px]'>{`${userId?.first_name ?? ""
              } ${userId?.last_name ?? ""}`}</p> */}
            <p className="text-[#0C1835] text-[10px] font-light whitespace-nowrap overflow-hidden text-ellipsis w-[90%]">
              {item?.description}
            </p>

          </div>
        </div>
      </div>
      <div className='flex items-center justify-start gap-[8px]'>
        <p className='text-[#6D7486] text-[16px] font-normal pb-[4px]'>{`${item?.userId?.email ?? ""
          }`}</p>
        <div className='flex items-center justify-end gap-[8px]'>
          <div onClick={() => { handleApprove() }} className='py-[4px] px-[8px] w-fit leading-[13px] text-[#fff] bg-[#2A8DDE] border-[#2A8DDE] border rounded-[4px] text-[12px] font-normal'>
            {isApporved ? 'Approved' : 'Approve'}
          </div>
          <div onClick={() => setIsOpen(true)} dangerouslySetInnerHTML={{ __html: icons.MessageIcon }} />
        </div>
      </div>
      <CustomModal showModal={isOpen} setShowModal={setIsOpen} bodyClassName={`rounded-[16px] overflow-hidden`}>
        <div className='space-y-[16px] pt-[16px]  px-[25px] pb-[25px]'>
          <p className='text-[16px] font-medium'>Send Message to : {item?.userId?.first_name ?? ""}  {item?.userId?.last_name ?? ""}</p>
          <div>
            <label htmlFor='description' className='text-[16px] font-medium '>Description</label>
            <div>
              <textarea className='mt-[6px] w-full focus-visible:outline-none focus:outline-none p-[16px] border border-[#EAF4FC] rounded-[6px] resize-none' placeholder={`Description`} id='description' onChange={(e) => { setAdminData({ ...adminData, description: e.target.value }) }} />
            </div>
          </div>
          <div className='flex justify-end'>
            <Bluebtn Label={isSending ? 'Sending...' : 'Send Message'} onClick={isSending ? () => { } : handleSend} className={`text-[14px] font-medium py-[8px] px-[16px] w-fit text-white ${isSending ? 'cursor-not-allowed !bg-[#6D7486]' : 'cursor-pointer'} `} />
          </div>
        </div>
      </CustomModal>
      <CustomModal showModal={showDetails} setShowModal={setShowDetails} bodyClassName={`rounded-[16px] overflow-hidden`}>
        <div className='p-[25px]'>
          <div className='flex items-center justify-between gap-[16px]'>
            <h1 className='2lg:text-[22px] 2sm:text-[20px] md:text-[18px] text-[16px]   leading-[normal] font-[600] capitalize'>{type} Details</h1>
            <div onClick={() => { setShowDetails(false); }} className='cursor-pointer ' dangerouslySetInnerHTML={{ __html: icons.closeIcon }} />
          </div>
          <div>
            <div className='flex items-start justify-start gap-[10px] py-[10px]'>
              <div className='w-[60px] h-[60px] rounded-[8px] overflow-hidden'>
                <img className='w-full h-full object-cover' src={process.env.REACT_APP_IMAGE_URL + item?.image} alt={item?.name} />
              </div>
              <div>
                <p className='text-[#0C1835] text-[16px] font-medium'>{item?.name}</p>
                <p className='text-[#6D7486] text-[12px] font-normal pb-[4px]'>{`${item?.userId?.first_name ?? ""
                  } ${item?.userId?.last_name ?? ""}`}</p>
              </div>
            </div>
            <pre className='text-[#6D7486] text-[16px] font-normal text-wrap'>{item?.description}</pre>
          </div>
        </div>
      </CustomModal>
    </div>
  )
} 