import DOMPurify from 'dompurify';
import Cookies from 'js-cookie';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import { Editor } from "primereact/editor";
import { FileUpload } from 'primereact/fileupload';
import { InputSwitch } from 'primereact/inputswitch';
import { Rating } from 'primereact/rating';
import { Tag } from 'primereact/tag';
import { Toast } from 'primereact/toast';
import { classNames } from 'primereact/utils';
import React, { useEffect, useRef, useState } from 'react';
import Bluebtn from '../../Components/Common/Btns/Bluebtn';
import CustomModal from '../../Components/Common/CustomModel';
import CustomInput from '../../Components/Common/Inputs/CustomInput';
import PageLoader from '../../Components/Common/PageLoader';
import CustomSelect from '../../Components/Common/Select/CustomSelect';
import { icons } from '../../helpers/images';
import autoInstance from '../../utils/axios';

export default function CommunitiesTable({ pageTitle }) {
    let emptyProduct = {
        "title": "Calue innfefoiqw",
        "type": "Article",
        "description": "",
        "file": "uploads\\image\\1717572706914-934878750.jpg",
        "category": "",
        "userId": {
            "$oid": "665ff959e2758210f5779d8d"
        },
        "isActive": true,
    };
    const [data, setData] = useState(null);
    const [climateVault, setClimateVault] = useState(null);
    const [showDetails, setShowDetails] = useState(false);
    const [item, setItem] = useState(null);
    const [products, setProducts] = useState(null);
    const [productDialog, setProductDialog] = useState(false);
    const [idEdit, setIsedit] = useState(false)
    const [deleteProductDialog, setDeleteProductDialog] = useState(false);
    const [deleteProductsDialog, setDeleteProductsDialog] = useState(false);
    const [product, setProduct] = useState(emptyProduct);
    const [selectedProducts, setSelectedProducts] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [checked, setChecked] = useState(true);
    const [globalFilter, setGlobalFilter] = useState(null);
    const toast = useRef(null);
    const dt = useRef(null);
    const [loading, setLoading] = useState(false);
    const [productId, setProductId] = useState(null)
    const [isActiveLoading, setIsActiveLoading] = useState(false);
    const [climateType, setClimateType] = useState("Article");
    const [isPosting, setIsPosting] = useState(false)
    const [isDeleteLoading, setDeleteLoading] = useState(false)
    const [showDeleteVaultModal, setShowDeleteVaultModal] = useState(false)
    const [categoryOptions, setCategoryOptions] = useState([{
        label: "",
        value: ""
    }]);
    const [sanitizedHTML, setSanitizedHTML] = useState("")
    const [sanitizedSummaryHTML, setSanitizedSummaryHTML] = useState("")
    const [newProduct, setNewProduct] = useState({
        title: '',
        type: "Article",
        image: null,
        category_id: '',
        summary: "",
        video: "",
    })
    const [ProductDescription, setProductDescription] = useState(
        idEdit ? product.description : "",
    )



    const [user, setUser] = useState(null);
    const Auth = () => { return !!Cookies.get('g2n0AdminAuth') }

    const getProfile = async () => {
        const response = await autoInstance.get('/user', { isAdmin: true }).then((data) => {
            setUser(data.data.data)
        });
    }
    const getList = async () => {
        if (pageTitle === "Communities") {
            const response = await autoInstance.get('/community', { isAdmin: true }).then((data) => {
                setData(data.data.data)
            });
        } else if (pageTitle === "Collaborations") {
            const response = await autoInstance.get('/collaboration', { isAdmin: true }).then((data) => {
                setData(data.data.data)
            });
        } else if (pageTitle === "Climate Vault") {
            const response = await autoInstance.get('/climate-vault?type=Article', { isAdmin: true }).then((data) => {
                setData(data.data.data)
            });
        }
    }

    const getCategory = async () => {
        try {
            const response = await autoInstance.get('/climate-vault/category/list', { isAdmin: true });
            const categoryData = response?.data?.data?.map(category => ({
                label: category.name,
                value: category._id
            }));
            setCategoryOptions(categoryData);
        } catch (error) {
            console.error(error);
            console.error("Error fetching category data:", error);
        }
    }



    useEffect(() => {
        if (Auth()) {
            try {
                getList()
                if (pageTitle === "Climate Vault") {
                    getCategory()
                }
                getProfile()

            } catch (error) {
                console.error(error);
            }
        }
    }, []);

    useEffect(() => {
        if (data) {
            data.forEach(user => {
            });
        }
    }, [products])

    const formatCurrency = (value) => {
        return value.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
    };

    const openNew = () => {
        setProduct(emptyProduct);
        setSubmitted(false);
        setIsedit(false)
        setProductDialog(true);
    };

    const hideDialog = () => {
        setSubmitted(false);
        setIsedit(false)
        setNewProduct({
            title: '',
            type: "Article",
            image: null,
            category_id: '',
            summary: '',
            video: "",
        });
        setProductDialog(false);
    };

    const createClimateVault = async () => {
        try {
            setIsPosting(true)
            const formData = new FormData();
            if (climateType === "Article") {
                formData.append('title', newProduct?.title);
                formData.append('description', ProductDescription);
                formData.append('type', newProduct.type);
                formData.append('file', newProduct?.image);
                formData.append('summary', newProduct?.summary);
                formData.append('category_id', newProduct.category_id);
                formData.append('isActive', true);
            } else {
                formData.append('video', newProduct?.video);
            }


            const response = await autoInstance.post(`/climate-vault`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
                isAdmin: true,
            }

            )
            if (response.status === 200) {
                hideDialog()
                getList();
                newProduct({
                    title: '',
                    type: "Article",
                    image: null,
                    category_id: '',
                    summary: "",
                    video: "",
                })
                setNewProduct({
                    title: '',
                    type: "Article",
                    image: null,
                    category_id: '',
                    summary: "",
                    video: "",
                })
                setIsPosting(false);
            }
        } catch (error) {
            setIsPosting(false);
            console.error(error);
        }
    }
    const updateClimateVault = async () => {
        try {
            setIsPosting(true)
            const formData = new FormData();
            if (climateType === "Article") {
                formData.append('title', newProduct?.title);
                formData.append('description', ProductDescription ? ProductDescription : newProduct?.description);
                formData.append('type', newProduct.type);
                formData.append('file', newProduct?.image);
                formData.append('summary', newProduct?.summary ? newProduct?.summary : product?.summary);
                formData.append('category_id', newProduct.category_id);
                formData.append('isActive', true);
            } else {
                formData.append('video', newProduct?.video);
            }


            const response = await autoInstance.patch(`/climate-vault/${newProduct._id}`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
                isAdmin: true,
            }

            )
            if (response.status === 200) {
                hideDialog()
                getList();
                setProduct({
                    title: '',
                    type: "Article",
                    image: null,
                    category_id: '',
                    summary: "",
                    video: "",
                })
                setNewProduct({
                    title: '',
                    type: "Article",
                    image: null,
                    category_id: '',
                    summary: "",
                    video: "",
                })
                setIsPosting(false);
            }
        } catch (error) {
            setIsPosting(false);
            console.error(error);
        }
    }

    const hideDeleteProductDialog = () => {
        setDeleteProductDialog(false);
    };

    const hideDeleteProductsDialog = () => {
        setDeleteProductsDialog(false);
    };

    const saveProduct = () => {

        setSubmitted(true);
        if (product.name.trim()) {
            let _products = [...products];
            let _product = { ...product };

            if (product.id) {
                const index = findIndexById(product.id);

                _products[index] = _product;
                toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Product Updated', life: 3000 });
            } else {
                _product.id = createId();
                _product.image = 'product-placeholder.svg';
                _products.push(_product);
                toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Product Created', life: 3000 });
            }

            setProducts(_products);
            setProductDialog(false);
            setProduct(emptyProduct);
        }
    };
    const confirmDeleteProduct = (product) => {
        setProduct(product);
        setDeleteProductDialog(true);
    };

    const editProduct = (product) => {
        setProduct({ ...product });
        setIsedit(true)
        setNewProduct({
            ...product
        })
        setProductDialog(true);
    };


    const deleteProduct = async (rowData) => {
        try {
            setDeleteLoading(true)
            const response = await autoInstance.delete(`/climate-vault/${productId}`, { isAdmin: true });
            if (response.status === 200) {
                setDeleteLoading(false)
                getList();
            }
        }
        catch (error) {
            setDeleteLoading(false)
            console.error(error);
        }
    };

    const findIndexById = (id) => {
        let index = -1;

        for (let i = 0; i < products.length; i++) {
            if (products[i].id === id) {
                index = i;
                break;
            }
        }

        return index;
    };

    const createId = () => {
        let id = '';
        let chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

        for (let i = 0; i < 5; i++) {
            id += chars.charAt(Math.floor(Math.random() * chars.length));
        }

        return id;
    };

    const exportCSV = () => {
        dt.current.exportCSV();
    };

    const confirmDeleteSelected = () => {
        setDeleteProductsDialog(true);
    };

    const deleteSelectedProducts = () => {
        let _products = products.filter((val) => !selectedProducts.includes(val));

        setProducts(_products);
        setDeleteProductsDialog(false);
        setSelectedProducts(null);
        toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Products Deleted', life: 3000 });
    };

    const onCategoryChange = (e) => {
        let _product = { ...product };

        _product['category'] = e.value;
        setProduct(_product);
    };

    const onInputChange = async (e, name) => {
        try {
            setIsActiveLoading(true);
            const val = e.target.value;
            if (pageTitle === "Communities") {
                const response = await autoInstance.patch(`/community/${e.target.id}`, { isActive: val }, { isAdmin: true }).then((data) => {
                    if (data.status == 200) {
                        getList();
                    }
                    setIsActiveLoading(false);
                })
            } else if (pageTitle === "Collaborations") {
                const response = await autoInstance.patch(`/collaboration/${e.target.id}`, { isActive: val }, { isAdmin: true }).then((data) => {
                    if (data.status == 200) {
                        getList();
                    }
                    setIsActiveLoading(false);
                })
            } else if (pageTitle === "Climate Vault") {
                const response = await autoInstance.patch(`/climate-vault/${e.target.id}`, { isActive: val }, { isAdmin: true }).then((data) => {
                    if (data.status == 200) {
                        getList();
                    }
                    setIsActiveLoading(false);
                })
            }
        } catch (error) {
            console.error(error);
            setIsActiveLoading(false);
        }
    };

    const onInputNumberChange = (e, name) => {
        const val = e.value || 0;
        let _product = { ...product };

        _product[`${name}`] = val;

        setProduct(_product);
    };

    const leftToolbarTemplate = () => {
        return (
            <div className="flex flex-wrap gap-2">
                <Button label="New" icon="pi pi-plus" severity="success" onClick={openNew} />
                <Button label="Delete" icon="pi pi-trash" severity="danger" onClick={confirmDeleteSelected} disabled={!selectedProducts || !selectedProducts.length} />
            </div>
        );
    };

    const rightToolbarTemplate = () => {
        return <Button label="Export" icon="pi pi-upload" className="p-button-help" onClick={exportCSV} />;
    };

    const imageBodyTemplate = (rowData) => {
        return <img src={`https://primefaces.org/cdn/primereact/images/product/${rowData.image}`} alt={rowData.image} className="shadow-2 border-round" style={{ width: '64px' }} />;
    };

    const priceBodyTemplate = (rowData) => {
        return formatCurrency(rowData.price);
    };

    const ratingBodyTemplate = (rowData) => {
        return <Rating value={rowData.rating} readOnly cancel={false} />;
    };

    const statusBodyTemplate = (rowData) => {
        return <Tag value={rowData.inventoryStatus} severity={getSeverity(rowData)}></Tag>;
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <div className=''>
                {/* <div dangerouslySetInnerHTML={{ __html: icons.Edit }} className="mr-2 w-[20px] h-[20px]" onClick={() => editProduct(rowData)} /> */}
                <InputSwitch checked={rowData.isActive} id={rowData._id} name='isActive' value={rowData.isActive} onChange={(e) => onInputChange(e, 'isActive')} />
            </div>
        );
    };

    const getSeverity = (product) => {
        switch (product.inventoryStatus) {
            case 'INSTOCK':
                return 'success';

            case 'LOWSTOCK':
                return 'warning';

            case 'OUTOFSTOCK':
                return 'danger';

            default:
                return null;
        }
    };

    const header = (
        <div className='flex items-center justify-between'>
            <p className='text-[24px] font-semibold pl-[8px]'>{pageTitle}</p>
            <div className="flex flex-wrap gap-2 align-items-center justify-end">
                <div className='flex w-[250px] py-[12px] px-[16px] rounded-[8px] border-[#EFF3F4] border items-center justify-start gap-[10px]'>
                    {/* Assuming icons.search contains the search icon */}
                    <div dangerouslySetInnerHTML={{ __html: icons.search }} />
                    <input onInput={(e) => setGlobalFilter(e.target.value)}
                        className='leading-[22px] text-[16px] bg-transparent font-normal focus:outline-none' type="text" placeholder='Search' />
                </div>
                {
                    pageTitle === "Climate Vault" &&
                    <div className=''>
                        {/* Assuming Bluebtn is a custom button component */}
                        <Bluebtn Label={`Add New ${pageTitle}`} className={`text-[14px] font-medium px-[16px] py-[14px] w-fit text-white`} onClick={openNew} />
                    </div>
                }
            </div>
        </div>
    );
    const productDialogFooter = (
        <React.Fragment>
            <Button label="Cancel" icon="pi pi-times" outlined onClick={hideDialog} />
            <Button label="Save" icon="pi pi-check" onClick={saveProduct} />
        </React.Fragment>
    );
    const deleteProductDialogFooter = (
        <React.Fragment>
            <Button label="No" icon="pi pi-times" outlined onClick={hideDeleteProductDialog} />
            <Button label="Yes" icon="pi pi-check" severity="danger" onClick={deleteProduct} />
        </React.Fragment>
    );
    const deleteProductsDialogFooter = (
        <React.Fragment>
            <Button label="No" icon="pi pi-times" outlined onClick={hideDeleteProductsDialog} />
            <Button label="Yes" icon="pi pi-check" severity="danger" onClick={deleteSelectedProducts} />
        </React.Fragment>
    );

    const [totalSize, setTotalSize] = useState(0);
    const fileUploadRef = useRef(null);

    const onTemplateSelect = (e) => {
        setTotalSize(0);
        const file = e.files[0]; // Assuming only one file is selected
        const reader = new FileReader();
        reader.onload = () => {
            setNewProduct(prevState => ({
                ...prevState,
                image: file // Set image to base64 data URL
            }));
        };
        reader.readAsDataURL(file); // Read file as base64
    };



    const onTemplateUpload = (e) => {
        let _totalSize = 0;

        e.files.forEach((file) => {
            _totalSize += file.size || 0;
        });

        setTotalSize(_totalSize);
        toast.current.show({ severity: 'info', summary: 'Success', detail: 'File Uploaded' });
    };

    const onTemplateRemove = (file, callback) => {
        setTotalSize(totalSize - file.size);
        callback();
    };

    const headerTemplate = (options) => {
        const { className, chooseButton, uploadButton, cancelButton } = options;
        const value = totalSize / 10000;
        const formatedValue = fileUploadRef && fileUploadRef.current ? fileUploadRef.current.formatSize(totalSize) : '0 B';
        if (newProduct?.image === null) {
            return (
                <div className={` `} style={{ backgroundColor: 'transparent', display: 'flex', alignItems: 'center' }}>
                    {chooseButton}
                </div>
            );
        } else {
            return (<></>);
        }

    };
    const onTemplateClear = () => {
        setTotalSize(0);
        setNewProduct(prevState => ({
            ...prevState,
            image: null // Clear image
        }));
    };

    const itemTemplate = (file, props) => {
        return (
            <div className="flex align-center flex-wrap justify-center relative !p-0 max-h-[200px]  items-center rounded-[8px] overflow-hidden">
                <div className='absolute cursor-pointer right-[16px] top-[16px] w-[24px] h-[24px] flex-center bg-[#fff] rounded-full p-[6px]' dangerouslySetInnerHTML={{ __html: icons.closeIcon }} onClick={() => {
                    props.onRemove(file);
                    setNewProduct(prevState => ({
                        ...prevState,
                        image: null
                    }));
                }} />
                <div className='rounded-[8px] overflow-hidden flex-center max-h-[200px]'>
                    <img alt={file?.name} className='w-full h-full object-cover' role="presentation" src={file?.objectURL} />
                </div>
            </div>
        );
    };

    const emptyTemplate = () => {
        return (
            <div className="flex align-items-center flex-column">
                <i className="pi pi-image mt-3 p-5" style={{ fontSize: '5em', borderRadius: '50%', backgroundColor: 'var(--surface-b)', color: 'var(--surface-d)' }}></i>

            </div>
        );
    };

    const chooseOptions = {
        icon: (props) => (
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                <path stroke-linecap="round" stroke-linejoin="round" d="M9 8.25H7.5a2.25 2.25 0 0 0-2.25 2.25v9a2.25 2.25 0 0 0 2.25 2.25h9a2.25 2.25 0 0 0 2.25-2.25v-9a2.25 2.25 0 0 0-2.25-2.25H15m0-3-3-3m0 0-3 3m3-3V15" />
            </svg>
        ),
        iconOnly: true,
        className: ''
    };
    const uploadOptions = { icon: 'pi pi-fw pi-cloud-upload', iconOnly: true, className: 'custom-upload-btn p-button-success p-button-rounded p-button-outlined' };
    const cancelOptions = { icon: 'pi pi-fw pi-times', iconOnly: true, className: 'custom-cancel-btn p-button-danger p-button-rounded p-button-outlined' };


    const nameBodyTemplate = (rowData) => {

        if (pageTitle === "Climate Vault") {
            return (
                <div onClick={() => { setShowDetails(true); setItem(rowData); setSanitizedHTML(DOMPurify.sanitize(rowData?.description)); setSanitizedSummaryHTML(DOMPurify.sanitize(rowData?.summary)); }} className="text-[16px] font-medium cursor-pointer flex align-items-center">
                    <span>{rowData.title}</span>
                </div>
            );
        }
        return (
            <div onClick={() => { setShowDetails(true); setItem(rowData); }} className="text-[16px] font-medium cursor-pointer flex align-items-center">
                <span>{rowData.name}</span>
            </div>
        );
    };
    const ownerBodyTemplate = (rowData) => {
        if (pageTitle === "Climate Vault") {

            return (
                <div target='_blank' to={`user/in/${rowData._id}`} className="flex align-items-center">
                    <span>{`${rowData?.userId?.first_name ?? ""
                        } ${rowData?.userId?.last_name ?? ""}`}</span>
                </div>
            );
        }
        return (
            <div target='_blank' to={`user/in/${rowData.userId?._id}`} className="flex align-items-center">
                <span>{`${rowData?.userId?.first_name ?? ""
                    } ${rowData?.userId?.last_name ?? ""}`}</span>
            </div>
        );
    };
    const ownerEmailTemplate = (rowData) => {
        if (pageTitle === "Climate Vault") {

            return (
                <div target='_blank' to={`user/in/${rowData._id}`} className="flex align-items-center">
                    <span>{`${rowData?.userId?.email ?? ""
                        }`}</span>
                </div>
            );
        }
        return (
            <div target='_blank' to={`user/in/${rowData.userId?._id}`} className="flex align-items-center">
                <span>{`${rowData?.userId?.email ?? ""
                    }`}</span>
            </div>
        );
    };

    const EditAndUpdate = (rowData) => {
        return (
            <div className='flex items-center justify-center gap-[16px]'>
                <div dangerouslySetInnerHTML={{ __html: icons.editProduct }} className="cursor-pointer w-[20px] h-[20px]" onClick={() => editProduct(rowData)} />
                <div dangerouslySetInnerHTML={{ __html: icons.deleteProduct }} className="cursor-pointer w-[20px] h-[20px]" onClick={() => { setShowDeleteVaultModal(true); setProductId(rowData._id) }} />
            </div>
        )
    }

    const SelectedOptions = idEdit && categoryOptions.filter((item) => product?.category?.includes(item.value));


    return (
        <div className='h-[calc(100vh-80px)] overflow-y-auto hide-scrollbar'>
            {
                isActiveLoading && isDeleteLoading && <PageLoader />
            }
            <Toast ref={toast} />
            <div className="border border-[#EFF3F4] rounded-[8px] overflow-hidden m-[16px]">
                <DataTable ref={dt} value={data} selection={selectedProducts} onSelectionChange={(e) => setSelectedProducts(e.value)}
                    dataKey="id" paginator rows={10} rowsPerPageOptions={[5, 10, 25]}
                    paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink  "
                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products" globalFilter={globalFilter} header={header}>
                    <Column field="name" header="Name" body={nameBodyTemplate} sortable style={{ minWidth: '12rem' }}  ></Column>
                    {/* {
                        pageTitle === "Communities" && */}
                    <Column field="owner" header="Email" body={ownerEmailTemplate} style={{ minWidth: '16rem' }}></Column>
                    {/* } */}
                    <Column field="owner" header="Owner" body={ownerBodyTemplate} style={{ minWidth: '16rem' }}></Column>
                    {pageTitle === "Climate Vault" ?
                        <Column key="editColumn" body={EditAndUpdate} style={{ minWidth: '5rem' }}></Column> :
                        <Column header="Active" body={actionBodyTemplate} exportable={false} style={{ minWidth: '5rem' }}></Column>
                    }
                </DataTable>
            </div>

            {/* <Dialog visible={productDialog} style={{ width: '40rem' }} breakpoints={{ '960px': '75vw', '641px': '90vw' }} header={`Add ${pageTitle} `} modal className="p-fluid" footer={productDialogFooter} onHide={hideDialog}>
                {product.image && <img src={`https://primefaces.org/cdn/primereact/images/product/${product.image}`} alt={product.image} className="product-image block m-auto pb-3" />}
              
            </Dialog> */}
            <CustomModal bodyClassName=" rounded-[16px] overflow-hidden " showModal={productDialog} setShowModal={hideDialog}>
                <div className='2sm:pt-[30px] pt-[20px] px-[30px] space-y-[16px] max-h-[80vh] overflow-y-auto relative'>
                    <p className='text-[18px] font-semibold text-[#0C1835]'>{idEdit ? `Update` : `Add`} {pageTitle}</p>
                    {/* {product.image && <img src={`https://primefaces.org/cdn/primereact/images/product/${product.image}`} alt={product.image} className="product-image block m-auto pb-3" />} */}
                    {!idEdit &&
                        <div>
                            <label htmlFor="SelectType" className="text-[16px] font-medium">Select Type</label>
                            <div className='flex items-center justify-start gap-[16px] pt-[10px]'>
                                <div className="flex items-center cursor-pointer" onClick={(e) => setClimateType('Article')}>
                                    <div className={`${climateType === 'Article' ? "border-[#2A8DDE]" : "border-[#a9b3cc]"} w-[16px] h-[16px] flex-center rounded-full border-2    cursor-pointer`}>
                                        {
                                            climateType === 'Article' ? <div className="w-[10px] h-[10px] bg-[#2A8DDE] rounded-full"></div> : <div className="w-[10px] h-[10px] bg-[#FFF] rounded-full"></div>
                                        }
                                    </div>
                                    <label htmlFor="ingredient1" className="ml-2 cursor-pointer font-medium">Article</label>
                                </div>
                                <div className="flex items-center cursor-pointer" onClick={(e) => setClimateType('Video')}>
                                    <div className={`${climateType === 'Video' ? "border-[#2A8DDE]" : "border-[#a9b3cc]"} w-[16px] h-[16px] flex-center rounded-full border-2    cursor-pointer`}>
                                        {
                                            climateType === 'Video' ? <div className="w-[10px] h-[10px] bg-[#2A8DDE] rounded-full"></div> : <div className="w-[10px] h-[10px] bg-[#FFF] rounded-full"></div>
                                        }
                                    </div>
                                    <label htmlFor="ingredient2" className="ml-2 cursor-pointer font-medium">Video</label>
                                </div>
                            </div>
                        </div>
                    }
                    {
                        climateType === "Article" ?
                            <div className='space-y-[16px] '>
                                <div className="field">
                                    <CustomInput id="title" label={`Title`} defultValue={idEdit && product.title} placeholder={'Title'} onChange={(e) => setNewProduct({ ...newProduct, title: e.target.value })} required autoFocus className={classNames({ 'p-invalid': submitted && !product.title })} />
                                    {submitted && !newProduct.title && <small className="p-error">Title is required.</small>}
                                </div>
                                <div className="field">
                                    <label htmlFor="description" className="text-[16px] font-medium block">Description</label>
                                    <div className='mt-[6px]'>
                                        <Editor
                                            value={idEdit && product?.description}
                                            onTextChange={(e) => setProductDescription(e.htmlValue || '')}
                                            style={{ minHeight: '110px' }}
                                            placeholder='write description'
                                        />
                                    </div>
                                    {submitted && !newProduct.description && <small className="p-error">Description is required.</small>}
                                </div>
                                <div>
                                    <label htmlFor="summary" className="text-[16px] font-medium">Summary</label>
                                    <Editor
                                        id='summary'
                                        value={idEdit && product?.summary}
                                        onTextChange={(e) => setNewProduct({ ...newProduct, summary: e.htmlValue })}
                                        style={{ minHeight: '110px' }}
                                        placeholder='write summary'
                                    />

                                </div>
                                <div>
                                    <CustomSelect
                                        value={SelectedOptions.length > 0 ? { label: SelectedOptions[0].label, value: SelectedOptions[0].value } : undefined}

                                        label="Choose category" position={"top"} options={categoryOptions} onChange={(e) => setNewProduct({ ...newProduct, category_id: e.value })} className={classNames({ 'p-invalid': submitted && !product.status })} />
                                </div>
                                <div>
                                    <label htmlFor="description" className="text-[16px] font-medium">Upload  image </label>
                                    <div className='border border-[#EFF3F4] rounded-[8px] p-[16px] mt-[6px]'>
                                        <FileUpload
                                            ref={fileUploadRef}
                                            name="demo[]"
                                            url="/api/upload"
                                            // multiple
                                            accept="image/*"
                                            maxFileSize={10000000}
                                            onUpload={onTemplateUpload}
                                            onSelect={onTemplateSelect}
                                            onError={onTemplateClear}
                                            onClear={onTemplateClear}
                                            headerTemplate={headerTemplate}
                                            itemTemplate={itemTemplate}
                                            chooseOptions={chooseOptions}
                                            uploadOptions={uploadOptions}
                                            cancelOptions={cancelOptions}
                                        />
                                        {product?.file && idEdit &&
                                            <div className="flex align-center flex-wrap justify-between relative !p-0">
                                                <div className='absolute cursor-pointer right-[16px] top-[16px] w-[24px] h-[24px] flex-center bg-[#fff] rounded-full p-[6px]' dangerouslySetInnerHTML={{ __html: icons.closeIcon }} onClick={() => {
                                                    setNewProduct(prevState => ({
                                                        ...prevState,
                                                        image: null
                                                    }));
                                                    setProduct(prevState => ({
                                                        ...prevState,
                                                        file: null
                                                    }));
                                                }} />
                                                <div className='rounded-[8px] overflow-hidden max-h-[200px] flex items-center justify-center'>
                                                    <img alt={product?.name} className='w-full h-full object-cover' role="presentation" src={process.env.REACT_APP_IMAGE_URL + product?.file} />
                                                </div>
                                            </div>

                                        }
                                    </div>

                                </div>

                            </div> :
                            <div className='space-y-[16px]'>
                                <div>
                                    <CustomInput id="video" label={`Video url`} placeholder={'Video url'} onChange={(e) => setNewProduct({ ...newProduct, video: e.target.value })} />
                                </div>
                            </div>
                    }
                    <div className='flex items-center justify-end gap-[16px] sticky bottom-0 z-10 py-[20px] bg-white'>
                        <Bluebtn onClick={() => hideDialog()} className=" bg-[#B7BCCA]  text-[14px] font-medium py-[8px] px-[16px] w-fit text-white" Label="Cancel" />
                        <Bluebtn onClick={() => isPosting ? null : idEdit ? updateClimateVault() : createClimateVault()} className={`  text-[14px] font-medium py-[8px] px-[16px] w-fit text-white ${isPosting ? 'bg-[#B7BCCA]' : 'bg-[#2A8DDE]'} `} Label="Update" />
                    </div>

                </div>
            </CustomModal>

            <CustomModal bodyClassName=" rounded-[16px] " showModal={showDeleteVaultModal} setShowModal={setShowDeleteVaultModal} >
                <div class="w-full h-full  bg-white shadow-lg rounded-[16px] 2sm:p-[24px] xsm:p-[20px] p-[15px] relative ">
                    <div className='flex items-center justify-between'>
                        <h1 className='2lg:text-[26px] 2sm:text-[24px] md:text-[22px] text-[20px]  leading-[normal] font-[600]'>Delete Climate Vault</h1>
                        <div onClick={() => { setShowDeleteVaultModal(false); }} className='cursor-pointer ' dangerouslySetInnerHTML={{ __html: icons.closeIcon }} />
                    </div>
                    <p className='text-[#21272A] font-normal text-[12px] leading-[140%] py-[10px]'>Are you sure you want to delete this climate vault?</p>
                    <div className='flex justify-end'>
                        <Bluebtn className="text-[14px] font-medium py-[8px] px-[16px] w-fit mt-[16px]  text-white bg-[#6D7486]" Label="Delete" onClick={() => { deleteProduct(); setShowDeleteVaultModal(false) }} />
                    </div>
                </div>
            </CustomModal>
            <Dialog visible={deleteProductDialog} style={{ width: '32rem' }} breakpoints={{ '960px': '75vw', '641px': '90vw' }} header="Confirm" footer={deleteProductDialogFooter} onHide={hideDeleteProductDialog}>
                <div className="confirmation-content">
                    <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                    {product && (
                        <span>
                            Are you sure you want to delete <b>{product.name}</b>?
                        </span>
                    )}
                </div>
            </Dialog>

            <Dialog visible={deleteProductsDialog} style={{ width: '32rem' }} breakpoints={{ '960px': '75vw', '641px': '90vw' }} header="Confirm" modal footer={deleteProductsDialogFooter} onHide={hideDeleteProductsDialog}>
                <div className="confirmation-content">
                    <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                    {product && <span>Are you sure you want to delete the selected products?</span>}
                </div>
            </Dialog>
            <CustomModal showModal={showDetails} setShowModal={setShowDetails} bodyClassName={`${pageTitle === 'Climate Vault' ? "max-w-[80vw] w-full" : " "} rounded-[16px] overflow-hidden`}>
                <div className='p-[25px]'>
                    <div className='flex items-center justify-between gap-[16px]'>
                        <h1 className='2lg:text-[22px] 2sm:text-[20px] md:text-[18px] text-[16px]   leading-[normal] font-[600]'>{pageTitle === 'Climate Vault' ? "Climate Vault" : pageTitle === "Communities" ? "Community" : "Collaboration"} Details</h1>
                        <div onClick={() => { setShowDetails(false); }} className='cursor-pointer ' dangerouslySetInnerHTML={{ __html: icons.closeIcon }} />
                    </div>
                    <div className='max-h-[calc(80vh_-_40px)] overflow-auto'>
                        {
                            sanitizedSummaryHTML !== null && sanitizedSummaryHTML !== "" && sanitizedSummaryHTML !== undefined && sanitizedSummaryHTML !== "null" && sanitizedSummaryHTML !== "" &&
                            <div className=' text-[16px] font-normal leading-[140%] space-y-[16px] '>
                                <div className='valut-description' dangerouslySetInnerHTML={{ __html: sanitizedSummaryHTML }} />
                            </div>

                        }
                        <div className='flex items-start justify-start gap-[10px] py-[10px]'>
                            {
                                pageTitle === 'Climate Vault' ?
                                    <div className='w-fit   mx-auto  max-h-[300px] rounded-[8px] overflow-hidden'>
                                        <img className='w-auto rounded-[8px] overflow-hidden h-full max-h-[inherit]' src={process.env.REACT_APP_IMAGE_URL + item?.file} alt={item?.name} />
                                    </div> :
                                    <div className='w-[60px] h-[60px] rounded-[8px] overflow-hidden'>
                                        <img className='w-full h-full object-cover' src={process.env.REACT_APP_IMAGE_URL + item?.image} alt={item?.name} />
                                    </div>
                            }
                            {pageTitle === 'Climate Vault' ? <></> :
                                <div>
                                    <p className='text-[#0C1835] text-[16px] font-medium'>{item?.name}</p>
                                    <p className='text-[#6D7486] text-[12px] font-normal pb-[4px]'>{`${item?.userId?.first_name ?? ""
                                        } ${item?.userId?.last_name ?? ""}`}</p>
                                </div>
                            }
                        </div>
                        {pageTitle === 'Climate Vault' ?
                            <div className=' text-[16px] font-normal leading-[140%] space-y-[16px] '>
                                <div className='valut-description' dangerouslySetInnerHTML={{ __html: sanitizedHTML }} />
                            </div>
                            :
                            <p className='text-[#6D7486] text-[16px] font-normal '>{item?.description}</p>
                        }
                    </div>
                </div>
            </CustomModal >
        </div >
    );
}