
import Cookies from 'js-cookie';
import { Badge } from 'primereact/badge';
import { Menu } from 'primereact/menu';
import { Menubar } from 'primereact/menubar';
import { classNames } from 'primereact/utils';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Toast from '../../helpers/Toast';
import autoInstance from '../../utils/axios';

export default function AdminNavBar({ page }) {
    const navigate = useNavigate();
    const menuRight = useRef(null);
    const [user, setUser] = useState(null);

    useEffect(() => {
        getAdmin();
    }, [])

    const itemRenderer = (item) => (
        <a className="flex align-items-center p-menuitem-link">
            <span className="mx-2">{item.label}</span>
            {item.badge && <Badge className="ml-auto" value={item.badge} />}
            {item.shortcut && <span className="ml-auto border-1 surface-border border-round surface-100 text-xs p-1">{item.shortcut}</span>}
        </a>
    );
    const getAdmin = async () => {
        try {
            const response = await autoInstance.get('/user', { isAdmin: true });
            if (response.status === 200) {
                setUser(response.data.data);
            }
        } catch (error) {
            console.error(error);
        }
    }
    let displayName;

    if (user?.last_name) {
        displayName = `${user?.first_name?.charAt(0)}${user?.last_name?.charAt(0)}`;
    } else {
        displayName = user?.first_name.charAt(0);
    }

    const items = [
        {
            items: [
                {
                    command: () => {
                        Cookies.remove('g2n0AdminAuth');
                        Toast('Logout Successful', 'success');
                        navigate('/admin');
                    },
                    template: (item, options) => {
                        return (
                            <div onClick={(e) => options.onClick(e)} className={classNames(options.className, 'mx-[20px] px-[8px]  border-t border-[#EFF3F4] py-[16px] flex items-center justify-start gap-[20px]')}>


                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M9 21H5C4.46957 21 3.96086 20.7893 3.58579 20.4142C3.21071 20.0391 3 19.5304 3 19V5C3 4.46957 3.21071 3.96086 3.58579 3.58579C3.96086 3.21071 4.46957 3 5 3H9M16 17L21 12M21 12L16 7M21 12H9" stroke="#6D7486" strokeWidth="2" stroke-linecap="round" stroke-linejoin="round" />
                                </svg>


                                <p className='text-[16px] font-medium text-[#0C1835]'>Logout</p>
                            </div>
                        );
                    }
                }
            ]
        }
    ];

    const start = <div className='text-[24px] font-semibold pl-[8px]'>{page}</div>;
    const end = (
        <div className="flex align-items-center gap-2 pr-2">

            {/* {user?.profileImage ? (
                <div className="cursor-pointer md:h-[2.625rem] h-[2rem] md:w-[2.625rem] w-[2rem] rounded-[.5rem] overflow-hidden flex items-center justify-center border border-[#EFF3F4]">
                    <img
                        className="w-full h-full object-cover"
                        src={process.env.REACT_APP_IMAGE_URL + user?.profileImage}
                        alt="Nav"
                    />
                </div>
            ) : (
            )} */}

            <div
                onClick={(event) => menuRight.current.toggle(event)}
                className="cursor-pointer md:h-[2.625rem] h-[2rem] text-[#6D7486] text-[1.125rem] font-medium md:w-[2.625rem] w-[2rem] rounded-[.5rem] overflow-hidden flex items-center justify-center border border-[#EFF3F4] bg-[#EFF3F4]">
                {displayName}
            </div>
        </div>
    );

    return (
        <div className="">
            <Menubar className='h-[80px] border-b border-[#EFF3F4] bg-white' end={end} />
            <Menu model={items} popup ref={menuRight} id="admin_menu_right" className='' popupAlignment="right" />

        </div>
    )
}
