import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import EmptyState from '../../Components/Common/EmptyState';
import Post from '../../Components/Post';
import HomeLayout from '../../layouts/HomeLayout';
import autoInstance from '../../utils/axios';

function PostPage() {

    const navigate = useNavigate();
    const location = useLocation();
    const postId = location.pathname.split('/')[3];
    const [user, setUser] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [pageLoaded, setPageLoaded] = useState(false)
    const [PostData, setPostData] = useState();

    useEffect(() => {
        if (postId) {
            getPost()
        }
    }, [postId])
    // const getUser = async () => {
    //     try {
    //         setIsLoading(true)
    //         const response = await autoInstance.get(`/user/${userId}`)
    //         const source = response.data
    //         setUser(source?.data)
    //         setIsLoading(false)
    //     } catch (error) {
    //         setIsLoading(false)
    //         console.error(error)
    //     }
    //     setIsLoading(false)
    // }
    const getPost = async () => {
        try {
            setIsLoading(true)
            const response = await autoInstance.get(`/post/${postId}`)
            setPostData(response.data.data)
            setIsLoading(false)
        } catch (error) {
            setIsLoading(false)
            console.error(error)
        }
        setIsLoading(false)
    }

    const AllActivities = (options) => {
        return (
            <div className="py-[10px] px-[20px] rounded-[18px]" style={{ cursor: 'pointer' }} onClick={options.onClick}>
                <span className="font-[500] white-space-nowrap text-[16px] leading-[110%]">All Activities</span>
            </div>
        );
    };

    const Questions = (options) => {
        return (
            <div className="py-[10px] px-[20px] rounded-[18px]" style={{ cursor: 'pointer' }} onClick={options.onClick}>
                <span className="font-[500] white-space-nowrap text-[16px] leading-[110%]">Questions / Posts</span>
            </div>
        )
    };

    const Responses = (options) => {
        return (
            <div className="py-[10px] px-[20px] rounded-[18px]" style={{ cursor: 'pointer' }} onClick={options.onClick}>
                <span className="font-[500] white-space-nowrap text-[16px] leading-[110%]">Responses</span>
            </div>
        )
    };
    const Activity = (options) => {
        return (
            <p className="font-[600] text-[#6D7486] white-space-nowrap text-[14px] leading-[110%] p-[12px]" style={{ cursor: 'pointer' }} onClick={options.onClick}>Activity</p>

        )
    };
    const About = (options) => {
        return (
            <p className="font-[600] text-[#6D7486] white-space-nowrap text-[14px] leading-[110%] p-[12px]" style={{ cursor: 'pointer' }} onClick={options.onClick}>About</p>
        )
    };

    const [activeTabIndex, setActiveTabIndex] = useState(0);
    const menuRight = useRef(null);

    const handleMenuItemClick = (event, tabIndex) => {
        setActiveTabIndex(tabIndex);

    };


    const Filter = [
        {
            items: [
                {
                    label: "All Activities",
                    command: (event) => handleMenuItemClick(event, 0)
                },
                {
                    label: "Questions only",
                    command: (event) => handleMenuItemClick(event, 1)
                },
                {
                    label: "Responses only",
                    command: (event) => handleMenuItemClick(event, 2)
                },
            ]
        }
    ];
    const handleMenuItemClick2 = () => {
        navigate(-1)
    };

    return (
        <div>
            <HomeLayout children={
                <div>
                    <div className='flex items-center justify-start gap-[14px] 2sm:p-[24px] xsm:p-[20px] p-[15px]  border-b border-[#EFF3F4] bg-white'>
                        <div onClick={() => { handleMenuItemClick2() }} className='cursor-pointer'>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M15 18L9 12L15 6" stroke="#6D7486" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                        </div>
                        <p className="text-[24px] font-semibold leading-[110%]">
                            Post
                        </p>
                    </div>
                    <div className='overflow-y-auto lg:h-[calc(100vh_-_155px)] 2sm:h-[calc(100vh_-_70px)] h-[calc(100vh_-_128px)] hide-scrollbar '>
                        {/* Profile banner  */}

                        {PostData ?
                            <div className='py-[11px] 2sm:px-[20px] px-[15px] h-full'>
                                <Post isDetailsPage={true} PostData={PostData} UpdateData={getPost} />
                            </div>
                            :
                            <div className='py-[11px] h-full '>
                                <EmptyState page={"post"} title={"Post Not Found"} />
                            </div>
                        }

                    </div>
                </div>
            } />
        </div >
    )
}

export default PostPage