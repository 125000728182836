import Cookies from "js-cookie";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import AuthLayout from "../Components/Auth/AuthLayout";
import Bluebtn from "../Components/Common/Btns/Bluebtn";
import CustomInput from "../Components/Common/Inputs/CustomInput";
import Toast from "../helpers/Toast";
import autoInstance from "../utils/axios";
import { getToken } from "firebase/messaging";
import { messaging } from "../utils/firebase";
import { requestPermissionAndGetToken } from "../Components/Common/Notification/Notification";

function AdminLogin() {

  const navigate = useNavigate();
  const [signupLoading, setSignupLoading] = useState(false);
  const [password, setPassword] = useState('');
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [userData, setUserData] = useState({
    email: '',
    password: '',
    });
    
    useEffect(() => {
      const token = Cookies?.get('g2n0AdminAuth');
      if (token) {
        navigate('/admin/dashboard');
        } else {
          navigate('/admin');
          }
          }, [navigate]);
          
          const [FcmToken, setFcmToken] = useState("");
  useEffect(() => {
    const requestPermission = async () => {
      try {
        if ((await Notification.permission) !== 'granted') {
          const permission = await Notification.requestPermission();
          if (permission === 'granted') {
            const token = await getToken(messaging, {
              vapidKey:
                'BHMq-TCQcGwVnSePu2PvNmXvzWFepLFR59z7aFo9uGYE6rEZylWm4lPFjOaLLnkkQHvoiv_SR7XHv6zdOfUrJFY'
            });
            setFcmToken(token);
            // if (chatSocket) {
            //   chatSocket.emit('storeToken', token);
            // }
          } else if (permission === 'denied') {
            console.log('Please Allow Notification Permission in your Browser');
          }
        } else {
          const token = await getToken(messaging, {
            vapidKey:
              'BHMq-TCQcGwVnSePu2PvNmXvzWFepLFR59z7aFo9uGYE6rEZylWm4lPFjOaLLnkkQHvoiv_SR7XHv6zdOfUrJFY'
          });
          setFcmToken(token);
          // if (chatSocket) {
          //   chatSocket.emit('storeToken', token);
          // }
        }
      } catch (error) {
        console.log(error);
      }
    };
    requestPermission();
  }, []);

  const handleError = () => {
    setEmailError("");
    setPasswordError("");
    let isError = false;
    if (!/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(userData.email)) {
      setEmailError("Please Enter a valid Email Address");
      isError = true
    }
    // Password validation logic
    if (userData.password.length < 6) {
      setPasswordError('Password must be at least 6 characters long');
      isError = true;
    }
    if (isError) throw new Error("Validation Error");
  }

  const handleLoginAccount = async (e) => {
    e.preventDefault();
    try {
      setSignupLoading(true);
      handleError();
      if (emailError === "" && passwordError === "") {
        const deviceToken = await requestPermissionAndGetToken();
        const response = await autoInstance.post(`/auth/login`, { email: userData.email, password: userData.password, device_token: deviceToken, platform: 'WEB' }, { isAdmin: true });
        if (response?.data?.data?.roleId === 1 || response?.data?.data?.roleId === 2) {
          navigate("/login");
          Toast("You're not an Admin ", "success");
        } else {
          if (response.status === 200) {
            Toast(response.data.message, "success");
            const expirationDate = new Date();
            expirationDate.setMonth(expirationDate.getMonth() + 2);
            Cookies.set('g2n0AdminAuth', response.data.data.token, { expires: expirationDate });
            navigate("/admin/dashboard");
          }
          else { Toast(response.data.message, "error"); }
        }
      }
    } catch (error) {
      if (error.response?.data) { Toast(error.response.data.message, "error") }
      else { console.error(error.message) }
    } finally {
      setSignupLoading(false);
    }
  }

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleLoginAccount(event);
    }
  };

  return (
    <AuthLayout children={
      <div className="2lg:p-[50px] lg:p-[40px] md:p-[35px] 2sm:p-[30px] sm:p-[25px] p-[20px]">
        <div className="md:w-[19.6875rem] w-[75vw]">
          <h1 className='2lg:text-[1.5rem] 2sm:text-[1.375rem] md:text-[1.25rem] text-[1.125rem]  leading-[110%] font-[500] text-center'>Log in to Get2net0 Admin</h1>
          <div className="space-y-[1rem] pt-[1rem]">
            <CustomInput label='Email' type='email' value={userData.email} name='email' onChange={(e) => setUserData({ ...userData, email: e.target.value })} placeholder='xyz@example.com' />
            {emailError && <p className="text-[#FF0000] font-[400] text-[.875rem] leading-[120%]">{emailError}</p>}
            <CustomInput onKeyPress={handleKeyPress} label='Password' type='password' value={userData.password} name='password' onChange={(e) => setUserData({ ...userData, password: e.target.value })} placeholder='Password' />
            {passwordError && <p className="text-[#FF0000] font-[400] text-[.875rem] leading-[120%]">{passwordError}</p>}
            <div className="mt-[1rem]">
              <Bluebtn onClick={signupLoading ? null : handleLoginAccount} className={`lg:p-[1.125rem] p-[.875rem] w-full 2sm:text-[1rem] text-[.875rem] font-medium leading-[100%]  text-white`} Label={`${signupLoading ? 'Loading...' : 'Sign In as Admin'}`} />
            </div>
          </div>
        </div>
      </div>
    } />
  )
}

export default AdminLogin