import { useState } from 'react'
import Toast from '../../../helpers/Toast'
import autoInstance from '../../../utils/axios'
import Bluebtn from '../Btns/Bluebtn'
import CustomInput from '../Inputs/CustomInput'

function UpdatePassword({ setIsOpen }) {
  const [currentPassword, setCurrentPassword] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [error, setError] = useState('')

  const [currentPasswordError, setCurrentPasswordError] = useState('')
  const [passwordError, setPasswordError] = useState('');
  const [confirmPasswordError, setConfirmPasswordError] = useState('');

  const handleError = () => {
    setPasswordError('');
    setConfirmPasswordError('');
    setCurrentPasswordError('')
    let isError = false;

    if (currentPassword === '') {
      setCurrentPasswordError("Enter current password")
      isError = true;
    }

    if (!/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@#$!%*?&_])[A-Za-z\d@#$!%*?&_]{8,20}$/.test(newPassword)) {
      setPasswordError("Please enter a strong password");
      isError = true;
    }

    if (newPassword.length < 8 || newPassword.length > 20) {
      setPasswordError("Password must be between 8 and 20 characters");
      isError = true
    }


    if (newPassword === '') {
      setPasswordError("Required");
      isError = true
    }
    if (confirmPassword === '') {
      setConfirmPasswordError("Required");
      isError = true
    }

    if (newPassword !== confirmPassword) {
      setConfirmPasswordError("Password does not match");
      isError = true
    }

    if (newPassword.length > 20) {
      setPasswordError("Password must be below 20 characters");
      isError = true
    }


    // if (confirmPassword.length < 8) {
    //   setConfirmPasswordError("Please enter a valid Password");
    //   isError = true
    // }
    if (confirmPassword.length > 20) {
      setConfirmPasswordError("Confirm Password must be below 20 characters");
      isError = true
    }
    // if (confirmPassword !== password) {
    //   setConfirmPasswordError("Passwords do not match");
    //   isError = true
    // }
    if (isError) throw new Error("Validation Error");
  }
  const handleUpdate = async () => {
    try {
      handleError()
      if (confirmPasswordError === '' && currentPasswordError === '' && passwordError === '') {
        const response = await autoInstance.post('/user/change-password', {
          oldPassword: currentPassword,
          newPassword: newPassword,
          confirmPassword: confirmPassword
        })
        if (response && response.status === 200) {
          setIsOpen(false)
          Toast(response.data.message, 'success')
        } else if (response.status === false) {
          setError(response.message)
        }
      }
    }
    catch (error) {
      setError(error?.response?.data?.message)
      Toast(error?.respons?.data?.message, 'error')
    }
  }
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleUpdate(event);
    }
  };



  return (
    <div className='2sm:p-[24px] xsm:p-[20px] p-[15px] space-y-[16px]'>
      <div className='flex items-center justify-between'>
        <h1 className='2lg:text-[26px] 2sm:text-[24px] md:text-[22px] text-[20px]  leading-[normal] font-[600]'>Update Password</h1>
        {/* <div onClick={() => { setIsOpen(false); }} className='cursor-pointer ' dangerouslySetInnerHTML={{ __html: icons.closeIcon }} /> */}
      </div>
      <CustomInput onChange={(e) => setCurrentPassword(e.target.value)} defultValue={currentPassword} label='Enter current password' type='password' placeholder='Enter current password' />
      {currentPasswordError && <p className='text-red-500 text-[14px] leading-[110%] font-[500]'>{currentPasswordError}</p>}
      <CustomInput onChange={(e) => setNewPassword(e.target.value)} label='Enter new password' type='password' placeholder='Enter new password' />
      {currentPasswordError === '' && passwordError && <p className='text-red-500 text-[14px] leading-[110%] font-[500]'>{passwordError}</p>}
      <CustomInput onKeyPress={handleKeyPress} onChange={(e) => setConfirmPassword(e.target.value)} label='Enter confirm password' type='password' placeholder='Enter confirm password' />
      {passwordError === '' && currentPasswordError === '' && confirmPasswordError && <p className='text-red-500 text-[14px] leading-[110%] font-[500]'>{confirmPasswordError}</p>}
      {passwordError === '' && currentPasswordError === '' && confirmPasswordError === '' && error && <p className='text-red-500 text-[14px] leading-[110%] font-[500]'>{error}</p>}
      <div className='flex items-center justify-end gap-[16px]'>
        <Bluebtn onClick={() => setIsOpen(false)} className=" bg-[#B7BCCA]  text-[14px] font-medium py-[8px] px-[16px] w-fit text-white" Label="Cancel" />
        <Bluebtn onClick={() => handleUpdate()} className="  text-[14px] font-medium py-[8px] px-[16px] w-fit text-white" Label="Update" />
      </div>
    </div>
  )
}

export default UpdatePassword