import React, { useEffect, useState } from 'react';
import ClimateVaultCard, { ClimateVaultVideoCard } from '../../Components/Common/Cards/ClimateVaultCard';
import EmptyState from '../../Components/Common/EmptyState';
import Navbar from '../../Components/Common/Navbar';
import CustomSelect from '../../Components/Common/Select/CustomSelect';
import Sidebar from '../../Components/Common/Sidebar';
import { icons } from '../../helpers/images';
import autoInstance from '../../utils/axios';

function ClimateVault() {
    const [activeIndex, setActiveIndex] = useState(0);
    const [inkBarStyle, setInkBarStyle] = useState({});
    const [ClimateVault, setClimateVault] = useState([]);
    const [videoTabOpen, setVideoTabOpen] = useState(false);
    const [category, setCategory] = useState([]);
    const [CategoryId, setCategoryId] = useState(null);
    const [filterData, setFilterData] = useState([]);

    // Function to handle tab change and set the state accordingly

    useEffect(() => {
        handleTabClick(activeIndex);
        getClimateVault()
    }, []); // Empty dependency array ensures this runs only once on mount

    const getClimateVault = async () => {
        const climateVaultResponse = await autoInstance.get('climate-vault?type=Article')
        setClimateVault(climateVaultResponse?.data?.data ?? [])
        setFilterData(climateVaultResponse?.data?.data ?? [])
    }

    const getCategory = async () => {
        const categoryResponse = await autoInstance.get('/climate-vault/category/list')
        setCategory(categoryResponse?.data?.data ?? [])
    }


    const handleTabClick = (index) => {
        setActiveIndex(index);
        const tabItem = document.querySelector(`#tabItem${index}`);
        if (tabItem) {
            setInkBarStyle({
                width: tabItem.offsetWidth,
                transform: `translateX(${tabItem.offsetLeft}px)`
            });
        }
    };


    const Articles = () => {
        return (
            <div className={`${!videoTabOpen ? "bg-[#EAF4FC] text-[#2A8DDE]" : ""} py-[10px] px-[20px] rounded-[18px] cursor-pointer`} onClick={() => setVideoTabOpen(false)} >
                <span className="font-[500] white-space-nowrap text-[16px] leading-[110%]">Articles</span>
            </div>
        );
    };

    const Videos = () => {
        return (
            <div className={`${videoTabOpen ? "bg-[#EAF4FC] text-[#2A8DDE]" : ""} py-[10px] px-[20px] rounded-[18px] cursor-pointer`} onClick={() => setVideoTabOpen(true)} >
                <span className="font-[500] white-space-nowrap text-[16px] leading-[110%]">Videos</span>
            </div>
        )
    };

    const [selectedFilter, setSelectedFilter] = useState("mostrecent");
    const [selectedVideoFilter, setSelectedVideoFilter] = useState("all");


    const filterOptions = [
        {
            label: "A to Z",
            value: "AtoZ"
        },
        {
            label: "Most recent",
            value: "mostrecent"
        }
    ];
    const VideoOptions = [
        {
            label: "A to Z",
            value: "AtoZ"
        },
        {
            label: "Most recent",
            value: "mostrecent"
        }
    ];



    const ClimateVaultVideo = [
        {
            name: "Green Horizons",
            description: "Cras efficitur orci vel libero blandit, in congue nunc tincidunt. Suspendisse potenti.",
            username: "John Doe",
            profileImage: "https://xsgames.co/randomusers/assets/avatars/male/8.jpg",
            image: "https://source.unsplash.com/featured/?green",
            createdAt: new Date("2023-01-25")
        },
        {
            name: "EcoSolutions",
            description: "Morbi tincidunt ex non diam facilisis vestibulum. Aliquam euismod purus at lorem commodo, at mollis felis aliquet.",
            username: "Alice Johnson",
            profileImage: "https://xsgames.co/randomusers/assets/avatars/female/7.jpg",
            image: "https://source.unsplash.com/featured/?ecology",
            createdAt: new Date("2023-08-05")
        },
        {
            name: "GreenTech Innovators",
            description: "Curabitur volutpat odio sed vehicula pharetra. Vivamus dapibus nisi et dolor gravida, at lacinia tellus laoreet.",
            username: "Michael Brown",
            profileImage: "https://xsgames.co/randomusers/assets/avatars/male/15.jpg",
            image: "https://source.unsplash.com/featured/?innovation",
            createdAt: new Date("2023-03-17")
        },
        {
            name: "Sustainable Futures",
            description: "Etiam ut urna sit amet mi venenatis sollicitudin. Phasellus luctus risus nec tincidunt gravida.",
            username: "Emily White",
            profileImage: "https://xsgames.co/randomusers/assets/avatars/female/5.jpg",
            image: "https://source.unsplash.com/featured/?future",
            createdAt: new Date("2023-11-10")
        },
        {
            name: "Green Horizons",
            description: "Fusce vel nulla consectetur, consequat enim ut, suscipit arcu. Sed dignissim dolor ut neque luctus, quis blandit libero fermentum.",
            username: "John Doe",
            profileImage: "https://xsgames.co/randomusers/assets/avatars/male/8.jpg",
            image: "https://source.unsplash.com/featured/?landscape",
            createdAt: new Date("2023-06-22")
        }
    ]



    const handleFilterChange = (selected) => {
        setSelectedFilter(selected.value);
    };
    const handleVideoFilterChange = (selected) => {
        setSelectedVideoFilter(selected.value);
    };


    const filteredClimateVaultFun = () => selectedFilter === "AtoZ" ?
        ClimateVault.slice().sort((a, b) => {
            if (a.title < b.title) return -1;
            if (a.title > b.title) return 1;
            return 0;
        }) : (selectedFilter === "mostrecent") ? ClimateVault.slice().sort((a, b) => {
            return new Date(b.createdAt) - new Date(a.createdAt);
        }) : ClimateVault;


    const filteredClimateVaultVideos = () => ClimateVaultVideo?.filter(item => {
        if (selectedVideoFilter === "all") return true;
        if (selectedVideoFilter === "mostrecent") {
            const mostRecentDate = new Date(Math.max.apply(null, ClimateVaultVideo.map(item => new Date(item?.createdAt))));
            return new Date(item?.createdAt).getTime() === mostRecentDate.getTime();
        }
    });
    useEffect(() => {
        setFilterData(filteredClimateVaultFun());
    }, [selectedFilter]);

    useEffect(() => {
        getClimateVaultTabs();
    }, [activeIndex]);

    const getClimateVaultTabs = async () => {
        try {
            if (CategoryId === null) {
                getClimateVault()
                return
            }
            const climateVaultResponse = await autoInstance.get(`climate-vault?category_id=${CategoryId}&type=Article`)
            setClimateVault(climateVaultResponse?.data?.data ?? [])
        } catch (error) {
            console.error(error)
        }
    }
    const handleSearch = (searchQuery) => {
        const trimmedSearchQuery = searchQuery.trim();
        if (trimmedSearchQuery !== "") {
            const filteredClimateVault = ClimateVault.filter(item => {
                return item.title.toLowerCase().includes(trimmedSearchQuery.toLowerCase()) ||
                    item.description.toLowerCase().includes(trimmedSearchQuery.toLowerCase());
            });
            setFilterData(filteredClimateVault);
        } else {
            setFilterData(ClimateVault);
        }
    };


    return (
        <div>
            {/* Header */}
            <Navbar />
            {/* ENd Header */}
            <div className='flex items-start justify-start lg:h-[calc(100vh_-_80px)] 2sm:h-[calc(100vh_-_70px)] h-[calc(100vh_-_60px)] overflow-hidden'>
                {/* Sidebar  */}
                <Sidebar />
                {/* Sidebar End */}
                <div className='h-full bg-[#F3F5F7] 3lg:w-[calc(100%_-_300px)] lg:w-[calc(100%_-_250px)] 2sm:w-[calc(100%_-_60px)] w-full'>
                    <div className='bg-white w-full'>
                        <div className=' 3lg:w-[70vw] 2md:w-[570px] w-full  '>
                            <div className='pt-[24px] pb-[12px] pl-[24px]'>
                                <p className='text-[24px] font-[600] leading-[110%]'>Climate Vault</p>
                            </div>

                        </div>
                        {/* Tabs  */}
                        <div className=' bg-white x '>
                            <div className=''>
                                <div className='flex items-center justify-between  3lg:w-[70vw] 2md:w-[570px] w-full  '>
                                    <div className="tabs-header flex mx-[24px] self-end ">
                                        {/* this is First Tab  */}
                                        <div id="tabItem0" className={`tab-item cursor-pointer ${activeIndex === 0 && 'active'}`} onClick={() => { handleTabClick(0); setCategoryId(null); setVideoTabOpen(false) }}>
                                            <div className='p-[12px]'>
                                                <p className={` ${activeIndex === 0 ? 'text-[#0C1835] font-semibold' : 'text-[#6D7486] font-[500]'}  white-space-nowrap text-[14px] leading-[110%]`} style={{ cursor: 'pointer' }}>All</p>
                                            </div>
                                        </div>
                                        {
                                            category && category?.map((item, index) => {
                                                const Tabindex = index + 1
                                                return (<div id={`tabItem${Tabindex}`} className={`tab-item cursor-pointer ${activeIndex === Tabindex && 'active'}`} onClick={() => { handleTabClick(Tabindex); setCategoryId(item._id); setVideoTabOpen(false) }}>
                                                    <div className='p-[12px]'>
                                                        <p className={`  ${activeIndex === Tabindex ? 'text-[#0C1835] font-semibold' : 'text-[#6D7486] font-[500]'}  white-space-nowrap text-[14px] leading-[110%] `} style={{ cursor: 'pointer' }}>{item.name}</p>
                                                    </div>
                                                </div>)

                                            })
                                        }
                                        <div className="ink-bar" style={inkBarStyle}></div>
                                    </div>
                                    <label htmlFor="search" className='mb-[12px] border cursor-pointer boder-[#EFF3F4] rounded-[8px] py-[8px] px-[12px] flex items-center justify-start gap-[8px]'>
                                        <div dangerouslySetInnerHTML={{ __html: icons?.smallSearch }} />
                                        <input onChange={(e) => handleSearch(e.target.value)} placeholder="Search" type="text" id='search' name='search' className='border-none outline-none focus:outline-none text-[14px] font-normal leading-[140%]' />
                                    </label>
                                </div>
                                {/* All Tab */}
                                {activeIndex == 0 &&
                                    <div className='bg-[#F3F5F7] w-full py-[20px] px-[24px] relative'>

                                        <div className='bg-[#F3F5F7]  3lg:w-[calc(70vw_-_24px)] 2md:w-[calc(570px_-_24px)] w-full space-y-[8px] profile-content-tabs tabs-header-bg '>
                                            <div className='flex items-center justify-between'>
                                                <div className='flex items-center justify-start gap-[8px]'>
                                                    {Articles()}
                                                    {/* {Videos()} */}
                                                </div>
                                                <div className='min-w-[200px] z-40 relative'>
                                                    {videoTabOpen ? (
                                                        <CustomSelect
                                                            ClassCss="Valut-filter"
                                                            options={VideoOptions}
                                                            value={VideoOptions[1]}
                                                            placeholder="Select"
                                                            className="w-full"
                                                            onChange={handleVideoFilterChange}
                                                        />
                                                    ) : (
                                                        <CustomSelect
                                                            ClassCss="Valut-filter"
                                                            options={filterOptions}
                                                            value={filterOptions[1]}
                                                            placeholder="Select"
                                                            className="w-full"
                                                            onChange={handleFilterChange}
                                                        />
                                                    )}
                                                </div>
                                            </div>

                                            {/* render Components based on active tab */}
                                            <div className=''>
                                                <div className='py-[16px]  overflow-auto h-[calc(100vh-253px)] hide-scrollbar'>
                                                    {!videoTabOpen ? (
                                                        filterData?.length === 0 &&
                                                        <EmptyState className={"absolute top-[50%] left-[50%] translate-y-[-50%] translate-x-[-50%]"} page={"Climate Vault"} />
                                                    ) : (
                                                        filteredClimateVaultVideos?.length === 0 &&
                                                        <EmptyState className={"absolute top-[50%] left-[50%] translate-y-[-50%] translate-x-[-50%]"} page={"Climate Vault"} />
                                                    )
                                                    }
                                                    {
                                                        filterData?.length > 0 &&
                                                        <div className='grid  2md:grid-cols-3 2sm:grid-cols-2 gap-[16px]'>
                                                            {!videoTabOpen ? (
                                                                <>
                                                                    {filterData?.map((item, index) => (
                                                                        <ClimateVaultCard key={index} item={item} />
                                                                    ))}
                                                                </>
                                                            ) : (
                                                                <> {filteredClimateVaultVideos?.map((item, index) => (
                                                                    <ClimateVaultVideoCard key={index} item={item} />
                                                                ))}
                                                                </>
                                                            )}
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }
                                {activeIndex > 0 &&
                                    <>
                                        {category?.map((item, index) => (
                                            <div className='bg-[#F3F5F7] w-full py-[20px] px-[24px] relative'>
                                                <div className='bg-[#F3F5F7]  3lg:w-[70vw] 2md:w-[570px] w-full space-y-[8px] profile-content-tabs tabs-header-bg '>
                                                    {filterData?.length > 0 &&
                                                        <div className='flex items-center justify-between'>
                                                            <div className='flex items-center justify-start gap-[8px]'>
                                                                {Articles()}
                                                                {/* {Videos()} */}
                                                            </div>

                                                            <div className='min-w-[200px]'>
                                                                {videoTabOpen ? (
                                                                    <CustomSelect
                                                                        ClassCss="Valut-filter"
                                                                        options={filterOptions}
                                                                        placeholder="Select"
                                                                        className="w-full"
                                                                        onChange={handleVideoFilterChange}
                                                                    />
                                                                ) : (
                                                                    <CustomSelect
                                                                        ClassCss="Valut-filter"
                                                                        options={VideoOptions}
                                                                        placeholder="Select"
                                                                        className="w-full"
                                                                        onChange={handleFilterChange}
                                                                    />
                                                                )}
                                                            </div>
                                                        </div>
                                                    }
                                                    {/* Render Components based on active tab */}
                                                    <div className=''>
                                                        <div className='py-[16px]  overflow-auto h-[calc(100vh-253px)] hide-scrollbar'>
                                                            {!videoTabOpen ? (
                                                                filterData?.length === 0 &&
                                                                <EmptyState className={"absolute top-[50%] left-[50%] translate-y-[-50%] translate-x-[-50%]"} page={"Climate Vault"} />
                                                            ) : (
                                                                filteredClimateVaultVideos?.length === 0 &&
                                                                <EmptyState className={"absolute top-[50%] left-[50%] translate-y-[-50%] translate-x-[-50%]"} page={"Climate Vault"} />
                                                            )
                                                            }


                                                            {
                                                                filterData?.length > 0 &&
                                                                <div className='grid 2sm:grid-cols-2 gap-[16px]'>
                                                                    {!videoTabOpen ? (
                                                                        <>
                                                                            {filterData.map((item, index) => (
                                                                                <ClimateVaultCard key={index} item={item} />
                                                                            ))}
                                                                        </>
                                                                    ) : (
                                                                        <>
                                                                            {filteredClimateVaultVideos?.map((item, index) => (
                                                                                <ClimateVaultVideoCard key={index} item={item} />
                                                                            ))}
                                                                        </>
                                                                    )}
                                                                </div>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        ))}
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default ClimateVault;