import { useEffect, useRef, useState } from 'react';
import Cropper from 'react-easy-crop';
import { useDispatch } from 'react-redux';
import Bluebtn from '../Components/Common/Btns/Bluebtn';
import CustomModal from '../Components/Common/CustomModel';

const PhotoCropper = ({
  modalTitle,
  yourImage,
  setYourImage,
  cropperVisible,
  setCropperVisible,
  isCreatePostChecked,
  setCreatePostChecked,
  apiUrl,
  imageKeyName,
  setBinaryImage,
  aspectRatios,
  photoUpdateSuccess
}) => {
  const dispatch = useDispatch();
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedArea, setCroppedArea] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);
  const [BgcroppedImage, setBgCroppedImage] = useState(null);
  let initialAspect = imageKeyName === 'coverImage' ? 16 / 9 : 1 / 1;
  const [aspect, setAspect] = useState(initialAspect);
  const cropperRef = useRef();
  const [postLoading, setPostLoading] = useState(false);
  useEffect(() => {
    const fetchData = async () => {
      try {
        await new Promise((resolve) => setTimeout(resolve, 2000));
      } finally {
      }
    };
    fetchData();
  }, [dispatch]);
  const onCropComplete = (croppedArea, croppedAreaPixels) => {
    setCroppedArea(croppedAreaPixels);
  };
  const dataURItoBlob = (dataURI) => {
    const byteString = atob(dataURI.split(',')[1]);
    const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: mimeString });
  };
  const getOriginalAspectRatio = async (imageURL) => {
    return new Promise((resolve, reject) => {
      const image = new Image();
      image.onload = () => {
        const aspectRatio = image.naturalWidth / image.naturalHeight;
        resolve(aspectRatio);
      };
      image.onerror = (error) => {
        reject(error);
      };
      image.src = imageURL;
    });
  };
  const handleAspectChange = async (e) => {
    const selectedValue = e.target.value;
    if (selectedValue === 'original') {
      try {
        const originalAspectRatio = await getOriginalAspectRatio(yourImage);
        setAspect(originalAspectRatio);
      } catch (error) {
        console.error(
          'Error loading image for original aspect ratio : ',
          error
        );
      }
    } else {
      const selectedAspect = parseFloat(selectedValue);
      setAspect(selectedAspect);
    }
  };
  const getCroppedImg = async () => {
    if (!yourImage) return;
    if (aspect === 'original') {
      setCroppedImage(yourImage);
      setCropperVisible(false);
      return;
    }
    const croppedImageDataURL = await getCroppedImage();
    setBgCroppedImage(`url('${croppedImageDataURL}')`);
    setCroppedImage(croppedImageDataURL);
    setYourImage(croppedImageDataURL);
    setCropperVisible(false);
  };
  const getCroppedImage = async () => {
    if (!croppedArea) return;
    const image = new Image();
    image.src = yourImage;
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    canvas.width = croppedArea.width;
    canvas.height = croppedArea.height;
    ctx.drawImage(
      image,
      croppedArea.x * scaleX,
      croppedArea.y * scaleY,
      croppedArea.width * scaleX,
      croppedArea.height * scaleY,
      0,
      0,
      canvas.width,
      canvas.height
    );
    return canvas.toDataURL('image/jpeg');
  };

  const uploadPost = async () => {
    setPostLoading(true);
    if (croppedImage) {
      const blob = await dataURItoBlob(croppedImage);
      setYourImage(blob);
      setBinaryImage(null);
    }
  };
  const handleCreatePostToggle = () => {
    // setCreatePostChecked(!isCreatePostChecked);
    // console.log(isCreatePostChecked , 'isCreatePostChecked');
    // if (!isCreatePostChecked) {

    setCropperVisible(false);
    setYourImage(null);
    setCroppedImage(null);
    // }
  };
  return (
    <>
      <CustomModal bodyClassName='max-w-[60vw] rounded-[16px]' showModal={cropperVisible} setShowModal={setCropperVisible}>
        <div>
          <div className=' relative  2md:pt-[27px] 2sm:pt-[20px] pt-[12px] 2md:pb-[16px] pb-[12px] 2md:px-[16px] px-[12px] text-left  '>
            <label
              onClick={() => handleCreatePostToggle()}
              className=' hover:bg-transparent absolute 2md:right-[18px] 2sm:right-[16px] right-[12px] 2md:top-[27px] 2sm:top-[20px] top-[12px]'
            >
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='20'
                height='21'
                viewBox='0 0 20 21'
                fill='none'
              >
                <path
                  d='M10 20.5C12.6522 20.5 15.1957 19.4464 17.0711 17.5711C18.9464 15.6957 20 13.1522 20 10.5C20 7.84784 18.9464 5.3043 17.0711 3.42893C15.1957 1.55357 12.6522 0.5 10 0.5C7.34784 0.5 4.8043 1.55357 2.92893 3.42893C1.05357 5.3043 0 7.84784 0 10.5C0 13.1522 1.05357 15.6957 2.92893 17.5711C4.8043 19.4464 7.34784 20.5 10 20.5ZM6.83594 7.33594C7.20312 6.96875 7.79688 6.96875 8.16016 7.33594L9.99609 9.17188L11.832 7.33594C12.1992 6.96875 12.793 6.96875 13.1562 7.33594C13.5195 7.70312 13.5234 8.29688 13.1562 8.66016L11.3203 10.4961L13.1562 12.332C13.5234 12.6992 13.5234 13.293 13.1562 13.6562C12.7891 14.0195 12.1953 14.0234 11.832 13.6562L9.99609 11.8203L8.16016 13.6562C7.79297 14.0234 7.19922 14.0234 6.83594 13.6562C6.47266 13.2891 6.46875 12.6953 6.83594 12.332L8.67188 10.4961L6.83594 8.66016C6.46875 8.29297 6.46875 7.69922 6.83594 7.33594Z'
                  fill='#A69EAE'
                />
              </svg>
            </label>
            <div>
              <p className='text-[1.125rem] 2md:font-semibold font-medium leading-[110%]  text-left'>
                {modalTitle}
              </p>
            </div>
            <div className=' 2md:pt-[23px] 2sm:pt-[16px] pt-[12px] 2sm:gap-[12px] gap-[8px]'>
              {!cropperVisible && (
                <div>
                  {croppedImage && (
                    <div
                      className={`relative rounded-[12px] overflow-hidden max-h-[510px] max-w-[100%] h-full  `}
                    >
                      <div
                        className='absolute inset-0 bg-cover bg-center'
                      // style={{
                      //   backgroundImage: BgcroppedImage,
                      //   filter: 'blur(10px)'
                      // }}
                      ></div>
                      <div className='relative z-10 flex items-center justify-center max-h-[510px] max-w-[100%] h-full'>
                        <img
                          className='max-w-full max-h-[500px] h-full mx-auto'
                          src={croppedImage}
                          alt='Cropped'
                        />
                        <div
                          onClick={() => {
                            handleCreatePostToggle();
                          }}
                          className='cursor-pointer bg-[#00000060] flex justify-center items-center w-[24px] h-[24px] rounded-[50%] absolute top-[8px] right-[8px]'
                        >
                          <svg
                            width='12'
                            height='12'
                            viewBox='0 0 12 12'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                          >
                            <path
                              id='Vector'
                              d='M11.7365 10.4637C12.0878 10.815 12.0878 11.3852 11.7365 11.7365C11.3852 12.0878 10.815 12.0878 10.4637 11.7365L6 7.27279L1.53629 11.7365C1.18496 12.0878 0.614842 12.0878 0.263504 11.7365C-0.0878345 11.3852 -0.0878345 10.815 0.263504 10.4637L4.72721 6L0.263504 1.53629C-0.0878345 1.18496 -0.0878345 0.614842 0.263504 0.263504C0.614842 -0.0878345 1.18496 -0.0878345 1.53629 0.263504L6 4.72721L10.4637 0.263504C10.815 -0.0878345 11.3852 -0.0878345 11.7365 0.263504C12.0878 0.614842 12.0878 1.18496 11.7365 1.53629L7.27279 6L11.7365 10.4637Z'
                              fill='#F8F8F8'
                            />
                          </svg>
                        </div>
                      </div>
                    </div>
                  )}
                  <div className='flex items-center justify-between 2md:mt-[16px] mt-[10px]'>
                    {/* attac files  */}
                    <div className='flex items-center justify-start 2sm:gap-[16px] gap-[8px] '></div>
                    <div>
                      <Bluebtn
                        className={`2sm:text-[14px] text-[12px] pink-bg-hover font-medium sm:py-[6px] py-[4px] 2sm:px-[16px] px-[12px] 2sm:rounded-[8px] rounded-[4px]`}
                        Lable={postLoading ? 'Posting...' : 'Post'}
                        disabled={postLoading}
                        type='button'
                        onClick={uploadPost}
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>
            {cropperVisible && (
              <>
                <div className='relative h-[500px] rounded-[12px] overflow-hidden'>
                  {cropperVisible && (
                    <Cropper
                      ref={cropperRef}
                      image={yourImage}
                      crop={crop}
                      zoom={zoom}
                      aspect={aspect}
                      onCropChange={setCrop}
                      onCropComplete={onCropComplete}
                      onZoomChange={setZoom}
                    />
                  )}
                </div>
                <div className='flex justify-end mt-[16px]'>
                  <div className=' items-center justify-center hidden'>
                    <label htmlFor='aspectRatio'>Aspect Ratio: </label>
                    <select
                      id='aspectRatio'
                      className='bg-transparent focus:outline-none'
                      onChange={handleAspectChange}
                      value={aspect}
                    >
                      {aspectRatios.map((ratio, index) => (
                        <option
                          key={index}
                          className='text-[#1d1d1d]'
                          value={ratio.value}
                        >
                          {ratio.label}
                        </option>
                      ))}
                    </select>
                  </div>
                  <Bluebtn
                    type='button'
                    className='2sm:text-[14px] text-[12px] pink-bg-hover font-medium sm:py-[6px] py-[4px] 2sm:px-[16px] px-[12px] 2sm:rounded-[8px] text-white rounded-[4px]'
                    onClick={getCroppedImg}
                    Label='Crop Image'
                  ></Bluebtn>
                </div>
              </>
            )}
          </div>
        </div>

      </CustomModal>
    </>
  );
};

export default PhotoCropper;
