
import AdminReports from "../../../Admin/AdminReports";
import ClimateVaultAdmin from "../../../Admin/ClimateVaultAdmin";
import CollaborationsAdmin from "../../../Admin/CollaborationsAdmin";
import CommunitiesAdmin from "../../../Admin/CommunitiesAdmin";
import AdminDashboard from "../../../Admin/Dashboard";
import AdminLogin from "../../../Admin/Login";
import AdminUsers from "../../../Admin/Users";


const adminRoutes = [
    {
        path: "/admin",
        children: [
            {
                path: "dashboard", // Relative path
                element: <AdminDashboard />,
            },
            {
                path: "reports", // Relative path
                element: <AdminReports />,
            },
            
            {
                path: "users", // Relative path
                element: <AdminUsers />,
            },
            {
                path: "communities", // Relative path
                element: <CommunitiesAdmin />,
            },
            {
                path: "collaborations", // Relative path
                element: <CollaborationsAdmin />,
            },
            {
                path: "climatevault", // Relative path
                element: <ClimateVaultAdmin />,
            },
            {
                path: "packages", // Relative path
                element: <CommunitiesAdmin />,
            },
            {
                path: "feed", // Relative path
                element: <CommunitiesAdmin />,
            }
        ],
    }
]

export default adminRoutes;