import Communities from "../../../pages/Communities"
import CommunitiesDetails from "../../../pages/Communities/CommunitiesDetails"
import Home from "../../../pages/Home";
const CommunitiesRoutes = {
    path: "/communities",
    children: [
        {
            path: "",
            element: <Communities />,
            // element: <Home />,
        },
        {
            path: "details/:id",
            element: <CommunitiesDetails />,
            // element: <Home />,
        },
    ]
}
export default CommunitiesRoutes;