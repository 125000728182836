import { useRef, useState } from "react";
import { useSelector } from "react-redux";
import { icons } from "../../helpers/images";
import { selectUser } from "../../utils/reduxtoolkit/slices/userSlice";
import { useSocket } from "../Socket/WebSocketContext";

export const IMessageType = {
  TEXT: "text",
  IMAGE: "image",
  VIDEO: "video",
  AUDIO: "audio",
  DOCUMENT: "document",
};

export const MessageInputBox = ({ selectedUser }) => {
  const user = useSelector(selectUser);
  const { sendMessage, onFileUpload } = useSocket();

  const [inputStr, setInputStr] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectAttachment, setSelectAttachment] = useState(null);
  const [filePreview, setFilePreview] = useState(null);
  const [isLoading, setIsLoading] = useState(false)
  const fileInputRef = useRef(null);


  const handleSendMessage = () => {
    if (selectAttachment) {
      sendMessage({
        toUserId: selectedUser?._id,
        content: inputStr,
        msgType: selectAttachment.fileType,
        attachment_url: selectAttachment?.uploadedUrl ?? null
      });
      setInputStr("");
      setSelectAttachment(null)
      setFilePreview(null)
      setSelectedFile(null)
      return true
    }
    if (!inputStr.trim()) return;
    sendMessage({
      toUserId: selectedUser?._id,
      content: inputStr,
      msgType: IMessageType.TEXT,
    });
    setInputStr("");
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      handleSendMessage();
    }
  };

  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    // if (file.type.startsWith('image/')) {
    setIsLoading(true)
    const response = await onFileUpload(file)
    setIsLoading(false)
    if (response) {
      setSelectAttachment(response?.data);
      setSelectedFile(file);
    }
    return true;
  };

  const handleRemoveFile = () => {
    setSelectedFile(null);
    setFilePreview(null);
    fileInputRef.current.value = null;
  };

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        handleSendMessage();
      }}
      className=" bg-[#ffffff] 3lg:px-[1.25rem] p-[15px] border-[#EFF3F4] border-t z-50 absolute bottom-[-111px] w-full"
    >
      <div className="w-full">
        {/* {showPicker && <EmojiPicker pickerStyle={{ width: '100%' }} onEmojiClick={handleEmojiClick} />} */}
        <label htmlFor="message" className=" bg-[#ffffff] ">
          <div className=" rounded-[8px] border border-[#EFF3F4] px-[16px] py-[9px]">
            {selectedFile && (
              <div className="flex items-center justify-between mb-2">
                {filePreview ? (
                  <img
                    src={filePreview}
                    alt="File Preview"
                    className="mr-2 max-w-[100px] max-h-[100px]"
                  />
                ) : (
                  <p className="mr-2 2md:text-[16px] md:text-[14px] text-[12px]">{selectedFile.name}</p>
                )}
                <button
                  type="button"
                  onClick={handleRemoveFile}
                  className="text-red-500 hover:text-red-600 no-select 2md:text-[16px] md:text-[14px] text-[12px] "
                >
                  <span>Remove</span>
                </button>
              </div>
            )}
            <div className="flex items-center justify-between gap-[10px]">
              {
                selectedUser?.isBlocked ? (
                  <p className="text-center">Your user is blocked</p>
                ) : (
                  <>
                    <div>
                      <div
                        className="cursor-pointer"
                        onClick={() => fileInputRef.current.click()}
                        dangerouslySetInnerHTML={{ __html: icons.Attachment }}
                      />
                      <input
                        type="file"
                        ref={fileInputRef}
                        style={{ display: "none" }}
                        onChange={handleFileChange}
                      />
                    </div>
                    <textarea
                      value={inputStr}
                      onChange={(e) => setInputStr(e.target.value)}
                      onKeyDown={handleKeyDown}
                      id="message"
                      className="dark:bg-transparent placeholder:-[#EFF3F4] text-[1rem] font-normal focus:outline-none leading-[140%]   w-full focus-visible:none "
                      placeholder="Write a message"

                    ></textarea>
                    {
                      isLoading ? (
                        <div
                          className="text-[14px] font-medium px-[16px] py-[8px] w-fit text-white bg-blue-500 rounded-md hover:bg-blue-600"
                        >
                          <p className="2sm:block hidden">Loading...</p>

                        </div>
                      ) : (
                        <button
                          type="submit"
                          className="text-[14px] font-medium px-[16px] py-[8px] w-fit text-white bg-blue-500 rounded-md hover:bg-blue-600"
                        >
                          <p className="2sm:block hidden">Send</p>
                          <div
                            className="2sm:hidden block white-icon"
                            dangerouslySetInnerHTML={{ __html: icons.Send }}
                          />
                        </button>
                      )
                    }
                  </>
                )
              }
            </div>
          </div>
        </label>
      </div>
    </form>
  );
};
