import Cookies from 'js-cookie';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { InputSwitch } from 'primereact/inputswitch';
import { Toast } from 'primereact/toast';
import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import Bluebtn from '../../Components/Common/Btns/Bluebtn';
import CustomModal from '../../Components/Common/CustomModel';
import CustomInput from '../../Components/Common/Inputs/CustomInput';
import PageLoader from '../../Components/Common/PageLoader';
import { icons } from '../../helpers/images';
import autoInstance from '../../utils/axios';

export default function UserTable({ pageTitle }) {
    let emptyProduct = {
        id: null,
        name: '',
        image: null,
        description: '',
        category: null,
        price: 0,
        quantity: 0,
        rating: 0,
        inventoryStatus: 'INSTOCK'
    };

    const [users, setUsers] = useState(null);
    const [products, setProducts] = useState(null);
    const [UserDialog, setUserDialog] = useState(false);
    const [isPosting, setIsPosting] = useState(false)
    const [deleteUserDialog, setDeleteUserDialog] = useState(false);
    const [deleteProductsDialog, setDeleteProductsDialog] = useState(false);
    const [product, setProduct] = useState(emptyProduct);
    const [selectedProducts, setSelectedProducts] = useState(null);
    const [password, setPassword] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const [dataError, setDataError] = useState({
        name: '',
        email: '',
    })
    const [data, setData] = useState({
        name: '',
        email: '',
    });
    const [submitted, setSubmitted] = useState(false);
    const [isActiveLoading, setIsActiveLoading] = useState(false);
    const [checked, setChecked] = useState(true);
    const [confirmPassword, setConfirmPassword] = useState('');
    const [confirmPasswordError, setConfirmPasswordError] = useState('');
    const [globalFilter, setGlobalFilter] = useState(null);
    const toast = useRef(null);
    const dt = useRef(null);
    const Auth = () => { return !!Cookies.get('g2n0AdminAuth') }
    const screenWidth = window.innerWidth

    const getList = async () => {
        const response = await autoInstance.get('/user/list', { isAdmin: true }).then((data) => {
            const FilterUser = data.data.data.filter((item) => item?.verified === true && item?.isProfileCompleted === true && item?.roleId !== 3)
            setUsers(FilterUser)
        });
    }
    useEffect(() => {
        if (Auth()) {
            try {
                getList()

            } catch (error) {
                console.error(error);
            }
        }
    }, []);

    const handleError = (step = 1) => {
        setDataError({
            name: '',
            email: '',
        })
        setPasswordError('');
        setConfirmPasswordError('');
        let isError = false;
        if (step === 1) {
            if (password !== confirmPassword) {
                setConfirmPasswordError("Password does not match");
                isError = true
            }
            if (password.length < 8 || password.length > 20) {
                setPasswordError("Password must be between 8 and 20 characters");
                isError = true
            }
            if (password.length < 8) {
                setPasswordError("Please enter a valid Password");
                isError = true
            }
            if (password.length > 20) {
                setPasswordError("Password must be below 20 characters");
                isError = true
            }
            if (!/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@#$!%*?&_])[A-Za-z\d@#$!%*?&_]{8,20}$/.test(password)) {
                setPasswordError("Please enter a stronger password");
                isError = true;
            }
            if (confirmPassword.length > 20) {
                setConfirmPasswordError("Confirm Password must be below 20 characters");
                isError = true
            }
        } else if (step === 2) {
            if (data.email === '') {
                setDataError({
                    ...dataError,
                    email: 'Required',
                })
                isError = true
            }
            if (data.name === '') {
                setDataError({
                    ...dataError,
                    name: 'Required',
                })
                isError = true
            }
        }
        if (isError) {
            return isError;
        }
    }

    const handleKeyPress = (event) => {
        if (event.key === "Enter") {

        }
    };
    const handleBlur = (event) => {
        try {
            handleError();
        } catch (e) {
        }

    };

    useEffect(() => {
        if (users) {
            users.forEach(user => {
            });
        }
    }, [products])

    const formatCurrency = (value) => {
        return value.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
    };

    const openNew = () => {
        setProduct(emptyProduct);
        setSubmitted(false);
        setUserDialog(true);
    };

    const hideDialog = () => {
        setPasswordError('');
        setConfirmPasswordError('');
        setPassword('');
        setConfirmPassword('');
        setSubmitted(false);
        setUserDialog(false);
    };

    const hideDeleteUserDialog = () => {
        setDeleteUserDialog(false);
    };

    const hideDeleteProductsDialog = () => {
        setDeleteProductsDialog(false);
    };

    const saveProduct = () => {
        setSubmitted(true);

        if (product.name.trim()) {
            let _products = [...products];
            let _product = { ...product };

            if (product.id) {
                const index = findIndexById(product.id);

                _products[index] = _product;
                toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Product Updated', life: 3000 });
            } else {
                _product.id = createId();
                _product.image = 'product-placeholder.svg';
                _products.push(_product);
                toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Product Created', life: 3000 });
            }

            setProducts(_products);
            setUserDialog(false);
            setProduct(emptyProduct);
        }
    };

    const editProduct = (product) => {
        setProduct({ ...product });
        setUserDialog(true);
    };



    const deleteProduct = () => {
        let _products = products.filter((val) => val.id !== product.id);

        setProducts(_products);
        setDeleteUserDialog(false);
        setProduct(emptyProduct);
        toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Product Deleted', life: 3000 });
    };

    const findIndexById = (id) => {
        let index = -1;

        for (let i = 0; i < products.length; i++) {
            if (products[i].id === id) {
                index = i;
                break;
            }
        }

        return index;
    };

    const createId = () => {
        let id = '';
        let chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

        for (let i = 0; i < 5; i++) {
            id += chars.charAt(Math.floor(Math.random() * chars.length));
        }

        return id;
    };

    const exportCSV = () => {
        dt.current.exportCSV();
    };

    const confirmDeleteSelected = () => {
        setDeleteProductsDialog(true);
    };

    const deleteSelectedProducts = () => {
        let _products = products.filter((val) => !selectedProducts.includes(val));

        setProducts(_products);
        setDeleteProductsDialog(false);
        setSelectedProducts(null);
        toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Products Deleted', life: 3000 });
    };

    const onCategoryChange = (e) => {
        let _product = { ...product };

        _product['category'] = e.value;
        setProduct(_product);
    };

    const onInputChange = async (e, name) => {
        try {
            setIsActiveLoading(true);
            const val = e.target.value;
            const response = await autoInstance.post('/user/change-status', { id: e.target.id, isActive: val }, { isAdmin: true }).then((data) => {
                if (data.status == 200) {
                    getList();
                    setIsActiveLoading(false);
                }
            })
        } catch (error) {
            setIsActiveLoading(false);
            console.error(error);
        }
    };



    const actionBodyTemplate = (rowData) => {
        return (
            <div className='flex items-center justify-center gap-[10px]'>
                {/* <div dangerouslySetInnerHTML={{ __html: icons.Edit }} className="mr-2 w-[20px] h-[20px]" onClick={() => editProduct(rowData)} /> */}
                <InputSwitch checked={rowData.isActive} id={rowData._id} name='isActive' value={rowData.isActive} onChange={(e) => onInputChange(e, 'isActive')} />
            </div>
        );
    };


    const header = (
        <div className='flex items-center justify-between'>
            <p className='text-[24px] font-semibold pl-[8px]'>{pageTitle}</p>
            <div className="flex flex-wrap gap-2 align-items-center justify-end ">
                <div className='flex w-[250px] py-[12px] px-[16px] rounded-[8px] broder-[#EFF3F4] border items-center justify-start gap-[10px]'>
                    <div dangerouslySetInnerHTML={{ __html: icons.search }} />
                    <input onInput={(e) => setGlobalFilter(e.target.value)}
                        className='leading-[22px] text-[16px] bg-transparent font-normal focus:outline-none' type="text" placeholder='Search' />
                </div>

                {/* <div className=''>
                    <Bluebtn Label={`Add New Admin`} className={`text-[14px] font-medium px-[16px] py-[14px] w-fit text-white`} onClick={openNew} />
                </div> */}
            </div>
        </div>
    );
   ;

    const nameBodyTemplate = (rowData) => {
        return (
            <div target='_blank' to={`/user/in/${rowData._id}`} className='flex items-center justify-start gap-[10px]'>
                <p className='text-[14px] font-medium'>{rowData.first_name ?? ""} {rowData.last_name ?? ""}</p>
            </div>
        );
    };

    const CreateAdmin = async () => {
        try {
            setIsPosting(true);
            const isError = handleError(2);
            if (isError) {
                setIsPosting(false);
                return;
            }
            const response = await autoInstance.post('/admin/create', {

            }, { isAdmin: true }).then((data) => {
                if (data.status == 200) {
                    getList();
                    setIsPosting(false);
                }
            })
        }
        catch (error) {
            console.error(error);
            setIsPosting(false);
        }
    }

    return (
        <div className='h-[calc(100vh-80px)] overflow-y-auto hide-scrollbar'>
            {isActiveLoading && <PageLoader />}
            <Toast ref={toast} />
            <div className="border border-[#EFF3F4] rounded-[8px] overflow-hidden m-[16px]">
                {/* <Toolbar className="mb-4" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar> */}

                <DataTable ref={dt} value={users} selection={selectedProducts} onSelectionChange={(e) => setSelectedProducts(e.value)}
                    dataKey="id" paginator rows={10} rowsPerPageOptions={[5, 10, 25]}
                    paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink  "
                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products" globalFilter={globalFilter} header={header}>
                    <Column field="first_name" header="Name" body={nameBodyTemplate} style={{ minWidth: '12rem' }} sortable   ></Column>
                    <Column field="email" header="Email" style={{ minWidth: '16rem' }} ></Column>
                    <Column field="iam" header="Role" style={{ minWidth: '10rem' }}></Column>
                    <Column body={actionBodyTemplate} exportable={false} style={{ minWidth: '12rem' }}></Column>
                </DataTable>
            </div>
            <CustomModal showModal={UserDialog} setShowModal={setUserDialog} bodyClassName={"rounded-[16px] "} >
                <div className=''>
                    <div className='2sm:pt-[30px] pt-[20px] px-[30px] space-y-[16px] max-h-[80vh] overflow-y-auto relative'>
                        <p className='text-[18px] font-semibold text-[#0C1835]'>Create Admin - {pageTitle}</p>

                        <div className='space-y-[16px] '>
                            <div className="field">
                                <CustomInput id="title" label={`Name`} onChange={(e) => setData({ ...data, name: e.target.value })} placeholder={'Enter admin name'} />
                                {dataError?.name && <p className='text-[14px] text-red-500'>{dataError.name}</p>}
                            </div>
                            <div className="field">
                                <CustomInput id="email" label={`Email`} onChange={(e) => setData({ ...data, email: e.target.value })} placeholder={'Enter email'} />
                                {dataError?.email && <p className='text-[14px] text-red-500'>{dataError.email}</p>}
                            </div>
                            <CustomInput
                                label='Set a Password'
                                type='password'
                                onBlur={() => handleError()}
                                value={password}
                                onChange={(e) => { setPassword(e.target.value); }}
                                placeholder='Password'
                            />
                            {passwordError &&
                                <>
                                    {!passwordError === "Please enter a strong password" ? <p className='text-[14px] text-red-500'>{passwordError}</p> : <div className='flex items-center justify-start gap-[8px] text-[14px] text-red-500'>
                                        <p>{passwordError}</p>
                                        <div className='flex items-center relative group cursor-pointer'>
                                            <svg
                                                xmlns='http://www.w3.org/2000/svg'
                                                viewBox='0 0 24 24'
                                                fill='none'
                                                stroke='currentColor'
                                                strokeWidth='2'
                                                strokeLinecap='round'
                                                strokeLinejoin='round'
                                                className='lucide lucide-info text-[#6D7486] h-4 w-4'
                                            >
                                                <circle cx='12' cy='12' r='10' />
                                                <path d='M12 16v-4' />
                                                <path d='M12 8h.01' />
                                            </svg>
                                            <div className={` ${screenWidth < 768 ? 'top-[30px] right-[10px]' : 'top-[30px] right-0'} bg-[#B7BCCA] text-white px-[8px] py-[4px] rounded-[4px] absolute hidden group-hover:block w-[250px] z-50`}>
                                                <ul className='list-disc list-inside'>
                                                    <li>At least one uppercase letter</li>
                                                    <li>At least one lowercase letter</li>
                                                    <li>At least one digit</li>
                                                    <li>  At least one special character</li>
                                                    <li>@#$!%*?_&</li>
                                                    <li>Length of 8 to 20 characters</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>}
                                </>
                            }

                            <CustomInput
                                label='Confirm Password'
                                type='password'
                                onKeyPress={handleKeyPress}
                                onBlur={handleBlur}
                                value={confirmPassword}
                                onChange={(e) => { setConfirmPassword(e.target.value); handleError() }}
                                placeholder='Confirm Password'
                            />
                            {confirmPasswordError && <p className='text-[14px] text-red-500'>{confirmPasswordError}</p>}

                        </div>

                        <div className='flex items-center justify-end gap-[16px] sticky bottom-0 z-10 py-[20px] bg-white'>
                            <Bluebtn onClick={() => hideDialog()} className=" bg-[#B7BCCA]  text-[14px] font-medium py-[8px] px-[16px] w-fit text-white" Label="Cancel" />
                            <Bluebtn onClick={() => isPosting ? null : CreateAdmin()} className={`  text-[14px] font-medium py-[8px] px-[16px] w-fit text-white ${isPosting ? 'bg-[#B7BCCA]' : 'bg-[#2A8DDE]'} `} Label={`${isPosting ? 'Createing...' : 'Create'}`} />
                        </div>

                    </div>
                </div>
            </CustomModal>


        </div>
    );
}
