import moment from "moment";
import React, { useEffect, useState } from "react";
import { icons } from "../../../helpers/images";
import { useSocket } from "../../Socket/WebSocketContext";

function ProfileCard({
  name,
  profileImage,
  acitve,
  userName,
  conversation,
  lastMessage,
  key,
  time,
  isOnline,
  unRead,
  createdAt,
  onClick,
}) {
  const [updateLastMessage, setUpdateLastMessage] = useState(null);
  const [isDelievered, setIsDelievered] = useState(false);
  const { socket, onMessageSeen } = useSocket();

  useEffect(() => {
    if (socket) {
      socket.on(`last-message-receive`, (data) => { 
        onMessageSeen(data)
        setUpdateLastMessage(data);
        setIsDelievered(true)
      });
      socket.on(`message-delivered`, (data) => { 
        setIsDelievered(false)
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socket]);

  const formatRelativeTime = (timestamp) => {
    if (!timestamp) return '';
    const now = moment();
    const duration = moment.duration(now.diff(moment(timestamp)));

    if (duration.asSeconds() < 60) {
      return 'now';
    } else if (duration.asMinutes() < 60) {
      return `${Math.floor(duration.asMinutes())}min`;
    } else if (duration.asHours() < 24) {
      return `${Math.floor(duration.asHours())}h`;
    } else if (duration.asDays() < 30) {
      return `${Math.floor(duration.asDays())}d`;
    } else if (duration.asMonths() < 12) {
      return `${Math.floor(duration.asMonths())}m`;
    } else {
      return `${Math.floor(duration.asYears())}y`;
    }
  };

  const lastMessageText = updateLastMessage ? updateLastMessage : { ...lastMessage, status: 'read' };
  
  return (
    <div
      onClick={onClick}
      key={key}
      className={
        acitve === true
          ? `w-full cursor-pointer rounded-[8px] p-[12px] flex items-center justify-between hover:bg-[#EAF4FC] transition-all duration-300 bg-[#EAF4FC]`
          : `w-full cursor-pointer rounded-[8px] p-[12px] flex items-center justify-between hover:bg-[#EAF4FC] transition-all duration-300`
      }
    >
      <div className="flex items-center justify-start gap-[8px] w-full">
        <div className="relative">
          {profileImage ?
            <div className="flex-center w-[36px] h-[36px] rounded-[8px] overflow-hidden">
              <img
                src={
                  process.env.REACT_APP_IMAGE_URL + profileImage
                }
                className=" w-full h-full object-cover"
                alt=""
              />
            </div> :
            <div className="w-[36px] h-[36px] rounded-[8px] overflow-hidden flex items-center justify-center border border-[#EFF3F4]" dangerouslySetInnerHTML={{ __html: icons.defaultProfile }} />
          }
          {isOnline === true ? (
            <div className="absolute bottom-[-2px] right-[-2px] w-[10px] h-[10px] bg-[#30D85C] rounded-full border border-white"></div>
          ) : null}
        </div>
        <div className="w-[calc(100%_-_56px)]">
          <div className="flex items-center justify-between">
            <p className="text-[16px] font-medium leading-[110%]">{name}</p>
            <div className="flex flex-col align-end">
              <span className="text-[#6D7486] text-[12px] font-normal leading-[140%]">{formatRelativeTime(lastMessageText?.createdAt)}</span>
              {
                isDelievered && (
                  <div className="w-[.5rem] h-[.5rem] self-end bg-[#2A8DDE] rounded-full border border-white mt-[.25rem]"></div>
                )
              }
            </div>
          </div>
          <p className="text-[12px] font-normal leading-[140%] text-[#6D7486] whitespace-nowrap overflow-hidden text-ellipsis w-[98%]">
            {lastMessageText?.content ?? ''}
          </p>
        </div>
      </div>
    </div>
  );
}

export default ProfileCard;
