import { Sidebar } from 'primereact/sidebar';
import React, { useState } from 'react';
import { Link, NavLink, useLocation, useNavigate } from 'react-router-dom';
import Bluebtn from '../../Components/Common/Btns/Bluebtn';
import { icons } from '../../helpers/images';
import CookieConsent from '../../Components/Common/Cookie';
const Cookies = require('js-cookie');


function WebHeader({ className, isSticky, svgHide }) {
    const [open, setOpen] = useState(false)

    const cockies = () => {
        return !!Cookies.get('g2n0Auth')
    }


    const location = useLocation();
    const pathname = location.pathname;

    const navigate = useNavigate();
    return (
        <div>
            {/* <CookieConsent /> */}

            <div className={`${className && className}  w-screen bg-transparent`}>
                <div className={`header-shadow w-screen ${pathname === '/home' ? "" : "fixed web-header"} z-[11] bg-white 2sm:py-[16px] py-[12px] left-[0px] `}>
                    <div className={` xl:max-w-6xl 3lg:max-w-5xl max-w-4xl w-full mx-auto flex items-center justify-between 2sm:px-[24px] sm:px-[20px] px-[15px] `}>
                        <div className='flex justify-start items-center  z-[10] relative'>
                            {/* logo here */}
                            <Link
                                to="/home"
                                className="flex items-center justify-center 3lg:w-[218px]  2sm:w-[130px] w-[90px]"
                            >
                                <div
                                    className="3lg:w-[218px]  2sm:w-[130px] w-[90px] flex-center"
                                    dangerouslySetInnerHTML={{ __html: icons.logoTogether }}
                                />
                            </Link>
                        </div>
                        {
                            cockies() ?
                                <>
                                    <Bluebtn Label={'Home'} onClick={() => { navigate('/') }} className={'2md:block hidden 2lg:px-[16px] px-[12px] py-[9px]    text-[16px]  hover:bg-[#30d85c] font-medium text-[#ffffff]  z-10 relative'} />
                                </> :
                                <div className='flex justify-start items-center 4lg:gap-[30px] 3lg:gap-[24px] 2lg:gap-[20px] gap-[16px] z-30 relative'>
                                    <NavLink to={'/about-us'} className=' 2md:block hidden xl:text-[18px] text-[16px] font-medium  text-[#2A8DDE] hover:text-[#30d85c] trasiction-all duration-300'>About Us</NavLink>
                                    <div className='w-[1px] h-[24px] bg-[#2A8DDE] rounded-[2px] 2md:block hidden'></div>
                                    <Link to={'/login'} className='2md:block hidden xl:text-[18px] text-[16px] font-medium  text-[#2A8DDE] hover:text-[#30d85c] trasiction-all duration-300'>Sign in</Link>
                                    <Bluebtn Label={'Sign Up'} onClick={() => { navigate('/signup') }} className={'2md:block hidden 2lg:px-[16px] px-[12px] py-[9px] 2lg:text-[16px] text-[14px]   hover:bg-[#30d85c] font-medium text-[#ffffff] relative z-30'} />
                                    <div className='2md:hidden block cursor-pointer' onClick={() => { setOpen(true) }}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                            <path d="M4 12H20M4 6H20M4 18H20" stroke="#0C1835" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                        </svg>
                                    </div>
                                </div>
                        }
                    </div>
                </div>
                <Sidebar visible={open} onHide={() => setOpen(false)}>
                    <div className='flex items-center justify-between'>
                        <Link
                            to="/home"
                            className="flex items-center justify-center  2sm:w-[130px] w-[100px]"
                        >
                            <div
                                className="3lg:w-[218px]  2sm:w-[130px] w-[90px] flex-center"
                                dangerouslySetInnerHTML={{ __html: icons.logoTogether }}
                            />
                        </Link>
                        <div onClick={() => setOpen(false)} dangerouslySetInnerHTML={{ __html: icons.closeIcon }} />
                    </div>
                    <ul className='space-y-[16px] '>
                        <li>
                            {/* <Link className='text-[18px] font-bold tracking-[1.8px] text-[] hover:text-[#30d85c] trasiction-all duration-300'>Challenges</Link> */}
                        </li>
                        <li>
                            <Link to={'/about-us'} className='text-[18px] font-bold tracking-[1.8px] hover:text-[#30d85c] trasiction-all duration-300'>About Us</Link>
                        </li>
                        <li>
                            <Link to={'/login'} className='text-[18px] 2md:hidden block font-bold tracking-[1.8px] hover:text-[#30d85c] trasiction-all duration-300'>Log In</Link>
                        </li>
                        <li>
                            <Link to={'/signup'} className='text-[18px] 2md:hidden block font-bold tracking-[1.8px] text-[] hover:text-[#30d85c] trasiction-all duration-300'>Sign Up</Link>
                        </li>
                        <li>
                            {/* <Link className='text-[18px] font-bold tracking-[1.8px] text-[] hover:text-[#30d85c] trasiction-all duration-300'>FAQ</Link> */}
                        </li>

                    </ul>
                </Sidebar>

            </div>

        </div>
    )
}

export default WebHeader