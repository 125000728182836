import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { icons } from '../../helpers/images';
import autoInstance from '../../utils/axios';
import ProfileCard from '../Common/Cards/ProfileCard';
import { useSocket } from '../Socket/WebSocketContext';

function ChatSidebar({ conversationList, selectedUser, handleUserSelect }) {
    const { getMessageListByConversationId } = useSocket();
    const params = useParams();
    const sessionUserString = localStorage.getItem('g2n0User');
    const sessionUser = sessionUserString ? JSON.parse(sessionUserString) : null;
    const [searchQuery, setSearchQuery] = useState('');
    const [searchResults, setSearchResults] = useState([]);

    const onSendNewConversation = useCallback(async () => {
        if (params?.id) {
            try {
                const response = await autoInstance.post(`/messages/new-message-send`, {
                    id: params?.id,
                });
                const sources = response.data;
                let userDetail = {};
                if (sessionUser?._id !== sources.data.toUser?._id) {
                    userDetail = sources.data.toUser;
                } else if (sessionUser?._id !== sources.data.fromUser?._id) {
                    userDetail = sources.data.fromUser;
                }
                getMessageListByConversationId(sources.data._id);
                handleUserSelect({ ...userDetail, conversation: sources.data._id, lastMessage: sources.data.lastMessage, isBlocked: false }, true);
            } catch (error) {
                console.error(error);
            }
        }
    }, [getMessageListByConversationId, handleUserSelect, params?.id, sessionUser?._id]);

    useEffect(() => {
        if (conversationList) {
            setSearchResults(conversationList);
        }
    }, [conversationList]);

    useEffect(() => {
        if (params?.id) {
            onSendNewConversation();
        }
    }, [onSendNewConversation, params?.id]);

    const handleUserClick = useCallback((item) => {
        getMessageListByConversationId(item.conversation);
        handleUserSelect(item);
    }, [getMessageListByConversationId, handleUserSelect]);

    const handleSearch = (value) => {
        const query = value.toLowerCase();
        setSearchQuery(query);
        if (query?.length > 2) {
            const filteredResults = conversationList.filter((item) => {
                const userDetail = sessionUser?._id !== item.toUser?._id ? item.toUser : item.fromUser;
                const fullName = `${userDetail?.first_name} ${userDetail?.last_name}`.toLowerCase();
                return fullName.includes(query);
            });
            setSearchResults(filteredResults);
        } else {
            setSearchResults(conversationList);
        }
    };

    return (
        <div className={`3lg:w-[450px] 2md:w-[320px] w-full 2md:block ${Object.keys(selectedUser).length !== 0 ? 'hidden' : 'block'}  p-[16px]  border-r border-[#EFF3F4] `}>
            <div className='flex py-[12px]  px-[16px] rounded-[8px] broder-[#EFF3F4] border items-center justify-start gap-[10px]'>
                <div dangerouslySetInnerHTML={{ __html: icons.search }} />
                <input
                    onChange={(e) => {
                        const query = e?.target?.value;
                        if (query) {
                            setSearchQuery(query);
                            handleSearch(query);
                        }
                    }}
                    className='leading-[22px] text-[16px] font-normal focus:outline-none'
                    type="text"
                    placeholder='Search for user'
                />
            </div>
            <div className='3lg:h-[calc(100vh_-_13rem)] h-[calc(100vh_-_12rem)] overflow-y-auto dropdown w-full py-[10px] space-y-[8px]'>
                {searchResults?.length > 0 ? (
                    <>
                        {searchResults.map((value, index) => {
                            let userDetail = {};
                            if (sessionUser?._id !== value.toUser?._id) {
                                userDetail = value.toUser;
                            } else if (sessionUser?._id !== value.fromUser?._id) {
                                userDetail = value.fromUser;
                            }
                            return (
                                <ProfileCard
                                    key={index}
                                    onClick={() => handleUserClick({ ...userDetail, conversation: value._id, lastMessage: value.lastMessage, isBlocked: Boolean(userDetail.isBlocked) })}
                                    name={`${userDetail?.first_name ?? ''} ${userDetail?.last_name ?? ''}`}
                                    userName={userDetail?.email ?? ''}
                                    isOnline={userDetail?.isOnline}
                                    profileImage={userDetail?.profileImage ?? ''}
                                    acitve={selectedUser?._id === userDetail?._id}
                                    lastMessage={value.lastMessage}
                                    createdAt={value.lastMessage?.createdAt}
                                    conversation={value._id}
                                />
                            );
                        })}
                    </>
                ) : (
                    <div className="h-full w-full flex-center !mt-[0px]">
                        <span className="text-[#9C9C9C] text-[16px] font-normal ">You have no messages.</span>

                    </div>
                )}

            </div>
        </div>
    );
}

export default ChatSidebar;
