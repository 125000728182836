import { toast } from "react-toastify";

const Toast = (message, type = "success", options = {}) => {
  const toastOptions = {
    autoClose: 4000,
    hideProgressBar: true,
    position: "bottom-center",
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
    ...options,
  };

  switch (type) {
    case "success":
      toast.success(message, {
        ...toastOptions,
        position: "bottom-center", // Customize position here if needed
        icon: false, // Remove icon
      });
      break;
    case "error":
      toast.error(message, {
        ...toastOptions,
        position: "bottom-center", // Customize position here if needed
        icon: false, // Remove icon
      });
      break;
    case "warning":
      toast.warn(message, {
        ...toastOptions,
        position: "bottom-center", // Customize position here if needed
        icon: false, // Remove icon
      });
      break;
    default:
      toast(message, toastOptions); // Pass message directly instead of an object
      break;
  }
};

export default Toast;
