import { TabPanel, TabView } from 'primereact/tabview';
import React, { useEffect, useState } from 'react';
import NotificationCard from '../../Components/Common/Cards/notificationCard';
import Navbar from '../../Components/Common/Navbar';
import Sidebar from '../../Components/Common/Sidebar';
import autoInstance from '../../utils/axios';

function NotificationPage() {
    const [notificationData, setNotificationData] = useState([])
    const reverseNotifications = notificationData.slice().reverse();
    const postNotifications = notificationData.filter((item) => item.type === "post" || item.type === "Like");
    const ResponseNotifications = notificationData.filter((item) => item.type === "Comments");
    const All = (options) => {
        return (
            <div className="md:py-[10px] 2sm:py-[8px] py-[6px] 2md:px-[20px] md:px-[16px] px-[12px] 2md:rounded-[18px] md:rounded-[16px] 2sm:rounded-[12px] rounded-[10px] overflow-hidden cursor-pointer" onClick={options.onClick}>
                <span className="font-[500] white-space-nowrap text-[16px] leading-[110%]">All</span>
            </div>
        );
    };
    const MyPosts = (options) => {
        return (
            <div className="md:py-[10px] 2sm:py-[8px] py-[6px] 2md:px-[20px] md:px-[16px] px-[12px] 2md:rounded-[18px] md:rounded-[16px] 2sm:rounded-[12px] rounded-[10px] overflow-hidden cursor-pointer" onClick={options.onClick}>
                <span className="font-[500] white-space-nowrap text-[16px] leading-[110%]">My Posts</span>
            </div>
        );
    };
    const Responses = (options) => {
        return (
            <div className="md:py-[10px] 2sm:py-[8px] py-[6px] 2md:px-[20px] md:px-[16px] px-[12px] 2md:rounded-[18px] md:rounded-[16px] 2sm:rounded-[12px] rounded-[10px] overflow-hidden cursor-pointer" onClick={options.onClick}>
                <span className="font-[500] white-space-nowrap text-[16px] leading-[110%]">Responses</span>
            </div>
        );
    };


    useEffect(() => {
        getNotification()
    }, [])

    const getNotification = async () => {
        try {
            const response = await autoInstance.get('/notification')
            const source = response.data
            setNotificationData(source?.data)
        } catch (error) {
            console.error(error)
        }
    }

    function generateRandomImage() {
        const randomNumber = Math.floor(Math.random() * 100) + 1;
        const gender = Math.random() < 0.5 ? 'male' : 'female';
        const imageUrl = `https://xsgames.co/randomusers/assets/avatars/${gender}/${randomNumber}.jpg`;
        return imageUrl;
    }
    return (
        <div>
            {/* Header */}
            <Navbar />
            {/* ENd Header */}
            <div className='flex items-start justify-start lg:h-[calc(100vh_-_80px)] 2sm:h-[calc(100vh_-_70px)] h-[calc(100vh_-_128px)] overflow-hidden'>
                {/* Sidebar  */}
                <Sidebar />
                {/* Sidebar End */}
                <div className='h-full bg-[#FFF]  3lg:w-[calc(100%_-_300px)] lg:w-[calc(100%_-_250px)] 2sm:w-[calc(100%_-_60px)] w-full'>
                    <p className='2sm:p-[24px] xsm:p-[20px] p-[15px] text-[24px] font-semibold leading-[110%] border-b border-[#EFF3F4]'>Notifications</p>
                    <div className='py-[16px] px-[24px]'>
                        <div className='bg-[#FFF]  w-full  notificaiton-tabs    profile-content-tabs  tabs-header-bg relative'>
                            <TabView defaultValue={0} className='relative'>
                                <TabPanel header="" headerTemplate={All}>
                                    <div className='overflow-auto  h-[calc(100vh-253px)] my-[20px]'>
                                        <div className=' 3lg:w-[740px]  2lg:w-[570px] 2md:w-[512px]'>
                                            {
                                                reverseNotifications.length === 0 ? <div className='text-[#0C1835] font-semibold text-[16px] py-[20px]'>No Notifications</div> : null
                                            }
                                            {
                                                reverseNotifications.length > 0 &&
                                                <div className=''>
                                                    {
                                                        reverseNotifications.map((data, index) => {
                                                            const { name, time, reqFor, IsPost, profileImage, message, title, payload } = data
                                                            return (
                                                                <NotificationCard key={index} data={data} updateData={getNotification} />
                                                            )
                                                        })
                                                    }
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </TabPanel>
                                <TabPanel headerTemplate={MyPosts}  >
                                    <div className='overflow-auto  h-[calc(100vh-253px)] my-[20px]'>
                                        <div className=' 3lg:w-[740px]  2lg:w-[570px] 2md:w-[512px]'>
                                            {
                                                postNotifications.length === 0 ? <div className='text-[#0C1835] font-semibold text-[16px] py-[20px]'>No Notifications</div> : null
                                            }
                                            {
                                                postNotifications.length > 0 &&
                                                <div className=''>
                                                    {
                                                        postNotifications.map((data, index) => {
                                                            const { name, time, reqFor, IsPost, profileImage, message, title, payload } = data
                                                            return (
                                                                <NotificationCard key={index} data={data} updateData={getNotification} />
                                                            )
                                                        })
                                                    }
                                                </div>
                                            }

                                        </div>
                                    </div>
                                </TabPanel>
                                <TabPanel headerTemplate={Responses}  >
                                    <div className='overflow-auto  h-[calc(100vh-253px)] my-[20px]'>
                                        <div className=' 3lg:w-[740px]  2lg:w-[570px] 2md:w-[512px]'>
                                            {
                                                ResponseNotifications?.length === 0 ? <div className='text-[#0C1835] font-semibold text-[16px] py-[20px]'>No Responses Notifications</div> : null
                                            }
                                            {
                                                ResponseNotifications?.length > 0 &&
                                                <div className=''>
                                                    {
                                                        ResponseNotifications?.map((data, index) => {
                                                            const { name, time, reqFor, IsPost, profileImage, message, title, payload } = data
                                                            return (
                                                                <NotificationCard key={index} data={data} updateData={getNotification} />
                                                            )
                                                        })
                                                    }
                                                </div>
                                            }

                                        </div>
                                    </div>
                                </TabPanel>
                            </TabView>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default NotificationPage