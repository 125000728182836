import { useState } from 'react';
import { useSelector } from 'react-redux';
import autoInstance from '../../../utils/axios';
import { selectUser } from '../../../utils/reduxtoolkit/slices/userSlice';
import Bluebtn from '../Btns/Bluebtn';
import CustomInput from '../Inputs/CustomInput';
import { icons } from '../../../helpers/images';
import Toast from '../../../helpers/Toast';

function AddEmail({ setIsOpen, updateData }) {
    const user = useSelector(selectUser);
    const [showAddEmail, setShowAddEmail] = useState(false);
    const [isError, setIsError] = useState(false);
    const [email, setEmail] = useState(null);
    const [emailError, setEmailError] = useState("");
    const handleError = () => {
        setEmailError("");
        let isError = false;
        if (email == null) {
            setEmailError("Required");
            isError = true
        }
        if (email) {
            if (!/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(email)) {
                setEmailError("Please Enter a valid Email Address");
                isError = true
            }
            if (user.email === email) {
                setEmailError("Email already exists");
                isError = true
            }
        }
        // Password validation logic
        if (isError) throw new Error("Validation Error");
    }


    const checkEmail = async () => {
        try {
            const response = await autoInstance.post('auth/check-email', {
                email: email
            });
            if (response.status === 200) {
                if (response.data.data.available === true) {
                    return false
                } else {
                    return true
                }
            }
        } catch (error) {
            console.error(error);
        }
    };
    const addSecondryEmail = async () => {
        try {
            setEmailError("");
            const emailAvailable = await checkEmail();
            if (emailAvailable) {
                setEmailError("Email already exists");
                return
            }
            handleError();
            const form = new FormData();
            form.append("first_name", user?.first_name)
            form.append("last_name", user?.last_name)
            form.append("email1", email)
            const response = await autoInstance.post("/user/update-profile", form)
            if (response.status == 200) {
                updateData()
                Toast("Your profile has been update successfully", "success")
                setIsOpen(false)
            }
        }
        catch (err) {
            console.error(err)
        }
    }
    return (
        <div className='2sm:p-[24px] xsm:p-[20px] p-[15px] space-y-[16px]'>
            <div className='flex items-center justify-between'>
                <h1 className='2lg:text-[26px] 2sm:text-[24px] md:text-[22px] text-[20px]  leading-[normal] font-[600]'>Add secondary email</h1>
                {/* <div onClick={() => { setIsOpen(false); }} className='cursor-pointer ' dangerouslySetInnerHTML={{ __html: icons.closeIcon }} /> */}
            </div>
            <CustomInput label='Email' type='text' placeholder='Enter email address' onChange={(e) => setEmail(e.target.value)} />
            {
                emailError && <div className="text-[14px] text-red-500">{emailError}</div>
            }
            <div className='flex items-center justify-end gap-[16px]'>
                <Bluebtn onClick={() => setIsOpen(false)} className="bg-[#B7BCCA]  text-[14px] font-medium py-[8px] px-[16px] w-fit text-white" Label="Cancel" />
                <Bluebtn onClick={() => addSecondryEmail()} className=" text-[14px] font-medium py-[8px] px-[16px] w-fit text-white" Label="Add Email" />
            </div>
        </div>
    )
}

export default AddEmail