import React from 'react'
import { icons } from '../../../helpers/images'
import { classNames } from 'primereact/utils'

function EmptyState({ page , className , title , icon })  {
    return (
        <div className={`h-full flex-center ${className}`}>
            <div>
                {
                    icon ? <div className='mx-auto EmptySvg' dangerouslySetInnerHTML={{ __html: icon }} /> : <div className='mx-auto EmptySvg' dangerouslySetInnerHTML={{ __html: icons.NoData }} />
                }
                <p className={`${icon ? "font-normal" : "font-medium"} text-[14px]  text-center leading-[110%] text-[#6D7486] mt-[24px] text-cneter `}>{title ? title : `You do not have any suggested ${page}.`}</p>
            </div>
        </div>
    )
}

export default EmptyState