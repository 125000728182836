import Cookies from "js-cookie";
import { useEffect } from "react";
import { Navigate, Outlet, RouterProvider, createBrowserRouter } from "react-router-dom";
import { requestPermissionAndGetToken } from "./Components/Common/Notification/Notification";
import { SocketProvider } from "./Components/Socket/WebSocketContext";
import adminRoutes from "./helpers/routes/AdminRoutes";
import commonRoutes from './helpers/routes/CommonRoutes';
import privateRoutes from './helpers/routes/private';
import publicRoutes from './helpers/routes/public';

const Auth = () => { return !!Cookies.get('g2n0Auth') && Cookies.get('g2n0Auth') !== 'undefined' && Cookies.get('g2n0Auth') !== 'null' && Cookies.get('g2n0Auth') !== '' };
// const Auth = () => true 
// const isAdmin = () => {return Cookies.get('isAdmin') };
const isAdmin = () => !!Cookies.get('g2n0AdminAuth') && Cookies.get('g2n0AdminAuth') !== 'undefined' && Cookies.get('g2n0AdminAuth') !== 'null' && Cookies.get('g2n0AdminAuth') !== '';

const PrivetRoutes = () => {
  if (Auth()) { return (<Outlet />); }
  else { return <Navigate to="/home" />; }
};

const PublicRoutes = () => {
  if (Auth()) { return <Navigate to="/" />; }
  else { return <Outlet />; }
};

const AdminRoutes = () => {
  if (isAdmin()) {
    return <Outlet />;
  } else {
    return <Navigate to="/admin" />;
  }
};



const AppRoutes = () => {
  useEffect(() => {
    const getToken = async () => {
      const deviceToken = await requestPermissionAndGetToken();
    };

    getToken();
  }, []);

  return (
    <SocketProvider>
      <RouterProvider
        router={createBrowserRouter([
          ...commonRoutes,
          { element: <PublicRoutes />, children: publicRoutes, },
          { element: <PrivetRoutes />, children: privateRoutes, },
          { element: <AdminRoutes />, children: adminRoutes }
        ])}
      />
    </SocketProvider>
  );
};

export default AppRoutes;