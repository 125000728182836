import moment from "moment";
import { Button } from "primereact/button";
import { Menu } from "primereact/menu";
import { classNames } from "primereact/utils";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { icons } from "../../helpers/images";
import Toast from "../../helpers/Toast";
import autoInstance from "../../utils/axios";
import {
  login,
  logout,
  selectUser,
} from "../../utils/reduxtoolkit/slices/userSlice";
import { useSocket } from "../Socket/WebSocketContext";
import NotificationCard from "./Cards/notificationCard";
import CollaborationsCard from "./CollaborationsCard";
import CommunitiesCard from "./CommunitiesCard";
import PostSearchCard from "./PostSearchCard";

function Navbar() {
  const menuRight = useRef(null);
  const mesMenu = useRef(null);
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const [isLoading, setIsLoading] = useState(true);
  const notificationMenu = useRef(null);
  const [notificationData, setNotificationData] = useState([]);
  const [messagesNotificationData, setMessagesNotificationData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState();
  const [searchedData, setSearchedData] = useState([]);
  const navigate = useNavigate();
  const { message: realtimeNotification } = useSocket()
  const [isFollowing, setIsFollowing] = useState(false);
  const [isFollowLoading, setIsFollowLoading] = useState(false);
  const [Following, setFollowing] = useState([]);
  const searchContainerRef = useRef(null);
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);

  useEffect(() => {
    checkProfile();
    getNotification();
    getConversation();
    getFollowings();
  }, []);

  const followingMap = {};
  Following?.forEach(id => {
    followingMap[id] = true;
  });


  // Refs for input and search dropdown
  const searchDropdownRef = useRef(null);

  const handleClickOutside = (event) => {
    if (
      searchContainerRef.current &&
      !searchContainerRef.current.contains(event.target) &&
      searchDropdownRef.current &&
      !searchDropdownRef.current.contains(event.target)
    ) {
      // Only hide the dropdown, do not clear searchTerm
      setIsDropdownVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleFocus = () => {
    setIsDropdownVisible(true); // Show dropdown on input focus
  };

  const handleSearchInputChange = (e) => {
    const rawInputValue = e.target.value;
    const inputValue = rawInputValue.trim() === "" ? "" : rawInputValue;
    setSearchTerm(inputValue);

    if (inputValue === "") {
      setSearchedData(null);
      setIsDropdownVisible(false); // Hide dropdown if input is empty
    } else {
      handleSearch(inputValue);
      setIsDropdownVisible(true); // Show dropdown when there's input
    }
  };




  const checkProfile = async () => {
    try {
      const response = await autoInstance.get("/user");
      const source = response.data;
      // if (source?.data == []) {
      //     dispatch(logout())
      //     setIsLoading(false)
      // }
      if (source?.data?.isProfileCompleted === true) {
        dispatch(
          login({
            user: source?.data,
            userType: source?.data.roleId,
          })
        );
        setIsLoading(false);
      } else {
        navigate("/profiledetails");
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const getNotification = async () => {
    try {
      const response = await autoInstance.get("/notification");
      const source = response.data;
      setNotificationData(source?.data);
    } catch (error) {
      console.error(error);
    }
  };
  const getConversation = async () => {
    try {
      const response = await autoInstance.get("/messages");
      const source = response.data;
      setMessagesNotificationData(source?.data);
    } catch (error) {
      console.error(error);
    }
  };


  const items = [
    {
      items: [
        {
          command: () => {
            navigate(`/user/in/${user?._id}`);
          },
          template: (item, options) => {
            return (
              <button
                onClick={(e) => options.onClick(e)}
                className={classNames(
                  options.className,
                  "w-full  flex align-items-center gap-[1rem] py-[1rem] px-[1.25rem] border-b border-[#EFF3F4]"
                )}
              >
                {user?.profileImage ? (
                  <div className="cursor-pointer h-[2.25rem] w-[2.25rem] rounded-[.5rem] overflow-hidden flex items-center justify-center">
                    <img
                      className="w-full h-full  object-cover"
                      src={process.env.REACT_APP_IMAGE_URL + user?.profileImage}
                      alt="Nav"
                    />
                  </div>
                ) : (
                  <div
                    className="cursor-pointer h-[2.25rem] w-[2.25rem] rounded-[.5rem] overflow-hidden flex items-center justify-center"
                    dangerouslySetInnerHTML={{ __html: icons.defaultProfile }}
                  />
                )}
                <span className="font-semibold text-[1.125rem] whitespace-nowrap w-[calc(100%-3.25rem)] text-ellipsis overflow-hidden text-left leading-[110%] text-[#0C1835]">{`${user?.first_name ?? ""
                  } ${user?.last_name ?? ""}`}</span>
              </button>
            );
          },
        },
        {
          command: () => {
            navigate(`/user/in/${user?._id}`);
          },
          template: (item, options) => {
            return (
              <div
                onClick={(e) => options.onClick(e)}
                className={classNames(
                  options.className,
                  "mx-[1.25rem] px-[.5rem] py-[1rem] flex items-center justify-start gap-[1.25rem]"
                )}
              >
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M19 21V19C19 17.9391 18.5786 16.9217 17.8284 16.1716C17.0783 15.4214 16.0609 15 15 15H9C7.93913 15 6.92172 15.4214 6.17157 16.1716C5.42143 16.9217 5 17.9391 5 19V21"
                    stroke="#6D7486"
                    strokeWidth="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M12 11C14.2091 11 16 9.20914 16 7C16 4.79086 14.2091 3 12 3C9.79086 3 8 4.79086 8 7C8 9.20914 9.79086 11 12 11Z"
                    stroke="#6D7486"
                    strokeWidth="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>

                <p className="text-[1rem] font-medium text-[#0C1835]">
                  My Profile
                </p>
              </div>
            );
          },
        },
        {
          command: () => {
            dispatch(logout());
            navigate("/login");
          },
          template: (item, options) => {
            return (
              <div
                onClick={(e) => options.onClick(e)}
                className={classNames(
                  options.className,
                  "mx-[1.25rem] px-[.5rem]  border-t border-[#EFF3F4] py-[1rem] flex items-center justify-start gap-[1.25rem]"
                )}
              >
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9 21H5C4.46957 21 3.96086 20.7893 3.58579 20.4142C3.21071 20.0391 3 19.5304 3 19V5C3 4.46957 3.21071 3.96086 3.58579 3.58579C3.96086 3.21071 4.46957 3 5 3H9M16 17L21 12M21 12L16 7M21 12H9"
                    stroke="#6D7486"
                    strokeWidth="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>

                <p className="text-[1rem] font-medium text-[#0C1835]">Logout</p>
              </div>
            );
          },
        },
      ],
    },
  ];


  function generateRandomImage() {
    const randomNumber = Math.floor(Math.random() * 100) + 1;
    const gender = Math.random() < 0.5 ? "male" : "female";
    const imageUrl = `https://xsgames.co/randomusers/assets/avatars/${gender}/${randomNumber}.jpg`;
    return imageUrl;
  }

  const lastFiveNotifications = notificationData?.slice(-5)?.reverse();
  const lastFiveMessagesNotifications = messagesNotificationData?.length > 5 ? messagesNotificationData?.slice(5) : messagesNotificationData;

  useEffect(() => {
    if (realtimeNotification) {
      getConversation()
    }
  }, [realtimeNotification])


  const Notificationitems = [
    {
      template: () => (
        <p className="2md:text-[1.25rem] md:text-[1.125rem] sm:text-[1rem] text-[.875rem] font-semibold leading-[110%] 2md:pt-[1.25rem] md:pt-[1.125rem] 2sm:pt-[1rem] pt-[.875rem] 2md:px-[1.25rem] md:px-[1.125rem] 2sm:px-[1rem] px-[.875rem] pb-[.5rem]">
          Notifications
        </p>
      )
    },
    {
      template: () => (
        lastFiveNotifications.length > 0
          ? lastFiveNotifications.map((data, index) => (
            <NotificationCard key={index} data={data} updateData={getNotification} />
          ))
          : (
            <p className="text-center p-[10px] text-[#6D7486] text-[14px] font-normal">
              No Notifications Found
            </p>
          )
      )
    },
    {
      template: () => (
        <button
          onClick={() => navigate(`/notification`)}
          className="w-full flex items-start gap-[.5rem] py-[.75rem] px-[1.25rem] text-[#2A8DDE] bg-[#EFF3F4]"
        >
          <div className="text-left">
            <p className="text-[.875rem] font-medium leading-[110%]">
              View all Notifications
            </p>
          </div>
        </button>
      )
    }
  ];


  const getFollowings = async () => {
    try {
      const response = await autoInstance.get(`/follow-request/my-followers/${user?._id}`);
      if (response.status === 200) {
        const data_ = response?.data?.data?.map(item => (item.receiverId._id))
        setFollowing(data_);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleFollow = async (SelectedId) => {
    setIsFollowLoading(true);
    try {
      const response = await autoInstance.post(`/follow-request/${SelectedId}`);
      if (response.status === 200) {
        getFollowings();
        Toast(response.data.message, 'success');
      }
    } catch (error) {
      console.error(error);
    }
    setIsFollowLoading(false);
  };
  const handleUnFollow = async (SelectedId) => {
    setIsFollowLoading(true);
    try {
      const response = await autoInstance.delete(`/follow-request/${SelectedId}`);
      if (response.status === 200) {
        getFollowings()
        Toast(response.data.message, 'success');
      }
    } catch (error) {
      console.error(error);
    }
    setIsFollowLoading(false);
  };


  const Messageitems = [
    {
      template: () => (
        <p className="2md:text-[1.25rem] md:text-[1.125rem] sm:text-[1rem] text-[.875rem] font-semibold leading-[110%] 2md:pt-[1.25rem] md:pt-[1.125rem] 2sm:pt-[1rem] pt-[.875rem] 2md:px-[1.25rem] md:px-[1.125rem] 2sm:px-[1rem] px-[.875rem] pb-[.5rem]">
          Messages
        </p>
      )
    },
    {
      template: () => (
        lastFiveMessagesNotifications.length > 0
          ? lastFiveMessagesNotifications.map((data, index) => (
            <MesNotificationCard key={index} data={data} />
          ))
          : (
            <p className="text-center p-[10px] text-[#6D7486] text-[14px] font-normal">
              You have no messages. Send your first message now.
            </p>
          )
      )
    },
    {
      template: () => (
        <button
          onClick={() => navigate(`/chat`)}
          className="w-full flex items-start gap-[.5rem] py-[.75rem] px-[1.25rem] text-[#2A8DDE] bg-[#EFF3F4]"
        >
          <div className="text-left">
            <p className="text-[.875rem] font-medium leading-[110%]">
              View all Messages
            </p>
          </div>
        </button>
      )
    }
  ];

  const handleSearch = async (search) => {
    // Check if search is empty or contains only blank spaces
    if (search === "") {
      setSearchedData(null); // Set searchedData to null or an empty object
      return;
    }

    try {
      const response = await autoInstance.get(`/post/search/all?search=${search}`);
      setSearchedData(response.data);
    } catch (error) {
      console.error('Error searching:', error);
    }
  };


  // const search     

  return (
    <div className="lg:h-[5rem] 2sm:h-[4.375rem] h-[3.75rem] w-screen pr-[1.5rem] border-b bg-white border-[#EFF3F4] flex items-center justify-between header-shadow">
      <Menu
        model={Messageitems}
        popup
        ref={mesMenu}
        id="profile_menu_right"
        className="profile_menu_right_hide  messages_menu"
        popupAlignment="right"
      />

      <Menu
        model={Notificationitems}
        popup
        ref={notificationMenu}
        id="profile_menu_right"
        className="profile_menu_right_hide  notification_menu"
        popupAlignment="right"
      />
      {/* Logo  */}
      <div className="flex items-center justify-start 2md:gap-[2rem] gap-0">
        <Link
          to="/"
          className="flex items-center justify-center 3lg:w-[18.75rem] 2md:w-[15.625rem] w-[9.375rem]"
        >
          <div
            className="3lg:w-[auto] 3lg:h-[auto] 2md:w-[8.125rem] w-[5.625rem] flex-center"
            dangerouslySetInnerHTML={{ __html: icons.logo }}
          />
        </Link>
        {/* show min-screen :  980px   */}
        <div className="relative 2sm:block hidden">
          <div
            className="flex 3lg:w-[21.875rem] lg:w-[16.875rem] w-[13.125rem] lg:py-[.75rem] py-[.625rem] lg:px-[1rem] px-[.75rem] rounded-[.5rem] border-[#EFF3F4] border items-center justify-start gap-[.625rem]"
            ref={searchContainerRef}
          >
            <div
              className="lg:w-[1.25rem] w-[1rem] flex-center"
              dangerouslySetInnerHTML={{ __html: icons.search }}
            />
            <input
              value={searchTerm}
              autoComplete="off"
              onChange={handleSearchInputChange}
              onFocus={handleFocus}
              className="leading-[1.375rem] text-[1rem] font-normal focus:outline-none w-[calc(100%-2.5rem)]"
              type="text"
              placeholder="Search by title or content"
            />
          </div>

          {isDropdownVisible &&
            (searchedData?.data?.posts?.length > 0 ||
              searchedData?.data?.community?.length > 0 ||
              searchedData?.data?.users?.length > 0 ||
              searchedData?.data?.collaboration?.length > 0) && (
              <div
                className="absolute 3lg:w-[21.875rem] w-[16.875rem] max-h-[31.25rem] search-drop z-40 overflow-auto hide-scrollbar top-[3.9375rem] bg-white border-[#EFF3F4] p-[.5rem] rounded-t-none rounded-b-[1rem] space-y-[.625rem] border"
                ref={searchDropdownRef}
              >
                {/* Collobration Search */}
                {searchedData?.data?.posts?.length > 0 && (
                  <div className="space-y-[.625rem]">
                    <p className="text-[1rem] font-medium">Posts</p>
                    {searchedData?.data?.posts?.map((data, index) => (
                      <PostSearchCard
                        key={index}
                        item={data}
                        updateContents={handleSearch}
                      />
                    ))}
                  </div>
                )}

                {searchedData?.data?.community?.length > 0 && (
                  <div className="space-y-[.625rem]">
                    <p className="text-[1rem] font-medium">Communities</p>
                    {searchedData?.data?.community?.map((data, index) => (
                      <CommunitiesCard
                        key={index}
                        item={data}
                        updateContents={handleSearch}
                      />
                    ))}
                  </div>
                )}

                {searchedData?.data?.collaboration?.length > 0 && (
                  <div className="space-y-[.625rem]">
                    <p className="text-[1rem] font-medium">Collaborations</p>
                    {searchedData?.data?.collaboration?.map((data, index) => (
                      <CollaborationsCard
                        key={index}
                        item={data}
                        updateContents={handleSearch}
                      />
                    ))}
                  </div>
                )}

                {searchedData?.data?.users?.length > 0 && (
                  <div className="space-y-[.625rem]">
                    <p className="text-[1rem] font-medium">Users</p>
                    {searchedData?.data?.users?.map((data, index) => {
                      const isFollowing = followingMap[data?._id];

                      return (
                        <div
                          key={index}
                          className="flex items-center justify-between p-[8px] w-full rounded-[12px] bg-white hover:bg-[#EAF4FC] cursor-pointer border border-[#EFF3F4]"
                        >
                          <Link
                            to={`/user/in/${data?._id}`}
                            className="flex items-center justify-start gap-[8px]"
                          >
                            {data?.profileImage ? (
                              <div className="w-[40px] h-[40px] rounded-[8px] overflow-hidden flex items-center justify-center">
                                <img
                                  src={
                                    process.env.REACT_APP_IMAGE_URL + data?.profileImage
                                  }
                                  alt={data?.first_name}
                                />
                              </div>
                            ) : (
                              <div
                                className="w-[40px] h-[40px] rounded-[8px] overflow-hidden flex items-center justify-center"
                                dangerouslySetInnerHTML={{ __html: icons.defaultProfile }}
                              />
                            )}
                            <div className="w-[calc(100%_-_68px)]">
                              <p className="text-[14px] font-medium leading-[100%] whitespace-nowrap pb-[4px]">
                                {data?.first_name ?? ''} {data?.last_name ?? ''}
                              </p>
                              <p className="text-[12px] font-normal leading-[100%]">
                                {data?.iam ?? ''}
                              </p>
                            </div>
                          </Link>
                          <div className="w-[calc()]">
                            <button
                              onClick={() =>
                                isFollowing
                                  ? handleUnFollow(data?._id)
                                  : handleFollow(data?._id)
                              }
                              className={`${isFollowing
                                  ? 'bg-[#EAF4FC] text-[#2A8DDE] text-[14px]'
                                  : 'bg-[#2A8DDE] text-[#fff] text-[14px]'
                                } py-[4px] px-[8px] rounded-[4px] text-[14px] font-medium leading-[100%]`}
                            >
                              {isFollowing ? 'Following' : 'Follow'}
                            </button>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
            )}
        </div>

      </div>
      {/* show max-screen :  980px  for Tablet & mobile  */}

      <div className="flex items-center justify-end 2md:gap-[2rem] gap-[1rem]">
        <div onClick={(event) => mesMenu.current.toggle(event)}>
          <div
            className="cursor-pointer flex-center md:w-[1.875rem] w-[1.25rem]"
            dangerouslySetInnerHTML={{ __html: icons.message }}
          />
        </div>
        <div
          className="relative"
          onClick={(event) => notificationMenu.current.toggle(event)}
        >
          {/* {
            Notificationitems.length > 0 && <div className="absolute w-[.5rem] h-[.5rem] rounded-full bg-[#30d85c] top-[.125rem] right-[.25rem] cursor-pointer"></div>
          } */}
          <div
            className="cursor-pointer flex-center md:w-[1.875rem] w-[1.25rem]"
            dangerouslySetInnerHTML={{ __html: icons.notification }}
          />
        </div>
        <Button
          className="focus-visible:outline-0 focus:shadow-none"
          onClick={(event) => menuRight.current.toggle(event)}
          aria-controls="profile_menu_right"
          aria-haspopup
        >
          {user?.profileImage ? (
            <div className="cursor-pointer md:h-[2.625rem] h-[2rem] md:w-[2.625rem] w-[2rem] rounded-[.5rem] overflow-hidden flex items-center justify-center border border-[#EFF3F4]">
              <img
                className="w-full h-full object-cover"
                src={process.env.REACT_APP_IMAGE_URL + user?.profileImage}
                alt="Nav"
              />
            </div>
          ) : (
            <div
              className="cursor-pointer md:h-[2.625rem] h-[2rem] md:w-[2.625rem] w-[2rem] rounded-[.5rem] overflow-hidden flex items-center justify-center border border-[#EFF3F4]"
              dangerouslySetInnerHTML={{ __html: icons.defaultProfile }}
            />
          )}
        </Button>
        <Menu
          model={items}
          popup
          ref={menuRight}
          id="profile_menu_right"
          popupAlignment="right"
          className="profile_menu"
        />
      </div>
    </div >
  );
}

export default Navbar;


export const MesNotificationCard = ({ key, data }) => {
  const sessionUserString = localStorage.getItem('g2n0User');
  const sessionUser = sessionUserString ? JSON.parse(sessionUserString) : null;

  let userDetail = {}
  if (sessionUser?._id !== data.toUser?._id) {
    userDetail = data.toUser
  } else if (sessionUser?._id !== data.fromUser?._id) {
    userDetail = data.fromUser
  }

  const NotificationRedirect = async () => {
    try {
      const response = await autoInstance.patch(`/notification/`, { status: "read" });
      if (response.status === 200) {
        // navigate(`/chat/${payload?.data?._id}` : ``);
      }
    }
    catch (error) {
      console.error(error);
    }
  }

  const item = userDetail;
  const lastMessage = data?.lastMessage;
  return (
    <div key={key} className="2md:mx-[20px] md:mx-[18px] sm:mx-[16px] px-[14px] md:p-[12px] p-[10px] flex items-center justify-between ">
      <Link to={`/chat/${item?._id}`} className="flex items-center justify-start 2md:gap-[16px] 2sm:gap-[14px] sm:gap-[12px] gap-[10px] w-[calc(100%-4.25rem)]">
        {
          item?.profileImage ? (
            <div className="cursor-pointer md:h-[42px] h-[32px] md:w-[42px] w-[32px] rounded-[8px] overflow-hidden flex items-center justify-center border border-[#EFF3F4]">
              <img
                className="w-full h-full object-cover"
                src={process.env.REACT_APP_IMAGE_URL + item?.profileImage}
                alt="Nav"
              />
            </div>
          ) : (
            <div
              className="cursor-pointer md:h-[42px] h-[32px] md:w-[42px] w-[32px] rounded-[8px] overflow-hidden flex items-center justify-center border border-[#EFF3F4]"
              dangerouslySetInnerHTML={{ __html: icons.defaultProfile }}
            />
          )
        }
        <div className="w-[calc(100%-4.25rem)] overflow-hidden">
          <p className="2md:text-[16px] 2sm:text-[14px] text-[14px] font-medium text-ellipsis leading-[normal]">{`${item?.first_name ?? ""} ${item?.last_name ?? ""}`}</p>
          <p className="text-[12px] font-normal leading-[140%] whitespace-nowrap text-ellipsis w-[90%] overflow-hidden">{lastMessage?.content}</p>
        </div>
      </Link>
      <div className="flex flex-col align-end">
        <p className="2md:text-[14px] text-[12px]">{moment(lastMessage?.createdAt).format("LT")}</p>
        <div className="w-[8px] h-[.5rem] self-end bg-[#2A8DDE] rounded-full border border-white mt-[.25rem]"></div>
      </div>
    </div>
  )
} 