import { getToken } from 'firebase/messaging';
import Cookies from 'js-cookie';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import Toast from '../../../helpers/Toast';
import autoInstance from '../../../utils/axios';
import { messaging } from '../../../utils/firebase';
import { login } from '../../../utils/reduxtoolkit/slices/userSlice';
import Bluebtn from '../../Common/Btns/Bluebtn';
import CustomInput from '../../Common/Inputs/CustomInput';
import AuthLayout from '../AuthLayout';
import { requestPermissionAndGetToken } from '../../Common/Notification/Notification';

function Login() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [Next, setNext] = useState(false);
  const [toggleModel, setToggleModel] = useState(false);
  const [VerifyLoad, setVerifyLoad] = useState(false);
  const [confirmEmailError, setConfirmEmailError] = useState(false);
  const [signupLoading, setSignupLoading] = useState(false);
  const [password, setPassword] = useState('');
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState('');
  const [ShowPassword, setShowPassword] = useState(false);
  const [linkdinLoading, setlinkdinLoading] = useState(false);
  const [userData, setUserData] = useState({
    email: '',
    confirmEmail: '',
  });
  const [countdown, setCountdown] = useState(0);
  const initialCountdown = 60;

  const [FcmToken, setFcmToken] = useState("");

  useEffect(() => {
    const requestPermission = async () => {
      try {
        if ((await Notification.permission) !== 'granted') {
          const permission = await Notification.requestPermission();
          if (permission === 'granted') {
            const token = await getToken(messaging, {
              vapidKey:
                'BHMq-TCQcGwVnSePu2PvNmXvzWFepLFR59z7aFo9uGYE6rEZylWm4lPFjOaLLnkkQHvoiv_SR7XHv6zdOfUrJFY'
            });
            setFcmToken(token);
            // if (chatSocket) {
            //   chatSocket.emit('storeToken', token);
            // }
          } else if (permission === 'denied') {
            console.log('Please Allow Notification Permission in your Browser');
          }
        } else {
          const token = await getToken(messaging, {
            vapidKey:
              'BHMq-TCQcGwVnSePu2PvNmXvzWFepLFR59z7aFo9uGYE6rEZylWm4lPFjOaLLnkkQHvoiv_SR7XHv6zdOfUrJFY'
          });
          setFcmToken(token);
          // if (chatSocket) {
          //   chatSocket.emit('storeToken', token);
          // }
        }
      } catch (error) {
        console.log(error);
      }
    };
    requestPermission();
  }, []);


  const startCountdown = () => {
    setCountdown(initialCountdown);
  };

  const decrementCountdown = () => {
    setCountdown(prevCountdown => prevCountdown - 1);
  };

  useEffect(() => {
    if (countdown > 0) {
      const timer = setTimeout(() => {
        decrementCountdown();
      }, 1000);

      return () => clearTimeout(timer);
    }
  }, [countdown]);

  const isResendDisabled = countdown > 0 || signupLoading;
  const formattedCountdown = `${countdown} seconds`;


  const handleError = () => {
    setEmailError("");
    setPasswordError("");
    let isError = false;
    if (!/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(userData.email)) {
      setEmailError("Please Enter a valid Email Address");
      isError = true
    }
    // Password validation logic
    if (password.length < 6) {
      setPasswordError('Password must be at least 6 characters long');
      isError = true;
    }
    if (isError) throw new Error("Validation Error");
  }
  const resetHandleError = () => {
    setEmailError("");
    let isError = false;
    if (!/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(userData.email)) {
      setEmailError("Please Enter a valid Email Address");
      isError = true
    }
    if (isError) throw new Error("Validation Error");
  }

  const handelResendPassword = async (e) => {
    e.preventDefault();
    try {
      setSignupLoading(true);
      resetHandleError();
      if (emailError === "") {
        const response = await autoInstance.post(`/auth/forgotPassword`, { email: userData.email });
        if (response.status === 200) {
          Toast("Please check your email to reset your password", "success");
          setSignupLoading(false);
          startCountdown();
        }
        else { Toast(response.data.message, "error"); }
      }

    } catch (error) {
      if (error.response?.data) { Toast(error.response.data.message, "error") }
      else { console.error(error.message) }
    } finally {
      setSignupLoading(false);
    }
  }

  const handleLoginAccount = async (e) => {
    e.preventDefault();
    try {
      setSignupLoading(true);
      handleError();
      if (emailError === "" && passwordError === "") {
        const deviceToken = await requestPermissionAndGetToken();
        const response = await autoInstance.post(`/auth/login`, { email: userData.email, password: password, device_token: deviceToken, platform: 'WEB' });
        if (response.data.status === 200) {
          if (response?.data?.data?.roleId === 3) {
            navigate("/admin");
            Toast("You're an Admin! Log in with Admin Credentials.", "success");
          } else {
            if (response.data.data.isProfileCompleted === true) {
              Toast(response.data.message, "success");
              const expirationDate = new Date();
              expirationDate.setMonth(expirationDate.getMonth() + 2);
              Cookies.set('g2n0Auth', response.data.data.token, { expires: expirationDate });
              dispatch(login({
                user: response.data.data,
                userType: response.data.data.roleId
              }));
              navigate("/");
            } else {
              const expirationDate = new Date();
              expirationDate.setMonth(expirationDate.getMonth() + 2);
              Cookies.set('g2n0Auth', response.data.data.token, { expires: expirationDate });
              dispatch(login({
                user: response.data.data,
                userType: response.data.data.roleId
              }));
              navigate("/profiledetails");
            }

          }
        }
        else { Toast(response.data.message, "error"); }
      }
    } catch (error) {
      if (error.response?.data) { Toast(error.response.data.message, "error") }
      else { console.error(error.message) }
    } finally {
      setSignupLoading(false);
    }
  }

  const onLoginStart = useCallback(() => {
    setlinkdinLoading(true);
    window.location.href = `${process.env.REACT_APP_BACKEND_URL}/api/v1/auth/linkedin-redirect`;
  }, [])



  const handleKeyPress = (event) => {
    if (event?.key === "Enter") {
      handleLoginAccount(event);
    }
  };
  const handleMailKeyPress = (event) => {
    if (event?.key === "Enter") {
      handelResendPassword(event);
    }
  };


  return (
    <AuthLayout children={
      <>
        {
          ShowPassword ?
            <div className='w-full 2lg:p-[50px] lg:p-[40px] md:p-[35px] 2sm:p-[30px] sm:p-[25px] p-[20px]'>
              <p className='2lg:text-[24px] 2sm:text-[22px] md:text-[20px] text-[18px] leading-[110%] font-[500]'>Forgot Password</p>
              <div className='2sm:pt-[30px] pt-[20px] md:w-[315px] w-[75vw] space-y-[16px]'>
                <CustomInput onKeyPress={handleMailKeyPress} label='Email' type='email' value={userData.email} name='email' onChange={(e) => setUserData({ ...userData, email: e.target.value })} placeholder='xyz@example.com' />
                {emailError.length > 0 && <p className='2sm:text-[14px] text-[12px] text-red-500'>{emailError}</p>}
                <Bluebtn onClick={(e) => signupLoading || isResendDisabled ? null : handelResendPassword(e)} className={`${signupLoading || isResendDisabled ? "bg-[#B7BCCA] " : ""} lg:p-[18px] p-[14px] w-full 2sm:text-[16px] text-[14px] font-medium leading-[100%] text-white`} Label={`${signupLoading ? 'Loading...' : isResendDisabled ? formattedCountdown : 'Reset Password'}`} />
                <div className='flex font-medium 2md:text-[16px] text-[14px] items-center justify-center gap-[8px] '>
                  <p>Back to</p><Link to='/login' onClick={() => setShowPassword(false)} className='text-[#2A8DDE] font-medium'>sign in</Link>
                </div>
              </div>
            </div>
            :
            <div className='2lg:p-[50px] lg:p-[40px] md:p-[35px] 2sm:p-[30px] sm:p-[25px] p-[20px]'>
              <div className='flex justify-start md:w-[315px] w-[75vw] ' >
                {/* // Email & Confim email */}
                <div className='w-full'>
                  <h1 className='2lg:text-[24px] 2sm:text-[22px] md:text-[20px] text-[18px] leading-[110%] font-[500] text-center'>Log in to Get2net0</h1>
                  <div className='py-[16px] w-full'>
                    {/* <LinkedIn
                      clientId={process.env.REACT_APP_LINKDIN_CLIENT_ID}
                      redirectUri={process.env.REACT_APP_LINKDIN_CALLBACK_URL}
                      onSuccess={(code) => {
                        console.log(code);
                      }}
                      onError={(error) => {
                        console.log(error);
                      }}
                    >
                      {({ linkedInLogin }) => (
                        <Bluebtn onClick={linkedInLogin} className={`lg:p-[18px] p-[14px] w-full 2sm:text-[16px] text-[14px] font-medium leading-[100%]  text-white gap-[8px] ${linkdinLoading ? "bg-[#B7BCCA] text-[#FFFFFF]" : "!bg-[#007EBB]"}`} Label={`${linkdinLoading ? 'Loading...' : ' Sign In using LinkedIn'} `} />
                      )}
                    </LinkedIn> */}
                    <Bluebtn onClick={linkdinLoading ? null : onLoginStart} className={`lg:p-[18px] p-[14px] w-full 2sm:text-[16px] text-[14px] font-medium leading-[100%]  text-white gap-[8px] ${linkdinLoading ? "bg-[#B7BCCA] text-[#FFFFFF]" : "!bg-[#007EBB]"}`} Label={`${linkdinLoading ? 'Loading...' : ' Sign In using LinkedIn'} `} />

                  </div>
                  <div className='flex items-center justify-center gap-[10px]'>
                    <div className='h-[1px] bg-[#EFF3F4] w-[40%]'></div>
                    <div className='text-[16px] font-medium '>or</div>
                    <div className='h-[1px] bg-[#EFF3F4] w-[40%]'></div>
                  </div>
                  <div className='space-y-[16px] pt-[16px]'>
                    <CustomInput label='Email' type='email' value={userData.email} name='email' onChange={(e) => setUserData({ ...userData, email: e.target.value })} placeholder='xyz@example.com' />
                    {emailError.length > 0 && <p className='2sm:text-[14px] text-[12px] text-red-500'>{emailError}</p>}
                    <CustomInput
                      label='Password'
                      type='password'
                      onKeyPress={handleKeyPress}
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      placeholder='Password' />
                    {passwordError && <p className='2sm:text-[14px] text-[12px] text-red-500'>{passwordError}</p>}
                  </div>
                  <div onClick={() => setShowPassword(true)} className='flex items-center justify-end pt-[12px] pb-[16px] cursor-pointer'>
                    <p className='2sm:text-[14px] text-[12px] font-medium leading-[110%] text-[#2A8DDE]'>Forgot password?</p>
                  </div>

                  <div className=''>
                    <Bluebtn className={`lg:p-[18px] p-[14px] w-full 2sm:text-[16px] text-[14px] font-medium leading-[100%]  text-white ${signupLoading ? "bg-[#B7BCCA] " : ""}`} Label={`${signupLoading ? 'Loading...' : 'Sign In'}`} onClick={(e) => handleLoginAccount(e)} />
                  </div>
                  <div className='flex font-medium 2md:text-[16px] text-[14px] items-center justify-center gap-[8px] pt-[16px]'>
                    <p>Don’t have an account? </p><Link to='/signup' className='text-[#2A8DDE] font-medium'> sign up</Link>
                  </div>
                </div>
              </div>
            </div>
        }
      </>
    } />
  )
}

export default Login