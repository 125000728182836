import { configureStore } from '@reduxjs/toolkit';
import { persistStore } from 'redux-persist';
import postsReducer from './slices/postSlice';
import loadingReducer from './slices/skeletonSlice';
import userReducer from './slices/userSlice';

export const store = configureStore({
    reducer: {
        loading: loadingReducer,
        user: userReducer,
        posts: postsReducer,
    },
    devTools: true,
});

export const persistor = persistStore(store, {
    blacklist: ['posts'],
});

