import React, { useState } from 'react';
import { icons } from '../../../helpers/images';
import autoInstance from '../../../utils/axios';
import Showcase from '../../Showcase';
import Bluebtn from '../Btns/Bluebtn';
import CustomInput from '../Inputs/CustomInput';

function CreateShowCase({ setIsOpen, updateData }) {
    const [contactAdmin, setContactAdmin] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [showForm, setShowForm] = useState(false)
    const [slecteTemplate, setSelectedTemplate] = useState(null)
    const [showTemplate, setShowTemplate] = useState(true)
    const [showSelectType, setShowSelectType] = useState(true)
    const [selectedFile, setSelectedFile] = useState(null);
    const [selectedLogoFile, setSelectedLogoFile] = useState(null);
    const [isPosting, setIsPosting] = useState(false)
    const [isFilePicked, setIsFilePicked] = useState(null);
    const [isFileLogoPicked, setIsFileLogoPicked] = useState(null);
    const [fileError, setFileError] = useState();
    const [logoFileError, setLogoFileError] = useState();
    const [shareUrlError, setShareUrlError] = useState("");
    const [showDataError, setShowDataError] = useState({
        title: '',
        description: '',
        shareUrl: '',
        image: '',
        logo: ''
    })

    const [showCaseData, setShowData] = useState({
        title: '',
        description: '',
        shareUrl: '',
        image: ''

    })

    const [adminData, setAdminData] = useState({
        email: '',
        description: '',
    })

    const Marbonate = {

    }

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        setIsFilePicked(file);
        if (file) {
            if (file.size > 2.9 * 1024 * 1024) { // Check if file size is greater than 2MB
                // Show error message
                setFileError("File size exceeds 2MB. Please select a smaller file.");
                // Clear selected file
                setSelectedFile(null);
                // Reset file input
                e.target.value = null;
            } else {
                // Reset error message
                setFileError(""); // or setError(null);
                const reader = new FileReader();
                reader.onload = () => {
                    setSelectedFile(reader.result);
                };
                reader.readAsDataURL(file);
            }
        }
    };

    const handleLogoFileChange = (e) => {
        const file = e.target.files[0];
        setIsFileLogoPicked(file);
        if (file) {
            if (file.size > 2.9 * 1024 * 1024) { // Check if file size is greater than 2MB
                // Show error message
                setLogoFileError("File size exceeds 2MB. Please select a smaller file.");
                // Clear selected file
                setSelectedLogoFile(null);
                // Reset file input
                e.target.value = null;
            } else {
                // Reset error message
                setLogoFileError(""); // or setLogoError(null);
                const reader = new FileReader();
                reader.onload = () => {
                    setSelectedLogoFile(reader.result);
                };
                reader.readAsDataURL(file);
            }
        }
    };


    const handleError = () => {
        setShowDataError({
            title: '',
            description: '',
            shareUrl: '',
            image: '',
            logo: ''
        })
        setShareUrlError("");
        let isError = false;

        if (showCaseData.title === '') {
            setShowDataError({ ...showDataError, title: 'Required' });
            isError = true;
        }
        if (showCaseData.description === '') {
            setShowDataError({ ...showDataError, description: 'Required' });
            isError = true;
        }
        if (isFilePicked === null) {
            setShowDataError({ ...showDataError, image: 'Required' });
            isError = true;
        }
            console.log(showCaseData.shareUrl.trim())
            console.log(!validateUrl(showCaseData.shareUrl))
            console.log(showCaseData.shareUrl.trim() === "" && !validateUrl(showCaseData.shareUrl))
        if (showCaseData.shareUrl.trim() === ""  && !validateUrl(showCaseData.shareUrl)) {
            setShareUrlError("Please enter a valid URL");
            isError = true;
        } else {
            console.log("noin")
            setShareUrlError("");
        }


        if (isError) {
            setIsPosting(false);
            setIsLoading(false);
        }
        return isError;
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        setShowDataError({
            title: '',
            description: '',
            shareUrl: '',
            image: '',
            logo: ''
        })
        setShareUrlError("");
        setIsLoading(true)
        const formData = new FormData();
        formData.append('title', showCaseData.title);
        formData.append('description', showCaseData.description);
        if (isFilePicked) {
            formData.append('uploadMedia', isFilePicked);
        }

        if (isFileLogoPicked) {
            formData.append('companyLogo', isFileLogoPicked);
        }
        formData.append('siteUrl', showCaseData.shareUrl);
        if (showDataError.description === "" || showDataError.title === "" || showDataError.image === "" || shareUrlError === "") {
            try {
                const isError = handleError();
                if (!isError) {
                    const response = await autoInstance.post('/show-case', formData)
                    const source = response.data
                    setShowData({
                        title: '',
                        description: '',
                        shareUrl: '',
                        image: ''
                    })
                    updateData()
                    setIsLoading(false)
                    setIsOpen(false)
                    setShowSelectType(true)
                    setShowForm(false)
                }
            }
            catch (error) {
                setIsLoading(false)
                console.error(error)
            }
        } else {
            setIsLoading(false)
        }
    }


    const handleAdmin = () => {
        setIsOpen(false)
    }


    const validateUrl = (url) => {
        const urlPattern = /^(https?:\/\/)?(www\.)?[a-zA-Z0-9-]+(\.[a-zA-Z]{2,})+(\/[\w@?^=%&/~+#-]*)?$/;

        return urlPattern.test(url);
    }
    const handleShareUrlChange = (e) => {
        const url = e.target.value;
        setShowData({ ...showCaseData, shareUrl: e.target.value })
        if (url.trim() !== "" && !validateUrl(url)) {
            setShareUrlError("Please enter a valid URL");
        } else {
            setShareUrlError("");
        }
    }

    const renderCoverImage = () => {
        if (isFilePicked instanceof File) {
            return <img src={URL?.createObjectURL(isFilePicked)} className='w-full h-full object-cover' alt="Cover image" />;
        } else {
            return <div className='w-full border border-[#EAF4FC] rounded-[10px] overflow-hidden relative h-full flex items-center'>

                <div className='cursor-pointer flex-center mx-auto' dangerouslySetInnerHTML={{ __html: icons.bluePlus }} />
            </div>;
        }
    };
    return (
        <div className={`relative rounded-[16px] overflow-hidden `}>
            <div className='flex items-center justify-between gap-[16px] pt-[25px] px-[25px]'>
                <h1 className='text-[21px] leading-[normal] font-[600]'>{contactAdmin ? "Contact Admin" : showForm ? 'Showcase details' : "Create showcase"}</h1>
                <div onClick={() => { setIsOpen(false); }} className='cursor-pointer ' dangerouslySetInnerHTML={{ __html: icons.closeIcon }} />
            </div>
            <div className=''>
                {/* 
                {
                    showSelectType &&
                    <div>
                        <div className="h-[250px] flex-center px-[25px] ">
                            <div className='grid 2sm:grid-cols-2 gap-[16px] '>
                                <div onClick={() => { setShowTemplate(true); setShowSelectType(false) }} className={`border border-[#EAF4FC] cursor-pointer text-center  hover:border-[#2A8DDE] transition-all duration-300 p-[16px] rounded-[16px]`}>
                                    <p className='text-[16px] font-medium leading-[110%]'>Create your Showcase</p>
                                </div>

                                <div onClick={() => { setContactAdmin(true); setShowSelectType(false) }} className={`border border-[#EAF4FC] cursor-pointer text-center hover:border-[#2A8DDE] transition-all duration-300 p-[16px] rounded-[16px]`}>
                                    <p className='text-[16px] font-medium leading-[110%]'>I need Custom help</p>
                                </div>
                            </div>

                        </div>
                    </div>
                } */}
                {
                    showTemplate &&
                    <div className='pt-[16px] pb-[25px] px-[25px]'>
                        <div className='py-[10px] max-w-[315px] mx-auto' >
                            <Showcase disableClick={true} />
                        </div>
                        <div className='flex items-center justify-center '>
                            <div>
                                <Bluebtn Label={"Create now"} onClick={() => { setShowForm(true); setShowTemplate(false) }} className={" mx-auto text-[14px] font-medium py-[8px] px-[16px] w-fit text-white"} />
                                <p className='text-[16px] font-medium leading-[110%] text-[#6D7486] pt-[10px]'>I need <span onClick={() => { setContactAdmin(true); setShowTemplate(false) }} className='text-[16px] font-medium text-[#2A8DDE] cursor-pointer'  > help</span>.</p>
                            </div>
                        </div>
                    </div>
                }
                {
                    showForm &&
                    <div>
                        <div className='space-y-[16px] pt-[16px] pb-[70px] w-[450px] max-h-[calc(100vh_-_200px)]  overflow-y-auto hide-scrollbar px-[25px]'>
                            <div>
                                <label htmlFor="Upload" className='cursor-pointer'>
                                    <label htmlFor='Upload' className='2md:text-[16px] text-[14px]  font-medium'>Upload image*</label>
                                    <input
                                        id="Upload"
                                        className='hidden'
                                        type='file'
                                        accept="image/png, image/jpeg"
                                        onChange={handleFileChange}
                                    />
                                    <div className='mt-[10px] flex items-center justify-start gap-[12px] relative'>
                                        <div className='bg-[#EAF4FC] rounded-[10px]  w-[24px] h-[24px] absolute cursor-pointer -right-[10px] -top-[10px] flex items-center justify-center overflow-hidden'>
                                            {
                                                !isFilePicked ?
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" fill="none">
                                                        <path d="M8.5 0.5H1.5C0.947715 0.5 0.5 0.947715 0.5 1.5V8.5C0.5 9.05229 0.947715 9.5 1.5 9.5H8.5C9.05229 9.5 9.5 9.05229 9.5 8.5V1.5C9.5 0.947715 9.05229 0.5 8.5 0.5Z" stroke="#6D7486" strokeLinecap="round" strokeLinejoin="round" />
                                                        <path d="M3.5 4.5C4.05228 4.5 4.5 4.05228 4.5 3.5C4.5 2.94772 4.05228 2.5 3.5 2.5C2.94772 2.5 2.5 2.94772 2.5 3.5C2.5 4.05228 2.94772 4.5 3.5 4.5Z" stroke="#6D7486" strokeLinecap="round" strokeLinejoin="round" />
                                                        <path d="M9.5 6.49985L7.957 4.95685C7.76947 4.76938 7.51516 4.66406 7.25 4.66406C6.98484 4.66406 6.73053 4.76938 6.543 4.95685L2 9.49985" stroke="#6D7486" strokeLinecap="round" strokeLinejoin="round" />
                                                    </svg> :

                                                    <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <g clip-path="url(#clip0_3882_12405)">
                                                            <path d="M6.25001 2.08333L7.91668 3.75M7.08334 1.25C7.18946 1.1246 7.32072 1.02252 7.46877 0.950285C7.61681 0.878045 7.77839 0.837224 7.9432 0.830424C8.10801 0.823623 8.27244 0.850992 8.426 0.910787C8.57957 0.970582 8.71891 1.06149 8.83514 1.17772C8.95137 1.29395 9.04193 1.43294 9.10107 1.58585C9.1602 1.73876 9.18661 1.90222 9.1786 2.06583C9.17059 2.22943 9.12834 2.38958 9.05455 2.53607C8.98076 2.68256 8.87704 2.81218 8.75001 2.91667L3.12501 8.54167L0.833344 9.16667L1.45834 6.875L7.08334 1.25Z" stroke="#0C1835" stroke-linecap="round" stroke-linejoin="round" />
                                                        </g>
                                                        <defs>
                                                            <clipPath id="clip0_3882_12405">
                                                                <rect width="10" height="10" fill="white" />
                                                            </clipPath>
                                                        </defs>
                                                    </svg>


                                            }
                                        </div>
                                        <div className='w-full h-[150px] rounded-[16px] overflow-hidden flex items-center justify-center'>
                                            {/* Display image preview if selected */}
                                            {renderCoverImage()}
                                        </div>
                                    </div>
                                </label>
                                {fileError && <p className="text-red-500 text-sm">{fileError}</p>}
                                {showDataError.image && <p className="text-red-500 text-sm">{showDataError.image}</p>}
                            </div>
                            <div>
                                <label htmlFor="LogoUpload" className='cursor-pointer'>
                                    <label htmlFor='LogoUpload' className='2md:text-[16px] text-[14px]  font-medium'>Upload Logo</label>
                                    <input
                                        type="file"
                                        id="LogoUpload"
                                        className='hidden'
                                        accept="image/png, image/jpeg"
                                        onChange={handleLogoFileChange}
                                    />
                                    <div className='mt-[10px] flex items-center justify-start gap-[12px]'>
                                        <div className='w-[60px] h-[60px] flex items-center justify-center'>
                                            {/* Display image preview if selected */}
                                            {selectedLogoFile ? <img src={selectedLogoFile} alt="Preview" className='rounded-[8px] overflow-hidden' style={{ width: '100%', height: '100%', objectFit: 'cover' }} /> : <div dangerouslySetInnerHTML={{ __html: icons.bluePlus }} />}
                                        </div>
                                    </div>
                                </label>
                                {logoFileError && <p className="text-red-500 text-sm">{logoFileError}</p>}
                            </div>
                            <CustomInput label="Title*" type="text" placeholder="Title" defultValue={showCaseData.title} onChange={(e) => { setShowData({ ...showCaseData, title: e.target.value }) }} />
                            {showDataError.title && <p className="text-red-500 text-sm">{showDataError.title}</p>}
                            <div>
                                <label htmlFor="description" className='text-[16px] font-medium '>Description*</label>
                                <textarea className='mt-[6px] w-full focus:outline-none focus-visible:outline-none p-[16px] border border-[#EAF4FC] rounded-[6px] resize-none' placeholder='Description' onChange={(e) => { setShowData({ ...showCaseData, description: e.target.value }) }} id='description' />
                                {showDataError.description && <p className="text-red-500 text-sm">{showDataError.description}</p>}
                            </div>
                            <CustomInput label="Share URL" type="text" placeholder="Share URL" defultValue={showCaseData.shareUrl} onChange={(e) => { handleShareUrlChange(e) }} />
                            {shareUrlError && <p className="text-red-500 text-sm">{shareUrlError}</p>}
                        </div>
                        <div className=' bg-white w-[100%] absolute bottom-0 left-0 py-[10px] px-[20px] flex justify-end border-t border-[#EAF4FC]'>
                            <Bluebtn Label={isLoading ? "Creating..." : "Create"} onClick={handleSubmit} className={`${isLoading ? "cursor-not-allowed !bg-[#B7BCCA]" : "cursor-pointer"}  text-[14px] font-medium py-[8px] px-[16px] w-fit text-white`} />
                        </div>

                    </div>
                }

                {
                    contactAdmin &&
                    <div className='space-y-[16px] pt-[16px] w-[450px] px-[25px] pb-[25px]'>
                        <CustomInput label={"Contact Email"} type="email" placeholder="Contact Email" defultValue={adminData?.email} onChange={(e) => { setAdminData({ ...adminData, email: e.target.value }) }} />
                        <div>
                            <label htmlFor='description' className='text-[16px] font-medium '>How can we help you?</label>
                            <div>
                                <textarea className='mt-[6px] w-full focus-visible:outline-none focus:outline-none p-[16px] border border-[#EAF4FC] rounded-[6px] resize-none' placeholder='Your custom showcase requirement' id='description' onChange={(e) => { setAdminData({ ...adminData, description: e.target.value }) }} />
                            </div>
                            <p className='text-[14px] font-medium text-[#6D7486]'>You will be contacted within 24 hours.</p>
                        </div>
                        <div className='flex justify-end'>
                            <Bluebtn Label={"Send"} onClick={handleAdmin} className={" text-[14px] font-medium py-[8px] px-[16px] w-fit text-white"} />
                        </div>
                    </div>
                }
            </div>
        </div>
    )
}

export default CreateShowCase