import axios from 'axios';
import { TabPanel, TabView } from 'primereact/tabview';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import Bluebtn from '../../Components/Common/Btns/Bluebtn';
import CustomModal from '../../Components/Common/CustomModel';
import CustomInput from '../../Components/Common/Inputs/CustomInput';
import AddEmail from '../../Components/Common/Models/AddEmail';
import { CreateExperience } from '../../Components/Common/Models/CreateExperience';
import DeleteAccount from '../../Components/Common/Models/DeleteAccount';
import UpdatePassword from '../../Components/Common/Models/UpdatePassword';
import Navbar from '../../Components/Common/Navbar';
import PageLoader from '../../Components/Common/PageLoader';
import CustomSelect from '../../Components/Common/Select/CustomSelect';
import Sidebar from '../../Components/Common/Sidebar';
import { icons, images } from '../../helpers/images';
import ProfilePhotoCropper from '../../helpers/ProfilePhotoCropper';
import Toast from '../../helpers/Toast';
import autoInstance from '../../utils/axios';
import { updateUser } from '../../utils/reduxtoolkit/slices/userSlice';


function Setting() {
    const [activeIndex, setActiveIndex] = useState(0);
    const [user, setUser] = useState()
    const [updatedUser, setUpdatedUser] = useState();
    const [pageLoaded, setPageLoaded] = useState(false);
    const [OnLoad, setOnLoad] = useState(false);
    const [updatePassword, setUpdatePassword] = useState(false);
    const [addExperience, setshowAddExperience] = useState(false);
    const [ModelData, setModelData] = useState();
    const [showDeleteExperience, setShowDeleteExperience] = useState(false)
    const [experienceDeleteId, setExperienceDeleteId] = useState(null)
    const [ModelType, setModelType] = useState();
    const [addSecondryEmail, setAddSecondryEmail] = useState(false);
    const dispatch = useDispatch();
    const [ShowNameInput, setShowNameInput] = useState(false);
    const [showCountry, setShowCountry] = useState(false);
    const [showIam, setShowIam] = useState(false);
    const [showAge, setShowAge] = useState(false);
    const [showGender, setShowGender] = useState(false);
    const [showLevelofEducation, setShowLevelofEducation] = useState(false);
    const [showIndustry, setShowIndustry] = useState(false);
    const [showInterests, setShowInterests] = useState(false);
    const [showServicesProvided, setShowServicesProvided] = useState(false);
    const [showCurrentPlan, setShowCurrentPlan] = useState(false);
    const [isCreatePostChecked, setCreatePostChecked] = useState(false)
    const [isCreateCoverChecked, setCreateCoverChecked] = useState(false)
    const [showShareUrl, setShowShareUrl] = useState(false);
    const [showLinkedin, setShowLinkedin] = useState(false);
    const [showDeleteAccount, setShowDeleteAccount] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const [countryData, setCountryData] = useState();
    const [shareUrlError, setShareUrlError] = useState("")
    const [linkedinUrlError, setLinkedinUrlError] = useState("");
    const [isDeleteing, setisDeleteing] = useState(false);
    const [otherServicesProvided, setotherServicesProvided] = useState();
    const [optionsOtherValue, setOptionsOtherValue] = useState({
        otherIndustry: false,
        otherInterest: false,
        otherLevelOfEducation: false,
        servicesProvided: false
    })
    const [otherIam, setOtherIam] = useState(false);
    const [cropperVisible, setCropperVisible] = useState(false)
    const [cropperCoverVisible, setCropperCoverVisible] = useState(false)
    const [selectedFile, setSelectedFile] = useState(null)
    const [selectedCoverFile, setSelectedCoverFile] = useState(null)
    const [otherLevelOfEducation, setOtherLevelOfEducation] = useState(false);


    const getProfile = async () => {
        try {
            setPageLoaded(true)
            const response = await autoInstance.get('/user')
            const source = response.data
            setUser(source?.data)
            setPageLoaded(false)
            dispatch(updateUser(response.data.data));
        } catch (error) {
            console.error(error)
            setPageLoaded(false)
        }
    }
    const [experience, setExperience] = useState(
        [
            {
                organization: null,
                city: null,
                country: null,
                role: null,
                from: null,
                to: null,
                workingHere: false,
                description: ""
            }
        ]
    );

    const [otherAboutUserData, setOtherAboutUserData] = useState({
        industry: '',
        areaOfInterest: '',
        servicesProvided: ''
    })


    const validateUrl = (url) => {
        const urlPattern = /^(https?:\/\/)?(www\.)?[a-zA-Z0-9-]+(\.[a-zA-Z]{2,})+(\/[\w@?^=%&/~+#-]*)?$/;

        return urlPattern.test(url);
    }


    const handleShareUrlChange = (e) => {
        const url = e.target.value;
        setUpdatedUser({ ...updatedUser, shareUrl: e.target.value })
        if (url.trim() !== "" && !validateUrl(url)) {
            setShareUrlError("Please enter a valid URL");
        } else {
            setShareUrlError("");
        }
    }

    const handleLinkedinUrlChange = (e) => {
        const url = e.target.value;
        setUpdatedUser({ ...updatedUser, linkdinUrl: e.target.value })
        if (url.trim() !== "" && !validateUrl(url)) {
            setLinkedinUrlError("Please enter a valid LinkedIn URL");
        } else {
            setLinkedinUrlError("");
        }
    }


    useEffect(() => {
        setOnLoad(true)
        Promise.all([getProfile(), getExperience(), getCountryName()])
            .then(() => {
                setOnLoad(false);
            })
            .catch(error => {
                // Handle error
                console.error("Error occurred:", error);
                setOnLoad(false); // Ensure to set onLoad false even in case of error
            });
        setOnLoad(false)
    }, [])
    useEffect(() => {
        setUpdatedUser(user)
    }, [user])
    // UseEffect & Api Area  ===========
    useEffect(() => {
        const routeToTabIndex = {
            '/setting/editprofile': 0,
            '/setting/userprofile': 1,
            '/setting/loginsecurity': 2,
            '/setting/membership': 3,
        };

        const tabIndex = routeToTabIndex[location.pathname];
        if (tabIndex !== undefined) {
            setActiveIndex(tabIndex);
        } else {
            navigate('/setting/editprofile');
        }
    }, [location.pathname, navigate]);
    const [isProfileError, setIsProfileError] = useState("")
    const [isCoverError, setCoverError] = useState("")
    const handleFileUpload = async (e) => {
        try {
            setPageLoaded(true);
            const file = e.target.files[0];
            const name = e.target.id;
            const maxSize = name === 'profileImage' ? 2.9 * 1024 * 1024 : 1.9 * 1024 * 1024; // Max file size in bytes

            // Clear previous error state
            if (name === 'profileImage') {
                setIsProfileError('');
            } else if (name === 'coverImage') {
                setCoverError('');
            }

            if (file) {
                if (file.size > maxSize) {
                    if (name === 'profileImage') {
                        setIsProfileError('File size should be less than 2MB');
                    } else if (name === 'coverImage') {
                        setCoverError('File size should be less than 1MB');
                    }
                    setPageLoaded(false);
                    return;
                }

                const reader = new FileReader();
                reader.onload = () => {
                    if (name === 'profileImage') {
                        setSelectedFile(reader.result);
                        setPageLoaded(false);
                        setCreatePostChecked(true);
                    } else if (name === 'coverImage') {
                        setSelectedCoverFile(reader.result);
                        setPageLoaded(false);
                        setCreateCoverChecked(true);
                    }
                };
                reader.readAsDataURL(file); // Ensure reader starts reading the file


                // const formData = new FormData();
                // formData.append(name, file);
                // formData.append('first_name', updatedUser?.first_name);

                // const response = await autoInstance.post('/user/update-profile', formData);
                // if (response.status === 200) {
                //     getProfile();
                //     setPageLoaded(false);
                //     Toast(response.data.message, 'success');
                // }
            } else {
                setPageLoaded(false);
            }
        } catch (error) {
            // Toast(error.message, 'error');
            setPageLoaded(false);
        }
    };


    const onevarIndustry = Array.isArray(otherAboutUserData.industry) ? otherAboutUserData.industry : (otherAboutUserData.industry ? [otherAboutUserData.industry] : []);
    const secondvarIndustry = Array.isArray(updatedUser?.industry) ? updatedUser?.industry : (updatedUser?.industry ? [updatedUser?.industry] : []);
    const mergedIndustry = [...new Set([...secondvarIndustry, ...onevarIndustry])];

    const onevarInterest = Array.isArray(otherAboutUserData.areaOfInterest) ? otherAboutUserData.areaOfInterest : (otherAboutUserData.areaOfInterest ? [otherAboutUserData.areaOfInterest] : []);
    const secondvarInterest = Array.isArray(updatedUser?.areaOfInterest) ? updatedUser?.areaOfInterest : (updatedUser?.areaOfInterest ? [updatedUser?.areaOfInterest] : []);
    const mergedInterest = [...new Set([...secondvarInterest, ...onevarInterest])];
    const otherProvided = Array.isArray(otherServicesProvided) ? otherServicesProvided : (otherServicesProvided ? [otherServicesProvided] : []);
    const selectedProvided = Array.isArray(updatedUser?.servicesProvided) ? updatedUser?.servicesProvided : (updatedUser?.servicesProvided ? [updatedUser?.servicesProvided] : []);
    const mergedServicesProvided = [...new Set([...selectedProvided, ...otherProvided])];

    const updateProfile = async (isChecked) => {
        try {
            setPageLoaded(true);
            const requestBody = {
                first_name: updatedUser.first_name,
                last_name: updatedUser.last_name,
                location: updatedUser.location,
                iam: updatedUser.iam,
                age: updatedUser.age,
                gender: updatedUser.gender,
                levelEducation: updatedUser.levelEducation,
                industry: mergedIndustry.filter(item => item !== "Other, please specify"),
                areaOfInterest: mergedInterest.filter(item => item !== "Other, please specify"),
                keepMePosted: isChecked,
                servicesProvided: mergedServicesProvided.filter(item => item !== "Other, please specify"),
                shareUrl: updatedUser.shareUrl,
                linkdinUrl: updatedUser.linkdinUrl
            };

            const response = await autoInstance.post('/user/update-profile', requestBody);
            if (response.status === 200) {
                getProfile();
                setPageLoaded(false);
                setOptionsOtherValue({
                    otherIndustry: false,
                    otherInterest: false,
                    otherLevelOfEducation: false,
                    servicesProvided: false
                })
                setOtherIam(false);
                setOtherLevelOfEducation(false);
                setotherServicesProvided()
                setOtherAboutUserData({
                    industry: '',
                    areaOfInterest: '',
                    servicesProvided: ''
                })
                Toast(response.data.message, 'success');
            }
        } catch (error) {
            setPageLoaded(false);
            Toast(error.message, 'error');
            console.error(error);
        }
    }

    const getCountryName = useCallback(async () => {
        try {
            const response = await axios.get('https://countriesnow.space/api/v0.1/countries');
            if (response.status === 200) {
                const countryOptions = response.data.data.map(country => ({
                    label: country.country,
                    value: country.country,
                }));
                // setLocationOptions(countryOptions);
                setCountryData(countryOptions);
            } else {
                Toast(response.data.message, 'error');
            }
        } catch (error) {
            Toast(error.message, 'error');
        }
    }, []);

    const handleTabChange = (e) => {
        setActiveIndex(e.index);
        switch (e.index) {
            case 0:
                navigate('/setting/editprofile');
                break;
            case 1:
                navigate('/setting/userprofile');
                break;
            case 2:
                navigate('/setting/loginsecurity');
                break;
            case 3:
                navigate('/setting/membership');
                break;
            // Add more cases as needed
            default:
                break;
        }
    };
    const prifleTab = (options) => {
        return (
            <div className='flex items-center justify-start gap-[4px] p-[12px]' onClick={options.onClick} style={{ cursor: 'pointer' }}>
                <p className="font-[500] text-[#6D7486] whitespace-nowrap text-[14px] leading-[110%] "  >{user?.roleId === 1 ? 'Personal' : 'Company'} Info</p>
            </div>

        )
    };
    const UserProfileTab = (options) => {
        return (
            <div className='flex items-center justify-start gap-[4px] p-[12px]' onClick={options.onClick} style={{ cursor: 'pointer' }}>
                <p className="font-[500] text-[#6D7486] whitespace-nowrap text-[14px] leading-[110%] "  >{user?.roleId === 1 ? 'User' : 'Company'} Profile</p>
            </div>

        )
    };
    const LoginSecurityTab = (options) => {
        return (
            <div className='flex items-center justify-start gap-[4px] p-[12px]' onClick={options.onClick} style={{ cursor: 'pointer' }}>
                <p className="font-[500] text-[#6D7486] whitespace-nowrap text-[14px] leading-[110%] "  >Login & Security</p>
            </div>

        )
    };
    const MembershipPlanTab = (options) => {
        return (
            <div className='flex items-center justify-start gap-[4px] p-[12px]' onClick={options.onClick} style={{ cursor: 'pointer' }}>
                <p className="font-[500] text-[#6D7486] whitespace-nowrap text-[14px] leading-[110%] "  >Membership Plan</p>
            </div>

        )
    };

    const IamOptions = [
        { label: 'an Expert', value: 'an Expert' },
        { label: 'a Solution Seeker', value: 'a Solution Seeker' },
        { label: 'a Solution Provider', value: 'a Solution Provider' },
        { label: 'Other', value: 'Other' },
    ];
    const AgeOptions = [
        { value: '<18', label: '<18' },
        { value: '18-25', label: '18-25' },
        { value: '26-35', label: '26-35' },
        { value: '36-45', label: '36-45' },
        { value: '46-60', label: '46-60' },
        { value: '61+', label: '61+' },
    ];

    const GenderOptions = [
        { value: 'Male', label: 'Male' },
        { value: 'Female', label: 'Female' },
        { value: 'Prefer not to disclose', label: 'Prefer not to disclose' },
    ];
    const EducationLevelOptions = [
        { value: 'No Formal Education', label: 'No Formal Education' },
        { value: 'Elementary School', label: 'Elementary School' },
        { value: 'High School or Equivalent', label: 'High School or Equivalent' },
        { value: 'Associate’s Degree', label: 'Associate’s Degree' },
        { value: 'Bachelor’s Degree', label: 'Bachelor’s Degree' },
        { value: 'Master’s Degree', label: 'Master’s Degree' },
        { value: 'Doctoral Degree', label: 'Doctoral Degree' },
        { value: 'Other', label: 'Other' },
    ];

    const InterestOptions = [
        { value: 'Climate Science', label: 'Climate Science' },
        { value: 'Ecology & Bio-diversity', label: 'Ecology & Bio-diversity' },
        { value: 'Renewable & Alternate Energy Sources', label: 'Renewable & Alternate Energy Sources' },
        { value: 'Carbon Capture and Storage or Repurposing', label: 'Carbon Capture and Storage or Repurposing' },
        { value: 'GHG Emissions Control', label: 'GHG Emissions Control' },
        { value: 'Policy & Regulation', label: 'Policy & Regulation' },
        { value: 'Mitigation related Project Funding', label: 'Mitigation related Project Funding' },
        { value: 'Monitoring and Data Analysis', label: 'Monitoring and Data Analysis' },
        { value: 'Networking for Business Development', label: 'Networking for Business Development' },
        { value: 'Access to Affordable Technologies', label: 'Access to Affordable Technologies' },
        { value: 'Partnerships and Collaboration', label: 'Partnerships and Collaboration' },
        { value: 'Other, please specify', label: 'Other, please specify' },
    ];
    const IndustryOptions = [
        { value: 'Agriculture', label: 'Agriculture' },
        { value: 'Aerospace', label: 'Aerospace' },
        { value: 'Automotive', label: 'Automotive' },
        { value: 'Banking and Finance', label: 'Banking and Finance' },
        { value: 'Biotech', label: 'Biotech' },
        { value: 'Climate Technologies', label: 'Climate Technologies' },
        { value: 'Construction', label: 'Construction' },
        { value: 'Education', label: 'Education' },
        { value: 'Energy (Oil, Gas)', label: 'Energy (Oil, Gas)' },
        { value: 'Energy (Renewable)', label: 'Energy (Renewable)' },
        { value: 'Entertainment', label: 'Entertainment' },
        { value: 'Food and Beverage', label: 'Food and Beverage' },
        { value: 'Healthcare', label: 'Healthcare' },
        { value: 'Hospitality (Hotels, Restaurants)', label: 'Hospitality (Hotels, Restaurants)' },
        { value: 'Information Technology (IT)', label: 'Information Technology (IT)' },
        { value: 'Insurance', label: 'Insurance' },
        { value: 'Manufacturing', label: 'Manufacturing' },
        { value: 'Media and Communications', label: 'Media and Communications' },
        { value: 'Pharmaceuticals', label: 'Pharmaceuticals' },
        { value: 'Real Estate', label: 'Real Estate' },
        { value: 'Retail', label: 'Retail' },
        { value: 'Telecommunications', label: 'Telecommunications' },
        { value: 'Transportation and Logistics', label: 'Transportation and Logistics' },
        { value: 'Utilities', label: 'Utilities' },
        { value: 'Other, please specify', label: 'Other, please specify' },
    ];
    const servicesProvidedOptions = [
        { value: 'Climate Risk Assessment and Management', label: 'Climate Risk Assessment and Management' },
        { value: 'Climate Change Mitigation Solutions', label: 'Climate Change Mitigation Solutions' },
        { value: 'Monitoring, Reporting and Analysis', label: 'Monitoring, Reporting and Analysis' },
        { value: 'Carbon Footprint Measurement and Reduction', label: 'Carbon Footprint Measurement and Reduction' },
        { value: 'Sustainable Infrastructure and Design', label: 'Sustainable Infrastructure and Design' },
        { value: 'Renewable Energy Solutions', label: 'Renewable Energy Solutions' },
        { value: 'Climate Adaptation Planning', label: 'Climate Adaptation Planning' },
        { value: 'Regulatory Compliance', label: 'Regulatory Compliance' },
        { value: 'Sustainable Supply Chain Management', label: 'Sustainable Supply Chain Management' },
        { value: 'Climate Education', label: 'Climate Education' },
        { value: 'Data and Technology Solutions', label: 'Data and Technology Solutions' },
        { value: 'Environmental Consulting', label: 'Environmental Consulting' },
        { value: 'Funding for Climate Projects', label: 'Funding for Climate Projects' },
        { value: 'Other, please specify', label: 'Other, please specify' },
    ]
    const plans = [
        { value: 'free', label: 'free' },
        { value: 'basic', label: 'basic' },
        { value: 'premium', label: 'premium' },
    ];

    const renderProfileImage = () => {
        if (updatedUser.profileImage instanceof File) {
            return <img src={URL.createObjectURL(updatedUser.profileImage)} alt="Profile image" className='w-full h-full' />;
        } else if (user?.profileImage) {
            return <img src={process.env.REACT_APP_IMAGE_URL + user.profileImage} alt="Profile image" className='object-cover w-full h-full' />;
        } else {
            return <div dangerouslySetInnerHTML={{ __html: icons.defaultProfile }} />;
        }
    };

    const renderCoverImage = () => {
        if (updatedUser.coverImage instanceof File) {
            return <img src={URL?.createObjectURL(updatedUser.coverImage)} className='w-full h-full object-cover' alt="Cover image" />;
        } else if (user?.coverImage) {
            return <img src={process.env.REACT_APP_IMAGE_URL + user.coverImage} className='w-full h-full object-cover' alt="Cover image" />;
        } else {
            return <img src={images.banner} alt="Default banner" />;
        }
    };

    const getExperience = async () => {
        try {
            const response = await autoInstance.get('/user-experience/get-user-experience');
            if (response.status === 200) {
                setExperience(response.data.data);
            }
        } catch (error) {
            console.error(error);
        }
    }

    const removeExperience = async (indexToRemove) => {
        try {
            const response = await autoInstance.delete(`/user-experience/${indexToRemove}`);
            if (response.status === 200) {
                setExperience(prevExperience => prevExperience.filter((_, index) => index !== indexToRemove));
                Toast(response.data.message, 'success');
            }
        } catch (error) {
            console.error(error);
        }
    }

    const handleDeleteExperience = async (_id) => {
        try {
            setisDeleteing(true);
            const response = await autoInstance.delete(`/user-experience/${_id}`);
            if (response.status === 200) {
                getExperience();
                setisDeleteing(false);
                Toast(response.data.message, 'success');
            }
        } catch (error) {
            setisDeleteing(false);
        }
    }



    // useEffect(() => {
    //     window.addEventListener('load', handlePageLoad);
    //     return () => window.removeEventListener('load', handlePageLoad);
    // }, []);

    // if(!pageLoaded){
    //     return <PageLoader />
    // }
    const removeEmailSecondary = async () => {
        try {
            const formData = new FormData();
            formData.append('first_name', updatedUser?.first_name);
            formData.append('email1', null);
            const response = await autoInstance.post(`user/update-profile`, formData);
            if (response.status === 200) {
                getProfile();
                setPageLoaded(false);
                Toast(response.data.message, 'success');
            }
        } catch (error) {
            console.error(error);
        }
    }

    const MakePrimaryEmail = async () => {
        try {
            const formData = new FormData();
            formData.append('first_name', updatedUser?.first_name);
            formData.append('email1', updatedUser?.email);
            formData.append('email', updatedUser?.email1);
            const response = await autoInstance.post(`user/update-profile`, formData);
            if (response.status === 200) {
                getProfile();
                setPageLoaded(false);
                Toast(response.data.message, 'success');
            }
        } catch (error) {
            console.error(error);
        }
    }

    if (OnLoad || isDeleteing) {
        return <PageLoader />
    }

    // useEffect(() => {
    //     const foundOption = IamOptions.find(option => option.value === updatedUser?.iam);
    //     const foundLevelOfOption = EducationLevelOptions.find(option => option.value === updatedUser?.iam);
    //     console.log("working", updatedUser)
    //     if (updatedUser?.servicesProvided && updatedUser?.servicesProvided?.includes("Other, please specify")) {
    //         setOptionsOtherValue(prevState => ({
    //             ...prevState,
    //             servicesProvided: true,
    //         }));
    //     } else if (updatedUser?.servicesProvided && !updatedUser.servicesProvided.includes("Other, please specify")) {
    //         setOptionsOtherValue(prevState => ({
    //             ...prevState,
    //             servicesProvided: false,
    //         }));
    //     }
    //     if (updatedUser?.industry && updatedUser?.industry?.includes("Other, please specify")) {
    //         setOptionsOtherValue(prevState => ({
    //             ...prevState,
    //             otherIndustry: true,
    //         }));
    //     } else if (updatedUser?.industry && !updatedUser.industry.includes("Other, please specify")) {
    //         setOptionsOtherValue(prevState => ({
    //             ...prevState,
    //             otherIndustry: false,
    //         }));
    //     }
    //     if (updatedUser?.areaOfInterest && updatedUser?.areaOfInterest?.includes("Other, please specify")) {
    //         setOptionsOtherValue(prevState => ({
    //             ...prevState,
    //             otherInterest: true,
    //         }));
    //     } else if (updatedUser?.areaOfInterest && !updatedUser?.areaOfInterest?.includes("Other, please specify")) {
    //         setOptionsOtherValue(prevState => ({
    //             ...prevState,
    //             otherInterest: false,
    //         }));
    //     }

    //     if (updatedUser?.iam && updatedUser?.iam === "Other") {
    //         setOtherIam(true);
    //     } else if (foundOption) {
    //         setOtherIam(false);
    //     }
    //     if (updatedUser?.levelEducation && updatedUser?.levelEducation === "Other") {
    //         setOtherLevelOfEducation(true);
    //     } else if (foundLevelOfOption) {
    //         setOtherLevelOfEducation(false);
    //     }
    // }, [updatedUser])

    return (
        <div>
            {pageLoaded &&
                <PageLoader />
            }
            {/* Header */}
            <Navbar />
            {/* ENd Header */}
            {
                updatedUser &&
                <div className='flex items-start justify-start lg:h-[calc(100vh_-_80px)] 2sm:h-[calc(100vh_-_70px)] h-[calc(100vh_-_128px)] overflow-hidden'>
                    {/* Sidebar  */}
                    <Sidebar />
                    {/* Sidebar End */}
                    <div className='h-full bg-[#F3F5F7] 3lg:w-[calc(100%_-_300px)] lg:w-[calc(100%_-_250px)] 2sm:w-[calc(100%_-_68px)] w-full'>
                        <div className='bg-white w-full'>
                            <div className=' '>
                                <div className='2sm:pt-[24px] pt-[20px] 2sm:pb-[12px] pb-[10px] 2sm:pl-[24px] pl-[20px] 2md:pr-[0px] 2sm:pr-[24px] pr-[20px]  flex items-center justify-between '>
                                    <p className='text-[24px] font-[600] leading-[110%]'>Settings</p>
                                </div>

                            </div>
                            {/* Tabs  */}
                            <div className=' bg-white setting-tabs '>
                                <div className=''>
                                    <TabView activeIndex={activeIndex} onTabChange={handleTabChange} className=''>
                                        {/* Activity Tab */}
                                        <TabPanel headerTemplate={prifleTab} className=''>
                                            <div className='2sm:h-[calc(100vh_-_180px)] h-[calc(100vh_-_225px)] overflow-auto hide-scrollbar 2sm:p-[24px] xsm:p-[20px] p-[15px]'>
                                                <div className='bg-[#F3F5F7] 4lg:w-[660px] 2lg:w-[570px] 2md:w-[420px] w-full  space-y-[8px] '>
                                                    <div className='bg-[#fff] w-full rounded-[12px]  space-y-[8px] 2sm:p-[24px] xsm:p-[20px] p-[15px]'>
                                                        <p className='text-[18px] font-[600] text-[#0C1835] leading-[110%]'>{user?.roleId === 1 ? 'Personal' : 'Company'} Info</p>
                                                        {/* Profile Image Upload */}
                                                        <div className=' py-[16px]'>
                                                            <div className='flex items-start justify-between  '>
                                                                <label htmlFor='profileImage' className='relative w-fit cursor-pointer rounded-[8px] border border-[#EAF4FC]'>
                                                                    <div className='bg-[#EAF4FC] rounded-[10px] w-[24px] h-[24px] absolute cursor-pointer -right-[10px] -top-[10px] flex items-center justify-center'>
                                                                        {
                                                                            !updatedUser?.profileImage ?
                                                                                <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" fill="none">
                                                                                    <path d="M8.5 0.5H1.5C0.947715 0.5 0.5 0.947715 0.5 1.5V8.5C0.5 9.05229 0.947715 9.5 1.5 9.5H8.5C9.05229 9.5 9.5 9.05229 9.5 8.5V1.5C9.5 0.947715 9.05229 0.5 8.5 0.5Z" stroke="#6D7486" strokeLinecap="round" strokeLinejoin="round" />
                                                                                    <path d="M3.5 4.5C4.05228 4.5 4.5 4.05228 4.5 3.5C4.5 2.94772 4.05228 2.5 3.5 2.5C2.94772 2.5 2.5 2.94772 2.5 3.5C2.5 4.05228 2.94772 4.5 3.5 4.5Z" stroke="#6D7486" strokeLinecap="round" strokeLinejoin="round" />
                                                                                    <path d="M9.5 6.49985L7.957 4.95685C7.76947 4.76938 7.51516 4.66406 7.25 4.66406C6.98484 4.66406 6.73053 4.76938 6.543 4.95685L2 9.49985" stroke="#6D7486" strokeLinecap="round" strokeLinejoin="round" />
                                                                                </svg> :
                                                                                <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                    <g clip-path="url(#clip0_3882_12405)">
                                                                                        <path d="M6.25001 2.08333L7.91668 3.75M7.08334 1.25C7.18946 1.1246 7.32072 1.02252 7.46877 0.950285C7.61681 0.878045 7.77839 0.837224 7.9432 0.830424C8.10801 0.823623 8.27244 0.850992 8.426 0.910787C8.57957 0.970582 8.71891 1.06149 8.83514 1.17772C8.95137 1.29395 9.04193 1.43294 9.10107 1.58585C9.1602 1.73876 9.18661 1.90222 9.1786 2.06583C9.17059 2.22943 9.12834 2.38958 9.05455 2.53607C8.98076 2.68256 8.87704 2.81218 8.75001 2.91667L3.12501 8.54167L0.833344 9.16667L1.45834 6.875L7.08334 1.25Z" stroke="#0C1835" stroke-linecap="round" stroke-linejoin="round" />
                                                                                    </g>
                                                                                    <defs>
                                                                                        <clipPath id="clip0_3882_12405">
                                                                                            <rect width="10" height="10" fill="white" />
                                                                                        </clipPath>
                                                                                    </defs>
                                                                                </svg>
                                                                        }
                                                                    </div>
                                                                    <div className='cursor-pointer h-[100px] w-[100px] rounded-[8px] overflow-hidden flex items-center justify-center'>
                                                                        {renderProfileImage()}
                                                                    </div>
                                                                </label>
                                                                <input type="file" accept="image/png, image/jpeg" id='profileImage' onChange={handleFileUpload} className='hidden' />
                                                            </div>
                                                            <div className='flex items-center justify-start gap-[10px]'>

                                                                {/* <label htmlFor="name" className='2md:text-[16px] text-[14px]  font-medium'>Profile Image</label> */}
                                                                {/* <p className='text-[14px] font-medium text-[#6D7486]'>Max. 2MB </p> */}
                                                                {isProfileError && <p className='text-[12px] text-[#F44336] font-medium'>{isProfileError}</p>}
                                                            </div>
                                                        </div>
                                                        <div className='space-y-[16px]'>
                                                            {/* Name */}
                                                            <div className='pb-[16px] border-b border-[#EFF3F4]'>
                                                                <div className='flex items-center justify-between'>
                                                                    <label htmlFor="name" className='2md:text-[16px] text-[14px]  font-medium'>Name</label>
                                                                    {!ShowNameInput ?
                                                                        <p onClick={() => setShowNameInput(!ShowNameInput)} className='text-[12px] cursor-pointer font-medium text-[#2A8DDE]'>Edit</p>
                                                                        :
                                                                        <p onClick={() => setShowNameInput(!ShowNameInput)} className='text-[12px] cursor-pointer font-medium text-[#6D7486]'>Cancel</p>
                                                                    }
                                                                </div>
                                                                {!ShowNameInput ?
                                                                    <p className='text-[14px] font-medium pt-[12px] text-[#6D7486]'>{`${user?.first_name ?? ""} ${user?.last_name ?? ""}`}</p>
                                                                    :
                                                                    <div>
                                                                        <div className={` ${user?.roleId === 1 ? "grid 2sm:grid-cols-2" : " "} gap-[16px] `}>
                                                                            <CustomInput onChange={(e) => { setUpdatedUser({ ...updatedUser, first_name: e.target.value }) }} className="max-w-[314px]" type="text" defultValue={user?.first_name} placeholder={`${user?.roleId === 1 ? "First Name" : "Company Name"}`} />
                                                                            {
                                                                                user?.roleId === 1 &&
                                                                                <CustomInput onChange={(e) => { setUpdatedUser({ ...updatedUser, last_name: e.target.value }) }} className="max-w-[314px]" type="text" defultValue={user?.last_name ?? ""} placeholder="Last Name" />
                                                                            }
                                                                        </div>
                                                                        <Bluebtn className="!w-fit mt-[16px] text-[14px] font-medium leading-[100%] py-[8px] px-[16px] rounded-[6px] text-white" Label="Update" onClick={() => { updateProfile(); setShowNameInput(!ShowNameInput) }} />
                                                                    </div>
                                                                }
                                                            </div>
                                                            {/* Country */}
                                                            <div className='pb-[16px] border-b border-[#EFF3F4]'>
                                                                <div className='flex items-center justify-between'>
                                                                    <label htmlFor="name" className='2md:text-[16px] text-[14px]  font-medium'>Country</label>
                                                                    {
                                                                        !showCountry ?
                                                                            <p onClick={() => { setShowCountry(!showCountry); getCountryName() }} className='text-[12px] cursor-pointer font-medium text-[#2A8DDE]'>Edit</p>
                                                                            :
                                                                            <p onClick={() => setShowCountry(!showCountry)} className='text-[12px] cursor-pointer font-medium text-[#6D7486]'>Cancel</p>
                                                                    }
                                                                </div>
                                                                {
                                                                    !showCountry ?
                                                                        <p className='text-[14px] font-medium pt-[12px] text-[#6D7486]'>{user?.location}</p>
                                                                        :
                                                                        <div>
                                                                            <CustomSelect
                                                                                className="max-w-[314px]"
                                                                                value={{ label: user?.location, value: user?.location }}
                                                                                options={countryData}
                                                                                onChange={(selected) => { setUpdatedUser({ ...updatedUser, location: selected?.value }) }}
                                                                                placeholder="Enter Country"
                                                                                isClearable // Ensure this prop is passed if not default
                                                                            />
                                                                            <Bluebtn className="!w-fit mt-[16px] text-[14px] font-medium leading-[100%] py-[8px] px-[16px] rounded-[6px] text-white" Label="Update" onClick={() => { updateProfile(); setShowCountry(!showCountry) }} />
                                                                        </div>
                                                                }
                                                            </div>
                                                            {/* I am */}

                                                            <div className='pb-[16px] border-b border-[#EFF3F4]'>
                                                                <div className='flex items-center justify-between'>
                                                                    <label htmlFor="name" className='2md:text-[16px] text-[14px]  font-medium'>{user?.roleId === 1 ? 'I am' : 'We are'}</label>
                                                                    {!showIam ?
                                                                        <p onClick={() => setShowIam(!showIam)} className='text-[12px] cursor-pointer font-medium text-[#2A8DDE]'>Edit</p>
                                                                        :
                                                                        <p onClick={() => setShowIam(!showIam)} className='text-[12px] cursor-pointer font-medium text-[#6D7486]'>Cancel</p>
                                                                    }
                                                                </div>
                                                                {
                                                                    !showIam ?
                                                                        <p className='text-[14px] font-medium pt-[12px] text-[#6D7486]'>{user?.iam}</p>
                                                                        :
                                                                        <div className='pt-[16px]'>
                                                                            <CustomSelect
                                                                                options={IamOptions}
                                                                                className="max-w-[314px]"
                                                                                onChange={(selected) => { setUpdatedUser({ ...updatedUser, iam: selected?.value }) }}
                                                                                value={{ label: user?.iam, value: user?.iam }}
                                                                                placeholder="Select I am"
                                                                            />
                                                                            {
                                                                                (updatedUser?.iam === "Other" || !IamOptions.map(a => a.value).includes(updatedUser?.iam) ) && (
                                                                                    <CustomInput onChange={(e) => { setUpdatedUser({ ...updatedUser, iam: e.target.value }) }} className="max-w-[314px]" type="text" placeholder={`Please specify*`} />
                                                                                )
                                                                            }
                                                                            <Bluebtn className="!w-fit mt-[16px] text-[14px] font-medium leading-[100%] py-[8px] px-[16px] rounded-[6px] text-white" Label="Update" onClick={() => { updateProfile(); setShowIam(!showIam) }} />
                                                                        </div>
                                                                }
                                                            </div>
                                                            {/* Age */}
                                                            {
                                                                user?.roleId === 1 &&
                                                                <div className='pb-[16px] border-b border-[#EFF3F4]'>
                                                                    <div className='flex items-center justify-between'>
                                                                        <label htmlFor="name" className='2md:text-[16px] text-[14px]  font-medium'>Age</label>
                                                                        {
                                                                            !showAge ?
                                                                                <p onClick={() => setShowAge(!showAge)} className='text-[12px] cursor-pointer font-medium text-[#2A8DDE]'>Edit</p>
                                                                                :
                                                                                <p onClick={() => setShowAge(!showAge)} className='text-[12px] cursor-pointer font-medium text-[#6D7486]'>Cancel</p>
                                                                        }
                                                                    </div>
                                                                    {
                                                                        !showAge ?
                                                                            <p className='text-[14px] font-medium pt-[12px] text-[#6D7486]'>{user?.age}</p>
                                                                            :
                                                                            <div className='pt-[16px]'>
                                                                                <CustomSelect
                                                                                    options={AgeOptions}
                                                                                    className="max-w-[314px]"
                                                                                    onChange={(selected) => { setUpdatedUser({ ...updatedUser, age: selected.value }) }}
                                                                                    value={{ label: user?.age, value: user?.age }}
                                                                                    placeholder="Select Age"
                                                                                />
                                                                                <Bluebtn className="!w-fit mt-[16px] text-[14px] font-medium leading-[100%] py-[8px] px-[16px] rounded-[6px] text-white" Label="Update" onClick={() => { updateProfile(); setShowAge(!showAge) }} />
                                                                            </div>
                                                                    }
                                                                </div>
                                                            }
                                                            {/* Gender */}
                                                            {
                                                                user?.roleId === 1 &&
                                                                <div className='pb-[16px] border-b border-[#EFF3F4]'>
                                                                    <div className='flex items-center justify-between'>
                                                                        <label htmlFor="name" className='2md:text-[16px] text-[14px]  font-medium'>Gender</label>
                                                                        {
                                                                            !showGender ?
                                                                                <p onClick={() => setShowGender(!showGender)} className='text-[12px] cursor-pointer font-medium text-[#2A8DDE]'>Edit</p>
                                                                                :
                                                                                <p onClick={() => setShowGender(!showGender)} className='text-[12px] cursor-pointer font-medium text-[#6D7486]'>Cancel</p>
                                                                        }
                                                                    </div>
                                                                    {
                                                                        !showGender ?
                                                                            <p className='text-[14px] font-medium pt-[12px] text-[#6D7486]'>{user?.gender}</p>
                                                                            :
                                                                            <div className='pt-[16px]'>
                                                                                <CustomSelect options={GenderOptions} className="max-w-[314px]" type="text" value={{ label: user?.gender, value: user?.gender }} placeholder="Select Gender" onChange={(selected) => { setUpdatedUser({ ...updatedUser, gender: selected.value }) }} />
                                                                                <Bluebtn className="!w-fit mt-[16px] text-[14px] font-medium leading-[100%] py-[8px] px-[16px] rounded-[6px] text-white" Label="Update" onClick={() => { updateProfile(); setShowGender(!showGender) }} />
                                                                            </div>
                                                                    }
                                                                </div>
                                                            }
                                                            {/* Areas of Interest */}

                                                            <div className='pb-[16px] border-b border-[#EFF3F4]'>
                                                                <div className='flex items-center justify-between'>
                                                                    <label htmlFor="name" className='2md:text-[16px] text-[14px]  font-medium'>Areas of Interest <span className='text-[#6D7486] text-[12px] font-medium'>(Select one or more)</span> </label>
                                                                    {
                                                                        !showInterests ?
                                                                            <p onClick={() => setShowInterests(!showInterests)} className='text-[12px] cursor-pointer font-medium text-[#2A8DDE]'>Edit</p>
                                                                            :
                                                                            <p onClick={() => setShowInterests(!showInterests)} className='text-[12px] cursor-pointer font-medium text-[#6D7486]'>Cancel</p>
                                                                    }

                                                                </div>
                                                                {
                                                                    !showInterests ?
                                                                        <p className='text-[14px] font-medium pt-[12px] text-[#6D7486]'>
                                                                            {user?.areaOfInterest && user?.areaOfInterest.join(',  ')}
                                                                        </p>
                                                                        :
                                                                        <div>
                                                                            <div className='space-y-[10px]'>
                                                                                <CustomSelect
                                                                                    value={user?.areaOfInterest.map(area => ({ label: area, value: area }))}
                                                                                    options={InterestOptions}
                                                                                    className="max-w-[314px]"
                                                                                    placeholder="Areas of Interest"
                                                                                    isMulti={true}
                                                                                    onChange={(selected) => {
                                                                                        const selectedValues = selected ? selected.map(option => option.value) : [];
                                                                                        setUpdatedUser({ ...updatedUser, areaOfInterest: selectedValues })
                                                                                    }}
                                                                                />
                                                                                {
                                                                                    (updatedUser?.areaOfInterest?.includes("Other, please specify")) && (
                                                                                        <CustomInput onChange={(e) => {
                                                                                            setOtherAboutUserData(prev => {
                                                                                                return { ...prev, areaOfInterest: e.target.value };
                                                                                            });
                                                                                        }} className="max-w-[314px]" type="text" placeholder={`Please specify*`} />
                                                                                    )
                                                                                }

                                                                            </div>
                                                                            <Bluebtn className="!w-fit mt-[16px] text-[14px] font-medium leading-[100%] py-[8px] px-[16px] rounded-[6px] text-white" Label="Update" onClick={() => { updateProfile(); setShowInterests(!showInterests) }} />
                                                                        </div>
                                                                }
                                                            </div>
                                                            {/* Cover Image  */}
                                                            <div>
                                                                <div className='flex items-center justify-between'>
                                                                    <label htmlFor="coverImage" className='2md:text-[16px] text-[14px]  font-medium'>Cover Image</label>
                                                                    <label htmlFor="coverImage" className='text-[12px] cursor-pointer font-medium text-[#2A8DDE]'>Change image</label>
                                                                    <input type="file" accept="image/png, image/jpeg" className='hidden' id='coverImage' onChange={handleFileUpload} name="cover" />
                                                                </div>
                                                                <p className='text-[14px] font-medium pt-[12px] text-[#6D7486]'>Max. 1MB {isCoverError && <span className='text-[12px] text-[#F44336] font-medium'>{isCoverError}</span>} </p>
                                                                <label htmlFor="coverImage" className='w-[100%] h-[110px] cursor-pointer rounded-[8px] border border-[#EAF4FC] overflow-hidden mt-[8px] flex-center'>
                                                                    {renderCoverImage()}
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </TabPanel>
                                        <TabPanel headerTemplate={UserProfileTab} className=''>
                                            <div className='2sm:h-[calc(100vh_-_180px)] h-[calc(100vh_-_225px)] overflow-auto hide-scrollbar 2sm:p-[24px] xsm:p-[20px] p-[15px]'>
                                                <div className='bg-[#F3F5F7] 4lg:w-[660px] 2lg:w-[570px] 2md:w-[420px] w-full space-y-[8px] '>
                                                    <div className='bg-[#fff] w-full  rounded-[12px]  space-y-[8px] 2sm:p-[24px] xsm:p-[20px] p-[15px]'>
                                                        <p className='text-[18px] font-[600] text-[#0C1835] leading-[110%]'>{user?.roleId === 1 ? 'User' : 'Company'} Profile</p>
                                                        {/* Profile Image Upload */}
                                                        <div className='space-y-[16px] pt-[16px]'>
                                                            {/* Level of Education */}
                                                            {
                                                                user?.roleId === 1 &&
                                                                <div className='pb-[16px] border-b border-[#EFF3F4]'>
                                                                    <div className='flex items-center justify-between'>
                                                                        <label htmlFor="name" className='2md:text-[16px] text-[14px]  font-medium'>Level of Education</label>
                                                                        {!showLevelofEducation ?
                                                                            <p onClick={() => setShowLevelofEducation(!showLevelofEducation)} className='text-[12px] cursor-pointer font-medium text-[#2A8DDE]'>Edit</p>
                                                                            :
                                                                            <p onClick={() => setShowLevelofEducation(!showLevelofEducation)} className='text-[12px] cursor-pointer font-medium text-[#6D7486]'>Cancel</p>
                                                                        }
                                                                    </div>
                                                                    {!showLevelofEducation ?
                                                                        <p className='text-[14px] font-medium pt-[12px] text-[#6D7486]'>{user?.levelEducation}</p>
                                                                        :
                                                                        <div>
                                                                            <div>
                                                                                <CustomSelect options={EducationLevelOptions} className="max-w-[314px]" value={{ label: user?.levelEducation, value: user?.levelEducation }} placeholder="Select Level of Education" onChange={(selected) => { setUpdatedUser({ ...updatedUser, levelEducation: selected.value }) }} />
                                                                                {
                                                                                    (updatedUser?.levelEducation === "Other" || !EducationLevelOptions?.map(a => a.value).includes(updatedUser?.levelEducation)) && (
                                                                                        <CustomInput onChange={(e) => { setUpdatedUser({ ...updatedUser, levelEducation: e.target.value }) }} className="max-w-[314px]" type="text" placeholder={`Please specify*`} />
                                                                                    )
                                                                                }
                                                                            </div>

                                                                            <Bluebtn className="!w-fit mt-[16px] text-[14px] font-medium leading-[100%] py-[8px] px-[16px] rounded-[6px] text-white" Label="Update" onClick={() => { updateProfile(); setShowLevelofEducation(!showLevelofEducation) }} />
                                                                        </div>
                                                                    }
                                                                </div>
                                                            }
                                                            {/* Industry */}
                                                            <div className='pb-[16px] border-b border-[#EFF3F4]'>
                                                                <div className='flex items-center justify-between'>
                                                                    <label htmlFor="name" className='2md:text-[16px] text-[14px]  font-medium'>Industry <span className='text-[#6D7486] text-[12px] font-medium'>(Select one or more)</span></label>
                                                                    {!showIndustry ?
                                                                        <p onClick={() => setShowIndustry(!showIndustry)} className='text-[12px] cursor-pointer font-medium text-[#2A8DDE]'>Edit</p>
                                                                        :
                                                                        <p onClick={() => setShowIndustry(!showIndustry)} className='text-[12px] cursor-pointer font-medium text-[#6D7486]'>Cancel</p>
                                                                    }
                                                                </div>
                                                                {!showIndustry ?
                                                                    <p className='text-[14px] font-medium pt-[12px] text-[#6D7486]'>
                                                                        {user?.industry && user?.industry.join(', ')}
                                                                    </p>
                                                                    :
                                                                    <div>
                                                                        <div className='space-y-[10px]'>
                                                                            <CustomSelect
                                                                                value={user?.industry.map(area => ({ label: area, value: area }))}
                                                                                options={IndustryOptions}
                                                                                className="max-w-[314px]"
                                                                                placeholder="Select Industry"
                                                                                isMulti={true}
                                                                                onChange={(selected) => {
                                                                                    const selectedValues = selected ? selected.map(option => option.value) : [];
                                                                                    setUpdatedUser({ ...updatedUser, industry: selectedValues })
                                                                                }}
                                                                            />
                                                                            {
                                                                                (updatedUser?.industry?.includes("Other, please specify")) && (
                                                                                    <div>
                                                                                        <CustomInput
                                                                                            label="Please specify Industry*"
                                                                                            type="text"
                                                                                            className="max-w-[314px]"
                                                                                            placeholder="Please specify Industry*"
                                                                                            onChange={(e) => {
                                                                                                setOtherAboutUserData(prev => {
                                                                                                    return { ...prev, industry: e.target.value };
                                                                                                });
                                                                                            }}
                                                                                        />
                                                                                    </div>
                                                                                )
                                                                            }

                                                                        </div>
                                                                        <Bluebtn className="!w-fit mt-[16px] text-[14px] font-medium leading-[100%] py-[8px] px-[16px] rounded-[6px] text-white" Label="Update" onClick={() => { updateProfile(); setShowIndustry(!showIndustry) }} />
                                                                    </div>
                                                                }
                                                            </div>
                                                            {/* Services Provided  */}
                                                            <div className='pb-[16px] border-b border-[#EFF3F4]'>
                                                                <div className='flex items-center justify-between'>
                                                                    <label htmlFor="name" className='2md:text-[16px] text-[14px]  font-medium'>Services Provided <span className='text-[#6D7486] text-[12px] font-medium'>(Select one or more)</span> </label>
                                                                    {!showServicesProvided ?
                                                                        <p onClick={() => setShowServicesProvided(!showServicesProvided)} className='text-[12px] cursor-pointer font-medium text-[#2A8DDE]'>Edit</p>
                                                                        :
                                                                        <p onClick={() => setShowServicesProvided(!showServicesProvided)} className='text-[12px] cursor-pointer font-medium text-[#6D7486]'>Cancel</p>
                                                                    }
                                                                </div>
                                                                {!showServicesProvided ?
                                                                    <p className='text-[14px] font-medium pt-[12px] text-[#6D7486]'>
                                                                        {user?.servicesProvided === 'undefined' || user?.servicesProvided === undefined ? '' : user?.servicesProvided?.join(', ')}
                                                                    </p>
                                                                    :
                                                                    <div>
                                                                        <div className='space-y-[10px]'>
                                                                            <CustomSelect
                                                                                value={user?.servicesProvided === 'undefined' || user?.servicesProvided === undefined ? [] : user?.servicesProvided.map(area => ({ label: area, value: area }))}
                                                                                options={servicesProvidedOptions}
                                                                                className="max-w-[314px]"
                                                                                placeholder="Select services provided"
                                                                                isMulti={true}
                                                                                onChange={(selected) => {
                                                                                    const selectedValues = selected ? selected.map(option => option.value) : [];
                                                                                    setUpdatedUser({ ...updatedUser, servicesProvided: selectedValues })
                                                                                }}
                                                                            />
                                                                            {
                                                                                (updatedUser?.servicesProvided?.includes("Other, please specify")) && (
                                                                                    <div>
                                                                                        <CustomInput
                                                                                            label="Please specify Services Provided*"
                                                                                            type="text"
                                                                                            className="max-w-[314px]"
                                                                                            placeholder="Please specify Services Provided*"
                                                                                            onChange={(e) => setotherServicesProvided(e.target.value)}
                                                                                        />
                                                                                    </div>
                                                                                )
                                                                            }
                                                                        </div>
                                                                        <Bluebtn className="!w-fit mt-[16px] text-[14px] font-medium leading-[100%] py-[8px] px-[16px] rounded-[6px] text-white" Label="Update"
                                                                            onClick={() => { updateProfile(); setShowServicesProvided(!showServicesProvided) }} />
                                                                    </div>
                                                                }
                                                            </div>
                                                            {/* User experience */}
                                                            <div className='pb-[16px] border-b border-[#EFF3F4]'>
                                                                <div className='flex items-center justify-between'>
                                                                    <label htmlFor="name" className='2md:text-[16px] text-[14px]  font-medium'>Experience</label>
                                                                    <div className="flex items-center justify-end gap-[10px]">
                                                                        {/* <p className='text-[12px] cursor-pointer font-medium text-[#2A8DDE]'>Edit</p> */}
                                                                        <p onClick={() => { setshowAddExperience(true); setModelType("Add") }} className='text-[12px] cursor-pointer font-medium text-[#2A8DDE]'>Add</p>
                                                                    </div>
                                                                </div>

                                                                <div className='space-y-[8px] mt-[8px]'>
                                                                    {
                                                                        experience?.length > 0 && experience.map((item, index) => {
                                                                            const fromDate = new Date(item.from).toLocaleString('en-US', { month: 'short', year: 'numeric' });
                                                                            const toDate = item.to ? new Date(item.to).toLocaleString('en-US', { month: 'short', year: 'numeric' }) : "Present";
                                                                            return (
                                                                                <div key={index} className='flex items-center justify-start gap-[8px] py-[8px] group relative'>
                                                                                    {/* <div className='flex-center overflow-hidden w-[36px] h-[36px] rounded-[4px] border border-[#EFF3F4]'>
                                                                                        <img src="" alt="" />
                                                                                    </div> */}
                                                                                    <div className=''>
                                                                                        <p className='text-[12px] font-normal leading-[140%] text-[#21272A]'>{item?.organization}</p>
                                                                                        <div className='flex items-center justify-start gap-[5px]'>
                                                                                            <p className='text-[10px] font-[400] leading-[140%] text-[#6D7486]'>{item?.title}</p>
                                                                                            <div className='w-[3px] h-[3px] rounded-full overflow-hidden bg-[#6D7486]'></div>
                                                                                            <p className='text-[10px] font-[400] leading-[140%] text-[#6D7486]'>{fromDate} - {toDate}</p>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className='absolute  right-[0px] top-[50%] translate-y-[-50%]'>
                                                                                        <div className="flex items-center justify-end gap-[10px]">
                                                                                            <div onClick={() => { setshowAddExperience(true); setModelData(item); setModelType("Edit") }} className='cursor-pointer' dangerouslySetInnerHTML={{ __html: icons.Edit }} />
                                                                                            <div onClick={() => { setShowDeleteExperience(true); setExperienceDeleteId(item?._id) }} className='cursor-pointer' dangerouslySetInnerHTML={{ __html: icons.deleteExperience }} />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            );
                                                                        })
                                                                    }

                                                                    {
                                                                        experience?.length === 0 && <p className='text-[14px] font-medium pt-[12px] text-[#6D7486]'>Add your experience</p>
                                                                    }

                                                                </div>
                                                            </div>


                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </TabPanel>
                                        <TabPanel headerTemplate={LoginSecurityTab} className=''>
                                            <div className='2sm:h-[calc(100vh_-_180px)] h-[calc(100vh_-_225px)] overflow-auto hide-scrollbar 2sm:p-[24px] xsm:p-[20px] p-[15px]'>
                                                <div className='bg-[#F3F5F7] 4lg:w-[660px] 2lg:w-[570px] 2md:w-[420px] w-full space-y-[8px] '>
                                                    <div className='bg-[#fff] w-full  rounded-[12px]  space-y-[8px] 2sm:p-[24px] xsm:p-[20px] p-[15px]'>
                                                        <p className='text-[18px] font-[600] text-[#0C1835] leading-[110%]'>Login & Security</p>
                                                        <div className='space-y-[16px] pt-[16px]'>
                                                            {/* Primary email  */}
                                                            <div className='pb-[16px] border-b border-[#EFF3F4]'>
                                                                <div className='flex items-center justify-between'>
                                                                    <label htmlFor="Primaryemail" className='2md:text-[16px] text-[14px]  font-medium'>Primary email</label>
                                                                    {user?.email1 === "null" || user?.email1 === "" || user?.email1 === undefined || user?.email1 === null ?
                                                                        <p onClick={() => setAddSecondryEmail(true)} className='text-[12px] cursor-pointer font-medium text-[#2A8DDE]'>Add Secondary Email</p> : <></>
                                                                    }
                                                                </div>
                                                                <p className='text-[14px] font-medium pt-[12px] text-[#6D7486]'>{user?.email}</p>
                                                            </div>
                                                            {/* Secondary email */}
                                                            {
                                                                user?.email1 === "null" || user?.email1 === "" || user?.email1 === undefined || user?.email1 === null ?
                                                                    <>

                                                                    </> :
                                                                    <div className='pb-[16px] border-b border-[#EFF3F4]'>
                                                                        <div>
                                                                            <div className='flex items-center justify-between'>
                                                                                <label htmlFor="Primaryemail" className='2md:text-[16px] text-[14px]  font-medium'>Secondary email</label>
                                                                                <div className='flex items-start justify-end gap-[16px]'>
                                                                                    <p onClick={() => { MakePrimaryEmail() }} className='text-[12px] cursor-pointer font-medium text-[#2A8DDE]'>Make Primary</p>
                                                                                    <p onClick={() => removeEmailSecondary()} className='text-[12px] cursor-pointer font-medium text-[#6D7486]'>Remove</p>
                                                                                </div>
                                                                            </div>
                                                                            <p className='text-[14px] font-medium pt-[12px] text-[#6D7486]'>{user?.email1}</p>
                                                                        </div>
                                                                    </div>
                                                            }
                                                            {/* Password   */}
                                                            <div className='pb-[16px] border-b border-[#EFF3F4]'>
                                                                <div className='flex items-center justify-between'>
                                                                    <label htmlFor="CurrentPlan" className='2md:text-[16px] text-[14px]  font-medium'>Password</label>
                                                                    <p onClick={() => setUpdatePassword(true)} className='text-[12px] cursor-pointer font-medium text-[#2A8DDE]'>Update</p>
                                                                </div>

                                                                <div className='flex items-center justify-start gap-[8px] pt-[12px]'>
                                                                    <div className='bg-[#B7BCCA] w-[16px] h-[16px] rounded-full overflow-hidden'></div>
                                                                    <div className='bg-[#B7BCCA] w-[16px] h-[16px] rounded-full overflow-hidden'></div>
                                                                    <div className='bg-[#B7BCCA] w-[16px] h-[16px] rounded-full overflow-hidden'></div>
                                                                    <div className='bg-[#B7BCCA] w-[16px] h-[16px] rounded-full overflow-hidden'></div>
                                                                    <div className='bg-[#B7BCCA] w-[16px] h-[16px] rounded-full overflow-hidden'></div>
                                                                    <div className='bg-[#B7BCCA] w-[16px] h-[16px] rounded-full overflow-hidden'></div>
                                                                    <div className='bg-[#B7BCCA] w-[16px] h-[16px] rounded-full overflow-hidden'></div>
                                                                </div>
                                                            </div>
                                                            {/* Share URL    */}
                                                            <div className='pb-[16px] border-b border-[#EFF3F4]'>
                                                                <div className='flex items-center justify-between'>
                                                                    <label htmlFor="name" className='2md:text-[16px] text-[14px]  font-medium'>{user?.roleId === 1 ? 'Personal' : 'Company'} URL</label>
                                                                    {!showShareUrl ?
                                                                        <p onClick={() => setShowShareUrl(!showShareUrl)} className='text-[12px] cursor-pointer font-medium text-[#2A8DDE]'>Edit</p>
                                                                        :
                                                                        <p onClick={() => setShowShareUrl(!showShareUrl)} className='text-[12px] cursor-pointer font-medium text-[#6D7486]'>Cancel</p>
                                                                    }
                                                                </div>
                                                                {!showShareUrl ?
                                                                    <p className='text-[14px] font-medium pt-[12px] text-[#6D7486]'>{user?.shareUrl}</p>
                                                                    :
                                                                    <div>
                                                                        <CustomInput className="max-w-[314px]" type="text" defultValue={user?.shareUrl}
                                                                            onChange={(e) => handleShareUrlChange(e)} placeholder="https://get2net0.com" />
                                                                        {shareUrlError && <span className="text-red-500">{shareUrlError}</span>}
                                                                        <Bluebtn className="!w-fit mt-[16px] text-[14px] font-medium leading-[100%] py-[8px] px-[16px] rounded-[6px] text-white" Label="Update"
                                                                            onClick={() => {
                                                                                if (!shareUrlError) {
                                                                                    updateProfile(); setShowShareUrl(!showShareUrl)
                                                                                } else {
                                                                                    // Handle errors, e.g., show error messages or prevent navigation
                                                                                    if (shareUrlError) {
                                                                                        // Handle shareUrlError, you can show an error message
                                                                                        console.error(shareUrlError);
                                                                                    }
                                                                                }
                                                                            }} />
                                                                    </div>
                                                                }
                                                            </div>
                                                            {/* Linkedin Url  */}
                                                            <div className='pb-[16px] border-b border-[#EFF3F4]'>
                                                                <div className='flex items-center justify-between'>
                                                                    <label htmlFor="Linkedin" className='2md:text-[16px] text-[14px]  font-medium'>Linkedin profile</label>
                                                                    {!showLinkedin ?
                                                                        <p onClick={() => setShowLinkedin(!showLinkedin)} className='text-[12px] cursor-pointer font-medium text-[#2A8DDE]'>Edit</p>
                                                                        :
                                                                        <p onClick={() => setShowLinkedin(!showLinkedin)} className='text-[12px] cursor-pointer font-medium text-[#6D7486]'>Cancel</p>
                                                                    }
                                                                </div>
                                                                {!showLinkedin ?
                                                                    <pre className={` leading-[140%] w-full caption-text text-[14px] font-medium pt-[12px] text-[#6D7486] `}>{user?.linkdinUrl}</pre>
                                                                    :
                                                                    <div>
                                                                        <CustomInput className="max-w-[314px]" type="text" defultValue={user?.linkdinUrl}
                                                                            onChange={(e) => handleLinkedinUrlChange(e)}
                                                                            placeholder="https://www.linkedin.com/" />
                                                                        {linkedinUrlError && <span className="text-red-500">{linkedinUrlError}</span>}

                                                                        <Bluebtn className="!w-fit mt-[16px] text-[14px] font-medium leading-[100%] py-[8px] px-[16px] rounded-[6px] text-white" Label="Update"
                                                                            onClick={() => {
                                                                                if (!linkedinUrlError) {
                                                                                    updateProfile(); setShowLinkedin(!showLinkedin)
                                                                                } else {
                                                                                    if (linkedinUrlError) {
                                                                                        console.error(linkedinUrlError);
                                                                                    }
                                                                                }
                                                                            }} />
                                                                    </div>
                                                                }
                                                            </div>
                                                            {/* Keep me posted on news and offers  */}
                                                            <div className='pb-[16px] border-b border-[#EFF3F4]'>
                                                                <div className='flex items-center justify-start gap-[8px]'>
                                                                    <div class="checkboxes__row">
                                                                        <div class="checkboxes__item">
                                                                            <label class="checkbox style-c">
                                                                                <input id='keepMe' type="checkbox" checked={user?.keepMePosted}
                                                                                    onChange={(e) => {
                                                                                        const isChecked = e.target.checked;
                                                                                        setUpdatedUser({ ...updatedUser, keepMePosted: isChecked });
                                                                                        updateProfile(isChecked);
                                                                                    }} />
                                                                                <div class="checkbox__checkmark"></div>
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                    <label htmlFor="keepMe" className='2md:text-[16px] text-[14px] cursor-pointer font-medium'>Keep me posted on news and offers</label>
                                                                </div>
                                                            </div>

                                                            <div onClick={() => { setShowDeleteAccount(true) }} className='text-[16px] leading-[110%] font-medium text-[#6D7486] cursor-pointer'>Delete your Account</div>


                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </TabPanel>
                                        {/* <TabPanel headerTemplate={MembershipPlanTab} className=''>
                                            <div className='2sm:h-[calc(100vh_-_180px)] h-[calc(100vh_-_225px)] overflow-auto hide-scrollbar 2sm:p-[24px] xsm:p-[20px] p-[15px]'>
                                                <div className='bg-[#fff] 4lg:w-[660px] 2lg:w-[570px] 2md:w-[420px] w-full  rounded-[12px]  p-[24px]'>
                                                    <p className='text-[18px] font-[600] text-[#0C1835] leading-[110%]'>Membership Plan</p>
                                                    <div className='pt-[16px]'>
                                                        <div className='flex items-center justify-between'>
                                                            <label htmlFor="CurrentPlan" className='2md:text-[16px] text-[14px]  font-medium'>Current Plan</label>
                                                            <Link to="/packages" className='text-[12px] cursor-pointer font-medium text-[#2A8DDE]'>Edit</Link>
                                                        </div>
                                                        {!showCurrentPlan ?
                                                            <p className='text-[14px] font-medium pt-[12px] text-[#6D7486]'></p>
                                                            :
                                                            <div>
                                                                <CustomSelect options={plans} name="CurrentPlan" className="max-w-[314px]" type="text" defultValue="Standard" onChange={() => { }} placeholder="Select Plan" />
                                                                <Bluebtn className="!w-fit mt-[16px] text-[14px] font-medium leading-[100%] py-[8px] px-[16px] rounded-[6px] text-white" Label="Update" onClick={() => setShowCurrentPlan(!showCurrentPlan)} />
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </TabPanel> */}
                                    </TabView>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
            {/* Delete Account CUstom model  */}
            <CustomModal bodyClassName=" rounded-[16px] " showModal={showDeleteAccount} setShowModal={setShowDeleteAccount}>
                <DeleteAccount setIsOpen={setShowDeleteAccount} />
            </CustomModal>
            {/* Delete Account CUstom model ENd  */}
            <CustomModal bodyClassName=" rounded-[16px] " showModal={showDeleteExperience} setShowModal={setShowDeleteExperience}>
                <div class="w-full  bg-white shadow-lg rounded-[16px] 2sm:p-[24px] xsm:p-[20px] p-[15px] relative ">
                    <div className='flex items-center justify-between'>
                        <h1 className='2lg:text-[26px] 2sm:text-[24px] md:text-[22px] text-[20px]  leading-[normal] font-[600]'>Delete Experience</h1>
                        <div onClick={() => { setShowDeleteExperience(false); }} className='cursor-pointer ' dangerouslySetInnerHTML={{ __html: icons.closeIcon }} />
                    </div>
                    <p className='text-[#21272A] font-normal text-[12px] leading-[140%] py-[10px]'>Are you sure you want to delete this experience?</p>
                    <div className='flex justify-end'>
                        <Bluebtn className="text-[14px] font-medium py-[8px] px-[16px] w-fit mt-[16px]  text-white bg-[#6D7486]" Label="Delete" onClick={() => { handleDeleteExperience(experienceDeleteId); setShowDeleteExperience(false) }} />
                    </div>
                </div>
            </CustomModal>
            {/* Add Secondry Email */}
            <CustomModal bodyClassName=" rounded-[16px]  !w-[400px]" showModal={addSecondryEmail} setShowModal={setAddSecondryEmail}>
                <AddEmail setIsOpen={setAddSecondryEmail} updateData={getProfile} />
            </CustomModal>
            {/* Add Secondry Email ENd  */}
            <CustomModal bodyClassName=" rounded-[16px]  !w-[400px]" showModal={updatePassword} setShowModal={setUpdatePassword}>
                <UpdatePassword setIsOpen={setUpdatePassword} />
            </CustomModal>
            {/* Add Experience  */}
            <CustomModal bodyClassName=" rounded-[16px] max-w-[525px] " showModal={addExperience} setShowModal={setshowAddExperience} ClearData={setModelData}>
                <CreateExperience setIsOpen={setshowAddExperience} type={ModelType} experienceModalData={ModelData} ClearData={setModelData} updateData={getExperience} />
            </CustomModal>
            {
                isCreatePostChecked &&
                <ProfilePhotoCropper
                    modalTitle={"Update profile photo"}
                    yourImage={selectedFile}
                    setYourImage={setSelectedFile}
                    updateData={getProfile}
                    isCreatePostChecked={isCreatePostChecked}
                    setCreatePostChecked={setCreatePostChecked}
                    first_name={user?.first_name}
                    imageKeyName="profileImage"
                    aspectRatios={[{ label: '1:1', value: 1 / 1 }]}
                />
            }
            {
                isCreateCoverChecked &&
                <ProfilePhotoCropper
                    modalTitle={"Update Cover photo"}
                    yourImage={selectedCoverFile}
                    setYourImage={setSelectedCoverFile}
                    updateData={getProfile}
                    isCreatePostChecked={isCreateCoverChecked}
                    setCreatePostChecked={setCreateCoverChecked}
                    imageKeyName="coverImage"
                    first_name={user?.first_name}
                    aspectRatios={[{ label: 'Cover Image', value: 329 / 30 }]}
                />
            }


        </div>
    )
}

export default Setting;