import axios from "axios";
import Cookies from "js-cookie";
import Toast from "../helpers/Toast";
import { config } from "react-transition-group";

const autoInstance = axios.create({
    baseURL: `${process.env.REACT_APP_BACKEND_URL}/api/v1`,
    // withCredentials: true,
})

autoInstance.interceptors.request.use((config) => {
    if (config?.isAdmin) {
        const token = Cookies.get('g2n0AdminAuth')
        config.headers.Authorization = `Bearer ${token}`
    } else {
        const token = Cookies.get('g2n0Auth')
        if (token) {
            config.headers.Authorization = `Bearer ${token}`
        } else {
            const token = Cookies.get('SignupToken')
            if (token) {
                config.headers.Authorization = `Bearer ${token}`
            }
        }
    }
    return config
})

autoInstance.interceptors.response.use((response) => {
    if (response.config.toastShow) {
        Toast(response.data.message, "success");
    }
    return response
}, (error) => {
    if (error.response.status === 401) {
        localStorage.clear();
        Cookies.remove('g2n0Auth')
        if(config.isAdmin) {
         window.location.href = "/admin"
        }
        window.location.href = "/home"
    } else {
        if (error.config.toastShow) {
            Toast(error.response.data.message, "error")
        }
    }
    return Promise.reject(error)
})

export default autoInstance
