import { Tooltip } from 'primereact/tooltip';
import React, { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';

const CustomInput = forwardRef(({ label, type, value, onChange, placeholder, className, defultValue, onPaste, onCopy, onKeyPress, maxLength, onBlur }, ref) => {
    const [showPassword, setShowPassword] = useState(false);
    const [passwordLength, setPasswordLength] = useState(0);
    const location = useLocation();
    const pathName = location.pathname;
    const isSignupPage = pathName === '/signup';
    const inputRef = useRef(null);
    const screenWidth = window.innerWidth;

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const handlePasswordChange = (e) => {
        const length = e.target.value.length;
        setPasswordLength(length);
        onChange(e);
    };

    const passwordDivs = Array.from({ length: passwordLength }).map((_, index) => (
        <div key={index} className='bg-[#B7BCCA] w-[16px] h-[16px] rounded-full'></div>
    ));

    // Expose a method to focus on the input element
    useImperativeHandle(ref, () => ({
        focusInput: () => {
            inputRef.current.focus();
        }
    }));


    const tooltipContent = `At least one uppercase letter
    At least one lowercase letter
    At least one digit
    At least one special character
    @#$!%*?_&
    Length of 8 to 20 characters`;


    return (
        <div>
            {label &&
                <div className='flex items-center justify-start gap-[8px]'>
                    <label htmlFor={label} className='2md:text-[16px] text-[14px]  font-medium'>{label}</label>
                    {
                        label === 'Set a Password' &&
                        <div className='cursor-pointer'>
                            <Tooltip
                                title={tooltipContent}
                                target=".Postinfo"
                                placement="bottom"
                                className="custom-tooltip"
                            />
                            <span
                                data-pr-tooltip={tooltipContent}
                                data-pr-position="right"
                                data-pr-at="right+2 top+9"
                                data-pr-my="left center-2"
                                className="Postinfo cursor-pointer"
                            >
                                <svg
                                    xmlns='http://www.w3.org/2000/svg'
                                    viewBox='0 0 24 24'
                                    fill='none'
                                    stroke='currentColor'
                                    strokeWidth='2'
                                    strokeLinecap='round'
                                    strokeLinejoin='round'
                                    className='lucide lucide-info text-[#6D7486] h-4 w-4'
                                >
                                    <circle cx='12' cy='12' r='10' />
                                    <path d='M12 16v-4' />
                                    <path d='M12 8h.01' />
                                </svg>
                            </span>
                        </div>
                    }
                </div>
            }
            <div className={`relative flex`}>
                <input
                    ref={inputRef}
                    type={showPassword ? 'text' : type}
                    value={value}
                    defaultValue={defultValue ? defultValue : null}
                    id={label}
                    onChange={type === 'password' ? handlePasswordChange : onChange}
                    onKeyDown={onKeyPress}
                    placeholder={placeholder}
                    onPaste={onPaste}
                    onCopy={onCopy}
                    onBlur={onBlur ? onBlur : null}
                    maxLength={maxLength ? maxLength : null}
                    className={`mt-[6px] leading-[100%] w-full lg:p-[16px] sm:text-[16px] text-[14px] p-[14px] rounded-[8px] border border-[#EFF3F4] focus:outline-none placeholder:text-[#6D7486] ${className}`}
                />
                {type === 'password' && (
                    <>
                        {showPassword ?
                            <div className='right-[20px] top-[50%] translate-y-[-50%] absolute cursor-pointer' onClick={togglePasswordVisibility}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                                    <path className='stroke-[1.5]' d="M0.818115 8.59983C0.818115 8.59983 3.27266 2.87256 8.99993 2.87256C14.7272 2.87256 17.1818 8.59983 17.1818 8.59983C17.1818 8.59983 14.7272 14.3271 8.99993 14.3271C3.27266 14.3271 0.818115 8.59983 0.818115 8.59983Z" stroke="#6D7486" />
                                    <path className='stroke-[1.5]' d="M8.99996 11.0544C10.3556 11.0544 11.4545 9.95538 11.4545 8.59981C11.4545 7.2442 10.3556 6.14526 8.99996 6.14526C7.64435 6.14526 6.54541 7.2442 6.54541 8.59981C6.54541 9.95538 7.64435 11.0544 8.99996 11.0544Z" stroke="#6D7486" />
                                </svg>
                            </div> :
                            <div className='right-[20px] top-[50%] translate-y-[-50%] absolute cursor-pointer' onClick={togglePasswordVisibility}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                                    <path className='stroke-[1.5]' d="M7.40997 7.41C7.18891 7.61595 7.01161 7.86442 6.88863 8.14035C6.76565 8.41635 6.69952 8.71432 6.6942 9.01642C6.68887 9.31852 6.74444 9.6186 6.8576 9.8988C6.97077 10.1789 7.1392 10.4335 7.35286 10.6471C7.56649 10.8607 7.82104 11.0292 8.10117 11.1424C8.38137 11.2555 8.68144 11.3111 8.98354 11.3058C9.28564 11.3005 9.58362 11.2343 9.85962 11.1113C10.1356 10.9884 10.384 10.811 10.59 10.59M8.04747 3.81C8.36344 3.77063 8.68152 3.75059 8.99997 3.75C14.25 3.75 16.5 9 16.5 9C16.1646 9.71782 15.7441 10.3927 15.2475 11.01" stroke="#6D7486" />
                                    <path className='stroke-[1.5]' d="M4.9575 4.9575C3.46594 5.97346 2.2724 7.36894 1.5 9C1.5 9 3.75 14.25 9 14.25C10.4369 14.2538 11.8431 13.8338 13.0425 13.0425M1.5 1.5L16.5 16.5" stroke="#6D7486" />
                                </svg>
                            </div>
                        }
                    </>
                )}
            </div>
        </div>
    );
});

export default CustomInput;
