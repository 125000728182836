import { Tooltip } from 'primereact/tooltip';
import React, { useState } from 'react';
import { icons } from '../../helpers/images';
import autoInstance from '../../utils/axios';
import Bluebtn from '../Common/Btns/Bluebtn';
import CustomInput from '../Common/Inputs/CustomInput';

function UpdateCommunityCollaboration({ pageTitle, setCreateNew, Data, updateData }) {
    const [checked, setChecked] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);
    const [isPosting, setIsPosting] = useState(false);
    const [image, setImage] = useState(null);
    const [fileError, setFileError] = useState("");
    const screenWidth = window.innerWidth;
    const [data, setData] = useState({
        name: Data.name || "",
        description: Data.description || "",
        isActive: true
    })

    const [isLoading, setIsLoading] = useState(false);
    const [dataError, setDataError] = useState({
        name: '',
        description: '',
        image: ''
    })

    const handleError = () => {
        setDataError({ ...dataError, name: '', description: '', image: '' });
        let isError = false;
        if (data.description === '') {
            setDataError({ ...dataError, description: 'Required' });
            isError = true;
        }

        if (data.name === '') {
            setDataError({ ...dataError, name: 'Required' });
            isError = true;
        }

        // if (image === null && Data.image === null) {
        //     setDataError({ ...dataError, image: 'Required' });
        //     isError = true;
        // }
        if (isError) {
            setIsPosting(false);
        }
        return isError;
    }
    const handleSubmit = async () => {

        try {
            setIsPosting(true);
            handleError();
            const formData = new FormData();
            formData.append('name', data.name);
            formData.append('description', data.description);
            if(image) formData.append('image', image);
            if (dataError.name === '' && dataError.description === '' && dataError.image === '' && fileError === '') {
                if (pageTitle === "Community") {
                    const response = await autoInstance.patch(`/community/${Data._id}`, formData, {
                        toastShow: true
                    });
                    if (response.data) {
                        updateData();
                        setIsPosting(false);
                        setCreateNew(false);
                    }
                }
                if (pageTitle === "Collaboration") {
                    const response = await autoInstance.patch(`/collaboration/${Data._id}`, formData, {
                        toastShow: true
                    });
                    if (response.data) {
                        updateData();
                        setIsPosting(false);
                        setCreateNew(false);
                    }
                }
            }

        } catch (error) {
            setIsPosting(false);
            console.error(error)
        }
    }

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        setImage(file);
        if (file) {
            if (file.size > 2.9 * 1024 * 1024) { // Check if file size is greater than 2MB
                // Show error message
                setFileError("File size exceeds 2MB. Please select a smaller file.");
                // Clear selected file
                setSelectedFile(null);
                // Reset file input
                e.target.value = null;
            } else {
                // Reset error message
                setFileError(""); // or setError(null);
                const reader = new FileReader();
                reader.onload = () => {
                    setSelectedFile(reader.result);
                };
                reader.readAsDataURL(file);
            }
        }
    };

    const tooltipContent = `Supported files:
  - JPEG
  - PNG
  - GIF
Maximum image size: 2MB
`;

    return (
        <div className='p-[24px]'>
            <div className='flex items-center justify-between'>
                <p className='2lg:text-[26px] 2sm:text-[24px] md:text-[22px] text-[20px]  leading-[normal] font-[600]'>Update a {pageTitle}</p>
                <div onClick={() => { setCreateNew(false); }} className='cursor-pointer ' dangerouslySetInnerHTML={{ __html: icons.closeIcon }} />
            </div>
            <div className='space-y-[12px] py-[10px]'>

                <div>
                    <label htmlFor="Upload" className='cursor-pointer'>
                        {/* <p className='2md:text-[16px] text-[14px]  font-medium'>Upload a {pageTitle.toLowerCase()} photo</p> */}
                        <input
                            type="file"
                            id="Upload"
                            className='hidden'
                            accept="image/png, image/jpeg"
                            onChange={handleFileChange}
                        />
                        <div className='mt-[10px] flex items-center justify-start gap-[12px]'>
                            <div className='w-[62px] h-[62px] flex items-center justify-center rounded-[8px] overflow-hidden'>
                                {/* Display image preview if selected */}
                                {selectedFile ? <img src={selectedFile} alt="Preview" style={{ width: '100%', height: '100%', objectFit: 'cover' }} /> :
                                    Data.image ? <img src={process.env.REACT_APP_IMAGE_URL + Data.image} alt="Preview" style={{ width: '100%', height: '100%', objectFit: 'cover' }} /> :
                                        <div dangerouslySetInnerHTML={{ __html: icons.bluePlus }} />
                                }
                            </div>
                            <div>
                                <div className='flex items-center justify-start gap-[6px] pb-[4px]'>
                                    <p className='text-[14px] font-[500] leading-[110%]  text-[#2A8DDE]'>Upload photo</p>
                                    <Tooltip
                                        title={tooltipContent}
                                        target=".Postinfo"
                                        placement="bottom"
                                        className="custom-tooltip"
                                    />
                                    <span
                                        data-pr-tooltip={tooltipContent}
                                        data-pr-position="right"
                                        data-pr-at="right+2 top+9"
                                        data-pr-my="left center-2"
                                        className="Postinfo cursor-pointer"
                                    >
                                        <svg
                                            xmlns='http://www.w3.org/2000/svg'
                                            viewBox='0 0 24 24'
                                            fill='none'
                                            stroke='currentColor'
                                            strokeWidth='2'
                                            strokeLinecap='round'
                                            strokeLinejoin='round'
                                            className='lucide lucide-info text-[#6D7486] h-4 w-4'
                                        >
                                            <circle cx='12' cy='12' r='10' />
                                            <path d='M12 16v-4' />
                                            <path d='M12 8h.01' />
                                        </svg>
                                    </span>

                                </div>
                                <p className='text-[12px] leading-[140%] text-[#6D7486]'>Max 2 MB</p>
                                {fileError && <p className="text-red-500 text-sm">{fileError}</p>}

                            </div>
                        </div>
                    </label>
                    {dataError.image && <p className='text-red-500 font-normal text-[14px]'>{dataError?.image}</p>}
                </div>
                <div>
                    <CustomInput value={data?.name} maxLength={60} onChange={(e) => setData({ ...data, name: e.target.value })} label='Name' type='text' placeholder={`${pageTitle} name`} />
                    {dataError.name && <p className='text-red-500 font-normal text-[14px]'>{dataError?.name}</p>}
                    <div className='flex justify-end pt-[6px]'>
                        <p className='text-[12px] font-[500] leading-[110%] text-[#6D7486]'>{data?.name?.length} / 60</p>
                    </div>
                </div>
                <div>
                    <label htmlFor="Description" className='2md:text-[16px] text-[14px]  font-medium'>Description</label>
                    <div className='pt-[6px]'>
                        <textarea value={data?.description} maxLength={300} onChange={(e) => setData({ ...data, description: e.target.value })} className='w-full focus-visible:outline-none border border-[#EFF3F4]  py-[16px] px-[10px] rounded-[8px]' name="description" id="Description" rows="2" placeholder={`${pageTitle} description`}></textarea>
                        {dataError.description && <p className='text-red-500 font-normal text-[14px]'>{dataError?.description}</p>}
                        <div className='flex justify-end pb-[6px]'>
                            <p className='text-[12px] font-[500] leading-[110%] text-[#6D7486]'>{data?.description?.length} / 300</p>
                        </div>
                    </div>
                </div>



                <div className='flex justify-end'>
                    <Bluebtn Label={`${isPosting ? "Updating" : "Update"}`} onClick={isPosting ? null : handleSubmit} className={`${isPosting ? "cursor-not-allowed !bg-[#B7BCCA]" : "cursor-pointer"} text-[14px] font-medium py-[8px] px-[16px] w-fit text-white`} />
                </div>
            </div>

        </div>
    )
}

export default UpdateCommunityCollaboration