import React from 'react'
import Navbar from '../../Components/Common/Navbar'
import Sidebar from '../../Components/Common/Sidebar'
import Packages from './index'
function PackagePage() {
    return (
        <div>
            {/* Header */}
            <Navbar />
            {/* ENd Header */}
            <div className='flex items-start justify-start lg:h-[calc(100vh_-_80px)] 2sm:h-[calc(100vh_-_70px)] h-[calc(100vh_-_128px)] overflow-hidden'>
                {/* Sidebar  */}
                <Sidebar />
                {/* Sidebar End */}
                <div className='h-full bg-[#FFF] 3lg:w-[calc(100%_-_300px)] lg:w-[calc(100%_-_250px)] 2sm:w-[calc(100%_-_60px)] w-full overflow-auto py-[24px] hide-scrollbar'>
                    <Packages />
                </div>
            </div>
        </div>
    )
}

export default PackagePage