import DOMPurify from 'dompurify'
import React, { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import Navbar from '../../Components/Common/Navbar'
import Sidebar from '../../Components/Common/Sidebar'
import { icons } from '../../helpers/images'
import autoInstance from '../../utils/axios'

function ClimateVaultDetails() {
  const location = useLocation();
  const pathname = location.pathname;
  const _id = pathname.split("/")[2];
  const originalString = _id;
  const decodedString = decodeURIComponent(originalString);
  const [details, setDetails] = useState()

  useEffect(() => {
    getDetails()
  }, [_id])
  const getDetails = async () => {
    try {
      const response = await autoInstance.get(`/climate-vault/${decodedString}`)
      setDetails(response.data.data)
    } catch (error) {
      console.error(error)
    }
  }

  const sanitizedHTML = DOMPurify.sanitize(details?.description);
  const sanitizedSummryHTML = DOMPurify.sanitize(details?.summary);

  return (
    <div>
      {/* Header */}
      <Navbar />
      {/* ENd Header */}
      <div className='flex items-start justify-start lg:h-[calc(100vh_-_80px)] 2sm:h-[calc(100vh_-_70px)] h-[calc(100vh_-_128px)] overflow-hidden'>
        {/* Sidebar  */}
        <Sidebar />
        {/* Sidebar End */}
        <div className='h-full bg-[#F3F5F7] 3lg:w-[calc(100%_-_300px)] lg:w-[calc(100%_-_250px)] 2sm:w-[calc(100%_-_60px)] w-full'>
          <div className='2sm:p-[24px] xsm:p-[20px] p-[15px] '>
            <div className='flex items-center justify-start gap-[4px] 2sm:pb-[22px] pb-[18px] '>
              <Link to="/climatevault">
                <div dangerouslySetInnerHTML={{ __html: icons.leftChevron }} />
              </Link>
              <p className='text-[#6D7486] text-[14px] font-normal'>Climate Vault</p>
            </div>
            <div className='h-[calc(100vh_-_153px)] overflow-auto '>
              <div className='3lg:w-[calc(100%_-_300px)] lg:w-[calc(100%_-_250px)] 2sm:w-[calc(100%_-_60px)] w-full '>
                <p className='2lg:text-[24px] 2sm:text-[22px] md:text-[20px] text-[18px] font-semibold leading-[110%] pb-[16px]'>{details?.title}</p>
                {details?.summary && <p className='text-[16px] font-medium leading-[140%] pb-[6px]'>Quick Read Version</p>}
                {
                  details?.summary &&
                  <div className=' text-[16px] font-normal leading-[140%] space-y-[16px]'>
                    <pre className='valut-description caption-text justify-text' dangerouslySetInnerHTML={{ __html: sanitizedSummryHTML }} />
                  </div>
                }
                <div className='flex-center rounded-[16px] overflow-hidden mb-[16px] w-fit   mx-auto 3lg:max-h-[389px] 2lg:max-h-[300px] 2md:max-h-[280px] sm:max-h-[250px]  max-h-[210px]'>
                  <img src={process.env.REACT_APP_IMAGE_URL + details?.file} alt="Climate Vault" className='w-auto rounded-[16px] overflow-hidden h-full max-h-[inherit] ' />
                </div>
                <div className=' text-[16px] font-normal leading-[140%] space-y-[16px]'>
                  <pre className='valut-description caption-text' dangerouslySetInnerHTML={{ __html: sanitizedHTML }} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ClimateVaultDetails