import React from 'react'
import { Link } from 'react-router-dom'
import { icons } from '../../helpers/images'

function WebFooter({ isCic }) {
    return (
        <div className={` xl:max-w-6xl 3lg:max-w-5xl max-w-4xl w-full  2lg:mx-auto  mx-4   2md:pb-[90px] md:pb-[55px] 2sm:pb-[40px] pb-[20px]`}>
            <div className='footer-menu  sm:flex  sm:justify-between justify-center items-center gap-[16px]  '>
                <div>
                    <Link to={'/home'}>
                        <div className='2xl:w-[auto] w-[140px] flex-center sm:mx-0 mx-auto  ' dangerouslySetInnerHTML={{ __html: icons.logo }} />
                    </Link>
                    <div className='pt-[20px]'>
                        <p className='text-[16px] font-medium leading-[140%]'>Contact us</p>
                        <Link to="mailto:info@get2net0.com" className='text-[14px] font-normal leading-[110%] pt-[6px]'>info@get2net0.com</Link>
                    </div>
                </div>
                <div className='flex  gap-[16px] items-center justify-between sm:mt-[0px] mt-[20px]'>
                    <Link to={'https://www.facebook.com/profile.php?id=61558509783118'} target='_blank'>
                        <svg width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect x="0.5" width="32" height="32" rx="16" fill="#EAF4FC" />
                            <path d="M14.523 24L14.5 17H11.5V14H14.5V12C14.5 9.3 16.172 8 18.58 8C19.733 8 20.724 8.086 21.013 8.124V10.945H19.343C18.033 10.945 17.78 11.568 17.78 12.481V14H21.5L20.5 17H17.78V24H14.523Z" fill="#2A8DDE" />
                        </svg>
                    </Link>
                    <Link to={'https://www.instagram.com/get2net0'} target='_blank'>

                        <svg width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect x="0.5" width="32" height="32" rx="16" fill="#EAF4FC" />
                            <path d="M20.645 12.892C21.1973 12.892 21.645 12.4443 21.645 11.892C21.645 11.3397 21.1973 10.892 20.645 10.892C20.0927 10.892 19.645 11.3397 19.645 11.892C19.645 12.4443 20.0927 12.892 20.645 12.892Z" fill="#2A8DDE" />
                            <path d="M16.5 20C14.294 20 12.5 18.206 12.5 16C12.5 13.794 14.294 12 16.5 12C18.706 12 20.5 13.794 20.5 16C20.5 18.206 18.706 20 16.5 20ZM16.5 14C15.397 14 14.5 14.897 14.5 16C14.5 17.103 15.397 18 16.5 18C17.603 18 18.5 17.103 18.5 16C18.5 14.897 17.603 14 16.5 14Z" fill="#2A8DDE" />
                            <path d="M20.5 24H12.5C10.444 24 8.5 22.056 8.5 20V12C8.5 9.944 10.444 8 12.5 8H20.5C22.556 8 24.5 9.944 24.5 12V20C24.5 22.056 22.556 24 20.5 24ZM12.5 10C11.565 10 10.5 11.065 10.5 12V20C10.5 20.953 11.547 22 12.5 22H20.5C21.435 22 22.5 20.935 22.5 20V12C22.5 11.065 21.435 10 20.5 10H12.5Z" fill="#2A8DDE" />
                        </svg>

                    </Link>
                    <Link to={`https://www.linkedin.com/company/get2net0`} target='_blank'>

                        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect width="32" height="32" rx="16" fill="#EAF4FC" />
                            <path d="M23.3 8H8.7C8.3 8 8 8.3 8 8.7V23.4C8 23.7 8.3 24 8.7 24H23.4C23.8 24 24.1 23.7 24.1 23.3V8.7C24 8.3 23.7 8 23.3 8ZM12.7 21.6H10.4V14H12.8V21.6H12.7ZM11.6 13C10.8 13 10.2 12.3 10.2 11.6C10.2 10.8 10.8 10.2 11.6 10.2C12.4 10.2 13 10.8 13 11.6C12.9 12.3 12.3 13 11.6 13ZM21.6 21.6H19.2V17.9C19.2 17 19.2 15.9 18 15.9C16.8 15.9 16.6 16.9 16.6 17.9V21.7H14.2V14H16.5V15C16.8 14.4 17.6 13.8 18.7 13.8C21.1 13.8 21.5 15.4 21.5 17.4V21.6H21.6Z" fill="#2A8DDE" />
                        </svg>

                    </Link>
                    <Link to={`https://twitter.com/get2net0`} target='_blank'>
                        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect width="32" height="32" rx="16" fill="#EAF4FC" />
                            <path d="M13.063 9L16.558 13.475L20.601 9H23.055L17.696 14.931L24 23H19.062L15.196 18.107L10.771 23H8.316L14.051 16.658L8 9H13.063ZM12.323 10.347H10.866L19.741 21.579H21.101L12.323 10.347Z" fill="#2A8DDE" />
                        </svg>

                    </Link>
                </div>
            </div>


        </div>
    )
}

export default WebFooter