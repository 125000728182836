import Cookies from 'js-cookie';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import AdminSidebar from './AdminSidebar';
import CommunitiesTable from './Communities';
import AdminNavBar from './NavBar/AdminNavbar';

function ClimateVaultAdmin() {
    const navigate = useNavigate()
    const Auth = () => { return !!Cookies.get('g2n0AdminAuth') }

    useEffect(() => {
        document.title = "Climate Vault | Admin"
        if (!Auth()) {
            navigate('/admin');
        }
    }, [])

    return (
        <div className='flex items-start justify-start h-screen'>
            <AdminSidebar />
            <div className='2md:w-[calc(100%-250px)] w-[calc(100%-60px)] '>
                <AdminNavBar page="Climate Vault" />
                {
                    Auth ? <CommunitiesTable pageTitle="Climate Vault" /> : null
                }
            </div>
        </div>
    )
}

export default ClimateVaultAdmin