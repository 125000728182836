import CommunityCollaborationLayout from '../../layouts/CommunityCollaborationLayout'

function Collaboration() {
  return (
    <CommunityCollaborationLayout
    pageTitle={"Collaborations"}
    listUrl={"Collaboration"}
    FilterUrl={"Collaboration"} />
  )
}

export default Collaboration