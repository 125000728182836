import React from 'react';
import Select, { components } from 'react-select';
import CreatableSelect from 'react-select/creatable';


const ClearIndicator = (props) => {
    return (
        <components.ClearIndicator {...props}>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="lucide lucide-x-circle"
                style={{ cursor: 'pointer', color: '#333' }}
            >
                <circle cx="12" cy="12" r="10"></circle>
                <line x1="15" y1="9" x2="9" y2="15"></line>
                <line x1="9" y1="9" x2="15" y2="15"></line>
            </svg>
        </components.ClearIndicator>
    );
};

const CustomSelect = ({
    label,
    value,
    onChange,
    options,
    placeholder,
    className,
    error,
    isMulti,
    ClassCss,
    position,
    showClearIcon = false  
}) => {
    // Custom styles for the select component
    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            borderColor: error ? 'red' : '#EFF3F4',
            '&:hover': {
                ...provided['&:hover'],
                borderColor: state.isFocused ? '#EFF3F4' : provided.borderColor
            },
            fontSize: '16px',
            fontFamily: 'Montserrat, sans-serif',
            color: '#333',
            placeholder: {
                ...provided.placeholder,
                color: '#6D7486',
                fontSize: '16px'
            }
        }),
        indicatorSeparator: () => ({
            display: 'none'
        })
    };

    // Custom MultiValueRemove component
    const CustomMultiValueRemove = (props) => (
        <components.MultiValueRemove {...props}>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="lucide lucide-x"
                style={{ cursor: 'pointer', color: '#333' }}
            >
                <path d="M18 6 6 18" />
                <path d="m6 6 12 12" />
            </svg>
        </components.MultiValueRemove>
    );

    return (
        <div className={`${ClassCss}`}>
            {label && <label htmlFor={label} className='2md:text-[16px] text-[14px] font-medium'>{label}</label>}
            <div className={`relative !cursor-pointer w-full`}>
                <Select
                    styles={customStyles}
                    defaultValue={value ? value : undefined}
                    onChange={onChange}
                    options={options}
                    placeholder={placeholder ? placeholder : `Select ${label}`}
                    className={`${className} ${label && 'mt-[6px]'} `}
                    components={{
                        DropdownIndicator,
                        MultiValueRemove: CustomMultiValueRemove,
                        ClearIndicator: showClearIcon ? components.ClearIndicator : null // Conditionally show the ClearIndicator
                    }}
                    isMulti={isMulti}
                    isClearable={showClearIcon} // Control clearability based on showClearIcon
                    menuPlacement={position ? position : "bottom"}
                />
            </div>
            {error && <div className="text-red-500">{error}</div>}
        </div>
    );
};


export default CustomSelect;



const DropdownIndicator = (props) => {
    return (
        <components.DropdownIndicator {...props}>
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
                <path d="M5 8.01099L10 13.011L15 8.01099" stroke="#6D7486" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
        </components.DropdownIndicator>
    );
};

export const CustomCreatableSelect = ({ label, value, onChange, options, placeholder, className, error, isMulti, ClassCss, defaultValue }) => {
    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            borderColor: state.isFocused ? '#EFF3F4' : provided.borderColor,
            fontSize: '16px',
            fontFamily: 'Montserrat, sans-serif',
            color: '#333',
            placeholder: {
                ...provided.placeholder,
                color: '#6D7486',
                fontSize: '16px'
            }
        }),
        indicatorSeparator: () => ({
            display: 'none'
        })
    };

    return (
        <div className={`${ClassCss}`}>
            {label && <label htmlFor={label} className='2md:text-[16px] text-[14px]  font-medium'>{label}</label>}
            <div className={`relative !cursor-pointer w-full`}>
                <CreatableSelect
                    defaultValue={defaultValue}
                    value={value}
                    onChange={onChange}
                    options={options}
                    menuIsOpen={true}
                    placeholder={placeholder ? placeholder : `Select ${label}`}
                    className={`${className} ${label && 'mt-[6px]'} `}
                    isMulti={isMulti}
                    styles={customStyles}
                />
            </div>
            {error && <div className="text-red-500">{error}</div>}
        </div>
    );
};
