import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { backendUrl } from '../../utils/globalConfig';
import autoInstance from '../axios';

// Define an asynchronous thunk for fetching data from the API
export const fetchPosts = createAsyncThunk('posts/fetchPosts', async (apiUrl) => {
    try {
        const response = await autoInstance.get(apiUrl, { withCredentials: true });
        return response.data;
    } catch (error) {
        console.error('Error fetching posts:', error);
        throw error;
    }
});
export const createPost = createAsyncThunk('posts/createPost', async ({ caption, imageBlob }) => {
    try {
        const formData = new FormData();
        formData.append('caption', caption);
        formData.append('image', imageBlob, 'cropped_image.jpg');

        const response = await autoInstance.post(`/posts/create`, formData, {
            withCredentials: true,
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });

        return response.data.post;
    } catch (error) {
        throw error;
    }
});