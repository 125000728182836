import { Menu, MenuButton, MenuItem } from '@szhsin/react-menu';
import moment from 'moment';
import React, { useLayoutEffect, useRef, useState } from 'react';
import { icons } from '../../helpers/images';
import autoInstance from '../../utils/axios';
import { useSocket } from '../Socket/WebSocketContext';
import { IMessageType } from './MessageInput';
import { Link } from 'react-router-dom';
import DocumentViewer from './component/DocumentViewer';

const ChatBody = ({ selectedUser }) => {
  const { message, getMessageListByConversationId } = useSocket();
  const chatContainerRef = useRef(null);
  const [messageDeleteing, setMessageDeleting] = useState(false);

  const sessionUserString = localStorage.getItem('g2n0User');
  const sessionUser = sessionUserString ? JSON.parse(sessionUserString) : null;

  useLayoutEffect(() => {
    // Scroll to the bottom of the chat container when a new message is received
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
    }
  }, [message]);

  const messageDelete = async (id) => {
    setMessageDeleting(true);
    try {
      const response = await autoInstance.delete(`/messages/delete-messages/${id}`);
      if (response.status === 200) {
        getMessageListByConversationId(selectedUser?.conversation);
      }
      setMessageDeleting(false);
    } catch (error) {
      console.error(error);
      setMessageDeleting(false);
    }
  }



  return (
    <div ref={chatContainerRef} className='2md:h-[calc(100vh_-_324px)] 2sm:h-[calc(100vh_-_300px)] h-[calc(100vh_-_280px)]  py-[13px] overflow-y-auto relative'>
      {message?.map((value, index) => {
        let isLeftMessage = false;
        if (sessionUser?._id === value.toUserId?._id) {
          isLeftMessage = true;
        }
        const messageClass = isLeftMessage ? 'bg-gray-300 p-2 rounded-lg' : 'bg-blue-500 p-2 rounded-lg text-white';
        const timeFormat = moment(value?.createdAt).format('LT');
        return (
          <div key={index} className={`group transition-all duration-300 flex justify-start  relative py-[12px]  gap-[10px] w-full`} >
            {value.fromUserId?.profileImage ? (
              <div className={`w-[24px] h-[24px] 2md:rounded-[8px] 2sm:rounded-[6px] rounded-[4px] overflow-hidden`}>
                <img src={process.env.REACT_APP_IMAGE_URL + value.fromUserId?.profileImage} alt={value.fromUserId.first_name} className='w-full h-full object-cover' />
              </div>
            ) : (
              <div
                className="cursor-pointer w-[24px] h-[24px] 2md:rounded-[8px] 2sm:rounded-[6px] rounded-[4px] overflow-hidden flex items-center justify-center border border-[#EFF3F4]"
                dangerouslySetInnerHTML={{ __html: icons.defaultProfile }}
              />
            )}
            <div className='lg:max-w-[55%] 2md:max-w-[60%] md:max-w-[70%] sm:max-w-[80%] max-w-[90%] '>
              <div className='flex items-start justify-start gap-[5px]'>
                <Link to={`${isLeftMessage ? `/user/in/${value.fromUserId?._id}` : ``}`} className='text-[16px] font-medium leading-[110%]'>{`
                ${isLeftMessage ? value.fromUserId?.first_name ?? "" + " " + value.fromUserId?.last_name ?? "" : "You"}
                `}</Link>
                <p className='text-[12px] font-normal leading-[140%] text-[#6D7486]'>{timeFormat}</p>
              </div>
              <div className={`w-full`}>
                {
                  value?.msgType === IMessageType.IMAGE && (
                    <div className='pt-[2px]'>
                      <img src={process.env.REACT_APP_IMAGE_URL + value?.attachment_url} alt={value.content} className='w-full h-full object-cover rounded-[6px] overflow-hidden' />
                      <pre className={`text-[14px] font-normal leading-[140%] mt-[10px] text-[#000] w-full caption-text`}>{value.content}</pre>
                    </div>
                  )
                }
                {
                  value?.msgType === IMessageType.VIDEO && (
                    <div className='pt-[2px]'>
                       <iframe src={process.env.REACT_APP_IMAGE_URL + value?.attachment_url} width="800" height="600" className='w-full h-full object-cover rounded-[6px] overflow-hidden'></iframe>
                      <pre className={`text-[14px] font-normal leading-[140%] mt-[10px] text-[#000] w-full caption-text`}>{value.content}</pre>
                    </div>
                  )
                }
                {
                  value?.msgType === IMessageType.DOCUMENT && (
                    <DocumentViewer value={value} />
                  )
                }
                {/* {
                  value?.msgType === IMessageType.DOCUMENT && (
                    <div className='pt-[2px]'>
                      <iframe src={process.env.REACT_APP_IMAGE_URL + value?.attachment_url} width="800" height="600" className='w-full h-full object-cover rounded-[6px] overflow-hidden'></iframe>
                      <pre className={`text-[14px] font-normal leading-[140%] mt-[10px] text-[#000] w-full caption-text`}>{value.content}</pre>
                    </div>
                  )
                } */}
                {
                  value?.msgType === IMessageType.TEXT && (
                    <pre className={`text-[14px] font-normal leading-[140%] w-full caption-text`}>{value.content}</pre>
                  )
                }

            </div>
          </div>
            {
          !isLeftMessage &&
            <Menu
              direction={"bottom"}
              align={"end"}
              viewScroll={"close"}
              menuButton={
                <MenuButton>
                  <div className='group-hover:block hidden transition-all duration-300 cursor-pointer ' >
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M8.00016 8.66683C8.36835 8.66683 8.66683 8.36835 8.66683 8.00016C8.66683 7.63197 8.36835 7.3335 8.00016 7.3335C7.63197 7.3335 7.3335 7.63197 7.3335 8.00016C7.3335 8.36835 7.63197 8.66683 8.00016 8.66683Z"
                        stroke="#6D7486"
                        strokeWidth="1.4"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M8.00016 4.00008C8.36835 4.00008 8.66683 3.7016 8.66683 3.33341C8.66683 2.96522 8.36835 2.66675 8.00016 2.66675C7.63197 2.66675 7.3335 2.96522 7.3335 3.33341C7.3335 3.7016 7.63197 4.00008 8.00016 4.00008Z"
                        stroke="#6D7486"
                        strokeWidth="1.4"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M8.00016 13.3333C8.36835 13.3333 8.66683 13.0349 8.66683 12.6667C8.66683 12.2985 8.36835 12 8.00016 12C7.63197 12 7.3335 12.2985 7.3335 12.6667C7.3335 13.0349 7.63197 13.3333 8.00016 13.3333Z"
                        stroke="#6D7486"
                        strokeWidth="1.4"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </div>
                </MenuButton>
              }
              transition
            >
              <MenuItem onClick={() => { messageDelete(value?._id) }}>
                <span className="!border-[0px]">
                  Delete
                </span>
              </MenuItem>
            </Menu>
        }
          </div>
  );
})}
    </div >
  );
};

export default ChatBody;
