import { TabPanel, TabView } from 'primereact/tabview'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import Bluebtn from '../Components/Common/Btns/Bluebtn'
import CollaborationsCard from '../Components/Common/CollaborationsCard'
import CommunitiesCard from '../Components/Common/CommunitiesCard'
import CustomModal from '../Components/Common/CustomModel'
import Navbar from '../Components/Common/Navbar'
import PageLoader from '../Components/Common/PageLoader'
import Sidebar from '../Components/Common/Sidebar'
import CreateCommunityCollaboration from '../Components/CreateCommunityCollaboration'
import { icons } from '../helpers/images'
import autoInstance from '../utils/axios'
import { selectUser } from '../utils/reduxtoolkit/slices/userSlice'

const CommunityCollaborationLayout = (props) => {
    const { pageTitle, listUrl, FilterUrl } = props;
    const [showAll, setShowAll] = useState(false);
    const user = useSelector(selectUser);
    const [Communitiesdata, setCommunitiesdata] = useState([])
    const [pageLoaded, setPageLoaded] = useState(true);
    const [myCommunitiesdata, setMyCommunitiesdata] = useState([])
    const [AllCommunitiesdata, setAllCommunitiesdata] = useState([])
    const [Collaboratingdata, setCollaboratingdata] = useState([])
    const [CreateNew, setCreateNew] = useState(false);
    const [myCollaboratingdata, setMyCollaboratingdata] = useState([])
    const [AllCollaboratingdata, setAllCollaboratingdata] = useState([])
    const [activeIndex, setActiveIndex] = useState(0);
    const [updateList, setUpdateList] = useState(false);


    useEffect(() => {
        try {

            if (pageTitle === "Communities") {
                getCommunitydata()
                // getAllCommunitiesdata()
            }
            if (pageTitle === "Collaborations") {
                getColloborationdata()
                // getAllColloborationdata()
            }
        } catch (error) {
            console.error(error)
        }
    }, [CreateNew, pageTitle, updateList])
    const getCommunitydata = async () => {
        const otherCommunitiesResponse = await autoInstance('/community?type=other')
        const myCommunitiesResponse = await autoInstance('/community?type=my-community')

        const myCommunityList = myCommunitiesResponse?.data?.data ?? []
        const suggestedList = otherCommunitiesResponse?.data?.data ?? []
        setCommunitiesdata(suggestedList);
        setMyCommunitiesdata(myCommunityList);
        setPageLoaded(false)
    }
    const getColloborationdata = async () => {
        const allCollaborationResponse = await autoInstance('/collaboration?type=other')
        const myCollaborationResponse = await autoInstance('/collaboration?type=my-community')

        const myCollaborationList = myCollaborationResponse?.data?.data ?? []
        const suggestedList = allCollaborationResponse?.data?.data ?? []
        setCollaboratingdata(suggestedList)
        setMyCollaboratingdata(myCollaborationList)
        setPageLoaded(false)
    }

    const getAllCommunitiesdata = async () => {
        const allCommunitiesResponse = await autoInstance('/community?type=all')
        setAllCommunitiesdata(allCommunitiesResponse.data.data);
        setPageLoaded(false)
    }

    const getAllColloborationdata = async () => {
        const allCollaborationResponse = await autoInstance('/collaboration?type=all')
        setAllCollaboratingdata(allCollaborationResponse.data.data)
        setPageLoaded(false)
    }


    const handleUpdateContents = () => {
        if (pageTitle === "Communities") {
            getCommunitydata()
            // getAllCommunitiesdata()
        }
        if (pageTitle === "Collaborations") {
            getColloborationdata()
            // getAllColloborationdata()
        }
    };
    const Suggested = (options) => {
        return (
            <div className='flex items-center justify-start gap-[4px] p-[12px]' style={{ cursor: 'pointer' }} onClick={options.onClick}>
                <p className={`font-[500] text-[#6D7486] white-space-nowrap sm:text-[14px] text-[12px] leading-[110%] `}>Suggested</p>
                <div className='w-[16px] h-[16px] bg-[#EAF4FC] rounded-full flex-center sm:text-[10px] text-[8px] font-medium leading-[110%]'>
                    {pageTitle === "Communities" ? Communitiesdata.length : Collaboratingdata.length}
                </div>
            </div>

        )
    };
    const MyCommunities = (options) => {
        return (
            <div className='flex items-center justify-start gap-[4px] p-[12px]' style={{ cursor: 'pointer' }} onClick={options.onClick}>
                <p className={`font-[500] text-[#6D7486] white-space-nowrap sm:text-[14px] text-[12px] leading-[110%] `}>My {pageTitle}</p>
                <div className='w-[16px] h-[16px] bg-[#EAF4FC] rounded-full flex-center sm:text-[10px] text-[8px] font-medium leading-[110%]'>
                    {pageTitle === "Communities" ? myCommunitiesdata.length : myCollaboratingdata.length}
                </div>
            </div>
        )
    };
    const All = (options) => {
        return (
            <div className='flex items-center justify-start gap-[4px] p-[12px]' style={{ cursor: 'pointer' }} onClick={options.onClick}>
                <p className="font-[500] text-[#6D7486] white-space-nowrap sm:text-[14px] text-[12px] leading-[110%] ">All</p>
            </div>
        )
    };

    const sortedCommunities = React.useMemo(() => {
        const sorted = [...myCommunitiesdata];

        sorted.sort((a, b) => {
            // Sorting by isActive: false first
            if (!a.isActive && b.isActive) return -1;
            if (a.isActive && !b.isActive) return 1;

            // Sorting by userId._id === user._id next
            const aIsUser = a.userId && a.userId._id === user._id;
            const bIsUser = b.userId && b.userId._id === user._id;

            if (aIsUser && !bIsUser) return -1;
            if (!aIsUser && bIsUser) return 1;

            // Default order for other cases (remaining items)
            return 0;
        });

        return sorted;
    }, [myCommunitiesdata, user._id]);
    const sortedColloboration = React.useMemo(() => {
        const sorted = [...myCollaboratingdata];

        sorted.sort((a, b) => {
            // Sorting by isActive: false first
            if (!a.isActive && b.isActive) return -1;
            if (a.isActive && !b.isActive) return 1;

            // Sorting by userId._id === user._id next
            const aIsUser = a.userId && a.userId._id === user._id;
            const bIsUser = b.userId && b.userId._id === user._id;

            if (aIsUser && !bIsUser) return -1;
            if (!aIsUser && bIsUser) return 1;

            // Default order for other cases (remaining items)
            return 0;
        });

        return sorted;
    }, [myCollaboratingdata, user._id]);


    return (
        <div>
            {pageLoaded &&
                <PageLoader />
            }
            {/* Header */}
            <Navbar />
            {/* ENd Header */}
            <div className='flex items-start justify-start lg:h-[calc(100vh_-_80px)] 2sm:h-[calc(100vh_-_70px)] h-[calc(100vh_-_128px)] overflow-hidden'>
                {/* Sidebar  */}
                <Sidebar />
                {/* Sidebar End */}
                <div className='h-full bg-[#F3F5F7] 3lg:w-[calc(100%_-_300px)] lg:w-[calc(100%_-_250px)] 2sm:w-[calc(100%_-_60px)] w-full'>
                    <div className='bg-white w-full'>
                        <div className='3lg:w-[740px]  2lg:w-[570px] 2md:w-[512px] w-full '>
                            <div className='2sm:pt-[24px] pt-[20px] 2sm:pb-[12px] pb-[10px] 2sm:pl-[24px] pl-[20px] 2md:pr-[0px] 2sm:pr-[24px] pr-[20px] flex items-center justify-between '>
                                <p className='2sm:text-[24px] text-[20px] font-[600] leading-[110%]'>{pageTitle === "Communities" ? pageTitle : "I’m Collaborating on"}</p>
                                <Bluebtn
                                    onClick={() => { setCreateNew(!CreateNew) }}
                                    className='py-[8px] sm:px-[16px] px-[8px] rounded-[6px] leading-[110%] text-[14px] font-medium text-white ' icon={<div className='sm:hidden flex items-center justify-center white-icon text-white' dangerouslySetInnerHTML={{ __html: icons.Plus }} />} Label={<p className='sm:block hidden'>Create New</p>} />
                            </div>
                            <CustomModal showModal={CreateNew} bodyClassName={"2sm:h-auto h-screen 2sm:rounded-[16px] rounded-[0px]"} setShowModal={setCreateNew}>
                                <CreateCommunityCollaboration pageTitle={pageTitle === "Communities" ? "Community" : "Collaboration"} setCreateNew={setCreateNew} />
                            </CustomModal>
                        </div>
                        {/* Tabs  */}
                        <div className=' bg-white Comunity-tabs  relative '>
                            <div className=''>
                                <TabView className='CommunityTabsFlex'>
                                    {/* MyCommunities Tab */}
                                    <TabPanel headerTemplate={MyCommunities}>
                                        <div className={`bg-[#F3F5F7] 3lg:w-[740px]  2lg:w-[570px] 2md:w-[495px] w-full   space-y-[8px] h-[calc(100vh_-_220px)] hide-scrollbar overflow-auto ${myCommunitiesdata?.length > 0 || myCollaboratingdata?.length > 0 ? " " : "flex-center"} `}>
                                            {
                                                myCommunitiesdata?.length > 0 || myCollaboratingdata?.length > 0 ?
                                                    <>
                                                        {
                                                            pageTitle === "Communities" ?
                                                                <>
                                                                    {
                                                                        sortedCommunities?.map((item, index) => (
                                                                            <CommunitiesCard key={index} item={item} updateContents={handleUpdateContents} />
                                                                        ))
                                                                    }
                                                                </>
                                                                :
                                                                <>
                                                                    {
                                                                        sortedColloboration?.map((item, index) => <CollaborationsCard key={index} item={item} updateContents={handleUpdateContents} />)
                                                                    }
                                                                </>
                                                        }
                                                    </> :
                                                    <div className='absolute top-[50%] left-[50%] translate-y-[-50%] translate-x-[-50%]'>
                                                        <div className='mx-auto EmptySvg' dangerouslySetInnerHTML={{ __html: icons.NoData }} />
                                                        <p className='text-[14px] font-medium text-center leading-[110%] text-[#6D7486] mt-[24px] '>You have not joined or created a  {pageTitle}.</p>
                                                    </div>
                                            }
                                        </div>
                                    </TabPanel>
                                    {/* Suggested Tab */}
                                    <TabPanel headerTemplate={Suggested} className=''>
                                        <div className={`bg-[#F3F5F7] 3lg:w-[740px]  2lg:w-[570px] 2md:w-[495px] w-full   space-y-[8px] h-[calc(100vh_-_220px)] hide-scrollbar overflow-auto ${Communitiesdata?.length > 0 || Collaboratingdata?.length > 0 ? "" : "flex-center"}`}>
                                            {
                                                Communitiesdata?.length > 0 || Collaboratingdata?.length > 0 ?
                                                    <>
                                                        {
                                                            pageTitle === "Communities" ?
                                                                <>
                                                                    {
                                                                        Communitiesdata.map((item, index) => <CommunitiesCard key={index} item={item} updateContents={handleUpdateContents} />)
                                                                    }
                                                                </>
                                                                :
                                                                <>
                                                                    {
                                                                        Collaboratingdata.map((item, index) => <CollaborationsCard key={index} item={item} updateContents={handleUpdateContents} />)
                                                                    }
                                                                </>
                                                        }
                                                    </> :
                                                    <div className='absolute top-[50%] left-[50%] translate-y-[-50%] translate-x-[-50%]'>
                                                        <div className='mx-auto EmptySvg' dangerouslySetInnerHTML={{ __html: icons.NoData }} />
                                                        <p className='text-[14px] font-medium text-center leading-[110%] text-[#6D7486] mt-[24px] '>You do not have any suggested {pageTitle}.</p>
                                                    </div>
                                            }
                                        </div>
                                    </TabPanel>

                                    {/* All */}
                                    <TabPanel headerTemplate={All}>
                                        <div className={`bg-[#F3F5F7] 3lg:w-[740px]  2lg:w-[570px] 2md:w-[495px] w-full    space-y-[8px] h-[calc(100vh_-_220px)] hide-scrollbar overflow-auto ${Communitiesdata?.length > 0 || myCommunitiesdata?.length > 0 || Collaboratingdata?.length > 0 || myCollaboratingdata?.length > 0 ? "" : "flex-center"} `}>
                                            {
                                                Communitiesdata?.length > 0 || myCommunitiesdata?.length > 0 || Collaboratingdata?.length > 0 || myCollaboratingdata?.length > 0 ?
                                                    <>
                                                        {
                                                            pageTitle === "Communities" ?
                                                                <>
                                                                    {
                                                                        [...Communitiesdata, ...sortedCommunities]?.map((item, index) => <CommunitiesCard key={index} item={item} updateContents={handleUpdateContents} />)
                                                                    }
                                                                </>
                                                                :
                                                                <>
                                                                    {
                                                                        [...Collaboratingdata, ...sortedColloboration]?.map((item, index) => <CollaborationsCard key={index} item={item} updateContents={handleUpdateContents} />)
                                                                    }
                                                                </>
                                                        }
                                                    </> :
                                                    <div className='absolute top-[50%] left-[50%] translate-y-[-50%] translate-x-[-50%]'>
                                                        <div className='mx-auto EmptySvg' dangerouslySetInnerHTML={{ __html: icons.NoData }} />
                                                        <p className='text-[14px] font-medium text-center leading-[110%] text-[#6D7486] mt-[24px] '>You have not joined or created a  {pageTitle}.</p>
                                                    </div>
                                            }
                                        </div>
                                    </TabPanel>
                                </TabView>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CommunityCollaborationLayout