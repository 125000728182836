import Cookies from 'js-cookie';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import autoInstance from '../../../utils/axios';
import { login, selectUser } from '../../../utils/reduxtoolkit/slices/userSlice';
import PageLoader from '../../Common/PageLoader';
import AuthLayout from '../AuthLayout';
import Step1 from './Step1';
import Step2 from './Step2';

function Signup() {
  const location = useLocation();
  const user = useSelector(selectUser);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [PageLoading, setPageLoading] = useState(true); // Initially true to show loader until checked
  const queryParams = new URLSearchParams(location.search);
  const [token, setToken] = useState(queryParams.get('token'));
  const [queryStep, setqueryStep] = useState(queryParams.get('step'));
  const [apiDataLoaded, setApiDataLoaded] = useState(true);
  const [step, setStep] = useState(() => {
    const storedStep = queryStep ? queryStep : 1;
    if (storedStep === undefined) { return 1; }
    return storedStep ? parseInt(storedStep) : 1;
  });

  const setStephandler = (step) => {
    setStep(step);
  };

  useEffect(() => {
    const state = queryParams.get('state');
    const token = queryParams.get('token');

    if (state || token) {
      switch (state) {
        case 'linkdin':
          setApiDataLoaded(false);
          setPageLoading(true);
          linkdinUser();
          break;

        default:
          if (token) {
            setStep(2);
            setApiDataLoaded(true);
            setPageLoading(false);
          }
          break;
      }
    } else {
      setPageLoading(false); // No query params, stop loading
    }
  }, [queryParams]);

  const linkdinUser = useCallback(async () => {
    const token = queryParams.get('token');
    const expirationDate = new Date();
    expirationDate.setMonth(expirationDate.getMonth() + 2);
    if(token){
      Cookies.set('g2n0Auth', token, { expires: expirationDate });
    }
    try {
      const response = await autoInstance.get(`/user`);
      if (response.status === 200) {
        dispatch(login({
          user: response.data.data,
          userType: response.data.data.roleId,
        }));
        navigate('/profiledetails');
        setPageLoading(false);
        setApiDataLoaded(true);
      }
    } catch (error) {
      setPageLoading(false);
      setApiDataLoaded(true);
    }
  }, [queryParams, dispatch, navigate]);

  const goToNextStep = () => {
    setStep((prevStep) => prevStep + 1);
  };

  const goToPreviousStep = () => {
    setStep((prevStep) => prevStep - 1);
  };

  if (PageLoading) {
    return <PageLoader />;
  }

  return (
    apiDataLoaded && (
      <AuthLayout>
        <>
          {step === 1 && <Step1 onNextStep={goToNextStep} setStephandler={setStephandler} />}
          {step === 2 && <Step2 onNextStep={goToNextStep} onPreviousStep={goToPreviousStep} />}
        </>
      </AuthLayout>
    )
  );
}

export default Signup;
