import { createSlice } from '@reduxjs/toolkit';
import Cookies from 'js-cookie';
import Toast from '../../../helpers/Toast';

const initialState = {
    user: JSON.parse(localStorage.getItem('g2n0User')) || null,
    userType: localStorage.getItem('g2n0UserType') || null,
    isAuthenticated: !!Cookies.get('g2n0Auth'),
};

const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        step1: (state, action) => {
            const { email } = action.payload;
            state.user = { email: email };
            localStorage.setItem('g2n0User', JSON.stringify({ email: state.user.email }));
        },
        login: (state, action) => {
            const { user, userType } = action.payload;
            state.user = user || null;
            state.userType = userType;
            localStorage.setItem('g2n0User', JSON.stringify(state.user));
            localStorage.setItem('g2n0UserType', userType);
            state.isAuthenticated = true;
        },
        adminLogin: (state, action) => {
            const { admin } = action.payload;
            state.user = admin || null;
            state.isAuthenticated = true;
        },
        logout: (state) => {
            state.user = null;
            state.userType = null;
            state.isAuthenticated = false;
            Cookies.remove('g2n0Auth');
            localStorage.removeItem('g2n0User');
            localStorage.removeItem('g2n0UserType');
            Toast('Logout Successful', 'success');
        },
        setUserType: (state, action) => {
            const { userType } = action.payload;
            Cookies.set('g2n0UserType', userType);
        },
        updateUser: (state, action) => {
            state.user = action.payload;
            localStorage.setItem('g2n0User', JSON.stringify(state.user));
        }
    },
});


export const { login, logout, setUserType, updateUser, step1, adminLogin } = userSlice.actions;
export const selectUser = (state) => state.user.user;
export const getUserType = (state) => state.user.userType;
export default userSlice.reducer;