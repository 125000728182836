import React from 'react';
import ReactDOM from 'react-dom';

const CustomModal = ({ showModal, setShowModal, children, overlayClassName, bodyClassName, ClearData , ModelType }) => {
  const OnclickHandler = () => {

    if (ModelType === 'Experience') {

    }else{
      setShowModal(false)
    }
    if (ClearData) { ClearData() }

  }
  return (
    <>
      {showModal &&
        ReactDOM.createPortal(
          <div className={`absolute top-0 left-0 flex items-center justify-center h-screen w-screen `}>
            <div className={`modal-overlay z-[500] w-screen h-screen absolute top-0 left-0 bg-[rgba(0,0,0,0.5)] cursor-pointer ${overlayClassName}`} onClick={() => OnclickHandler()}></div>
            <div className={`modal-content w-[600px] bg-white   relative z-[555] ${bodyClassName}`}>
              {children}
            </div>
          </div>,
          document.body
        )}
    </>
  );
};

export default CustomModal;