import Home from "../../../pages/Home"
import ClimateVault from "../../../pages/ClimateVault"
import ClimateVaultDetails from "../../../pages/ClimateVault/ClimateVaultDetails"
const ClimateVaultRoutes = {
    path: "/climatevault",
    children: [
        {
            path: "",
            element: <ClimateVault />,
            // element: <Home />,
        },
        {
            path: ":id",
            element: <ClimateVaultDetails />,
            // element: <Home />,
        },
    ]
}
export default ClimateVaultRoutes