import axios from 'axios';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { icons, images } from '../../../helpers/images';
import Toast from '../../../helpers/Toast';
import autoInstance from '../../../utils/axios';
import { login, selectUser } from '../../../utils/reduxtoolkit/slices/userSlice';
import Bluebtn from '../../Common/Btns/Bluebtn';
import CustomInput from '../../Common/Inputs/CustomInput';
import CustomSelect from '../../Common/Select/CustomSelect';


function Step3({ onNextStep }) {
    const [selectedOption, setSelectedOption] = useState('');
    const user = useSelector(selectUser)
    const userType = localStorage.getItem('g2n0UserType');
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const [token, settoken] = useState(queryParams.get('token'));
    const [selectedLocation, setSelectedLocation] = useState();
    const [firstName, setFirstName] = useState(
        user?.first_name ? user?.first_name : ''
    );
    const [lastName, setLastName] = useState(
        user?.last_name ? user?.last_name : ''
    );
    const [otherValue, setOtherValue] = useState('');
    const [signupLoading, setSignupLoading] = useState(false);
    const [showMoreAbout, setShowMoreAbout] = useState(false);
    const [gender, setGender] = useState();
    const [errorState, setErrorState] = useState({
        firstNameError: "",
        lastNameError: "",
        locationError: "",
        IamError: "",
        educationLevelError: "",
        ageError: "",
        genderError: "",
        industryError: "",
        interestError: "",
    });
    const [companyErrorState, setCompanyErrorState] = useState({
        companyNameError: "",
        companyIamError: "",
        companyInterestError: "",
        companyIndustryError: "",
        companyLocationError: "",
    })
    const [educationLevelError, seteducationLevelError] = useState();
    const [locationOptions, setLocationOptions] = useState([]);
    const [countries, setCountries] = useState([]);
    const [filteredOptions, setFilteredOptions] = useState([]);
    const [filterValue, setFilterValue] = useState('');
    const [genderOptions, setGenderOptions] = useState([
        { value: 'Male', label: 'Male' },
        { value: 'Female', label: 'Female' },
        { value: 'Prefer not to disclose', label: 'Prefer not to disclose' },
    ]);
    const [ageOptions, setageOptions] = useState([
        { value: '<18', label: '<18' },
        { value: '18-25', label: '18-25' },
        { value: '26-35', label: '26-35' },
        { value: '36-45', label: '36-45' },
        { value: '46-60', label: '46-60' },
        { value: '61+', label: '61+' },
    ]);
    const [educationLevelOptions, seteducationLevelOptions] = useState([
        { value: 'No Formal Education', label: 'No Formal Education' },
        { value: 'Elementary School', label: 'Elementary School' },
        { value: 'High School or Equivalent', label: 'High School or Equivalent' },
        { value: 'Associate’s Degree', label: 'Associate’s Degree' },
        { value: 'Bachelor’s Degree', label: 'Bachelor’s Degree' },
        { value: 'Master’s Degree', label: 'Master’s Degree' },
        { value: 'Doctoral Degree', label: 'Doctoral Degree' },
        { value: 'Other', label: 'Other' },
    ]);
    const [IndustryOptions, setIndustryOptions] = useState([
        { value: 'Agriculture', label: 'Agriculture' },
        { value: 'Aerospace', label: 'Aerospace' },
        { value: 'Automotive', label: 'Automotive' },
        { value: 'Banking and Finance', label: 'Banking and Finance' },
        { value: 'Biotech', label: 'Biotech' },
        { value: 'Climate Technologies', label: 'Climate Technologies' },
        { value: 'Construction', label: 'Construction' },
        { value: 'Education', label: 'Education' },
        { value: 'Energy (Oil, Gas)', label: 'Energy (Oil, Gas)' },
        { value: 'Energy (Renewable)', label: 'Energy (Renewable)' },
        { value: 'Entertainment', label: 'Entertainment' },
        { value: 'Food and Beverage', label: 'Food and Beverage' },
        { value: 'Healthcare', label: 'Healthcare' },
        { value: 'Hospitality (Hotels, Restaurants)', label: 'Hospitality (Hotels, Restaurants)' },
        { value: 'Information Technology (IT)', label: 'Information Technology (IT)' },
        { value: 'Insurance', label: 'Insurance' },
        { value: 'Manufacturing', label: 'Manufacturing' },
        { value: 'Media and Communications', label: 'Media and Communications' },
        { value: 'Pharmaceuticals', label: 'Pharmaceuticals' },
        { value: 'Real Estate', label: 'Real Estate' },
        { value: 'Retail', label: 'Retail' },
        { value: 'Telecommunications', label: 'Telecommunications' },
        { value: 'Transportation and Logistics', label: 'Transportation and Logistics' },
        { value: 'Utilities', label: 'Utilities' },
        { value: 'Other, please specify', label: 'Other, please specify' },
    ]);
    const [interestOptions, setInterestOptions] = useState([
        { value: 'Climate Science', label: 'Climate Science' },
        { value: 'Ecology & Bio-diversity', label: 'Ecology & Bio-diversity' },
        { value: 'Renewable & Alternate Energy Sources', label: 'Renewable & Alternate Energy Sources' },
        { value: 'Carbon Capture and Storage or Repurposing', label: 'Carbon Capture and Storage or Repurposing' },
        { value: 'GHG Emissions Control', label: 'GHG Emissions Control' },
        { value: 'Policy & Regulation', label: 'Policy & Regulation' },
        { value: 'Mitigation related Project Funding', label: 'Mitigation related Project Funding' },
        { value: 'Monitoring and Data Analysis', label: 'Monitoring and Data Analysis' },
        { value: 'Networking for Business Development', label: 'Networking for Business Development' },
        { value: 'Access to Affordable Technologies', label: 'Access to Affordable Technologies' },
        { value: 'Partnerships and Collaboration', label: 'Partnerships and Collaboration' },
        { value: 'Other, please specify', label: 'Other, please specify' },
    ]);
    const [aboutUserData, setAboutUserData] = useState({
        name: '',
        email: '',
        location: '',
        Iam: '',
        age: '',
        gender: '',
        educationLevel: '',
        industry: null,
        interest: null,
    });

    const [otherAboutUserData, setOtherAboutUserData] = useState({
        name: '',
        email: '',
        location: '',
        Iam: '',
        age: '',
        gender: '',
        educationLevel: '',
        industry: null,
        interest: null,
    });

    const [aboutCompanyData, setAboutCompanyData] = useState({
        companyName: "",
        companyLocation: "",
        companyIam: "",
        companyIndustry: null,
        companyInterest: null,
    });
    const [optionsOtherValue, setOptionsOtherValue] = useState({
        otherEducationLevel: false,
        otherIndustry: false,
        otherInterest: false,
    })


    useEffect(() => {
        getCountryName()
    }, [])

    const handleError = (step = 1) => {
        setErrorState({
            firstNameError: "",
            lastNameError: "",
            locationError: "",
            IamError: "",
            educationLevelError: "",
            ageError: "",
            genderError: "",
            industryError: "",
            interestError: "",
        })
        let isError = false;
        if (step === 1) {
            if (firstName === '') {
                setErrorState((prev) => ({ ...prev, firstNameError: 'Rrequired' }));
                isError = true
            }
            if (lastName === '') {
                setErrorState((prev) => ({ ...prev, lastNameError: 'Required' }));
                isError = true
            }
            if (aboutUserData.location === '') {
                setErrorState((prev) => ({ ...prev, locationError: 'Required' }));
                isError = true
            }
            if (selectedOption === '') {
                setErrorState((prev) => ({ ...prev, IamError: 'Required' }));
                isError = true
            }
        } else {

            if (aboutUserData.age === '') {
                setErrorState((prev) => ({ ...prev, ageError: 'Required' }));
                isError = true
            }
            if (aboutUserData.gender === '') {
                setErrorState((prev) => ({ ...prev, genderError: 'Required' }));
                isError = true
            }
            if (aboutUserData.educationLevel === '') {
                setErrorState((prev) => ({ ...prev, educationLevelError: 'Required' }));
                isError = true
            }
            if (aboutUserData.interest === null) {
                setErrorState((prev) => ({ ...prev, interestError: 'Required' }));
                isError = true
            }
            if (aboutUserData.industry === null) {
                setErrorState((prev) => ({ ...prev, industryError: 'Required' }));
                isError = true
            }
        }
        if (isError) throw new Error("Validation Error");
    }
    const handleCompanyError = (step = 1) => {
        setCompanyErrorState({
            companyNameError: "",
            companyIamError: "",
            companyInterestError: "",
            companyLocationError: "",
            companyIndustryError: "",
            companyIamError: "",
        });


        let isError = false;

        if (step === 1) {
            if (aboutCompanyData.companyName === "") {
                setCompanyErrorState((prev) => ({ ...prev, companyNameError: "Required" }));
                isError = true

            }
            if (selectedOption === "") {
                setCompanyErrorState((prev) => ({ ...prev, companyIamError: "Required" }));
                isError = true
            }
            if (aboutCompanyData.companyLocation === "") {
                setCompanyErrorState((prev) => ({ ...prev, companyLocationError: "Required" }));
                isError = true
            }
        } else if (step === 2) {
            if (aboutCompanyData.companyInterest === null) {
                setCompanyErrorState((prev) => ({ ...prev, companyInterestError: "Required" }));
                isError = true
            }
            if (aboutCompanyData.companyIndustry === null) {
                setCompanyErrorState((prev) => ({ ...prev, companyIndustryError: "Required" }));
                isError = true
            }
        }

        if (isError) throw new Error("Validation Error");


    }

    const UserHandleOptionChange = (e) => {
        setSelectedOption(e.target.value);
    };

    const OptionshandleChange = (selected, field) => {
        if (field === 'interest' || field === 'industry') {
            const selectedValues = selected.map(option => option.label);
            setAboutUserData(prev => ({ ...prev, [field]: selectedValues }));
        } else {
            setAboutUserData(prev => ({ ...prev, [field]: selected.label }));
        }
    };
    const OptionCompanyshandleChange = (selected, field) => {
        if (field === 'companyInterest' || field === 'companyIndustry') {
            const selectedValues = selected.map(option => option.label);
            setAboutCompanyData(prev => ({ ...prev, [field]: selectedValues }));
        } else {
            setAboutCompanyData(prev => ({ ...prev, [field]: selected.label }));
        }
    };


    const onevarIndustry = Array.isArray(otherAboutUserData.industry) ? otherAboutUserData.industry : (otherAboutUserData.industry ? [otherAboutUserData.industry] : []);
    const secondvarIndustry = Array.isArray(aboutUserData.industry) ? aboutUserData.industry : (aboutUserData.industry ? [aboutUserData.industry] : []);
    const secondvarCompanyIndustry = Array.isArray(aboutCompanyData.companyIndustry) ? aboutCompanyData.companyIndustry : (aboutCompanyData.companyIndustry ? [aboutCompanyData.companyIndustry] : []);
    const mergedIndustry = [...new Set([...secondvarIndustry, ...onevarIndustry])];
    const mergedCompanyIndustry = [...new Set([...secondvarCompanyIndustry, ...onevarIndustry])];

    const onevarInterest = Array.isArray(otherAboutUserData.interest) ? otherAboutUserData.interest : (otherAboutUserData.interest ? [otherAboutUserData.interest] : []);
    const secondvarInterest = Array.isArray(aboutUserData.interest) ? aboutUserData.interest : (aboutUserData.interest ? [aboutUserData.interest] : []);
    const secondvarCompanyInterest = Array.isArray(aboutCompanyData.companyInterest) ? aboutCompanyData.companyInterest : (aboutCompanyData.companyInterest ? [aboutCompanyData.companyInterest] : []);
    const mergedInterest = [...new Set([...secondvarInterest, ...onevarInterest])];
    const mergedCompanyInterest = [...new Set([...secondvarCompanyInterest, ...onevarInterest])];
    const SaveUserData = async () => {

        try {
            handleError(2);
            // Send password data to backend API
            if (errorState.ageError === "" && errorState.genderError === "" && errorState.educationLevelError === "" && errorState.interestError === "" && errorState.industryError === "") {
                setSignupLoading(true);
                const response = await autoInstance.post(`/user/update-profile`, {
                    first_name: firstName,
                    last_name: lastName,
                    location: aboutUserData.location,
                    iam: otherValue ? otherValue : selectedOption,
                    age: aboutUserData.age,
                    gender: aboutUserData.gender,
                    levelEducation: otherAboutUserData.educationLevel ? otherAboutUserData.educationLevel : aboutUserData.educationLevel,
                    industry: mergedIndustry.filter(item => item !== "Other, please specify"),
                    areaOfInterest: mergedInterest.filter(item => item !== "Other, please specify"),
                });
                if (response.status === 200) {
                    dispatch(login({
                        user: response.data.data,
                        userType: response.data.data.roleId
                    }));
                    onNextStep();
                }
            }
        } catch (error) {
            console.error('Error setting password:', error.message);
            // Handle error, e.g., display error message to the user
        } finally {
            setSignupLoading(false);
        }
    };


    const SaveCompanyData = async () => {

        try {
            handleCompanyError(2);
            // Send password data to backend API
            if (companyErrorState.companyNameError === "" && companyErrorState.companyIamError === "" && companyErrorState.companyIndustryError === "" && companyErrorState.companyInterestError === "", companyErrorState.companyLocationError === "") {
                setSignupLoading(true);
                const response = await autoInstance.post(`/user/update-profile`, {
                    first_name: aboutCompanyData.companyName,
                    industry: mergedCompanyInterest.filter(item => item !== "Other, please specify"),
                    areaOfInterest: mergedCompanyIndustry.filter(item => item !== "Other, please specify"),
                    location: aboutCompanyData.companyLocation,
                    iam: otherValue ? otherValue : selectedOption
                });
                if (response.status === 200) {
                    dispatch(login({
                        user: response.data.data,
                        userType: response.data.data.roleId
                    }));
                    onNextStep();
                    // navigate('/signup?step=4');
                }
            }
        } catch (error) {
            console.error('Error setting password:', error.message);
            // Handle error, e.g., display error message to the user
        } finally {
            setSignupLoading(false);
        }
    };

    const getCountryName = useCallback(async () => {
        try {
            const response = await axios.get('https://countriesnow.space/api/v0.1/countries');
            if (response.status === 200) {
                const countryOptions = response.data.data.map(country => ({
                    label: country.country,
                    value: country.country,
                }));
                setLocationOptions(countryOptions);
                setCountries(response.data.data);
            } else {
                Toast(response.data.message, 'error');
            }
        } catch (error) {
            Toast(error.message, 'error');
        }
    }, []);

    const handleFilterChange = (value) => {
        setFilterValue(value);
        const filtered = locationOptions.filter(option =>
            option.label.toLowerCase().startsWith(value.toLowerCase())
        ).slice(0, 10); // Limiting to 10 options
        setFilteredOptions(filtered);
    };


    const handleNext = (type) => {
        if (type === 2) {
            try {
                handleCompanyError();
                if (companyErrorState.companyNameError === "" || selectedOption === "" || companyErrorState.companyLocationError === "") {
                    setShowMoreAbout(true);
                }
            }
            catch (error) {
                console.error(error.message);
            }
        } else {
            try {
                handleError();
                if (errorState.firstNameError === "" || errorState.lastNameError === "" || errorState.locationError === "" || errorState.IamError === "") {
                    setShowMoreAbout(true);
                }
            }
            catch (error) {
                console.error(error.message);
            }
        }
    }
    useEffect(() => {

        if (aboutUserData?.industry && aboutUserData?.industry?.includes("Other, please specify")) {
            setOptionsOtherValue(prevState => ({
                ...prevState,
                otherIndustry: true,
            }));
        } else if (aboutUserData?.industry && !aboutUserData.industry.includes("Other, please specify")) {
            setOptionsOtherValue(prevState => ({
                ...prevState,
                otherIndustry: false,
            }));
        }
        if (aboutUserData?.interest && aboutUserData?.interest?.includes("Other, please specify")) {
            setOptionsOtherValue(prevState => ({
                ...prevState,
                otherInterest: true,
            }));
        } else if (aboutUserData?.interest && !aboutUserData?.interest?.includes("Other, please specify")) {
            setOptionsOtherValue(prevState => ({
                ...prevState,
                otherInterest: false,
            }));
        }

        if (aboutUserData?.educationLevel === "Other") {
            setOptionsOtherValue(prevState => ({
                ...prevState,
                otherEducationLevel: true,
            }));
        } else if (!aboutUserData?.educationLevel === "Other") {
            setOptionsOtherValue(prevState => ({
                ...prevState,
                otherEducationLevel: false,
            }));
        }


    }, [aboutUserData]);


    return (
        <div className='w-full'>
            <div className='2md:flex grid justify-start 2md:max-w-[unset] md:max-w-[650px] max-w-[unset]'>
                <div className='2lg:py-[50px] lg:py-[40px] md:py-[35px] 2sm:py-[30px] sm:py-[25px] py-[20px] 2lg:pl-[50px] lg:pl-[40px] md:pl-[35px] 2sm:pl-[30px] sm:pl-[25px] pl-[20px] 2md:pr-[00px] md:pr-[35px] 2sm:pr-[30px] sm:pr-[25px] pr-[20px]'>
                    <div className=' 2md:w-[463px] '>
                        <h1 className='2lg:text-[24px] 2sm:text-[22px] md:text-[20px] text-[18px] leading-[110%] font-[500]'>{userType == `1` ? <>About you</> : <>Tell us more about your company</>}</h1>
                        {!showMoreAbout ?
                            <div className='w-full'>
                                <div className='space-y-[16px] pt-[30px]'>
                                    {userType == "1" &&
                                        <div className='grid 2md:grid-cols-2 gap-[16px]'>
                                            <div>
                                                <CustomInput defultValue={user?.first_name} onChange={(e) => setFirstName(e.target.value)} label='First Name*' type='text' placeholder='First Name' />
                                                {errorState.firstNameError && <p className='text-[14px] text-red-500'>{errorState.firstNameError}</p>}
                                            </div>
                                            <div>
                                                <CustomInput defultValue={user?.last_name} onChange={(e) => setLastName(e.target.value)} label='Last Name*' type='text' placeholder='Last name' />
                                                {errorState.lastNameError && <p className='text-[14px] text-red-500'>{errorState.lastNameError}</p>}
                                            </div>
                                        </div>
                                    }{userType == "2" &&
                                        <div>
                                            <CustomInput onChange={(e) => setAboutCompanyData({ ...aboutCompanyData, companyName: e.target.value })} defultValue={aboutCompanyData.companyName} label='Company name*' type='text' placeholder='Company name' />
                                            {companyErrorState.companyNameError && <p className='text-[14px] text-red-500'>{companyErrorState.companyNameError}</p>}
                                        </div>
                                    }
                                    {userType === "1" && (
                                        <div>
                                            <CustomSelect
                                                label="Select a Country"
                                                onChange={(selected) => OptionshandleChange(selected, "location")}
                                                options={filterValue ? filteredOptions : locationOptions}
                                                placeholder="Select a Country"
                                                className="mt-[6px] w-full "
                                                onInputChange={handleFilterChange}
                                            />
                                            {errorState.locationError && <p className='text-[14px] text-red-500'>{errorState.locationError}</p>}
                                        </div>
                                    )}

                                    {userType == "2" &&
                                        <div>
                                            <CustomSelect
                                                label="Company location"
                                                onChange={(selected) => OptionCompanyshandleChange(selected, "companyLocation")}
                                                options={locationOptions}
                                                placeholder="Select a location"
                                                className="mt-[6px] w-full "
                                            />
                                            {companyErrorState.companyLocationError && <p className='text-[14px] text-red-500'>{companyErrorState.companyLocationError}</p>}

                                        </div>
                                    }
                                    <div>
                                        <label className='2md:text-[16px] text-[14px]  font-medium'>{userType == "1" ? "I am*" : "We are*"}

                                            {
                                                userType == "1" &&
                                                errorState.IamError && <p className='text-[14px] font-normal text-red-500'>{errorState.IamError}</p>
                                            }

                                            {
                                                userType == "2" &&
                                                companyErrorState.companyIamError && <p className='text-[14px] font-normal text-red-500'>{companyErrorState.companyIamError}</p>
                                            }
                                        </label>
                                        <div className='grid grid-cols-4 2sm:gap-[24px] sm:gap-[16px] gap-[14px] justify-between pt-[10px]'>
                                            {
                                                userType == "1" &&
                                                // {/* Radio Btn 1 */}
                                                <div className={`text-center cursor-pointer 2md:rounded-[16px] rounded-[10px] sm:pt-[10px] pt-[6px] ${selectedOption === 'an Expert' ? 'bg-[#EAF4FC]' : ''}`}>
                                                    <label>
                                                        <input
                                                            type='radio'
                                                            name='registrationType'
                                                            value='an Expert'
                                                            checked={selectedOption === 'an Expert'}
                                                            onChange={UserHandleOptionChange}
                                                            style={{ display: 'none' }}
                                                        />
                                                        <div className='mx-auto sm:w-fit w-[60px] cursor-pointer flex-center' dangerouslySetInnerHTML={{ __html: icons.AnExpert }} />
                                                        <span className={` text-[14px] leading-[110%] font-medium cursor-pointer sm:pt-[10px] pt-[6px]`}>an Expert</span>
                                                    </label>
                                                </div>

                                            }
                                            {/* Radio Btn 2 */}
                                            <div className={`text-center cursor-pointer 2md:rounded-[16px] rounded-[10px] sm:pt-[10px] pt-[6px] ${selectedOption === 'a Solution Seeker' ? 'bg-[#EAF4FC]' : ''} `}>
                                                <label>
                                                    <input
                                                        type='radio'
                                                        name='registrationType'
                                                        value='a Solution Seeker'
                                                        checked={selectedOption === 'a Solution Seeker'}
                                                        onChange={UserHandleOptionChange}
                                                        style={{ display: 'none' }}
                                                    />
                                                    <div className='mx-auto sm:w-fit w-[60px] cursor-pointer flex-center' dangerouslySetInnerHTML={{ __html: icons.ASolutionSeeker }} />
                                                    <span className={` text-[14px] leading-[110%] font-medium cursor-pointer sm:pt-[10px] pt-[6px]`}>a Solution Seeker</span>
                                                </label>
                                            </div>
                                            {/* Radio Btn 3 */}
                                            <div className={`text-center cursor-pointer 2md:rounded-[16px] rounded-[10px] sm:pt-[10px] pt-[6px] ${selectedOption === 'a Solution Provider' ? 'bg-[#EAF4FC]' : ''} `}>
                                                <label>
                                                    <input
                                                        type='radio'
                                                        name='registrationType'
                                                        value='a Solution Provider'
                                                        checked={selectedOption === 'a Solution Provider'}
                                                        onChange={UserHandleOptionChange}
                                                        style={{ display: 'none' }}
                                                    />
                                                    <div className='mx-auto sm:w-fit w-[60px] cursor-pointer flex-center' dangerouslySetInnerHTML={{ __html: icons.ASolutionProvider }} />
                                                    <span className={` text-[14px] leading-[110%] font-medium cursor-pointer sm:pt-[10px] pt-[6px]`}>a Solution Provider</span>
                                                </label>
                                            </div>
                                            {/* Radio Btn 4 */}
                                            <div className={`text-center cursor-pointer 2md:rounded-[16px] rounded-[10px] sm:pt-[10px] pt-[6px] ${selectedOption === 'Other' ? 'bg-[#EAF4FC]' : ''}`}>
                                                <label>
                                                    <input
                                                        type='radio'
                                                        name='registrationType'
                                                        value='Other'
                                                        checked={selectedOption === 'Other'}
                                                        onChange={UserHandleOptionChange}
                                                        style={{ display: 'none' }}
                                                    />
                                                    <div className='mx-auto sm:w-fit w-[60px] cursor-pointer flex-center' dangerouslySetInnerHTML={{ __html: icons.Other }} />
                                                    <span className={` text-[14px] leading-[110%] font-medium cursor-pointer sm:pt-[10px] pt-[6px]`}>Other</span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    {selectedOption === 'Other' &&
                                        <div>
                                            <CustomInput label="Please specify*" type="text" placeholder={`Please specify ${userType == "1" ? "" : ""}`} value={otherValue} onChange={(e) => setOtherValue(e.target.value)} />
                                        </div>
                                    }
                                </div>
                                <div className='mt-[16px]'>
                                    <Bluebtn onClick={() => handleNext(user?.roleId)} className={`lg:p-[18px] p-[14px] w-full 2sm:text-[16px] text-[14px] font-medium leading-[100%]  text-white`} Label={`Next`} />
                                </div>
                            </div> :
                            <div className='space-y-[16px] mt-[30px] w-full'>
                                {
                                    userType == 1 ?
                                        <div className='grid 2md:grid-cols-2  gap-[16px]'>
                                            <div>
                                                <CustomSelect
                                                    label="Age*"
                                                    onChange={(selected) => OptionshandleChange(selected, "age")}
                                                    options={ageOptions}
                                                    placeholder="Select "
                                                    className="mt-[6px]  "
                                                />
                                                {errorState.ageError && <p className='text-[14px] text-red-500'>{errorState.ageError}</p>}
                                            </div>
                                            <div>
                                                <CustomSelect
                                                    label="Gender*"
                                                    onChange={(selected) => OptionshandleChange(selected, "gender")}
                                                    options={genderOptions}
                                                    placeholder="Select"
                                                    className="mt-[6px] "
                                                />
                                                {errorState.genderError && <p className='text-[14px] text-red-500'>{errorState.genderError}</p>}
                                            </div>
                                        </div> :
                                        <></>
                                }
                                {
                                    userType == 1 &&
                                    <div>
                                        <CustomSelect
                                            label="Level of Education*"
                                            onChange={(selected) => OptionshandleChange(selected, "educationLevel")}
                                            options={educationLevelOptions}
                                            placeholder="Select"
                                            className="mt-[6px] w-full "
                                        />
                                        {errorState.educationLevelError && <p className='text-[14px] text-red-500'>{errorState.educationLevelError}</p>}
                                    </div>
                                }

                                {
                                    userType == 1 &&
                                    optionsOtherValue.otherEducationLevel &&
                                    <div>
                                        <CustomInput label="Please specify education*" type="text" placeholder="Please specify education*" defultValue={otherAboutUserData.educationLevel}
                                            onChange={(e) => setOtherAboutUserData(prev => ({ ...prev, educationLevel: e.target.value }))}
                                        />
                                    </div>
                                }

                                <div>
                                    <CustomSelect
                                        label="Industry*"
                                        onChange={(selected) => {
                                            userType == 1 ?
                                                OptionshandleChange(selected, "industry")
                                                :
                                                OptionCompanyshandleChange(selected, "companyIndustry")
                                        }

                                        }
                                        options={IndustryOptions}
                                        isMulti={true}
                                        placeholder="Select one or more"
                                        className="mt-[6px] w-full "
                                    />
                                    {errorState.industryError && <p className='text-[14px] text-red-500'>{errorState.industryError}</p>}
                                    {companyErrorState.companyIndustryError && <p className='text-[14px] text-red-500'>{companyErrorState.companyIndustryError}</p>}
                                </div>
                                {
                                    optionsOtherValue.otherIndustry || aboutCompanyData?.companyIndustry?.includes("Other, please specify") && (
                                        <div>
                                            <CustomInput
                                                label="Please specify industry*"
                                                type="text"
                                                placeholder="Please specify industry*"
                                                defaultValue={otherAboutUserData.industry}
                                                onChange={(e) => {
                                                    setOtherAboutUserData(prev => {
                                                        return { ...prev, industry: e.target.value };
                                                    });
                                                }}
                                            />
                                        </div>
                                    )
                                }
                                <div>
                                    <CustomSelect
                                        label="Areas of Interest*"
                                        onChange={(selected) =>

                                            userType == 1 ?
                                                OptionshandleChange(selected, "interest")
                                                :

                                                OptionCompanyshandleChange(selected, "companyInterest")

                                        }
                                        options={interestOptions}
                                        isMulti={true}
                                        placeholder="Select one or more"
                                        className="mt-[6px] w-full "
                                    />
                                    {errorState.interestError && <p className='text-[14px] text-red-500'>{errorState.interestError}</p>}
                                    {companyErrorState.companyInterestError && <p className='text-[14px] text-red-500'>{companyErrorState.companyInterestError}</p>}
                                </div>
                                {
                                    optionsOtherValue.otherInterest || aboutCompanyData?.companyInterest?.includes("Other, please specify") && (
                                        <div>
                                            <CustomInput
                                                label="Please specify interest*"
                                                type="text"
                                                placeholder="Please specify interest*"
                                                defaultValue={otherAboutUserData.interest} // corrected from defultValue to defaultValue
                                                onChange={(e) => {
                                                    setOtherAboutUserData(prev => {
                                                        return { ...prev, interest: e.target.value };
                                                    });
                                                }}
                                            />
                                        </div>
                                    )
                                }
                                <div className=''>
                                    <Bluebtn onClick={signupLoading ? null : userType == 2 ? SaveCompanyData : SaveUserData} className={`p-[18px] w-full text-[16px] font-medium leading-[100%] ${signupLoading ? "bg-[#B7BCCA]" : ""}  text-white`} Label={`${signupLoading ? 'loading...' : 'Next'}`} />
                                </div>
                            </div>
                        }
                    </div>
                </div>
                <div className='2lg:pl-[50px] lg:pl-[40px] 2md:pl-[30px] text-center 2md:pt-0 pt-[16px]'>
                    {!showMoreAbout ?

                        (images.About1 ?
                            <div className='2md:w-[629px]  w-full h-auto 2md:h-[629px] 2sm:h-[629px]  flex-center 2md:rounded-tr-[24px] rounded-tr-[0px] md:rounded-br-[24px]   2sm:rounded-br-[16px] rounded-br-[14px] 2md:rounded-bl-[0px] md:rounded-bl-[24px] 2sm:rounded-bl-[16px] rounded-bl-[14px] overflow-hidden mx-auto'>
                                <img src={images.About1} alt="" className='w-full h-full object-cover' />
                            </div> :
                            <div className='mt-[16px] 2md:w-[400px] 2sm:w-[400px] w-full h-auto 2md:h-[400px] 2sm:h-[400px]   bg-[#C8E7F5] mx-auto'></div>)
                        : (images.About2 ?
                            <div className='2md:w-[629px]  w-full h-auto 2md:h-[629px] 2sm:h-[629px]  flex-center 2md:rounded-tr-[24px] rounded-tr-[0px] md:rounded-br-[24px]   2sm:rounded-br-[16px] rounded-br-[14px] 2md:rounded-bl-[0px] md:rounded-bl-[24px] 2sm:rounded-bl-[16px] rounded-bl-[14px] overflow-hidden mx-auto'>
                                <img src={images.About2} alt="" className='w-full h-full object-cover' />
                            </div> :
                            <div className='mt-[16px] 2md:w-[400px] 2sm:w-[400px] w-full h-auto 2md:h-[400px] 2sm:h-[400px]   bg-[#C8E7F5] mx-auto'></div>)
                    }
                    {/* {!showMoreAbout ?
                        <p className=' 2lg:text-[18px] lg:text-[16px] 2md:text-[14px] text-[12px] leading-[140%] pt-[8px] text-[#6D7486]'>
                            Create a new community to get a conversation going on a topic Central to Climate Change; ask questions or define projects, initiating communication for collaborative problem-solving
                        </p> :
                        <p className=' 2lg:text-[18px] lg:text-[16px] 2md:text-[14px] text-[12px] leading-[140%] pt-[8px] text-[#6D7486]'>Post personalized Professional or Company profiles, showcasing your expertise or service and product offerings.</p>
                    } */}
                </div>
            </div>
        </div>
    )
}

export default Step3;
