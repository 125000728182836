import { useSelector } from 'react-redux'; // Importing useSelector hook
import { icons } from '../../../helpers/images';
import PageLoader from '../../Common/PageLoader';
import { Link } from 'react-router-dom';


function AuthLayout({ children }) {
    const isLoading = useSelector((state) => state.loading.isLoading);
    return (
        <>
            {/* <PageLoader /> */}
            {isLoading && <PageLoader />}
            <div className={`relative bg-[#A7D3F6] w-[100vw] h-[100vh] hide-scrollbar flex justify-center items-start overflow-hidden ${isLoading ? 'hidden' : ''}`}>

                <div className='bg-[#fff] blur-[282px] opacity-[0.7] absolute top-0 left-[20%] w-[30%] h-[65%] rounded-full overflow-hidden'></div>
                <div className='bg-[#fff] blur-[282px] opacity-[0.7] absolute bottom-0 left-[40%] w-[30%] h-[65%] rounded-full overflow-hidden'></div>

                <div className='overflow-auto h-[100vh]  hide-scrollbar'>
                    <div className='2md:pt-[100px] md:pt-[80px] 2sm:pt-[60px] pt-[40px] relative z-10 2md:m-0 2sm:m-[30px] m-[20px]'>
                        <Link to="/home">
                            <div className='flex-center' dangerouslySetInnerHTML={{ __html: icons.logo }} />
                        </Link>
                        <div className='w-full 2md:mt-[50px] md:mt-[40px] 2sm:mt-[30px] mt-[20px]  bg-white 2md:rounded-[24px] md:rounded-[22px] 2sm:rounded-[16px] rounded-[14px] mb-[100px] '>
                            {children}
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default AuthLayout