// firebaseMessaging.js

import { getToken, messaging } from "../../../utils/firebase";

const requestPermissionAndGetToken = async () => {
  try {
    const currentPermission = Notification.permission;

    if (currentPermission === 'granted') {
      const token = await getToken(messaging, { vapidKey: 'BG0CgBbhIHLLeXAgpq2yNJDynk2dMH-uXE4UaP0AfQs76fyokpjcuX8PUEpQ4q_hN7-6KYM9mZ5-PvsQFse_B2E' }); 
      return token;
    } else if (currentPermission !== 'denied') {
      const permission = await Notification.requestPermission();

      if (permission === 'granted') {
        const token = await getToken(messaging, { vapidKey: 'BG0CgBbhIHLLeXAgpq2yNJDynk2dMH-uXE4UaP0AfQs76fyokpjcuX8PUEpQ4q_hN7-6KYM9mZ5-PvsQFse_B2E' });
        return token;
      }
    }
    console.log('Notification permission denied.');
    return null;
  } catch (error) {
    console.error('Error getting device token:', error);
    return null;
  }
};

export { requestPermissionAndGetToken };