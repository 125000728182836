import { initializeApp } from 'firebase/app';
import { getMessaging, getToken } from 'firebase/messaging';

const firebaseConfig = {
  apiKey: "AIzaSyCGpd6E_20LYPD4k4-PLST-Rh65e3GjH1c",
  authDomain: "get2net0-a3652.firebaseapp.com",
  projectId: "get2net0-a3652",
  storageBucket: "get2net0-a3652.appspot.com",
  messagingSenderId: "88958136235",
  appId: "1:88958136235:web:0641593437961a5c86acee",
  measurementId: "G-XSDLBVD962"
};

const firebase = initializeApp(firebaseConfig);
const messaging = getMessaging(firebase);

export { messaging, getToken, firebase };
