// SocketContext.js
import Cookies from "js-cookie";
import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';
import { io } from 'socket.io-client';
import autoInstance from '../../utils/axios';

const SocketContext = createContext();

export const useSocket = () => {
  return useContext(SocketContext);
};

export const SocketProvider = ({ children }) => {
  const [socket, setSocket] = useState(null);
  const [message, setMessage] = useState([]);

  useEffect(() => {
    const token = Cookies.get('g2n0Auth')
    const newSocket = io(process.env.REACT_APP_SOCKET_URL, {
      extraHeaders: {
        Authorization: `Bearer ${token}`
      }

    });
    newSocket.on('connect', () => {
    });
    newSocket.on('message-received', (data) => {
      setMessage((prev) => [...prev, data])
    });

    setSocket(newSocket);

    return () => {
      newSocket.disconnect();
    };
  }, []);

  const sendMessage = (payload) => {
    if (socket) {
      socket.emit('send-message', payload, (data) => {
        // Handle acknowledgment from the server

        if (data?.error) {
          alert(data?.error);
        }
        if (data?._id) {
          setMessage((prev) => [...prev, data])
        }
      });
    }
  };

  const onMessageSeen = (data) => {
    if (socket) {
      const payload = {
        id: data._id,
        fromUserId: data.fromUserId?._id
      }; 
      socket.emit('message-seen', payload, (data) => {
        // Handle acknowledgment from the server 
      });
    }
  };


  const getMessageListByConversationId = useCallback(async (conversationId) => {
    const response = await autoInstance.get(`/messages/conversation/message-list/${conversationId}`);
    const sources = response.data;
    setMessage(sources?.data ?? [])
  }, [])


  const onFileUpload = useCallback(async (selectedFile) => {
    try {
      const formData = new FormData();
      formData.append('file', selectedFile)
      const response = await autoInstance.post(`/messages/upload-file`, formData);
      const sources = response.data;
      return sources;
    } catch (error) {
      alert(error?.response?.data?.message);
      return null;
    }
  }, [])

  const onDeleteAllMessages = useCallback((conversationId) => {
    if (socket) {
      socket.emit('delete-all-messages', { conversationId }, (data) => {
        // Handle acknowledgment from the server
        setMessage(data)
      });
    }
  }, [socket])

  const onBlockUser = useCallback((userId, callback) => {
    if (socket) {
      socket.emit('block-user', { userId }, (data) => {
        // Handle acknowledgment from the server
        if (data?.error) {
          alert(data?.error)
          callback(null)
        } else {
          callback(data)
        }
      });
    } else {
      callback(null)
    }
  }, [socket])

  const onUnBlockUser = useCallback((userId, callback) => {
    if (socket) {
      socket.emit('unblock-user', { userId }, (data) => {
        callback(data)
      });
    } else {
      callback(null)
    }
  }, [socket])

  return (
    <SocketContext.Provider value={{ message, sendMessage, onMessageSeen, getMessageListByConversationId, onDeleteAllMessages, onBlockUser, onUnBlockUser, onFileUpload, socket }}>
      {children}
    </SocketContext.Provider>
  );
};
