import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import autoInstance from '../../../utils/axios';
import PageLoader from '../../Common/PageLoader';
import AuthLayout from '../AuthLayout';
import Step3 from './Step3';
import Step4 from './Step4';

function ProfileDetails() {
    const [step, setStep] = useState(3);
    const [isLoading, setIsLoading] = useState(true)
    const navigate = useNavigate();
    const goToNextStep = () => {
        setStep(prevStep => {
            const nextStep = prevStep + 1;
            return nextStep;
        });
    };
    useEffect(() => {
        getProfile()
    }, []);
    const getProfile = async () => {
        try {
            const response = await autoInstance.get('/user')
            const source = response.data
            if (source.data.isProfileCompleted === true) {
                navigate('/')
            } else {
                setIsLoading(false)
            }
        } catch (error) {
            console.error(error)
        }
    }
    if (isLoading) return <PageLoader />
    return (
        <AuthLayout>
            {
                step === 3 ? <Step3 onNextStep={goToNextStep} /> : <Step4 />
            }
        </AuthLayout>
    )
}

export default ProfileDetails