import React from 'react'
import CommunitieCollaborationDetails from '../../layouts/CommunitieCollaborationDetails'

function CommunitiesDetails() {
    return (
        <CommunitieCollaborationDetails
            pageTitle={"Community Details"} 
        />
    )
}

export default CommunitiesDetails