
import ClimateVaultRoutes from "./ClimateVaultRoutes";
import CollaborationRoutes from "./CollaborationsRoutes";
import CommunitiesRoutes from "./CommunitiesRoutes";
import HomePageRoutes from "./HomePageRoutes";
import SettingRoutes from "./SettingRoutes";
import UserRoutes from "./UserRoutes";

const privateRoutes = [
    HomePageRoutes,
    UserRoutes,
    SettingRoutes,
    ClimateVaultRoutes,
    CommunitiesRoutes,
    CollaborationRoutes
]


export default privateRoutes;