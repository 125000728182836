import React from 'react'
import { Link, NavLink } from 'react-router-dom'
import { icons } from '../../helpers/images'

function AdminSidebar() {
    const Sidebaritems = [
        {
            path: "/admin/dashboard",
            icon: `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path classname="group-hover:stroke-[#2A8DDE]" d="M3 9L12 2L21 9V20C21 20.5304 20.7893 21.0391 20.4142 21.4142C20.0391 21.7893 19.5304 22 19 22H5C4.46957 22 3.96086 21.7893 3.58579 21.4142C3.21071 21.0391 3 20.5304 3 20V9Z" stroke="#6D7486" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <path classname="group-hover:stroke-[#2A8DDE]" d="M9 22V12H15V22" stroke="#6D7486" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>`,
            name: "Dashboard",
        },
        // {
        //     path: "/admin/reports",
        //     icon: `
        //     <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        //     <path classname="group-hover:stroke-[#2A8DDE]"  d="M21.21 15.89C20.5739 17.3945 19.5788 18.7202 18.3119 19.7513C17.045 20.7824 15.5448 21.4874 13.9425 21.8048C12.3401 22.1221 10.6845 22.0421 9.12018 21.5718C7.55591 21.1014 6.13066 20.2551 4.96906 19.1066C3.80745 17.9582 2.94485 16.5427 2.45667 14.9839C1.96849 13.4251 1.8696 11.7705 2.16863 10.1646C2.46767 8.55877 3.15553 7.05061 4.17208 5.77202C5.18863 4.49342 6.50292 3.4833 8.00004 2.82999" stroke="#6D7486" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        //     <path classname="group-hover:stroke-[#2A8DDE]"  d="M22 12C22 10.6868 21.7413 9.38642 21.2388 8.17317C20.7362 6.95991 19.9997 5.85752 19.0711 4.92893C18.1425 4.00035 17.0401 3.26375 15.8268 2.7612C14.6136 2.25866 13.3132 2 12 2V12H22Z" stroke="#6D7486" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        //     </svg>
        //     `,
        //     name: "Reports",
        // },
        {
            path: "/admin/users",
            icon: `<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.7" stroke="#6D7486" class="w-6 h-6">
            <path classname="group-hover:stroke-[#2A8DDE]" stroke-linecap="round" stroke-linejoin="round" d="M15 19.128a9.38 9.38 0 0 0 2.625.372 9.337 9.337 0 0 0 4.121-.952 4.125 4.125 0 0 0-7.533-2.493M15 19.128v-.003c0-1.113-.285-2.16-.786-3.07M15 19.128v.106A12.318 12.318 0 0 1 8.624 21c-2.331 0-4.512-.645-6.374-1.766l-.001-.109a6.375 6.375 0 0 1 11.964-3.07M12 6.375a3.375 3.375 0 1 1-6.75 0 3.375 3.375 0 0 1 6.75 0Zm8.25 2.25a2.625 2.625 0 1 1-5.25 0 2.625 2.625 0 0 1 5.25 0Z" />
          </svg>
          `,
            name: "Users",
        },
        {
            path: "/admin/communities",
            icon: `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path classname="group-hover:stroke-[#2A8DDE]" d="M16 21V19C16 17.9391 15.5786 16.9217 14.8284 16.1716C14.0783 15.4214 13.0609 15 12 15H6C4.93913 15 3.92172 15.4214 3.17157 16.1716C2.42143 16.9217 2 17.9391 2 19V21" stroke="#6D7486" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <path classname="group-hover:stroke-[#2A8DDE]" d="M9 11C11.2091 11 13 9.20914 13 7C13 4.79086 11.2091 3 9 3C6.79086 3 5 4.79086 5 7C5 9.20914 6.79086 11 9 11Z" stroke="#6D7486" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <path classname="group-hover:stroke-[#2A8DDE]" d="M22 20.9999V18.9999C21.9993 18.1136 21.7044 17.2527 21.1614 16.5522C20.6184 15.8517 19.8581 15.3515 19 15.1299M16 3.12988C16.8604 3.35018 17.623 3.85058 18.1676 4.55219C18.7122 5.2538 19.0078 6.11671 19.0078 7.00488C19.0078 7.89305 18.7122 8.75596 18.1676 9.45757C17.623 10.1592 16.8604 10.6596 16 10.8799" stroke="#6D7486" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>`,
            name: "Communities"
        },
        {
            path: "/admin/collaborations",
            icon: `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path classname="group-hover:stroke-[#2A8DDE]" d="M11 17L13 19C13.197 19.197 13.4308 19.3532 13.6882 19.4598C13.9456 19.5665 14.2214 19.6213 14.5 19.6213C14.7786 19.6213 15.0544 19.5665 15.3118 19.4598C15.5692 19.3532 15.803 19.197 16 19C16.197 18.803 16.3532 18.5692 16.4598 18.3118C16.5665 18.0544 16.6213 17.7786 16.6213 17.5C16.6213 17.2214 16.5665 16.9456 16.4598 16.6882C16.3532 16.4308 16.197 16.197 16 16" stroke="#6D7486" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <path classname="group-hover:stroke-[#2A8DDE]" d="M14 14L16.5 16.5C16.8978 16.8978 17.4374 17.1213 18 17.1213C18.5626 17.1213 19.1022 16.8978 19.5 16.5C19.8978 16.1022 20.1213 15.5626 20.1213 15C20.1213 14.4374 19.8978 13.8978 19.5 13.5L15.62 9.62002C15.0575 9.05821 14.295 8.74265 13.5 8.74265C12.705 8.74265 11.9425 9.05821 11.38 9.62002L10.5 10.5C10.1022 10.8978 9.56259 11.1213 8.99998 11.1213C8.43737 11.1213 7.89781 10.8978 7.49998 10.5C7.10216 10.1022 6.87866 9.56262 6.87866 9.00002C6.87866 8.43741 7.10216 7.89784 7.49998 7.50002L10.31 4.69002C11.2222 3.78016 12.4119 3.20057 13.6906 3.04299C14.9694 2.88541 16.2641 3.15885 17.37 3.82002L17.84 4.10002C18.2658 4.357 18.772 4.44613 19.26 4.35002L21 4.00002" stroke="#6D7486" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <path classname="group-hover:stroke-[#2A8DDE]" d="M21 3L22 14H20M3 3L2 14L8.5 20.5C8.89782 20.8978 9.43739 21.1213 10 21.1213C10.5626 21.1213 11.1022 20.8978 11.5 20.5C11.8978 20.1022 12.1213 19.5626 12.1213 19C12.1213 18.4374 11.8978 17.8978 11.5 17.5M3 4H11" stroke="#6D7486" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>`,
            name: "Collaborations"
        },
        {
            path: "/admin/climatevault",
            icon: `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path classname="group-hover:stroke-[#2A8DDE]" d="M11 20C9.24404 20.0053 7.55023 19.3505 6.2545 18.1654C4.95876 16.9803 4.15575 15.3515 4.00471 13.6021C3.85368 11.8527 4.36567 10.1104 5.43913 8.72074C6.51259 7.33112 8.06911 6.3957 9.79998 6.1C15.5 5 17 4.48 19 2C20 4 21 6.18 21 10C21 15.5 16.22 20 11 20Z" stroke="#6D7486" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <path classname="group-hover:stroke-[#2A8DDE]" d="M2 21C2 18 3.85 15.64 7.08 15C9.5 14.52 12 13 13 12" stroke="#6D7486" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>`,
            name: "Climate vault"
        },
        // {
        //     path: "/admin/packages",
        //     icon: `<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.7" stroke="#6D7486" class="w-6 h-6">
        //     <path classname="group-hover:stroke-[#2A8DDE]" stroke-linecap="round" stroke-linejoin="round" d="M2.25 8.25h19.5M2.25 9h19.5m-16.5 5.25h6m-6 2.25h3m-3.75 3h15a2.25 2.25 0 0 0 2.25-2.25V6.75A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25v10.5A2.25 2.25 0 0 0 4.5 19.5Z" />
        //   </svg>
        //   `,
        //     name: "Packages"
        // },
        // {
        //     path: "/admin/feed",
        //     icon: `<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.7" stroke="#6D7486" class="w-6 h-6">
        //     <path classname="group-hover:stroke-[#2A8DDE]" stroke-linecap="round" stroke-linejoin="round" d="m2.25 15.75 5.159-5.159a2.25 2.25 0 0 1 3.182 0l5.159 5.159m-1.5-1.5 1.409-1.409a2.25 2.25 0 0 1 3.182 0l2.909 2.909m-18 3.75h16.5a1.5 1.5 0 0 0 1.5-1.5V6a1.5 1.5 0 0 0-1.5-1.5H3.75A1.5 1.5 0 0 0 2.25 6v12a1.5 1.5 0 0 0 1.5 1.5Zm10.5-11.25h.008v.008h-.008V8.25Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Z" />
        //   </svg>`,
        //     name: "Feeds"
        // }
    ]
    return (
        <div className='h-full bg-white 2md:w-[250px] w-fit border-r border-[#EFF3F4]'>
            <Link to="/admin/dashboard" className='h-[80px] 2md:flex hidden items-center justify-center '>
                <div className='flex-center' dangerouslySetInnerHTML={{ __html: icons.logo }} />
            </Link>
            <div className='space-y-[16px] sidemenu'>
                {
                    Sidebaritems.map((item, index) => (
                        <NavLink to={item.path} key={index} className=' flex items-center justify-start gap-[16px] 2md:py-[16px] py-[12px] 2md:px-[20px] px-[12px] mx-[10px] rounded-[8px] hover:bg-[#EAF4FC] group'>
                            <div className='!group-hover:text-[#2A8DDE]' dangerouslySetInnerHTML={{ __html: item.icon }} />
                            <p className='2md:block hidden group-hover:text-[#0C1835] font-medium text-[#6D7486]'>{item.name}</p>
                        </NavLink>
                    ))
                }
            </div>
        </div>
    )
}

export default AdminSidebar