import Aos from 'aos';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import banner from '../../assets/images/campaignImage.jpg';
import logoFull from '../../assets/images/logo_full.jpg';
import Bluebtn from '../../Components/Common/Btns/Bluebtn';
import CustomModal from '../../Components/Common/CustomModel';
import CustomInput from '../../Components/Common/Inputs/CustomInput';
import autoInstance from '../../utils/axios';

function Cic() {
    const [userData, setUserData] = useState({
        name: '',
        email: '',
    });

    const [errors, setErrors] = useState({ name: '', email: '' });
    const [loading, setLoading] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [countdown, setCountdown] = useState(7);
    const [checkboxChecked, setCheckboxChecked] = useState(false);


    const navigate = useNavigate(); // Initialize useNavigate

    useEffect(() => {
        Aos.init({
            duration: 800,
            easing: 'ease-in-out',
            once: true,
        });
    }, []);

    useEffect(() => {
        if (submitted) {
            // Start countdown when form is submitted
            const timerInterval = setInterval(() => {
                setCountdown((prevCountdown) => {
                    if (prevCountdown === 1) {
                        clearInterval(timerInterval);
                        setSubmitted(false); // Close the modal
                        navigate('/'); // Redirect to home page
                    }
                    return prevCountdown - 1;
                });
            }, 1000);

            // Cleanup the interval on component unmount
            return () => clearInterval(timerInterval);
        }
    }, [submitted, navigate]);

    const validateForm = () => {
        let formIsValid = true;
        const newErrors = { name: '', email: '' };

        if (!userData.name.trim()) {
            newErrors.name = 'Name is required';
            formIsValid = false;
        }
        if (userData.email?.trim() === '') {
            newErrors.email = 'Email is required';
            formIsValid = false;
        } else if (!/\S+@\S+\.\S+/.test(userData.email)) {
            newErrors.email = 'Invalid email format';
            formIsValid = false;
        }

        // Check if checkbox is checked
        if (!checkboxChecked) {
            formIsValid = false;
            newErrors.checkbox = 'You must agree to the terms to proceed';
        }

        setErrors(newErrors);
        return formIsValid;
    };

    const handleSubmit = async () => {
        if (!validateForm()) {
            return;
        }

        try {
            setLoading(true);
            const response = await autoInstance.post('/cic', {
                name: userData.name,
                email: userData.email,
            });

            // Handle success response
            console.log('Response:', response.data);
            setLoading(false);
            setSubmitted(true); // Show the Thank You modal
            setCountdown(7); // Reset the countdown
            setUserData({ name: '', email: '' }); // Optionally reset form
        } catch (error) {
            console.error('Error:', error);
            setLoading(false);
            // Optionally, handle error by setting error states or showing a notification
        }
    };

    const handleNameChange = (event) => {
        setErrors((prevErrors) => ({ ...prevErrors, name: '' }));
        const { value } = event.target;
        setUserData({ ...userData, name: value });

        if (!value.trim()) {
            setErrors((prevErrors) => ({ ...prevErrors, name: 'Name is required' }));
        }
    };

    const handleEmailChange = (event) => {
        setErrors((prevErrors) => ({ ...prevErrors, email: '' }));
        const { value } = event.target;
        setUserData({ ...userData, email: value });

        if (value && !/\S+@\S+\.\S+/.test(value)) {
            setErrors((prevErrors) => ({ ...prevErrors, email: 'Invalid email format' }));
        }
    };

    const handleCheckboxChange = (e) => {
        setCheckboxChecked(e.target.checked);
        setErrors({ ...errors, checkbox: '' }); // Clear checkbox error when it changes
    };


    return (
        <div className='overflow-y-auto overflow-x-hidden hide-scrollbar h-screen w-screen flex lg:items-center justify-center bg-[#F9F9F9]'>
            <div
                className='xl:max-w-6xl 3lg:max-w-5xl max-w-4xl w-full mx-auto 2md:px-0 px-5 2lg:py-8 2md:py-8 md:py-7 2sm:py-6 py-5 mt-20'
            >
                <div className='mx-auto text-base leading-140% font-medium text-[#4a3f51] space-y-4'>
                    <div className='lg:flex gap-[32px] items-end justify-center'>
                        <div aria-hidden="true"
                            data-aos="fade-right"
                            data-aos-delay="300" className='lg:max-w-[450px] sm:max-w-[400px] lg:mx-0 mx-auto w-full  2lg:h-[620px] h-auto  overflow-hidden rounded-[12px]'>
                            <img className='w-full h-full object-cover' src={banner} alt='Banner' />
                        </div>
                        <div className='lg:max-w-[480px]  w-full 2md:pt-[0px] pt-[20px]'>
                            <div>
                                <div aria-hidden="true"
                                    data-aos="fade-left"
                                    data-aos-delay="300" className='2lg:text-[22px] lg:text-[20px] 2md:text-[18px] md:text-[16px] 2sm:text-[14px]  leading-[140%] font-bold pb-2'>
                                    <p>Ready for a sustainable future?</p>
                                </div>
                                <div aria-hidden="true"
                                    data-aos="fade-left"
                                    data-aos-delay="500" className='  2lg:text-[22px] lg:text-[20px] 2md:text-[18px] md:text-[16px] 2sm:text-[14px]  leading-[140%] font-medium space-y-2'>
                                    <p>
                                        Developed in partnership with the Circular Innovation Council, this guide provides simple, practical steps to reduce waste and save money.
                                    </p>
                                    <p>
                                        Enter your details below to receive the guide.
                                    </p>
                                </div>
                                <div aria-hidden="true"
                                    data-aos="fade-left"
                                    data-aos-delay="500" className='flex gap-[12px] mt-[12px]'>
                                    <div className="checkboxes__row">
                                        <div className="checkboxes__item">
                                            <label className="checkbox style-c">
                                                <input
                                                    onChange={handleCheckboxChange}
                                                    name="offers"
                                                    id="offers"
                                                    type="checkbox"
                                                    checked={checkboxChecked} // Bind the state to checkbox
                                                />
                                                <div className="checkbox__checkmark"></div>
                                            </label>
                                        </div>
                                    </div>
                                    <label className='text-[14px] font-medium leading-[140%] flex-1' htmlFor="offers"> {/* Allow this part to take remaining space */}
                                        I consent to Get2net0 Inc. and Circular Innovation Council Inc. collecting and using my name and email address to send me relevant updates and communications. I understand my information will not be shared with third parties without my consent, and I can withdraw my consent at any time.
                                        {/* <span className="block mt-2"> */}
                                        {/* I understand my information will not be shared with third parties without my consent, and I can withdraw my consent at any time. */}
                                        {/* </span> */}
                                    </label>
                                </div>


                                {errors.checkbox && <p className='text-red-500 text-sm'>{errors.checkbox}</p>} {/* Display checkbox error */}


                            </div>
                            <div aria-hidden="true"
                                data-aos="fade-left"
                                data-aos-delay="700" className='space-y-4 mt-[15px]'>
                                <CustomInput
                                    label={false}
                                    type='text'
                                    value={userData.name} // Use 'value' instead of 'defaultValue'
                                    name='name'
                                    onChange={handleNameChange}
                                    placeholder='Name'
                                />
                                {errors.name && <p className='text-red-500 text-sm'>{errors.name}</p>}

                                <CustomInput
                                    label={false}
                                    type='email'
                                    name='email'
                                    onBlur={handleEmailChange}
                                    placeholder='johndoe@get2net0.com'
                                />
                                {errors.email && <p className='text-red-500 text-sm'>{errors.email}</p>}

                                <div className='mt-4'>
                                    <Bluebtn
                                        className="lg:p-4 p-3 w-full text-white font-medium"
                                        Label={loading ? 'Submitting...' : 'Get the free guide now'} // Show loading state
                                        onClick={() => {
                                            if (!loading) {
                                                handleSubmit(); // Only call handleSubmit if loading is false
                                            }
                                        }}
                                        disabled={loading}
                                    />
                                    <div className='hidden'>
                                        <img src={logoFull} alt="logo" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Thank You Modal */}
                <CustomModal showModal={submitted} setShowModal={setSubmitted} bodyClassName='rounded-2xl 2lg:w-[600px] w-[400] !max-w-[600px] overflow-hidden'>
                    <div className='space-y-4 2sm:p-[24px] xsm:p-[20px] p-[15px]'>
                        <div className='text-center'>
                            <p className=' text-lg'>
                                Check your inbox and spam folders for the free guide.
                            </p>
                            <div className='my-[10px]'>
                                <Bluebtn
                                    className={`py-2 px-3 mx-auto text-white font-medium`}
                                    Label={'Learn more about get2net0'}
                                    onClick={() => navigate('/')}
                                />
                            </div>
                            <p>You will be redirected to get2net0.com in  {countdown}...</p>
                        </div>
                    </div>
                </CustomModal>
            </div>
        </div>
    );
}

export default Cic;
